<template>
    
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :search="search"
                    :items="desserts"
                    class="elevation-1"
                    >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>{{title}}</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-dialog v-model="dialog" persistent max-width="500px" loading="dialogLoading">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark class="mb-2" v-on="on" v-on:click="add_edit='add';openadd()"><span style="color:#fff">{{$vuetify.lang.t('$vuetify.gatewayManagement.add')}}</span></v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline" v-if="add_edit ==='edit'">{{ $vuetify.lang.t('$vuetify.common.edit') }}</span>
                                        <span class="headline" v-else>{{ $vuetify.lang.t('$vuetify.common.add') }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-form
                                                ref="form"
                                                lazy-validation
                                                >
                                                <v-row>

                                                    <v-col cols="12" md="12">
                                                        <v-text-field 
                                                            v-model="smsgw_name" 
                                                            :label="$vuetify.lang.t('$vuetify.gatewayManagement.smsgw_name')" 
                                                            :rules="rules"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" md="4">
                                                        <v-select 
                                                            v-if="add_edit ==='edit'"
                                                            v-model="sdkval"
                                                            :items="sdkList"
                                                            item-value="ns" 
                                                            item-text="name" 
                                                            disabled
                                                            :label="$vuetify.lang.t('$vuetify.gatewayManagement.pickSdk')">
                                                        </v-select>
                                                        <v-select 
                                                            v-else
                                                            :items="sdkList"
                                                            item-value="ns" 
                                                            item-text="name" 
                                                            v-model="sdkval"
                                                            @change="changeSdk"
                                                            clearable
                                                            :rules="rules"
                                                            :label="$vuetify.lang.t('$vuetify.gatewayManagement.pickSdk')">
                                                        </v-select>
                                                    </v-col>

                                                    <v-col cols="12" md="4">
                                                        <v-select 
                                                            v-if="add_edit ==='edit'"
                                                            v-model="groupval"
                                                            :items="group"
                                                            item-value="id" 
                                                            item-text="name"
                                                            disabled
                                                            :label="$vuetify.lang.t('$vuetify.portalMac.pickjt')">
                                                        </v-select>
                                                        <v-select
                                                            v-else
                                                            :items="group"
                                                            item-value="id" 
                                                            item-text="name"
                                                            v-model="groupval"
                                                            clearable
                                                            :label="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                                            @change="changeGroup"
                                                        ></v-select>
                                                    </v-col>

                                                    <v-col cols="12" md="4">
                                                        <v-select 
                                                            v-if="add_edit ==='edit'"
                                                            v-model="shopval"
                                                            :items="shop"
                                                            item-value="id" 
                                                            item-text="name"
                                                            disabled
                                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_placename')">
                                                        </v-select>
                                                        <v-select
                                                            v-else
                                                            :items="shop"
                                                            item-value="id" 
                                                            item-text="name"
                                                            v-model="shopval"
                                                            clearable
                                                            @change="changeShop"
                                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                                        ></v-select>
                                                    </v-col>

                                                    <template v-for="(config,index) in configs">
                                                        <v-col v-if="config.type==='string'" :key="index" cols="12" sm="6" md="12">
                                                            <v-text-field 
                                                            v-model="config.val" 
                                                            :label="config.label" 
                                                            :rules="rules"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="config.type==='password'" :key="index" cols="12" sm="6" md="12">
                                                            <v-text-field 
                                                            v-model="config.val" 
                                                            type="password" 
                                                            :label="config.label" 
                                                            :rules="rules"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="config.type==='select'" :key="index" cols="12" sm="6" md="12">
                                                            <v-select
                                                                v-model="config.val"
                                                                :items="config.options"
                                                                item-value="val" 
                                                                item-text="name"
                                                                :rules="rules"
                                                                @change="changeChild"
                                                                :label="config.label"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col v-if="config.type==='textarea'" :key="index" cols="12" sm="6" md="12">
                                                            <v-textarea 
                                                            v-model="config.val" 
                                                            rows="2"
                                                            :label="config.label" 
                                                            :rules="rules"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </template>
                                                    <v-col cols="12" sm="6" md="12">
                                                        <v-alert v-if="configs.sdkdescribe" type="info" outlined>
                                                            {{configs.sdkdescribe}}
                                                        </v-alert>
                                                        <!-- sdkdescribe -->
                                                    </v-col>
                                            </v-row>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="add_edit ==='edit'" color="blue darken-1" text @click="editclose">{{ $vuetify.lang.t('$vuetify.common.cancelBtn') }}</v-btn>
                                    <v-btn v-else color="blue darken-1" text @click="addclose">{{ $vuetify.lang.t('$vuetify.common.cancelBtn') }}</v-btn>
                                    <v-btn v-if="add_edit ==='edit'" color="blue darken-1" text @click="editsave">{{ $vuetify.lang.t('$vuetify.common.saveBtn') }}</v-btn>
                                    <v-btn v-else color="blue darken-1" text @click="addsave">{{ $vuetify.lang.t('$vuetify.common.saveBtn') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                :label="$vuetify.lang.t('$vuetify.common.search')"
                                single-line
                                hide-details
                            ></v-text-field>
                            
                        </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <a class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="editItem(item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.editBtn')}}
                            </v-icon>
                        </a>
                        <a class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="deleteItem(item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
                            </v-icon>
                        </a>
                    </template>
                </v-data-table>

               
            </v-col>
        </v-row>

        <v-row>


            <v-dialog v-model="deldialog" width="70%">
                <v-card>
                    <v-card-title class="headline">
                        <v-icon large color="red" style="margin:10px 10px 10px 0">mdi-car-brake-alert</v-icon>
                        <span style="color:red;margin:0 20px 0 0 ">{{$vuetify.lang.t('$vuetify.common.confirmdel')}}?</span>
                        {{$vuetify.lang.t('$vuetify.common.del_gateway_warning')}}
                        
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="error" @click="handleDel">
                            <span style="color:#fff">{{$vuetify.lang.t('$vuetify.userManage.confirm')}}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>

</template>

<script>
import * as api from '../../api'
import { Notification  } from 'element-ui';
export default {
    data () {
      return {
            
            rules: [
                value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.common.input'),
                value => !!value || this.$vuetify.lang.t('$vuetify.common.input')
            ],
            
            //下拉数组
            sdkList: [],
            group: [],
            shop: [],

            smsgw_name: '',
            groupval: null,
            shopval: null,
            sdkval: null,
            sdkname: null,
            sdkdescribe: '',

            // //edit
            // editsdk: null,
            // editgroup: null,
            // editshop: null,

            configs:[],
            items: [],
            value: '',
            date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            menu: false,
            dialog: false,
            search: '',
            title: this.$vuetify.lang.t('$vuetify.gatewayManagement.tableName'),
            loading: false,
            range: [0, 10],
            data: [],
            
            headers: [
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.id'), value: 'id' },
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.name'), value: 'smsgw_name' },
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.sdk'), value: 'sdk' },
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.shopName'), value: 'shopName' },
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.groupName'), value: 'groupName' },
                { text: this.$vuetify.lang.t('$vuetify.gatewayManagement.config'), value: 'action' },
            ],
            desserts: [
            ],
            editedIndex: -1,

            dialogLoading:false,
            deldialog: false,
            add_edit: '',
            delId: '',
            
        
      }
    },

    computed: {
      formTitle () {
      },
    },

    mounted() {
        this.$nextTick(() => {
            this.sdkListInit()
            this.fetchGroupList()
            this.fetchTable()
        })
    },
    methods: {

        sdkListInit(){

            this.$http.post(api.default.sms.sdkList).then(resp => {
               
                this.sdkList = resp.data.list

            }).finally(resp => {
                
            })
        },

        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },

        reset(){

            this.smsgw_name = null
            this.sdkval = null
            this.groupval = null
            this.shopval = null
            this.configs = []
            this.$refs.form.resetValidation();

        },

        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
            
                this.group = resp.data

            }).finally(resp => {
                
            })
        },

        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                
            })
        },
        changeGroup(groupid){
            if (typeof groupid == "undefined") {
                this.groupval = null
                this.shop = []
            }else{
                this.fetchShopList(groupid)
                this.groupval = groupid
            }
        },
        changeShop(shopid){
            this.shopval = shopid
        },
        changeSdk(val){
            
            this.configs = []
            for (let index = 0; index < this.sdkList.length; index++) {
                if(this.sdkList[index]['ns'] == val){
                    this.configs = this.sdkList[index]['options']
                    this.sdkval = this.sdkList[index]['ns']
                    this.sdkname  = this.sdkList[index]['name']
                    this.sdkversion  = this.sdkList[index]['version']
                    this.configs.sdkdescribe = this.sdkList[index]['describe']
                }
            }
            this.fetchGroupList()
        },

        openadd(){
            this.sdkListInit()
        },

        editItem (item) {
            this.add_edit = 'edit'

            this.smsgw_name = item.smsgw_name
            this.sdkval = item.sdk
            this.groupval = item.groupID
            this.shopval = item.shopID

            this.sdkListInit()
            this.fetchShopList(item.groupID)
            this.configs = []
            for (let index = 0; index < this.sdkList.length; index++) {
               
                if(item.sdk == this.sdkList[index]['ns']){
                    const options = this.sdkList[index]['options']
                    
                    for(let key in options){
                        options[key].val = item.params[key]
                    }
                    this.configs = options
                    this.configs.id = item.id
                    this.configs.sdkns  = this.sdkList[index]['ns']
                    this.configs.sdkname  = this.sdkList[index]['name']
                    this.configs.sdkversion  = this.sdkList[index]['version']
                    this.configs.sdkdescribe  = this.sdkList[index]['describe']
                }
                
            }
            
            this.dialog = true
        },
        fetchTable(){
            this.loading = true
            const postData = {
                pagesize: 1000,
                pagenum: 1
            }
            this.$http.post(api.default.sms.gatewayList).then(resp => {

                this.desserts = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },

        deleteItem (item) {
            this.delId = item.id
            this.deldialog = true
        },
        handleDel(){
            this.$http.get(api.default.sms.gatewayDel +'?id='+ this.delId).then(resp => {
                if(!resp) return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.gatewayManagement.delGatewaySuccess')
                });
                this.fetchTable()
            }).finally(resp => {
                this.deldialog = false
            })
        },
        handleClose(){
            this.deldialog = false
            this.add_edit = ''
        },

        addclose () {
            this.dialog = false
            this.reset()
        },

        editclose () {
            this.dialog = false
            this.reset()
        },

        changeChild(item) {
        },
        addsave () {
            if (this.$refs.form.validate()) {

                const postData = {
                    shop_id: this.shopval,
                    group_id: this.groupval,
                    name: this.sdkname,
                    sdkns: this.sdkval,
                    smsgw_name: this.smsgw_name,
                    params: {},
                }
                /* 添加config值 */
                for (var key in this.configs) {
                    postData.params[key] = this.configs[key]["val"]
                }
                this.$http.post(api.default.sms.gatewayAdd, postData).then(resp => {
                    if(!resp) return
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.gatewayManagement.addGatewaySuccess')
                    });
                    this.fetchTable()
                }).finally(resp => {
                    this.reset()
                }) 
                this.dialog = false
            }
            
        },
        editsave () {
            if (this.$refs.form.validate()) {
                const postData = {
                    name: this.configs.sdkname,
                    id: this.configs.id,
                    smsgw_name: this.smsgw_name,
                    params: {}
                }
                /* 添加config值 */
                for (var key in this.configs) {
                    postData.params[key] = this.configs[key]["val"]
                }
                this.$http.post(api.default.sms.gatewayEdit, postData).then(resp => {
                    if(!resp) return
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.gatewayManagement.editGatewaySuccess')
                    });
                    this.fetchTable()
                }).finally(resp => {
                    this.reset()
                }) 
                this.dialog = false
                this.add_edit = ''
            }
            
        },

        busFunction(){
            
        },
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}

</style>