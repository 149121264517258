<template>
    
    <div class="syslog-layout">

        <log-search
            @handleSearch="handleSearch"
            :title ="title"
            :search="search"
            :logType="logType"
        ></log-search>

        <v-card>
            <v-tabs
                v-model="tab"
                @change="changeTab"
                background-color="primary"
                dark
            >
                <v-tab
                    v-for="item in tabItems"
                    :key="item.value"
                >
                    {{ item.label }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="item in tabItems"
                    :key="item.value"
                >
                    <v-card flat>
                        <log-data-table
                            @handleSearch="handleSearch"
                            :loading="loading"
                            :columns="columns"
                            :data   ="data"
                            :total  ="total"
                            :search ="search"
                            :logType="logType"
                        ></log-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import * as api from '../../api'
import LogSearch from './components/LogSearch.vue'
import LogDataTable from './components/LogDataTable.vue'

export default {

    components: {
        LogSearch,
        LogDataTable
    },

    data () {
      return {

            role: parseInt(window.sessionStorage.getItem('type')),

            tab: 0,

            logType: [
                {
                    label: 'ALL',
                    value: 1
                },{
                    label: 'DEBUG',
                    value: 2
                },{
                    label: 'INFO',
                    value: 3
                },{
                    label: 'WARN',
                    value: 4
                },{
                    label: 'ERROR',
                    value: 5
                },{
                    label: 'FATAL',
                    value: 6
                },{
                    label: 'OFF',
                    value: 7
                }
            ],

            tabItems: [
                {
                    value: 0,
                    label: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.user'),
                    action: "operate"
                },{
                    value: 1,
                    label: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.aaa'),
                    action: "aaa"
                },{
                    value: 2,
                    label: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.device'),
                    action: "audit"
                },{
                    value: 3,
                    label: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.netsafe'),
                    action: "gateway"
                },{
                    value: 4,
                    label: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.sms'),
                    action: "sms"
                }
            ],

            title: this.$vuetify.lang.t('$vuetify.systemManage.syslog.title.user'),
            dataUrl: api.default.system.opLogListUrl,

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.syslog.table.type.text'),
                    sortable: false,
                    value: 'level'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.syslog.table.content'),
                    sortable: false,
                    value: 'msg'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.syslog.table.createdAt'),
                    sortable: false,
                    value: 'timestamp'
                }
            ],

            data: [],

            loading: true,
            total  : 0,

            search: {
                pagesize : 10,
                pagenum  : 1,
                level    : null,
                msg      : null,
                timedate : null,
                action   : "operate"
            }
          
      }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.system.LogListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                if (resp.data.list==null) resp.data.list = [];
                this.data = resp.data.list;
            }).finally(resp => {
                this.loading = false;
            })
        },

        changeTab (value) {
            this.title = this.tabItems[value].label;
            this.search.action = this.tabItems[value].action;
            this.getData();
        },

        handleSearch () {
            this.getData();
        }

    }
}
</script>

<style scoped>

.syslog-layout {
    margin: 0 10px;
}

</style>