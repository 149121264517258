<template>
    
    <div class="site-layout">

        <role-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title ="title"
            :role  ="role"
            :group ="group"
            :search="search"
        ></role-search>


        <role-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >
            <template v-slot:action="action">
                <v-row justify="start">

                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.editBtn') }}
                    </span>

                    <div class="mx-1"></div>

                    <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.deleteBtn') }}
                    </span>

                </v-row>
            </template>
        </role-data-table>

        <add-role 
            ref="add"
            :group="group" 
            @handleSearch="handleSearch"
        ></add-role>

        <edit-role 
            ref="edit"
            @handleSearch="handleSearch"
            :privileges="privileges"
        ></edit-role>

        <del-role ref="del" @handleSearch="handleSearch"></del-role>
    </div>

</template>

<script>
import * as api from '../../api'
import RoleSearch from './components/RoleSearch.vue'
import RoleDataTable from './components/RoleDataTable.vue'
import AddRole from './components/AddRole.vue'
import EditRole from './components/EditRole.vue'
import DelRole from './components/DelRole.vue'

export default {

    components: {
        RoleSearch,
        RoleDataTable,
        AddRole,
        EditRole,
        DelRole
    },

    data () {
      return {

            role: parseInt(window.sessionStorage.getItem('type')),

            title: this.$vuetify.lang.t('$vuetify.userManage.role.title'),

            group: [],

            privileges: [{
                id: '0',
                name: this.$vuetify.lang.t('$vuetify.userManage.role.configBtn'),
                children: []
            }],

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.role.table.name'),
                    sortable: false,
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.role.table.groupName'),
                    sortable: false,
                    value: 'groupName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.role.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],

            data: [
                // {
                //     id: 1,
                //     name: '霏云',
                //     groupName: '霏云科技'
                // }
            ],

            loading: false,
            total  : 0,

            search: {
                pagesize : 10,
                pagenum  : 1,
                groupID  : null
            }
          
      }
    },

    created () {
        this.initialize();
    },

    methods: {

        initialize() {
            this.getData();
            this.getGroup();
            this.getPrivileges();
        },

        getGroup () {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.rbac.roleListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                if (resp.data.list == null){
                  this.data  = [];
                }else{
                  this.data  = resp.data.list;
                }
            }).finally(resp => {
                this.loading = false;
            })
        },

        getPrivileges () {
            this.$http.post(api.default.system.permissionListUrl, {
                pagesize : 1000,
                pagenum  : 1,
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.privileges[0].children = resp.data.list;
            });
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }

    }
}
</script>

<style scoped>

.site-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}
</style>