<template>
    <div>
        <v-card>

            <v-row
              class="pa-4"
              justify="space-between"
            >
                <v-col cols="5">
                    <v-treeview
                        v-if="data.length !== 0"
                        :active.sync="active"
                        :items="data"
                        :load-children="getData"
                        item-key="code"
                        item-text="name"
                        activatable
                        color="warning"
                        open-on-click
                        transition
                        dense
                    >
                    </v-treeview>
                </v-col>

                  <v-divider vertical></v-divider>

                  <v-col
                    class="d-flex text-center"
                  >
                    <v-scroll-y-transition mode="out-in">
                        <div
                            v-if="!selected"
                            class="title grey--text text--lighten-1 font-weight-light"
                            style="align-self: center;"
                        >
                            Select a Place
                        </div>
                        <v-card
                            v-else
                            :key="selected.code"
                            class="pt-6 mx-auto"
                            flat
                            max-width="400"
                        >
                            <v-card-text>
                                <h3 class="headline mb-2">
                                    {{ selected.name }}
                                </h3>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-row class="text-left" tag="v-card-text">
                                <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                                    {{ $vuetify.lang.t('$vuetify.systemManage.place.table.code') + ':' }}
                                </v-col>
                                <v-col>{{ selected.areacode }}</v-col>
                                <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                                    {{ $vuetify.lang.t('$vuetify.systemManage.place.table.action') + ':' }}
                                </v-col>
                                <v-col>
                                    <slot name='action' :value='{ selected }'></slot>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-scroll-y-transition>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import * as api from '../../../api'

const pause = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {

    props: {
        data: Array,
    },

    data () {
        return {
            active: [],
        }
    },

    computed: {
        selected () {
            if (!this.active.length) {
                this.$emit('handleTab', 0, {parentcode: null});
                return undefined;
            }

            var code = this.active[0];
            return this.selectItem(code);
        },
    },

    methods: {

        async getData (item) {
            if (item.code.length === 2) {
                this.getCity(item);
            }else{
                this.getArea(item);
            }
            await pause(1000);
            return true;
        },

        getCity (item) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: item.code
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.initCity(item, resp.data);
            })
        },

        initCity (item, value) {
            item.children = [{
                code: item.code,
                name: '本省信息'
            }];
            if (value.length < 1) return;
            var n = value.length;
            for (var i = 0; i < n; i++) {
                value[i].children = [];
                item.children.push(value[i]);
            }
        },

        getArea (item) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: item.code
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.initArea(item, resp.data);
            })
        },

        initArea (item, value) {
            item.children = [{
                code: item.code,
                name: '本市信息'
            }];
            if (value.length < 1) return;
            var n = value.length;
            for (var i = 0; i < n; i++) {
                item.children.push(value[i]);
            }
        },

        selectItem (code) {
            for(var i in this.data){

                if (this.data[i].code === code) {
                    this.$emit('handleTab', 1, {parentcode: this.data[i].code});
                    return {
                        areacode: this.data[i].code,
                        name: this.data[i].name,
                    };
                }

                var city = this.data[i].children;
                for (var j in city) {

                    if (city[j].code === code) {
                        this.$emit('handleTab', 2, {parentcode: city[j].code});
                        return {
                            areacode: city[j].code,
                            name: city[j].name,
                        };
                    }

                    var area = city[j].children;
                    for (var k in area) {
                        if (area[k].code === code) {
                            this.$emit('handleTab', 2, {parentcode: city[j].code});
                            return {
                                areacode: area[k].code,
                                name: area[k].name,
                            };
                        }
                    }
                }
            }
        },

    }
}
</script>