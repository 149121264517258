<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <h6 style="color: #000;">
                                        {{ $vuetify.lang.t('$vuetify.userManage.user.vlanExplain') }}
                                    </h6>
                                    <h6 style="color: #000;">
                                        具体格式为「 ip 」或「 ip/掩码 」, 例子如下
                                    </h6>
                                    <h6 style="color: #000;">
                                        192.168.1.1
                                    </h6>
                                    <h6 style="color: #000;">
                                        192.168.1.0/24
                                    </h6>
                                    <v-textarea
                                        outlined
                                        name="input-7-4"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.allowList')" 
                                        v-model="data.allowVlanList"
                                    ></v-textarea>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleUpdate" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';
import sha1 from 'js-sha1'
import md5 from 'js-md5'

export default {

    props: {
        group: Array
    },

    data () {
      return {
            title: this.$vuetify.lang.t('$vuetify.userManage.user.allowList'),
            loading: false,
            dialog: false,
            data: {
                id:"",
                allowVlanList:""
            },
            rules: {
                allowVlanList: [
                    value => (value !== "") || this.$vuetify.lang.t('$vuetify.userManage.user.rules.vlan')
                ],
            }
            
      }
    },

    methods: {
        handleOpen (item) {
            var new_obj_item = this.newObject(item)
            this.data.allowVlanList = new_obj_item.allow_vlan_list || ""
            this.data.id = new_obj_item.id || ""
            this.password = null;
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
            this.$refs.form.reset();
        },

        handleUpdate () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.post(api.default.rbac.accountEditVlan, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        newObject (item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        }

    }
}
</script>

<style scoped>



</style>