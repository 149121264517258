<template>
    <el-card class="box-card" style="width: 100%">
        <template #header>
            <div class="card-header">
                <h4 style="display:inline-block">应用top</h4>
                <el-button style="float:right" type="primary" @click="search">查询</el-button>
            </div>
            <el-form ref="form" size="small">
                <el-form-item label="集团">
                    <el-select
                        clearable
                        @visible-change="fetchGroupList"
                        @change="fetchShopList"
                        v-model="form.groupID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in grouplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="场所">
                    <el-select
                        clearable
                        v-model="form.shopID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in shoplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
                    <el-date-picker
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 225px"
                        v-model="form.Time_range"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="top数">
                    <el-input-number v-model="form.top_times" :min="1" ></el-input-number>
                </el-form-item>
                <el-form-item label="导出详细记录">
                    <el-button type="primary" @click="openExport">导出</el-button>
                </el-form-item>
            </el-form>
        </template>
        <el-table ref="multipleTable" :data="apptop" tooltip-effect="dark" style="width: 100%">
            <el-table-column
                type="index"
                label="排名">
            </el-table-column>
            <el-table-column prop="app_id" label="app名称">
            </el-table-column>
        </el-table>
        <el-dialog title="导出" :visible.sync="dialogExport" :before-close="handleclose" width="40%">
            <el-form ref="form">
                <el-form-item label="集团">
                    <el-select
                        clearable
                        @visible-change="fetchGroupList"
                        @change="fetchShopList"
                        v-model="Exportform.groupID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in grouplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="场所">
                    <el-select
                        clearable
                        v-model="Exportform.shopID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in shoplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
                    <el-date-picker
                        style="width: 225px"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        v-model="Exportform.Time_range"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="应用">
                    <el-cascader
                        v-model="AppNameList"
                        :options="options"
                        :props="{ multiple: true }"
                        filterable
                        collapse-tags
                        clearable>
                    </el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="Export()" v-loading="exportLoading">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>
<script>
import * as api from '../../../api'
import exportExcel from '../../../utils/export'
import { Notification } from 'element-ui';


export default {
    data () {
      return {
        form:{
            shopID:null,
            groupID:null,
            gateway_id:null,
            Time_range:"",
            top_times:10,
        },
        Exportform:{
            shopID:null,
            groupID:null,
            Time_range:"",
            app_name:[],
        },
        AppNameList:[],
        dialogExport:false,
        exportLoading:false,
        shoplist:[],
        grouplist:[],
        apptop:[],
        options:[
            {value: '聊天',label: '聊天',
            children: [
            { value:"QQ1",label:"QQ1"},
            { value:"微信",label:"微信"},
            { value:"微博",label:"微博"},
            { value:"陌陌",label:"陌陌"},
            { value:"支付宝",label:"支付宝"},
            { value:"钉钉",label:"钉钉"},
            { value:"Soul",label:"Soul"},
            { value:"伊对",label:"伊对"},
            { value:"探探",label:"探探"},
            { value:"多闪",label:"多闪"},
            { value:"派派",label:"派派"},
            { value:"Instagram",label:"Instagram"},
            { value:"Facebook",label:"Facebook"},
            { value:"WhatsApp",label:"WhatsApp"},
            { value:"Twitter",label:"Twitter"},
            { value:"微信支付",label:"微信支付"},
            { value:"QQ4",label:"QQ4"}
            ]},
            {   value: '游戏',label: '游戏',
            children: [
            {value:"王者荣耀",label:"王者荣耀"},
            {value:"和平精英",label:"和平精英"},
            {value:"英雄联盟",label:"英雄联盟"},
            {value:"荒野行动",label:"荒野行动"},
            {value:"欢乐斗地主",label:"欢乐斗地主"},
            {value:"梦幻西游",label:"梦幻西游"},
            {value:"明日之后",label:"明日之后"},
            {value:"QQ飞车",label:"QQ飞车"},
            {value:"跑跑卡丁车",label:"跑跑卡丁车"},
            {value:"开心消消乐",label:"开心消消乐"},
            {value:"狂野飙车",label:"狂野飙车"},
            {value:"率土之滨",label:"率土之滨"},
            {value:"一刀传世",label:"一刀传世"},
            {value:"第五人格",label:"第五人格"},
            {value:"我的世界",label:"我的世界"},
            {value:"皇室战争",label:"皇室战争"},
            {value:"炉石传说",label:"炉石传说"},
            {value:"阴阳师",label:"阴阳师"},
            {value:"狼人杀",label:"狼人杀"},
            {value:"小森生活",label:"小森生活"},
            {value:"使命召唤",label:"使命召唤"},
            {value:"欢乐麻将",label:"欢乐麻将"},
            {value:"原神",label:"原神"},
            {value:"天涯明月刀",label:"天涯明月刀"},
            {value:"微信小游戏",label:"微信小游戏"},
            {value:"英雄联盟手游",label:"英雄联盟手游"},
            {value:"迷你世界",label:"迷你世界"},
            {value:"天下手游",label:"天下手游"},
            {value:"天龙八部",label:"天龙八部"},
            {value:"一梦江湖",label:"一梦江湖"},
            {value:"我叫MT4",label:"我叫MT4"},
            {value:"神都夜行录",label:"神都夜行录"},
            {value:"镇魔曲",label:"镇魔曲"},
            {value:"倩女幽魂",label:"倩女幽魂"},
            {value:"哈利波特",label:"哈利波特"},
            {value:"光遇",label:"光遇"},
            {value:"保卫萝卜4",label:"保卫萝卜4"},
            {value:"uu加速器",label:"uu加速器"},
            {value:"腾讯加速器",label:"腾讯加速器"},
            {value:"蛋仔派对",label:"蛋仔派对"},
            {value:"猫和老鼠",label:"猫和老鼠"},
            {value:"9377游戏",label:"9377游戏"},
            {value:"4399游戏",label:"4399游戏"},
            {value:"7k7k游戏",label:"7k7k游戏"},
            {value:"17173游戏",label:"17173游戏"},
            {value:"37网游",label:"37网游"},
            {value:"游民星空",label:"游民星空"},
            {value:"游侠网",label:"游侠网"},
            {value:"hao123游戏",label:"hao123游戏"},
            {value:"51游戏",label:"51游戏"},
            {value:"百度游戏",label:"百度游戏"},
            {value:"4366页游",label:"4366页游"},
            {value:"百度页游",label:"百度页游"},
            {value:"hao123页游",label:"hao123页游"},
            {value:"hao123手游",label:"hao123手游"},
            {value:"hao123小游戏",label:"hao123小游戏"},
            {value:"战舰世界",label:"战舰世界"},
            {value:"坦克世界",label:"坦克世界"},
            {value:"传奇页游",label:"传奇页游"},
            {value:"2345游戏",label:"2345游戏"},
            {value:"冰雪打金",label:"冰雪打金"},
            {value:"暗区突围",label:"暗区突围"},
            ]},
            {   value: '视频',label: '视频',
            children: [
            {value:"抖音",label:"抖音"},
            {value:"火山小视频",label:"火山小视频"},
            {value:"腾讯视频",label:"腾讯视频"},
            {value:"爱奇艺",label:"爱奇艺"},
            {value:"微视",label:"微视"},
            {value:"斗鱼直播",label:"斗鱼直播"},
            {value:"虎牙直播",label:"虎牙直播"},
            {value:"快手",label:"快手"},
            {value:"小红书",label:"小红书"},
            {value:"花椒直播",label:"花椒直播"},
            {value:"映客直播",label:"映客直播"},
            {value:"YY",label:"YY"},
            {value:"哔哩哔哩",label:"哔哩哔哩"},
            {value:"芒果tv",label:"芒果tv"},
            {value:"西瓜视频",label:"西瓜视频"},
            {value:"搜狐视频",label:"搜狐视频"},
            {value:"播聊",label:"播聊"},
            {value:"咪咕视频",label:"咪咕视频"},
            {value:"韩剧TV",label:"韩剧TV"},
            {value:"人人视频",label:"人人视频"},
            {value:"央视影音",label:"央视影音"},
            {value:"土豆视频",label:"土豆视频"},
            {value:"最右",label:"最右"},
            {value:"风行视频",label:"风行视频"},
            {value:"企鹅电竞",label:"企鹅电竞"},
            {value:"波波视频",label:"波波视频"},
            {value:"酷狗短酷",label:"酷狗短酷"},
            {value:"酷狗直播",label:"酷狗直播"},
            {value:"一直播",label:"一直播"},
            {value:"刷宝短视频",label:"刷宝短视频"},
            {value:"么么直播",label:"么么直播"},
            {value:"KK直播",label:"KK直播"},
            {value:"九秀直播",label:"九秀直播"},
            {value:"微信直播",label:"微信直播"},
            {value:"天堂电影",label:"天堂电影"},
            {value:"好看视频",label:"好看视频"},
            {value:"电视家",label:"电视家"},
            {value:"电视直播TV",label:"电视直播TV"},
            {value:"uc视频",label:"uc视频"},
            {value:"优酷视频",label:"优酷视频"},
            {value:"今日头条视频",label:"今日头条视频"},
            {value:"网易新闻视频",label:"网易新闻视频"},
            {value:"图虫",label:"图虫"},
            {value:"腾讯Now直播",label:"腾讯Now直播"},
            {value:"美拍",label:"美拍"},
            {value:"Youtube",label:"Youtube"},
            {value:"ACFUN",label:"ACFUN"},
            {value:"视频号",label:"视频号"},
            {value:"拼多多视频",label:"拼多多视频"},
            {value:"QQ小世界",label:"QQ小世界"},
            {value:"闲鱼视频",label:"闲鱼视频"},
            {value:"uplive",label:"uplive"},
            {value:"蜜兔直播",label:"蜜兔直播"},
            {value:"抱抱直播",label:"抱抱直播"},
            {value:"花房直播",label:"花房直播"},
            {value:"天仙直播",label:"天仙直播"},
            {value:"妩媚直播",label:"妩媚直播"},
            {value:"蜜聊直播",label:"蜜聊直播"},
            {value:"95秀",label:"95秀"},
            {value:"秀色Live",label:"秀色Live"},
            {value:"他趣",label:"他趣"},
            {value:"觅觅",label:"觅觅"},
            {value:"棉花糖",label:"棉花糖"},
            {value:"直播吧",label:"直播吧"},
            {value:"直播中国",label:"直播中国"},
            {value:"CC直播",label:"CC直播"},
            {value:"水晶直播",label:"水晶直播"},
            {value:"乐嗨直播",label:"乐嗨直播"},
            {value:"妖姬直播",label:"妖姬直播"},
            {value:"千帆直播",label:"千帆直播"},
            {value:"齐齐直播",label:"齐齐直播"},
            {value:"鱼饵直播",label:"鱼饵直播"},
            {value:"火星直播",label:"火星直播"},
            {value:"趣直播",label:"趣直播"},
            {value:"美人鱼直播",label:"美人鱼直播"},
            {value:"18y",label:"18y"},
            {value:"达人直播",label:"达人直播"},
            {value:"HelloPal",label:"HelloPal"},
            {value:"乐播投屏",label:"乐播投屏"},
            {value:"六间房",label:"六间房"},
            {value:"百度直播",label:"百度直播"},
            {value:"度小视",label:"度小视"},
            {value:"电视猫",label:"电视猫"},
            {value:"当贝市场",label:"当贝市场"},
            {value:"华数TV",label:"华数TV"},
            {value:"极光TV",label:"极光TV"},
            {value:"2345影视",label:"2345影视"},
            {value:"hao123影视",label:"hao123影视"},
            {value:"番茄电影",label:"番茄电影"},
            {value:"南瓜电影",label:"南瓜电影"},
            {value:"月亮播放器",label:"月亮播放器"},
            {value:"美剧天堂",label:"美剧天堂"},
            {value:"Tale",label:"Tale"},
            {value:"1905电影",label:"1905电影"},
            {value:"豆瓣电影",label:"豆瓣电影"},
            {value:"龙珠直播",label:"龙珠直播"},
            {value:"浙江卫视",label:"浙江卫视"},
            {value:"江苏卫视",label:"江苏卫视"},
            {value:"荔枝网",label:"荔枝网"},
            {value:"山东电视台",label:"山东电视台"},
            {value:"安徽电视台",label:"安徽电视台"},
            {value:"CCTV",label:"CCTV"},
            {value:"小度互娱",label:"小度互娱"},
            {value:"乐视视频",label:"乐视视频"},
            {value:"爆米花视频",label:"爆米花视频"},
            {value:"第一视频",label:"第一视频"},
            {value:"酷6网",label:"酷6网"},
            {value:"新浪视频",label:"新浪视频"},
            {value:"搜视网",label:"搜视网"},
            {value:"翻咔",label:"翻咔"},
            {value:"艾米直播",label:"艾米直播"},
            {value:"我秀",label:"我秀"},
            {value:"乐嗨秀场",label:"乐嗨秀场"},
            {value:"梨视频",label:"梨视频"},
            {value:"美剧天堂",label:"美剧天堂"},
            {value:"DJ嗨嗨网",label:"DJ嗨嗨网"},
            {value:"嘀哩嘀哩",label:"嘀哩嘀哩"},
            {value:"酷米网",label:"酷米网"},
            {value:"唱鸭",label:"唱鸭"},
            {value:"猫耳FM",label:"猫耳FM"},
            {value:"酷燃视频",label:"酷燃视频"},
            {value:"抓饭直播",label:"抓饭直播"},
            {value:"悠视网",label:"悠视网"},
            {value:"天翼视讯",label:"天翼视讯"},
            {value:"天天直播",label:"天天直播"},
            {value:"小影",label:"小影"},
            {value:"网易CC",label:"网易CC"},
            {value:"迅雷直播",label:"迅雷直播"},
            {value:"易车视频",label:"易车视频"},
            {value:"Mono29",label:"Mono29"},
            {value:"Vidio",label:"Vidio"},
            {value:"TVB",label:"TVB"},
            {value:"LINE_TV",label:"LINE_TV"},
            {value:"HuluTV",label:"HuluTV"},
            {value:"小米电视",label:"小米电视"},
            {value:"云视听极光",label:"云视听极光"},
            {value:"云视听小电视",label:"云视听小电视"},
            {value:"银河奇异果",label:"银河奇异果"},
            {value:"CIBN酷喵",label:"CIBN酷喵"},
            ]},
            {value: '购物',label: '购物',
            children: [
            {value:"淘宝",label:"淘宝"},
            {value:"京东",label:"京东"},
            {value:"唯品会",label:"唯品会"},
            {value:"拼多多",label:"拼多多"},
            {value:"蘑菇街",label:"蘑菇街"},
            {value:"苏宁易购",label:"苏宁易购"},
            {value:"当当网",label:"当当网"},
            {value:"1号店",label:"1号店"},
            {value:"朴朴超市",label:"朴朴超市"},
            {value:"饿了么",label:"饿了么"},
            {value:"美团",label:"美团"},
            {value:"闲鱼",label:"闲鱼"},
            {value:"叮咚买菜",label:"叮咚买菜"},
            {value:"小米有品",label:"小米有品"},
            {value:"微店",label:"微店"},
            {value:"折800",label:"折800"},
            {value:"好省",label:"好省"},
            {value:"什么值得买",label:"什么值得买"},
            {value:"大众点评",label:"大众点评"},
            {value:"转转",label:"转转"},
            {value:"网易严选",label:"网易严选"},
            {value:"识货",label:"识货"},
            {value:"考拉海购",label:"考拉海购"},
            {value:"宜家家居",label:"宜家家居"},
            {value:"小象优品",label:"小象优品"},
            {value:"海豚家",label:"海豚家"},
            {value:"东方购物",label:"东方购物"},
            {value:"洋码头",label:"洋码头"},
            {value:"蜜源",label:"蜜源"},
            {value:"男人帮",label:"男人帮"},
            {value:"萌推",label:"萌推"},
            {value:"海淘免税",label:"海淘免税"},
            {value:"云集",label:"云集"},
            {value:"有货",label:"有货"},
            {value:"得物",label:"得物"},
            {value:"潮趣",label:"潮趣"},
            {value:"国美",label:"国美"},
            {value:"酒仙网",label:"酒仙网"},
            {value:"1688",label:"1688"},
            {value:"亚马逊",label:"亚马逊"},
            {value:"Lazada",label:"Lazada"},
            {value:"携程",label:"携程"},
            {value:"去哪儿网",label:"去哪儿网"},
            {value:"飞猪",label:"飞猪"},
            {value:"艺龙",label:"艺龙"},
            ]},
            {value: '音乐',label: '音乐',
            children: [
            {value:"网易云音乐",label:"网易云音乐"},
            {value:"QQ音乐",label:"QQ音乐"},
            {value:"酷狗音乐",label:"酷狗音乐"},
            {value:"酷我音乐",label:"酷我音乐"},
            {value:"喜马拉雅",label:"喜马拉雅"},
            {value:"千千音乐",label:"千千音乐"},
            {value:"虾米音乐",label:"虾米音乐"},
            {value:"音悦台",label:"音悦台"},
            {value:"豆瓣FM",label:"豆瓣FM"},
            {value:"唱吧",label:"唱吧"},
            {value:"音乐随心听",label:"音乐随心听"},
            {value:"懒人听书",label:"懒人听书"},
            {value:"Apple音乐",label:"Apple音乐"},
            {value:"铃声多多",label:"铃声多多"},
            {value:"九酷音乐",label:"九酷音乐"},
            {value:"九天音乐",label:"九天音乐"},
            ]},
            {value: '招聘',label: '招聘',
            children: [
            {value:"前程无忧",label:"前程无忧"},
            {value:"智联招聘",label:"智联招聘"},
            {value:"猎聘",label:"猎聘"},
            {value:"赶集网",label:"赶集网"},
            {value:"同城急聘",label:"同城急聘"},
            {value:"领英",label:"领英"},
            {value:"斗米",label:"斗米"},
            {value:"看准",label:"看准"},
            {value:"应届生求职",label:"应届生求职"},
            {value:"中华英才网",label:"中华英才网"},
            {value:"拉勾网",label:"拉勾网"},
            {value:"大街网",label:"大街网"},
            {value:"boss直聘",label:"boss直聘"},
            {value:"实习僧",label:"实习僧"},
            ]},
            {value: '下载',label: '下载',
            children: [
            {value:"迅雷",label:"迅雷"},
            {value:"AppStore",label:"AppStore"},
            {value:"samba共享",label:"samba共享"},
            {value:"ftp文件传输",label:"ftp文件传输"},
            {value:"vivo应用商店",label:"vivo应用商店"},
            {value:"王者荣耀更新",label:"王者荣耀更新"},
            {value:"天翼云盘",label:"天翼云盘"},
            {value:"腾讯微云",label:"腾讯微云"},
            {value:"坚果云",label:"坚果云"},
            {value:"蓝奏云",label:"蓝奏云"},
            {value:"华为云",label:"华为云"},
            {value:"腾讯游戏更新",label:"腾讯游戏更新"},
            {value:"windows更新",label:"windows更新"},
            {value:"华为应用市场",label:"华为应用市场"},
            {value:"向日葵",label:"向日葵"},
            {value:"TeamViewer",label:"TeamViewer"},
            {value:"阿里云盘",label:"阿里云盘"},
            {value:"SSH",label:"SSH"},
            {value:"WeGame下载器",label:"WeGame下载器"},
            {value:"华军软件园",label:"华军软件园"},
            {value:"360安全卫士",label:"360安全卫士"},
            {value:"360软件管家",label:"360软件管家"},
            {value:"鲁大师",label:"鲁大师"},
            {value:"小爱音箱",label:"小爱音箱"},
            {value:"搜狗拼音",label:"搜狗拼音"},
            {value:"电脑管家",label:"电脑管家"},
            ]},
            {value: '网站',label: '网站',
            children: [
            {value:"百度",label:"百度"},
            {value:"新浪",label:"新浪"},
            {value:"搜狐",label:"搜狐"},
            {value:"网易",label:"网易"},
            {value:"凤凰网",label:"凤凰网"},
            {value:"人民网",label:"人民网"},
            {value:"中华网",label:"中华网"},
            {value:"hao123",label:"hao123"},
            {value:"2345",label:"2345"},
            {value:"世纪佳缘",label:"世纪佳缘"},
            {value:"珍爱网",label:"珍爱网"},
            {value:"百合网",label:"百合网"},
            {value:"天涯社区",label:"天涯社区"},
            {value:"携程网",label:"携程网"},
            {value:"飞猪",label:"飞猪"},
            {value:"12306",label:"12306"},
            {value:"马蜂窝",label:"马蜂窝"},
            {value:"途牛",label:"途牛"},
            {value:"穷游网",label:"穷游网"},
            {value:"驴妈妈",label:"驴妈妈"},
            {value:"同程旅游",label:"同程旅游"},
            {value:"太平洋汽车",label:"太平洋汽车"},
            {value:"易车网",label:"易车网"},
            {value:"爱卡汽车",label:"爱卡汽车"},
            {value:"雪球",label:"雪球"},
            {value:"东方财富",label:"东方财富"},
            {value:"证券之星",label:"证券之星"},
            {value:"和讯",label:"和讯"},
            {value:"第一财经",label:"第一财经"},
            {value:"全景网",label:"全景网"},
            {value:"中彩网",label:"中彩网"},
            {value:"中国体育彩票",label:"中国体育彩票"},
            {value:"竞彩网",label:"竞彩网"},
            {value:"豆丁",label:"豆丁"},
            {value:"豆瓣",label:"豆瓣"},
            {value:"知乎",label:"知乎"},
            {value:"缤客",label:"缤客"},
            {value:"猫扑",label:"猫扑"},
            {value:"赶集网",label:"赶集网"},
            {value:"安居客",label:"安居客"},
            {value:"房天下",label:"房天下"},
            {value:"链家",label:"链家"},
            {value:"百姓网",label:"百姓网"},
            {value:"下厨房",label:"下厨房"},
            {value:"58同城",label:"58同城"},
            {value:"天眼查",label:"天眼查"},
            {value:"千图网",label:"千图网"},
            {value:"csdn社区",label:"csdn社区"},
            {value:"有道词典",label:"有道词典"},
            {value:"动漫之家",label:"动漫之家"},
            {value:"汽车之家",label:"汽车之家"},
            {value:"纵横中文网",label:"纵横中文网"},
            {value:"起点中文网",label:"起点中文网"},
            {value:"飞卢",label:"飞卢"},
            {value:"潇湘书院",label:"潇湘书院"},
            {value:"cctv5",label:"cctv5"},
            {value:"虎扑体育",label:"虎扑体育"},
            {value:"建设银行",label:"建设银行"},
            {value:"农业银行",label:"农业银行"},
            {value:"中国银行",label:"中国银行"},
            {value:"交通银行",label:"交通银行"},
            {value:"招商银行",label:"招商银行"},
            {value:"邮政储蓄",label:"邮政储蓄"},
            {value:"兴业银行",label:"兴业银行"},
            {value:"浦发银行",label:"浦发银行"},
            {value:"中信银行",label:"中信银行"},
            {value:"上海银行",label:"上海银行"},
            {value:"知网",label:"知网"},
            {value:"谷歌",label:"谷歌"},
            {value:"github",label:"github"},
            {value:"gitee",label:"gitee"},
            {value:"必应",label:"必应"},
            {value:"中国体彩",label:"中国体彩"},
            {value:"中国福利彩",label:"中国福利彩"},
            {value:"新浪彩票",label:"新浪彩票"},
            {value:"竞彩网",label:"竞彩网"},
            {value:"新浪体育",label:"新浪体育"},
            {value:"新浪新闻",label:"新浪新闻"},
            {value:"小米官网",label:"小米官网"},
            {value:"招商证券",label:"招商证券"},
            {value:"萤石云",label:"萤石云"},
            {value:"掌阅",label:"掌阅"},
            {value:"中关村在线",label:"中关村在线"},
            {value:"IT之家",label:"IT之家"},
            {value:"太平洋电脑",label:"太平洋电脑"},
            {value:"中国移动",label:"中国移动"},
            {value:"中国联通",label:"中国联通"},
            {value:"中国电信",label:"中国电信"},
            {value:"华为商城",label:"华为商城"},
            {value:"小米官网",label:"小米官网"},
            {value:"vivo官网",label:"vivo官网"},
            {value:"华为官网",label:"华为官网"},
            {value:"苹果官网",label:"苹果官网"},
            {value:"苹果官网",label:"苹果官网"},
            {value:"金融界",label:"金融界"},
            {value:"中金在线",label:"中金在线"},
            {value:"人民银行",label:"人民银行"},
            {value:"北京银行",label:"北京银行"},
            {value:"银联在线",label:"银联在线"},
            {value:"花旗银行",label:"花旗银行"},
            {value:"工商银行",label:"工商银行"},
            {value:"农业银行",label:"农业银行"},
            {value:"广发银行",label:"广发银行"},
            {value:"中信银行",label:"中信银行"},
            {value:"汇丰银行",label:"汇丰银行"},
            {value:"广大银行",label:"广大银行"},
            {value:"民生银行",label:"民生银行"},
            {value:"上海银行",label:"上海银行"},
            {value:"京东钱包",label:"京东钱包"},
            {value:"光明网",label:"光明网"},
            {value:"新华网",label:"新华网"},
            {value:"央视网",label:"央视网"},
            {value:"国际在线",label:"国际在线"},
            {value:"中经网",label:"中经网"},
            {value:"求是网",label:"求是网"},
            {value:"中青网",label:"中青网"},
            {value:"千图网",label:"千图网"},
            {value:"豆丁网",label:"豆丁网"},
            {value:"天眼查",label:"天眼查"},
            {value:"爱企查",label:"爱企查"},
            {value:"百度文库",label:"百度文库"},
            {value:"360文库",label:"360文库"},
            {value:"百度贴吧",label:"百度贴吧"},
            {value:"百度知道",label:"百度知道"},
            ]},
            {value: '挖矿',label: '挖矿',
            children: [
            {value:"比特币",label:"比特币"},
            {value:"以太坊",label:"以太坊"},
            {value:"挖矿网",label:"挖矿网"},
            {value:"KKOS挖矿",label:"KKOS挖矿"},
            {value:"挖矿588",label:"挖矿588"},
            {value:"88挖矿网",label:"88挖矿网"},
            {value:"挖矿软件",label:"挖矿软件"},
            {value:"虚拟币挖矿",label:"虚拟币挖矿"},
            {value:"挖矿助手",label:"挖矿助手"},
            {value:"蜂巢星球挖矿",label:"蜂巢星球挖矿"},
            {value:"挖矿超人",label:"挖矿超人"},
            {value:"欧易",label:"欧易"},
            {value:"币安",label:"币安"},
            {value:"火币",label:"火币"},
            {value:"蜜蜂矿池",label:"蜜蜂矿池"},
            {value:"AVIVE",label:"AVIVE"},
            {value:"FAN挖矿",label:"FAN挖矿"},
            {value:"pi币挖矿",label:"pi币挖矿"},
            {value:"RXR挖矿",label:"RXR挖矿"},
            {value:"AntPool",label:"AntPool"},
            {value:"F2Pool",label:"F2Pool"},
            {value:"ViaBTC",label:"ViaBTC"},
            {value:"Slush Pool",label:"Slush Pool"},
            {value:"微算力矿机",label:"微算力矿机"},
            {value:"NiceHash",label:"NiceHash"},
            {value:"Genesis Mining",label:"Genesis Mining"},
            {value:"MinerGate",label:"MinerGate"},
            {value:"BitMinter",label:"BitMinter"},
            ]},
            {value: '病毒',label: '病毒',
            children: [
            {value:"勒索病毒",label:"勒索病毒"},
            {value:"灰鸽子木马",label:"灰鸽子木马"},
            {value:"Emotet",label:"Emotet"},
            {value:"GandCrab",label:"GandCrab"},
            {value:"Necurs僵尸",label:"Necurs僵尸"},
            {value:"特洛伊木马",label:"特洛伊木马"},
            {value:"蠕虫病毒",label:"蠕虫病毒"},
            {value:"宏病毒",label:"宏病毒"},
            {value:"VBS病毒",label:"VBS病毒"},
            {value:"红色代码",label:"红色代码"},
            {value:"欢乐时光",label:"欢乐时光"},
            {value:"黑客病毒",label:"黑客病毒"},
            {value:"捆绑机病毒",label:"捆绑机病毒"},
            {value:"木马释放器",label:"木马释放器"},
            {value:"银行钓鱼网站",label:"银行钓鱼网站"},
            ]},
            {value: '钓鱼',label: '钓鱼',
            children: [
            {value:"社交媒体钓鱼网站",label:"社交媒体钓鱼网站"},
            {value:"电子邮件钓鱼网站",label:"电子邮件钓鱼网站"},
            {value:"在线支付钓鱼网站",label:"在线支付钓鱼网站"},
            {value:"在线商店钓鱼网站",label:"在线商店钓鱼网站"},
            {value:"假冒查询钓鱼网站",label:"假冒查询钓鱼网站"},
            {value:"购物网站钓鱼网站",label:"购物网站钓鱼网站"},
            {value:"支付平台钓鱼网站",label:"支付平台钓鱼网站"},
            {value:"游戏平台钓鱼网站",label:"游戏平台钓鱼网站"},
            {value:"航空公司钓鱼网站",label:"航空公司钓鱼网站"},
            {value:"政府机构钓鱼网站",label:"政府机构钓鱼网站"},
            {value:"教育机构钓鱼网站",label:"教育机构钓鱼网站"},
            {value:"健康保险钓鱼网站",label:"健康保险钓鱼网站"},
            ]},
        ],
      }
    },
    methods: {
        fetchGroupList(val){
            if(!val){
                return
            }
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                this.grouplist = resp.data
            })
        },
        fetchShopList(groupid){
            this.form.shopID=null
            if(groupid==""){
                return
            }
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                this.shoplist = resp.data.list
            })
        },
        search(){
            if(this.form.groupID==""){
                this.form.groupID=null
            }
            if(this.form.shopID==""){
                this.form.shopID=null
            }
            this.$http.post(api.default.toplist.apptop,this.form).then(resp => {
                this.apptop = resp.data
            })
        },
        openExport(){
            this.dialogExport = true
        },
        handleclose(){
            this.dialogExport = false
        },
        Export(){
            if (this.AppNameList.length<=0) {
                Notification({
                    type: 'info',
                    message: "应用不可为空"
                });
                return
            }
            var app_name = []
            this.AppNameList.forEach(element => {
                app_name.push(element[1])
            });

            this.Exportform.app_name = app_name
            if (this.Exportform.shopID=="") {
                this.Exportform.shopID=0
            }
            if (this.Exportform.groupID=="") {
                this.Exportform.groupID=0
            }
            var self = this
            this.exportLoading = true;
            this.$http.post(api.default.appfilter.getappauth,this.Exportform).then(resp => {
                if(resp.errno == 0){
                    if(resp.data.length>0){
                        const titleArr = [
                            "网关名称",
                            "身份信息",
                            "ip",
                            "mac",
                            "设备名称",
                            "认证时间",
                            "应用名称",
                            "使用时长",
                        ]
                        const exportdata = []
                        for (let i of resp.data) {
                            let tempdata = {
                                "device_name":i.device_name,
                                "auth_info": i.auth_info,
                                "ip": i.ip,
                                "mac": i.mac,
                                "host_name": i.host_name,
                                "auth_time": i.auth_time,
                                "app_name": i.app_name,
                                "total_time": i.total_time,
                            }
                            exportdata.push(tempdata)
                        }
                        exportExcel(exportdata, '应用访问记录', titleArr, 'sheetName');
                    }else{
                        Notification({
                            type: 'info',
                            message: "无数据"
                        });
                    }
                }
                self.exportLoading = false;
                console.log(self.exportLoading);
            })
        },
    }
}
</script>