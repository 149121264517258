<template>
    
    <div class="user-layout">

        <permission-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title ="title"
            :search="search"
        ></permission-search>

        <permission-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >
            <template v-slot:action="action">
                <v-row justify="start">

                        <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                        <span class="table-btn-span" @click="handleEidt(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.systemManage.editBtn') }}
                        </span>

                        <div class="mx-1"></div>

                        <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                        <span class="table-btn-span" @click="handleDelete(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.systemManage.deleteBtn') }}
                        </span>
                
                </v-row>
            </template>
        </permission-data-table>

        <add-permission ref="add" @handleSearch="handleSearch"></add-permission>
        <edit-permission ref="edit" @handleSearch="handleSearch"></edit-permission>
        <del-permission ref="del" @handleSearch="handleSearch"></del-permission>

    </div>
</template>

<script>
import * as api from '../../api'
import PermissionSearch from './components/PermissionSearch.vue'
import PermissionDataTable from './components/PermissionDataTable.vue'
import AddPermission  from './components/AddPermission.vue'
import EditPermission from './components/EditPermission.vue'
import DelPermission  from './components/DelPermission.vue'

export default {

    components: {
        PermissionSearch,
        PermissionDataTable,
        AddPermission,
        EditPermission,
        DelPermission
    },

    data () {
      return {

            role: parseInt(window.sessionStorage.getItem('type')),

            title: this.$vuetify.lang.t('$vuetify.systemManage.permission.title'),

            dialog: false,

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.permission.table.name'),
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.permission.table.router'),
                    value: 'apis'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.systemManage.permission.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],

            data: [],

            loading: true,
            total  : 1000,

            search: {
                pagesize : 10,
                pagenum  : 1,
                name     : null,
            }
          
      }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.system.permissionListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                this.data = this.formatData(resp.data.list);
            }).finally(resp => {
                this.loading = false;
            })
        },

        formatData (value) {
            var n = value.length;
            for (var i = 0; i < n; i++) {
                var temp = value[i].apis.split(',');
                value[i].apis = temp;
            }
            return value;
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }
    }
}
</script>

<style scoped>

.user-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>