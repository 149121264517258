<template>
    <div>
        <el-dialog :visible="isBindDialogShow"
                   :before-close="closeBindGatewayDialog"
                   :title="'绑定网关'">
            <el-row>
                <el-col v-if="userType == 0"
                        :span="5">
                    <el-select v-model="groupID"
                               placeholder="请选择集团"
                               @change="handleGroupChange">
                        <el-option v-for="item in groupArr"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="5"
                        :offset="1"
                        v-if="userType == 0 || userType == 1"
                        >
                    <el-select v-model="shopID"
                               placeholder="请选择场所">
                        <el-option v-for="item in shopArr"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="5"
                        :offset="1">
                    <el-button type="primary"
                               @click="search">查询</el-button>
                </el-col>
            </el-row>
            <div style="height:20px"></div>
            <el-row>
                <el-col>
                    <el-transfer v-model="hasBindArr"
                                 filterable
                                 filter-placeholder="请输入网关名称"
                                 :data="bindList"
                                 :titles="['未绑定', '已绑定']"
                                 :button-texts="['移除', '绑定']"
                                 @change="handleBindChange"
                                 :props="{
                                     key:'gateway_id',
                                     label:'name'
                                 }"></el-transfer>
                </el-col>
            </el-row>
            <span slot="footer"
                  class="dialog-footer">
                <el-button type="primary"
                           @click="closeBindGatewayDialog">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from "../../../api";
export default {
    data() {
        return {
            groupID: "",
            shopID: "",
            //totalBindCount: 0,
            bindList: [],
            hasBindArr: [],
        };
    },
    props: {
        userType: {
            type: String,
        },
        groupArr: {
            type: Array,
        },
        shopArr: {
            type: Array,
        },
        isBindDialogShow: {
            type: Boolean,
        },
        alertID: {
            type: String,
        },
    },
    methods: {
        handleGroupChange(event) {
            this.shopID = 0;
            this.$emit("getShopList", event);
        },
        search() {
            if (!this.groupID && this.userType == 0) {
                this.$notify.error({ message: "请选择一个集团" });
                return;
            }
            this.$http
                .post(api.gateway.alertBindList, {
                    groupID: this.groupID,
                    shopID: this.shopID,
                    alertID: this.alertID,
                })
                .then((res) => {
                    if (res.errno == 0) {
                      for(let j = 0,len=res.data.hasBindArr.length; j < len; j++) {
                        res.data.hasBindArr[j] = res.data.hasBindArr[j]['gateway_id']
                      }
                      this.bindList = res.data.bindList;
                      this.hasBindArr = res.data.hasBindArr;
                    }
                });
        },
        closeBindGatewayDialog() {
            this.groupID = null;
            this.shopID = null;
            this.bindList = [];
            this.hasBindArr = [];
            this.$emit("closeBindGatewayDialog");
        },

        /**
         * @Description: 解绑/绑定相关操作
         * @author: lyc
         * @param {*} value 右侧的数据
         * @param {*} target 数据变动的方向
         * @param {*} changedValue 变动的数据
         * @return {*}
         * @Date: 2021-03-01 16:07:46
         */
        handleBindChange(value, target, changedValue) {
            if (target == "left") {
                //解绑
                this.$http
                    .post(api.gateway.alertBindDelete, {
                        alertID: this.alertID,
                        data: changedValue,
                    })
                    .then((res) => {
                        if (res.errno == 0) {
                            this.search();
                            this.$notify.success({
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    });
            }
            if (target == "right") {
                //绑定
                this.$http
                    .post(api.gateway.alertBindAdd, {
                        alertID: this.alertID,
                        data: changedValue,
                    })
                    .then((res) => {
                        if (res.errno == 0) {
                            this.search();
                            this.$notify.success({
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>