<template>
    <div>
        <v-row justify="center">

            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">
                        <v-icon large color="red">warning</v-icon>
                        {{title}}
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="red darken-1" @click="handleUpdateRadacctStatus" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.confirm')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {  
    data () {
        return {
            loading: false,
            dialog: false,
            id:0,
            status:0,
            title: this.$vuetify.lang.t('$vuetify.userManage.site.updateRadacctStatusTooltip')
        }
    },

    methods: {

        handleOpen (id,status) {
            this.id = id;
            this.status = status
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
        },

        handleUpdateRadacctStatus(){
            this.loading = true;
             var param = {
                id: this.id,
                radacct_status: this.status,
            }
            this.$http.post(api.default.rbac.setRadacctStatusUrl, param).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                 this.$emit('handleSearch');

            }).finally(resp => {
                this.loading = false
                this.handleClose();
            })
        },
    }
}
</script>