<template>
    
    <v-container fill-height fluid grid-list-xl>
        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <v-row>
                        <v-col cols="12">
                            <v-toolbar :flat="true">
                                <v-toolbar-title>
                                    <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                </v-toolbar-title>

                                <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                                
                                <v-btn color="primary" dark  @click="search">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.common.search')}}
                                    </span>
                                </v-btn>
                                <!-- 搜索按钮 -->
                                <v-btn color="primary ml-3" dark  @click="fresh">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.refresh')}}
                                    </span>
                                </v-btn>
                                <!-- 刷新按钮 -->
                            </v-toolbar>
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="group"
                                            v-model="searchFilter.groupVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                            @change="changeGroup"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择集团 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="shop"
                                            v-model="searchFilter.shopVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            @change="changeShop"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择场所 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="searchFilter.username"
                                            placeholder="请输入认证账号"
                                            label="账号"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入账号 -->
                                    <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                        <v-menu
                                            ref="menu"
                                            v-model="searchFilter.menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="searchFilter.date"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="searchFilter.date"
                                                    :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                                    prepend-icon="event"
                                                    readonly
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="searchFilter.date"
                                            range
                                            scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="searchFilter.menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(searchFilter.date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- 选择时间段 -->
                                </v-row>
                            </v-card-subtitle>
                            <!-- 选择框 -->
                        </v-col>
                    </v-row>
                </v-card>
                <!-- 搜索card -->
            </v-col>
            <!-- 搜索框 -->
            <v-col cols="12" md="12">
                <material-table
                    :title="table.title"
                    :columns="table.columns"
                    :data="table.data"
                    :currentPage="table.page"
                    :url="table.url"
                    method="post"
                    :filter="table.filter"
                    :searchValue="table.searchValue"
                    v-on:searchValue="setSearchValue"
                >
                    <template slot="action" slot-scope="propData">
                        <a v-if="propData.value.item.status === '在线'" class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="offlineDev(propData.value.item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.offline')}}
                            </v-icon>
                        </a>
                    </template>
                </material-table>
                
            </v-col>
            <!-- table -->
        </v-row>

    </v-container>

</template>

<script>
import api from '@/api.js'
import { Notification } from 'element-ui';
// import CsvExport from './../../utils/CsvExport'
export default {
    data () {
      return {
        table:{
            title:this.$vuetify.lang.t('$vuetify.portalMac.tableName'),
            columns: [
                { text: this.$vuetify.lang.t('$vuetify.portalMac.id'), value: 'id' },
                { text: "认证账号", value: 'username' },
                { text: "认证状态", value: 'result' },
                { text: "集团", value: 'group_name' },
                { text: "场所", value: 'shop_name' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.created_at'), value: 'create_at' },
            ],
            data: [],
            filter: {
                timeRange: [this.getBeforeDate(1) + ' 00:00:00', new Date().toISOString().substr(0, 10) + ' 23:59:59'],
                group_id: null,
                shop_id: null,
                status: null,
                type: null,
                authzone: null,
            },
            url:api.data.radAuthDetailList,
            page:1,
            searchValue: 0,
        },
        group: [],
        shop: [],
        searchFilter:{
            groupVal:null,
            shopVal:null,
            username:"",
            menu: false,
            date: [this.getBeforeDate(1), new Date().toISOString().substr(0, 10)],
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$vuetify.lang.t('$vuetify.portalMac.newItem') : this.$vuetify.lang.t('$vuetify.portalMac.editItem')
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchTable()
            this.initGroupShop()
        })
    },
    methods: {
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
            }
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            if(groupid == null){
                this.groupVal = null
                this.shopVal = null
            }
            this.fetchShopList(groupid)
            this.groupVal = groupid
        },
        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.shopVal = null
            this.shopVal = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },
        fresh(){
            this.fetchTable()
            this.table.searchValue = 1
        },
        search(){
            this.table.page = 1
            this.fetchTable()
            this.table.searchValue = 1
        },

        fetchTable(){
            this.table.filter = {
                timeRange: [this.searchFilter.date[0] + ' 00:00:00', this.searchFilter.date[1] + ' 23:59:59'],
                group_id:this.searchFilter.groupVal,
                shop_id:this.searchFilter.shopVal,
                username: this.searchFilter.username,
            }
        },

        offlineDev(item){
            const postData = {
                id: item.id
            }
            this.$http.post(api.data.offlineDevice,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.fetchTable()
                this.searchValue = 1
            }).finally(resp => {
                this.loading = false
            })
        },

        handleExport(){
            // CsvExport(this.headers,this.desserts,this.title);
        },
        editItem (item) {
            this.$router.push('/data/auth_record/'+item.id)
            // this.editedIndex = this.desserts.indexOf(item)
            // this.editedItem = Object.assign({}, item)
            // this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        }
    }
}
</script>

<style scoped>
.v-card__subtitle
{
    padding-top: 0px;
}
.v-toolbar
{
    margin-bottom: 0px;
}

</style>