<template>
	<div id="tables">
		<v-data-table v-if="showSelect" @input="handleInput" show-select  :headers="headers" :server-items-length="total" :items="tableData" item-key="id" class="elevation-1" @update:page="handlePage" @update:items-per-page="handlePageSize" :page="pages" :items-per-page="pageSize" :loading="loading" :loading-text="$vuetify.lang.t('$vuetify.dataIterator.loadingText')" >
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>{{titleTable}}</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<slot name="select"></slot>
					<v-spacer></v-spacer>
					<!--<v-text-field v-model="search" :label="$vuetify.lang.t('$vuetify.common.search')" hide-details></v-text-field>-->
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<slot name='action' :child='{ item }'></slot>
			</template>
		</v-data-table>
		<v-data-table v-if="!showSelect"   :headers="headers" :server-items-length="total" :items="tableData" item-key="id" class="elevation-1" @update:page="handlePage" @update:items-per-page="handlePageSize" :page="pages" :items-per-page="pageSize" :loading="loading" :loading-text="$vuetify.lang.t('$vuetify.dataIterator.loadingText')" >
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>{{titleTable}}</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<slot name="select"></slot>
					<v-spacer></v-spacer>
					<!--<v-text-field v-model="search" :label="$vuetify.lang.t('$vuetify.common.search')" hide-details></v-text-field>-->
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<slot name='action' :child='{ item }'></slot>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
		data:()=>({
			search:null,
		}),
		props: ['titleTable', 'headers', 'tableData', 'showSelect', 'pages', 'pageSize', 'total', 'loading'],
		mounted() {},
		methods: {
			handlePage(data) {
				this.$emit('page', data);
			},
			handlePageSize(data) {
				this.$emit('pageSize', data);
			},
			handleInput(data) {
				this.$emit('selectData', data);
			}
		},
	}
</script>

<style language="scss">
	/* #tables .v-btn__content{
		color:#fff!important;
	} */
	#tables .theme--light.v-btn.v-btn--icon {
		color: rgba(0, 0, 0, .54)!important;
	}
	
	#tables .span {
		padding-right: 6px;
	}
	
	#tables .span i {
		color: #1976D2!important;
	}
	
	#tables table thead tr th {
		color: #333;
		font-size: 17px;
		font-weight: 300;
	}
	
	#tables .v-card--material__header {
		width: 175px;
	}
</style>