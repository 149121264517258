<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title[tab] }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.systemManage.place.table.code')" 
                                        v-model="data.areacode"
                                        :rules="rules.areacode" 
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.systemManage.place.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        required
                                        outlined
                                    ></v-text-field>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.systemManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.systemManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

	props: {
        tab: Number,
        parentcode: String,
	},

    data () {
        return {

            title: [
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.province'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.city'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.area')
            ],

            loading: false,

            dialog: false,

            data: {
                areacode: null,
                name: null
            },

            rules: {
                areacode: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.systemManage.place.rules.code')
                ],
                name: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.systemManage.place.rules.name')
                ]
            }
            
        }
    },

    methods: {
        
        handleOpen () {
            this.dialog = true;
        },

        handleReset () {
            this.data = {
                areacode: null,
                name: null
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.data.parentcode = this.parentcode;
                this.$http.post(api.default.system.placeAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        }

    }
}
</script>

<style scoped>



</style>