<template>
    
    <div class="syslog-layout">

        <place-search
            @handleAdd="handleAdd"
            :title ="title"
            :tab="tab"
        ></place-search>

        <place-data-table
            @handleTab="handleTab"
            :data="data"
        >
            <template v-slot:action="action">
                <v-row justify="start">

                        <v-icon color="primary" small @click="handleEidt(action.value.selected)">edit</v-icon>
                        <span class="table-btn-span" @click="handleEidt(action.value.selected)">
                            {{ $vuetify.lang.t('$vuetify.systemManage.editBtn') }}
                        </span>

                        <div class="mx-1"></div>

                        <v-icon color="primary" small @click="handleDelete(action.value.selected)">delete</v-icon>
                        <span class="table-btn-span" @click="handleDelete(action.value.selected)">
                            {{ $vuetify.lang.t('$vuetify.systemManage.deleteBtn') }}
                        </span>
                
                </v-row>
            </template>
        </place-data-table>

        <add-place 
            ref="add" 
            :tab="tab"
            :parentcode="parentcode"
            @handleSearch="handleSearch"
        ></add-place>

        <edit-place 
            ref="edit"
            :tab="tab"
            @handleSearch="handleSearch"
        ></edit-place>

        <del-place ref="del" @handleSearch="handleSearch"></del-place>
    </div>
</template>

<script>
import * as api from '../../api'
import PlaceSearch from './components/PlaceSearch.vue'
import PlaceDataTable from './components/PlaceDataTable.vue'
import AddPlace  from './components/AddPlace.vue'
import EditPlace from './components/EditPlace.vue'
import DelPlace  from './components/DelPlace.vue'

export default {

    components: {
        PlaceSearch,
        PlaceDataTable,
        AddPlace,
        EditPlace,
        DelPlace
    },

    data () {
        return {
            tab: 0,

            data: [],

            parentcode: null,

            title: this.$vuetify.lang.t('$vuetify.systemManage.place.title'),
        }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
        },

        getData () {
            this.$http.post(api.default.system.placeListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.data = this.formatData(resp.data);
            })
        },

        formatData (value) {
            var n = value.length;
            for (var i = 0; i < n; i++) {
                value[i].children = [];
            }
            return value;
        },

        handleTab (value, data) {
            this.tab = value;
            this.parentcode = data.parentcode;
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.tab = 0;
            this.data = [];
            this.getData();
        }

    }
}
</script>

<style scoped>

.syslog-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>