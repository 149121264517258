<template>
    <div class="site-layout">
        <ac-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title ="title"
            :role  ="role"
            :group ="group"
            :place ="place"
            :search="search"
        ></ac-search>
        <ac-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search">
            <template v-slot:action="action">
                <v-row justify="start">
                    <v-icon color="primary" small @click="handleApMac(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleApMac(action.value.item)">
                        Ap组
                    </span>
                    <div class="mx-1"></div>
                    <v-icon color="primary" small @click="handleWebHook(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleWebHook(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.ac.table.webHook') }}
                    </span>
                    <div class="mx-1"></div>
                    <v-icon color="primary" small @click="handleRule(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleRule(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.scheduler.table.rule') }}
                    </span>
                    <div class="mx-1"></div>
                    <div v-if="role == 0">
                        <div class="mx-1"></div>
                        <v-icon color="primary" small @click="handleDefault(action.value.item)">loop</v-icon>
                        <span class="table-btn-span" @click="handleDefault(action.value.item)">
                            默认
                        </span>
                    </div>
                    <div class="mx-1"></div>
                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.editBtn') }}
                    </span>
                    <div class="mx-1"></div>
                    <v-icon v-if="role !== 2" color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span v-if="role !== 2" class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.deleteBtn') }}
                    </span>
                </v-row>
            </template>
        </ac-data-table>
        <add-ac 
            ref="add"
            :role="role"
            :group="group"
            @handleSearch="handleSearch"
        ></add-ac>
        <edit-ac 
            ref="edit"
            :role="role"
            :group="group"
            @handleSearch="handleSearch"
        ></edit-ac>
        <del-ac ref="del" @handleSearch="handleSearch"></del-ac>
        <set-apmac ref="apmac" @handleSearch="handleSearch"></set-apmac>
        <set-webhook ref="webhook" @handleSearch="handleSearch"></set-webhook>
        <!-- 切换默认认证域提示框 -->
        <v-dialogs theme="切换默认认证域" :dialog="dialog.default.show" @dialogSure="handleSetDefault">
            <v-col cols="12" sm="7" md="7">
                <span class="app-f16">是否要设置此认证域为默认认证域</span>
            </v-col>
        </v-dialogs>
        <ac-rule 
            ref="rule" 
            @handleSearch="handleSearch"
        ></ac-rule>
    </div>
</template>

<script>
import * as api from '../../api'
import AcSearch from './components/AcSearch.vue'
import AcDataTable from './components/AcDataTable.vue'
import AddAc from './components/AddAc.vue'
import EditAc from './components/EditAc.vue'
import DelAc from './components/DelAc.vue'
import SetApmac from './components/SetApmac.vue'
import SetWebhook from './components/SetWebhook.vue'
import vDialogs from '@/components/core/Dialog.vue'
import AcRule from './components/AcRule.vue'

import { Notification } from 'element-ui'
export default {
    components: {
        AcSearch,
        AcDataTable,
        AddAc,
        EditAc,
        DelAc,
        SetApmac,
        SetWebhook,
        vDialogs,
        AcRule
    },
    data () {
      return {
            role: parseInt(window.sessionStorage.getItem('type')),
            title: this.$vuetify.lang.t('$vuetify.portalManage.ac.title'),
            group: [],
            place: [],
            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.name'),
                    sortable: false,
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.identCode'),
                    sortable: false,
                    value: 'gwid'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.groupName'),
                    sortable: false,
                    value: 'groupName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.shopName'),
                    sortable: false,
                    value: 'shopName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.areaName'),
                    sortable: false,
                    value: 'area'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.scheduler'),
                    sortable: false,
                    value: 'scheduleName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.smsNet'),
                    sortable: false,
                    value: 'smsgatewayName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.smsNetInter'),
                    sortable: false,
                    value: 'smsgatewayinterName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.userAc'),
                    sortable: false,
                    value: 'usercheckName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.macAc'),
                    sortable: false,
                    value: 'maccheckName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.phoneAllowList'),
                    sortable: false,
                    value: 'phoneAllowListName'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.portalManage.ac.table.aaaClient'),
                    sortable: false,
                    value: 'openAAA'
                },
                {
                    text : "默认认证域",
                    sortable: false,
                    value: 'default_type'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.ac.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],
            data: [],
            loading: false,
            total  : 0,
            dialog:{
                default:{
                    show:false,
                    data:{}
                }
            },
            search: {
                pagesize : 10,
                pagenum  : 1,
                provincecode: null,
                citycode : null,
                areacode : null,
                groupID  : null,
                shopID   : null,
                name     : null
            }
      }
    },
    created () {
      this.initialize()
    },
    methods: {
        initialize() {
            this.getData();
            this.getGroup();
            this.getPlace();
        },
        getPlace () {
            this.$http.post(api.default.system.placeListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.place = resp.data;
            })
        },
        getGroup () {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },
        getData () {
            this.loading = true;
            this.$http.post(api.default.portal.acListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                this.data = resp.data.list;
            }).finally(resp => {
                this.loading = false;
            })
        },
        handleAdd () {
            this.$refs.add.handleOpen();
        },
        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },
        handleSetDefault (status) {
            if(!status)
            {
                this.handleDefault()
            }
            else
            {
                this.$http.post(api.default.portal.acSetDefaultUrl, this.dialog.default.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    if(resp.errno == 0)
                    {
                        this.getData();
                        Notification({
                            type: 'success',
                            message: this.$vuetify.lang.t('$vuetify.common.success')
                        });
                    }
                    else
                    {
                        Notification({
                            type: 'warning',
                            message: resp.errmsg
                        });
                    }
                    this.handleDefault();
                }).finally(resp => {
                    this.loading = false;
                })
                // console.log(this.dialog.default.data)
            }
        },

        handleDefault (item) {
            this.dialog.default.data = item
            this.dialog.default.show = !this.dialog.default.show
            // this.$refs.edit.handleOpen(item);
        },

        handleWebHook (item) {
            this.$refs.webhook.handleOpen(item);
        },

        handleRule (item) {
            this.$refs.rule.handleOpen(item);
        },

        handleApMac (item) {
            this.$refs.apmac.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }

    }
}
</script>

<style scoped>

.site-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>