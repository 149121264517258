<template>
	<div id="Devices">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-card>
						<v-row>
							<v-col cols="2" sm="2" md="2">
								<v-toolbar :flat="true">
									<v-toolbar-title>{{$vuetify.lang.t('$vuetify.gateway.selecTitle')}}</v-toolbar-title>
									<v-divider class="mr-0 ml-4" inset vertical></v-divider>
								</v-toolbar>
							</v-col>
							<v-col cols="5" sm="5" md="5">
								<v-row>
									<v-col cols="4" sm="4" md="4">
										<v-select @change="handleProChange" v-model="params.provinceCode" class="mt-3" :items="itemsPro" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaPro')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaPro')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleCityChange" v-model="params.cityCode" class="mt-3" :items="itemsCity" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaCity')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaCity')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleAreaChange" v-model="params.areaCode" class="mt-3" :items="itemsArea" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaArea')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaArea')" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="6" sm="6" md="6">
										<v-text-field class="mt-0" v-model="params.name" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.name')" :label="$vuetify.lang.t('$vuetify.gateway.label.name')" dense  append-outer-icon="search" @click:append-outer="handleAreaChange()"></v-text-field>
									</v-col>
									<v-col cols="6" sm="6" md="6">
										<v-text-field class="mt-0" v-model="params.mac" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.mac')" :label="$vuetify.lang.t('$vuetify.gateway.label.mac')"  @click:append-outer="handleAreaChange()" append-outer-icon="search" dense></v-text-field>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="2" sm="2" md="2">
								<v-select 
								v-if="userType ==0"  
								@change="handleGroupChange" 
								v-model="params.groupID" 
								class="mt-3" 
								:items="tempgroup" 
								item-value="id" 
								item-text="name" 
								:label="$vuetify.lang.t('$vuetify.gateway.label.company')" 
								:placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.company')" 
                                @click="searchgroup"
								dense>
                                <template v-slot:prepend-item>
                                        <v-text-field
                                            dense
                                            placeholder="搜索"
                                            v-model="groupname"
                                            outlined
                                        >
                                        <v-btn class="ma-2" slot="append" text icon small
                                            @click="searchgroup">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                        </v-text-field>
                                    </template>
								</v-select>
								<v-select @change="handleAreaChange" v-model="params.status" class="mt-3" :items="itemsStatus" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.status')" :label="$vuetify.lang.t('$vuetify.gateway.label.status')" dense></v-select>
							</v-col>
							<v-col cols="2" sm="2" md="2">
								<v-select 
								v-if="userType == 1||userType ==0" 
								@change="handleAreaChange" 
								:placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.place')" 
								v-model="params.shopID" class="mt-3" 
								:items="tempshop" item-value="id" item-text="name" 
								:label="$vuetify.lang.t('$vuetify.gateway.label.place')"
								@click="searchshop"
								dense>
									<template v-slot:prepend-item>
                                        <v-text-field
                                            dense
                                            placeholder="搜索"
                                            v-model="shopname"
                                            outlined
                                        >
                                        <v-btn
                                            class="ma-2"
                                            slot="append"
                                            text
                                            icon
                                            small
                                            @click="searchshop"
                                        >
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                        </v-text-field>
                                    </template>
								</v-select>
							</v-col>
						</v-row>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-container fill-height fluid grid-list-xl class="app-container">
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table 
						@selectData="handleSelectKey" 
						:showSelect="showSelect" 
						:pages="params.pageNum" 
						@pageSize="handlePageSize" 			
						@page="handlePage" 
						:loading="loading" 
						:pageSize="params.pageSize" 
						:total="params.total" 
						:titleTable="titleTable" 
						:headers="headers"
						:tableData='tableData'>
						<template slot="select">
							<v-btn dark color="primary" @click="showAdd">
								{{$vuetify.lang.t('$vuetify.gateway.btn.add')}}
							</v-btn>
							<v-btn dark color="primary" @click="showIssue">
                                {{ $vuetify.lang.t('$vuetify.gateway.btn.issue') }}
                            </v-btn>
                            <v-btn dark color="primary" @click="showMwanTop">
                                {{ $vuetify.lang.t('$vuetify.gateway.btn.mwantop') }}
                            </v-btn>
						</template>
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}
							</a>
							<a class="span" @click="showLook(propData.child.item)">
								<v-icon small>mdi-eye-outline</v-icon>{{$vuetify.lang.t('$vuetify.gateway.btn.look')}}
							</a>
							<a class="span" @click="showApplist(propData.child.item)">
								<v-icon small>mdi-keyboard-tab</v-icon>{{$vuetify.lang.t('$vuetify.appfilter.showApplist')}}
							</a>
                            <a class="span" @click="showMwan(propData.child.item)">
								<v-icon small>mdi-eye-outline</v-icon>{{$vuetify.lang.t('$vuetify.gateway.btn.showmwan')}}
							</a>
							<a class="span" @click="showDelete(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
							</a>
						</template>
					</v-table>
					<v-dialogs :theme="themeAdd" :dialog="dialogAdd" @dialogSure="handleEmitAdd">
                            <v-form
                                ref="form"
                                lazy-validation
                                >
                                <v-text-field 
                                    v-model="defaultItem.name" 
                                    @change="defaultItem.name=formSpaces(defaultItem.name)"
                                    :rules="rules.name"
                                    :label="$vuetify.lang.t('$vuetify.gateway.table.name')" 
                                ></v-text-field>
                                <v-text-field 
                                    v-model="defaultItem.mac" 
                                    :label="$vuetify.lang.t('$vuetify.gateway.table.mac')" 
                                    @change="defaultItem.mac=formSpaces(defaultItem.mac)"
                                    :rules="rules.mac"
                                ></v-text-field>
                                <v-text-field 
                                    v-model="defaultItem.manage_ip" 
                                    label="Ip(xx.xx.xx.xx/xx)" 
                                    @change="defaultItem.manage_ip=formSpaces(defaultItem.manage_ip)"
                                    :rules="rules.manage_ip"
                                ></v-text-field>
								<v-text-field 
                                    v-model="defaultItem.lanip" 
                                    label="LanIp(xx.xx.xx.xx/xx)" 
                                    @change="defaultItem.lanip=formSpaces(defaultItem.lanip)"
                                    :rules="rules.manage_ip"
                                ></v-text-field>
                                <v-select 
                                    v-if="userType == 0" 
                                    @change="handleAddCompanyChange" 
                                    v-model="defaultItem.groupID" 
                                    class="mt-6" 
                                    :items="addtempgroup" 
                                    item-value="id" 
                                    item-text="name" 
                                    :label="$vuetify.lang.t('$vuetify.gateway.label.company')" 
                                    dense
                                    :rules="rules.groupID"
                                    @click="searchaddgroup"
                                >
                                    <template v-slot:prepend-item>
                                        <v-text-field
                                            dense
                                            placeholder="搜索"
                                            v-model="addgroupname"
                                            outlined
                                        >
                                        <v-btn
                                            class="ma-2"
                                            slot="append"
                                            text
                                            icon
                                            small
                                            @click="searchaddgroup"
                                        >
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                        </v-text-field>
                                    </template>
                                </v-select>
                                <v-select  
                                    v-if="userType == 0||userType ==1" 
                                    v-model="defaultItem.shopID" 
                                    class="mt-6" 
                                    :items="addtempshop" 
									@click="searchaddshop"
                                    item-value="id" 
                                    item-text="name" 
                                    :label="$vuetify.lang.t('$vuetify.gateway.label.place')" 
                                    dense
                                    :rules="rules.shopID"
                                >
									<template v-slot:prepend-item>
                                        <v-text-field
                                            dense
                                            placeholder="搜索"
                                            v-model="addshopname"
                                            outlined
                                        >
                                        <v-btn
                                            class="ma-2"
                                            slot="append"
                                            text
                                            icon
                                            small
                                            @click="searchaddshop"
                                        >
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                        </v-text-field>
                                    </template>
								</v-select>
                            </v-form>
					</v-dialogs>
					<v-dialogs :theme="themeIssue" :dialog="dialogIssue" @dialogSure="handleissue">
							<v-form ref="form" lazy-validation>
								<v-select 
		                                    v-if="userType == 0" 
		                                    v-model="issueId" 
		                                    class="mt-6" 
		                                    :items="issueDataList" 
		                                    item-value="id" 
		                                    item-text="name" 
			                                @change="handleIssueChange" 
		                                    :label="$vuetify.lang.t('$vuetify.gateway.label.issue')" 
		                                    dense
		                                    :rules="rules.issueId"
		                                ></v-select>
							</v-form>
						</v-dialogs>
					<v-dialogs :theme="themeEdit" :dialog="dialogEdit" @dialogSure="handleEmitEdit">
						<v-col cols="12" sm="7" md="7">
                            <v-text-field 
                                v-model="defaultItem.name" 
                                :label="$vuetify.lang.t('$vuetify.gateway.table.name')" 
                                :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.inputname')"
                                @change="defaultItem.name=formSpaces(defaultItem.name)"
                                :rules="rules.name"
                            ></v-text-field>
                            <v-text-field 
                                v-model="defaultItem.mac" 
                                :label="$vuetify.lang.t('$vuetify.gateway.table.mac')" 
                                @change="defaultItem.mac=formSpaces(defaultItem.mac)"
                                :rules="rules.mac"
                            ></v-text-field>
                            <v-text-field 
                                v-model="defaultItem.manage_ip"
                                label="Ip(xx.xx.xx.xx/xx)"
                                @change="defaultItem.manage_ip=formSpaces(defaultItem.manage_ip)"
                                :rules="rules.manage_ip"
                            ></v-text-field>
							<v-text-field 
                                v-model="defaultItem.lanip"
                                label="LanIp(xx.xx.xx.xx/xx)"
                                @change="defaultItem.lanip=formSpaces(defaultItem.lanip)"
                                :rules="rules.lanip"
                            ></v-text-field>
						</v-col>
					</v-dialogs>
					<v-dialogs :theme="themeDelete" :dialog="dialogDelete" @dialogSure="handleEmitDelete">
						<v-col cols="12" sm="7" md="7">
							<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
						</v-col>
					</v-dialogs>
				</v-flex>
			</v-layout>
		</v-container>
		<el-dialog title="App List" :visible.sync="dialogShowInfo" :before-close="handleclose" width="80%">
            <AppfList :gatewayId="gateway_id"/>
        </el-dialog>
        <Mwanlog ref="mwanlog"/>
        <Mwantop ref="mwantop"/>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification } from 'element-ui';
	import { parseTime } from '../../utils/common.js'
	import { returnSearch } from '../../utils/common'
	import AppfList from '../Appfilter/AppfilterList.vue'
    import Mwanlog from './components/mwanlog.vue'
    import Mwantop from './components/mwantop.vue'

	export default {
		data() {
			return {
				dialogShowInfo:false,
				gateway_id:0,
				showSelect: true,
				themeIssue: this.$vuetify.lang.t('$vuetify.gateway.btn.issue'),
				themeAdd: this.$vuetify.lang.t('$vuetify.gateway.btn.add'),
				themeEdit: this.$vuetify.lang.t('$vuetify.gateway.btn.edit'),
				themeDelete: this.$vuetify.lang.t('$vuetify.gateway.btn.delete'),
				dialogIssue: false,
				dialogAdd: false,
				dialogEdit: false,
				dialogDelete: false,
				titleTable: this.$vuetify.lang.t('$vuetify.gateway.title'),
				headers: [
					// {
					// 	text: this.$vuetify.lang.t('$vuetify.gateway.table.pro'),
					// 	value: 'gatewaylist.provinceName',
					// },{
					// 	text: this.$vuetify.lang.t('$vuetify.gateway.table.city'),
					// 	value: 'gatewaylist.cityName',
					// },{
					// 	text: this.$vuetify.lang.t('$vuetify.gateway.table.area'),
					// 	value: 'gatewaylist.areaName',
					// },
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.company'),
						value: 'groupName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.place'),
						value: 'shopName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.name'),
						value: 'name',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.mac'),
						value: 'mac',
					},
					{
						text: 'Ip',
						value: 'manage_ip',
					},
					{
						text: 'LanIp',
						value: 'lanip',
					},
					{
						text: 'MWan',
						value: 'mwan',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.uptime'),
						value: 'heartbeat',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.userflow.inrate'),
						value: 'rx_bytes',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.userflow.maxinrate'),
						value: 'max_rx_bytes',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.dashboard.firstText2'),
						value: 'online_cunt',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.AppIdent.connection'),
						value: 'lp_cunt',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.version'),
						value: 'version',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.status'),
						value: 'online',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
					},
				],
				tableData:[],
				defaultItem: {
					id:null,
					name:null,
					mac:null,
					manage_ip:null,
					groupID:null,
					shopID:null,
					lanip:null,
				},
				issueId: null,
                rules: {
                    name: [
                        value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                        value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
                    ],
                    mac: [
                        // value =>  this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                        // value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
                    ],
                    manage_ip: [
                        // value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                        // value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
                    ],
                    groupID: [
                        //value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                        value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
                    ],
                    shopID: [
                        // value =>  this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                        value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
					],
					issueId: [
					value => (value !== null) || this.$vuetify.lang.t('$vuetify.gateway.rule.ins')
				]
                },
				//baseRule: [v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.base')],
				itemsPro: [],
				itemsCity: [],
				itemsArea: [],
				itemsCompany: [],
				itemsAddCompany: [],
				issueDataList: [],
				itemsPlace: [],
				itemsAddPlace: [],
				shopname:"",
            	tempshop: [],
				addshopname:"",
            	addtempshop: [],
                groupname:"",
                tempgroup: [],
				addgroupname:"",
            	addtempgroup: [],

				itemsStatus: [{
						label: this.$vuetify.lang.t('$vuetify.gateway.statusDown'),
						value: 0,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.gateway.statusUp'),
						value: 1,
					},
				],
				selectKeyData: [],
				loading: true,
				params: {
					pageSize: 10, // 每页返回记录数
					pageNum: 1, // 页数
					provinceCode: null, // 可选，省
					cityCode: null, // 可选，市
					areaCode: null, // 可选，区
					groupID: null, // 可选，集团ID
					shopID: null, // 可选，场所ID
					name: null, // 可选，名称
					mac: null, // 可选，MAC
					status: 10 // 可
				},
				loading:true,
				userType: sessionStorage.getItem('type'),
				Devcode: [],
				Command:null,
			}
		},
		watch: {
		},
		props: [

		],
		components: {
			vTable,
			vDialogs,
			AppfList,
            Mwanlog,
            Mwantop,
		},
		mounted() {
			this.placeListUrl('pro');
			if(this.userType == 0){
				this.groupListUrl();
			}else if(this.userType == 1){
				this.shopListUrl(1);
			}
			this.deviceList();
		},
		methods: {
            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },
			showApplist(data){
				this.gateway_id = data.id
				this.dialogShowInfo = true
			},
			handleclose(){
				this.dialogShowInfo = false
			},
			handleAddCompanyChange(){
				var params = {pagesize:10000,pagenum:1,groupID:this.defaultItem.groupID};
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
						this.itemsAddPlace = resp.data.list;
				}).finally(resp => {});	
			},
			// 下发指令选中下拉框触发事件
		handleIssueChange(item) {
			this.Command = item
		},
			handleGroupChange(){
				this.$http.post(api.default.rbac.shopListUrl,{pagesize:10000,pagenum:1,groupID:this.params.groupID}).then(resp => {
						this.itemsPlace = resp.data.list;
				}).finally(resp => {});
				this.deviceList();
			},
			shopListUrl(type){
				var params = {pagesize:10000,pagenum:1};
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
						this.itemsPlace = resp.data.list;
						this.itemsAddPlace = resp.data.list;
				}).finally(resp => {});
			},
			groupListUrl(){
				this.$http.post(api.default.rbac.groupListUrl).then(resp => {
					this.itemsCompany = resp.data;
					this.itemsAddCompany = resp.data;
				}).finally(resp => { })
				this.$http.get(api.default.rbac.groupCommandListUrl).then(resp => {
				this.issueDataList = resp.data
			}).finally(resp => { })
			},
			handlePage(data){
				this.params.pageNum = data;
				this.deviceList();
			},
			handlePageSize(data){
				this.params.pageSize = data;
				this.deviceList();
			},
			handleAreaChange(){
				this.params.pageNum=1
				this.deviceList();
			},
			handleCityChange(){
				this.placeListUrl('area',{areacode:this.params.cityCode});
				this.deviceList();
			},
			handleProChange(){
				this.placeListUrl('city',{areacode:this.params.provinceCode});
				this.deviceList();
			},
			placeListUrl(type,params){
				if(type=='pro'){
					params = {};
				}
				this.$http.post(api.default.system.placeListUrl,params).then(resp => {
					if(type=='pro'){
						this.itemsPro = resp.data;
					}else if(type=='city'){
						this.itemsCity = resp.data;
					}else if(type=='area'){
						this.itemsArea = resp.data;
					}
				}).finally(resp => {})	
			},
			deviceToaudit(){
				var params = {
				    idlist:[]
				};
				if(this.selectKeyData.length == 0)return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.gateway.rule.gateway')
				});
				for(var i=0;i< this.selectKeyData.length;i++){
					params.idlist.push(parseInt(this.selectKeyData[i].id));
				}
				this.$http.post(api.default.gateway.deviceToaudit,params).then(resp => {
					if(resp.errno == 0){
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})	
			},
			deviceDelete(){
				this.$http.get(api.default.gateway.deviceDelete+'?id='+this.defaultItem.id).then(resp => {
					if(resp.errno == 0){
						this.defaultItem.id = null;
						this.dialogDelete = false;
						this.deviceList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})	
			},
			deviceAdd(){
                if (this.$refs.form.validate()) {
                    this.$http.post(api.default.gateway.deviceAdd,
						{
							name:this.defaultItem.name,
							manage_ip:this.defaultItem.manage_ip,
							mac:this.defaultItem.mac,
							shopID:this.defaultItem.shopID,
							lanip:this.defaultItem.lanip
						}).then(resp => {
                        if(resp.errno == 0){
                            this.defaultItem.name = null;
                            this.defaultItem.mac = null;
                            this.defaultItem.shopID = null;
                            this.defaultItem.manage_ip = null;
							this.defaultItem.lanip = null;
                            this.dialogAdd = false;
                            this.deviceList();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {
                        this.formReset()
                    })	
                }
			},
			deviceList() {
				this.loading = true; 
				if(this.params.pageSize==-1)this.params.pageSize=10000
				this.$http.post(api.default.gateway.deviceList,this.params).then(resp => {
					this.params.total = parseInt(resp.data.total);
					this.tableData = []
					if (resp.data.list == null){
            			this.tableData = []
          			}else{
						for (var i = 0; i < resp.data.list.length; i++) {
                            var temp = resp.data.list[i].gatewaylist
                            temp["status"] = resp.data.list[i].status
							this.tableData.push(temp)
						}
          			}
						for (var i = 0; i < this.tableData.length; i++) {
							if (this.tableData[i].online == 0) {
								this.tableData[i].online = this.$vuetify.lang.t('$vuetify.gateway.statusDown')
						} else {
							this.tableData[i].online = this.$vuetify.lang.t('$vuetify.gateway.statusUp')
						}
						this.tableData[i].heartbeat = parseTime(this.tableData[i].heartbeat, '{y}-{m}-{d} {h}:{i}:{s}');
					this.tableData[i].rx_bytes = this.toBytes(this.tableData[i].rx_bytes, 1)
					this.tableData[i].tx_bytes = this.toBytes(this.tableData[i].tx_bytes, 1)
					this.tableData[i].max_rx_bytes = this.toBytes(this.tableData[i].max_rx_bytes, 0)
					this.tableData[i].max_tx_bytes = this.toBytes(this.tableData[i].max_tx_bytes, 0)
				}
					this.loading = false;
				}).finally(resp => {})	
			},
			
			showLook(data) {
				this.$router.push({
					'path': '/gateway/devices_list',
					'query':{
						id:data.id
					}
				})
			},
			showLogin(data) {
				console.log(data);
			},
			// 表格数据前的选择框点击触发事件
			handleSelectKey(data) {
				if (data.length == "") {
					this.Devcode = []
				} else {
					this.Devcode = []
					for (let i = 0; i < data.length; i++){
						this.Devcode.push(data[i].mac)
					}
				}
				this.selectKeyData = data;
			},
			handleEmitAdd(params) {
				if(!params) return this.dialogAdd = !this.dialogAdd;
                this.deviceAdd();
			},
			// 发送指令弹框确认按钮触发事件
			handleissue(params) {
				if (!params) return this.dialogIssue = !this.dialogIssue;
				if (this.$refs.form.validate()) {
					this.$http.post(api.default.rbac.groupCommandAddUrl, { Devcode: this.Devcode, Command: this.Command, }).then(resp => {
						if (resp.errno == 0) { 
							Notification({
								type: 'success',
								message: this.$vuetify.lang.t('$vuetify.common.success')
							});
							this.dialogIssue = !this.dialogIssue
						}
					}).finally(resp => { });
				}
			},
            formReset(){
                this.defaultItem = {
					id:null,
					name:null,
					mac:null,
					manage_ip:null,
					groupID:null,
					shopID:null,
					lanip:null,
                }
                this.$refs.form.resetValidation()
            },
			handleEmitEdit(params) {
                if(!params) {
                    this.deviceList(); 
                    return this.dialogEdit = !this.dialogEdit; 
                }
                if(!this.defaultItem.name||this.defaultItem.name=="") return Notification({
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.gateway.rule.name')
                });
                this.deviceEdit();
			},
			deviceEdit(){
                this.$http.post(api.default.gateway.deviceEdit,{
                    id:this.defaultItem.id,
                    name:this.defaultItem.name,
                    mac:this.defaultItem.mac,
                    manage_ip:this.defaultItem.manage_ip,
					lanip:this.defaultItem.lanip
                }).then(resp => {
					if(!resp) return
					this.defaultItem.name = null;
					this.defaultItem.id = null;
					this.dialogEdit = false;
					this.deviceList();
					Notification({
						type: 'success',
						message: this.$vuetify.lang.t('$vuetify.common.success')
					});
				}).finally(resp => {
                })	
			},
			handleEmitDelete(params) {
				if(!params) return this.dialogDelete = !this.dialogDelete;
				this.deviceDelete();
			},
			showAdd() {
				this.defaultItem.name = null;
				this.defaultItem.mac = null;
				this.defaultItem.shopID = null;
				this.defaultItem.groupID = null;
				this.defaultItem.manage_ip = null;
				this.defaultItem.lanip = null;
				this.dialogAdd = !this.dialogAdd;
			},
			showIssue() {
                this.dialogIssue = !this.dialogIssue;
            },
			showEdit(data) {
				this.defaultItem = data;
				this.dialogEdit = !this.dialogEdit;
			},
			showDelete(data) {
				this.defaultItem = data;
				this.dialogDelete = !this.dialogDelete;
			},
			searchshop(){
				this.tempshop = returnSearch(this.shopname,this.itemsPlace)
			},
			searchaddshop(){
				this.addtempshop = returnSearch(this.addshopname,this.itemsAddPlace)
			},
            searchgroup(){
				this.tempgroup = returnSearch(this.groupname,this.itemsCompany)
			},
			searchaddgroup(){
				this.addtempgroup = returnSearch(this.addgroupname,this.itemsAddCompany)
			},
			toBytes(bytes,type){
				var mbyte = 1048576
				var outstring = "0.00 KB/S"
				var kbytesfloat = bytes/(mbyte/1024)
				var timep = 10
				if (type ==1){
					timep = 300
				}
				if (kbytesfloat < 1024 ){
					return  (kbytesfloat/timep).toFixed(2)+" KB/S"
				}
				var mbytesfloat = bytes / mbyte
				if (mbytesfloat > 1024) {
					var gbytesfloat = mbytesfloat / 1024
					outstring = (gbytesfloat/timep).toFixed(2)+" G/S"
				} else {
					outstring = (mbytesfloat/timep).toFixed(2)+" MB/S"
				}
				return outstring
			},
            showMwan(data){
                this.$refs.mwanlog.open(data.id,data.status.simcard);
            },
            showMwanTop(){
                this.$refs.mwantop.open();
            },
		}
	}
</script>

<style language="scss">
	.uploadbox {
		position: relative;
		font-size: 14px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		overflow: hidden;
		cursor: pointer;
	}
	
	.uploadbox .upload {
		width: 64px;
		height: 36px;
		position: absolute;
		top: -8px;
		bottom: 0;
		left: -16px;
		right: 0;
		opacity: 0;
	}
	
	.add {
		display: block;
	}
</style>