<template>
    <el-row :gutter="20">
        <el-col :span="6">
           <AppTop></AppTop>
        </el-col>
        <el-col :span="18">
            <TopClient></TopClient>
        </el-col>
    </el-row>
</template>
<script>
import AppTop from './Apptop.vue'
import TopClient from './TopClient.vue'

export default {
    components: {
        AppTop,
        TopClient,
    },
    data () {
      return {
        
      }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
            }
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                this.group = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                this.shop = resp.data.list
            }).finally(resp => {
                this.loading = false
            })
        },
        /* 获取认证域列表 */
        fetchAuthzoneList(){
            const postData = {
                group_id: this.groupVal,
                shop_id: this.shopVal
            }
            this.$http.post(api.default.portal.zonenameListUrl,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authzones = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            if(groupid == null){
                this.groupVal = null
                this.shopVal = null
            }
            this.fetchShopList(groupid)
            this.fetchAuthzoneList()
            this.groupID = groupid
        },
        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.shopVal = null
            this.fetchAuthzoneList()
            this.shopID = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },
        fresh(){
            this.fetchTable()
            this.searchValue = 1
        },
        search(){
            this.page = 1
            this.fetchTable()
            this.searchValue = 1
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        },
    }
}

</script>