<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="450px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <div style="margin-top: 10px;">
                                请选择时间段
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                            mdi-lightbulb
                                        </v-icon>
                                    </template>
                                    <span style="color:white">时间单位为月。
                                        不选择结束月份:范围为起始-当前月份</span>
                                </v-tooltip>
                            </div>
                            <div style="margin-top: 20px;">
                                <v-date-picker
                                    style="width:100%"
                                    v-model="currentDate"
                                    no-title range scrollable
                                    type="month" 
                                    :show-current="true"
                                    >
                                </v-date-picker>
                            </div>
                        </v-container>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="handleClose">
                                {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                            </v-btn>
                            <v-btn color="primary" @click="handleSave">
                                {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

export default {

    data () {
        return {
            dialog: false,
            currentDate: [null,null],
        }
    },

    methods: {

        handleOpen (item) {
            this.currentDate = item
            this.dialog = true;
        },

        handleClose () {
            this.currentDate = [];
            this.dialog = false;
        },

        handleSave () {
            this.$emit('saveDate', this.currentDate);
            this.handleClose();
        },

    }
}
</script>