<template>
     <div id="Statistics" class="div_statistics">
        <v-row>
            <v-col cols="12" md="12">
                 <statistics-search
                    :userType="userType"
                    :groupArr="groupArr"
                    :shopArr="shopArr"
                    :searchParams="searchParams"
                    :loading="statistic_list.export_button.loading"
                    @exportStatement="exportStatement"
                    @handleGroupChange="handleGroupChange"
                    @handleShopChange="handleShopChange"
                    @getStatisticsData="fetchStatisticsData"/>
            </v-col>
        </v-row>
         <v-row>
            <v-col cols="12" md="12">
                <v-card
                    class="pa-2"
                    outlined
                    title
                    >
                    <!-- <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{ $vuetify.lang.t('$vuetify.radacctStatistic.tableTitle') }}</span>
                        </v-toolbar-title>
                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                    </v-toolbar> -->

                    <statistics-data-table
                        :loading="loading"
                        class="mt-8"
                        :columns="columns"
                        :data="data">

                    </statistics-data-table>         
                </v-card>
            </v-col>
        </v-row>
     </div>
</template>
<script>
import * as api from '../../api'
import StatisticsSearch from './component/StatisticsSearch.vue';
import StatisticsDataTable from './component/StatisticsDataTable.vue';
import moment from 'moment'
import axios from 'axios'

export default {
    components:{StatisticsSearch, StatisticsDataTable },
    data() {
        return {
            userType: sessionStorage.getItem("type"), 
            groupArr: [],
            shopArr: [],
            
            searchParams: {
                group_id: 0,
                shop_id: 0,
                search_start_date: moment().subtract(1, 'month').startOf('months').format('YYYY-MM'),//设置查询日期为当前月份上一个月
                search_stop_date:  moment().subtract(1, 'month').endOf('months').format('YYYY-MM'),
            },

            loading:false,
            statistic_list: {
                export_button: {
                    loading: false
                }
            },
            data:[],    //统计数据
            columns: [
                {
                    text: "集团名",
                    sortable: false,
                    value: 'group_name'
                },
                {
                    text: "场所名",
                    sortable: false,
                    value: 'shop_name'
                },
                {
                    text: "统计开始日期",
                    sortable: false,
                    value: 'radacct_start_time'
                },
                 {
                    text: "统计结束日期",
                    sortable: false,
                    value: 'radacct_stop_time'
                },
                {
                    text: "收费",
                    sortable: false,
                    value: 'price'
                },
            ],              //表格列名字
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchStatisticsData()
        })
    },
    methods: {
        handleGroupChange(groupid) {
            if (groupid == null) {
                this.searchParams.group_id = null
                this.searchParams.shop_id = null
            }
            this.fetchShopList(groupid)
            this.searchParams.group_id = groupid
        },
        handleShopChange(shopid) {
            if (typeof shopid == "undefined")
                this.searchParams.shop_id = null
            this.searchParams.shop_id = shopid
        },

        //请求集团列表
        fetchGroupList() {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {

                this.groupArr = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },

        //请求场所列表
        fetchShopList(groupid) {
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl, postData).then(resp => {

                this.shopArr = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },

        //请求计费数据
        fetchStatisticsData() {
            this.loading = true
            this.searchParams.search_start_date = moment(this.searchParams.search_start_date).startOf("month").format("YYYY-MM-DD 00:00:00");
            this.searchParams.search_stop_date = moment(this.searchParams.search_stop_date).endOf("month").format("YYYY-MM-DD 23:59:59");

            this.$http.post(api.default.radacct.statisticsList, this.searchParams).then(resp => {
                if (!resp) return;
                if (resp.data.list == null) {
                    this.data = []
                } else {
                    this.data = resp.data.list;
                }

            }).finally(resp => {
                this.loading = false
            })
        },

        //导出报表
        exportStatement(){
            this.statistic_list.export_button.loading = true

            this.searchParams.search_start_date = moment(this.searchParams.search_start_date).startOf("month").format("YYYY-MM-DD 00:00:00");
            this.searchParams.search_stop_date = moment(this.searchParams.search_stop_date).endOf("month").format("YYYY-MM-DD 23:59:59");

            axios({
                method: 'POST',
                url: api.default.radacct.export,
                data: this.searchParams,
                responseType: 'blob'
            }).then(response => {
                if (response.status != 200 || !response.data) {
                    Notification({
                        type: 'warning',
                        message: "导出失败"
                    });
                    return
                }
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '计费记录-'+this.searchParams.search_start_date+'~'+
                    this.searchParams.search_stop_date+'.xlsx')

                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                Notification({
                    type: 'warning',
                    message: "导出失败"
                });
                return
            }).finally(resp => {
                this.statistic_list.export_button.loading = false
            })

        }
    },
}
</script>
<style lang="scss" scoped>
.div_statistics{
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}
</style>