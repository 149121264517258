<template>
    <v-container>
        <v-row>
            <v-col>
                <material-table
                    ref="apgroup_list"
                    title="Ap组列表"
                    :url="ap_group.table.api"
                    :columns="ap_group.table.headers"
                    :data="ap_group.table.list"
                    :filter="ap_group.table.filter"
                    :searchValue="ap_group.table.searchValue"
                >
                    <template slot="title">
                        <v-btn color="primary" @click="searchTable('apgroup_list')">搜索</v-btn>
                        <v-btn color="primary" class="ml-5" @click="showDialog('apgroup_add')">添加Ap组</v-btn>
                    </template>
                    <!-- title -->
                    <template slot="body">
                        <v-card-subtitle>
                            <v-row>
                                <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="ap_group.table.filter.apgroup_name"
                                        placeholder="请输入Ap组名"
                                        label="Ap组名"
                                        append-outer-icon="search"
                                        @click:append-outer="searchTable('apgroup_list')"
                                        ></v-text-field>
                                </v-col>
                                <!-- Apmac -->
                                <v-col cols="12" xs="12" sm="4" md="3" class="pt-0 pb-0">
                                    <v-autocomplete
                                        v-model="ap_group.table.filter.group_id"
                                        @change="handleSearchGroupChange" 
                                        :items="ap_group.group_list"
                                        label="集团"
                                        placeholder="请选择所属集团"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <!-- 集团 -->
                                <v-col cols="12" xs="12" sm="4" md="3" class="pt-0 pb-0">
                                    <v-autocomplete
                                        v-model="ap_group.table.filter.shop_id"
                                        :items="ap_group.table.shop_list"
                                        label="场所"
                                        placeholder="请选择所属场所"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <!-- 场所 -->
                            </v-row>
                        </v-card-subtitle>
                        <!-- 选择框 -->
                    </template>
                    <!-- search -->
                    <template slot="action" slot-scope="data">
                        <a class="span" @click="showApGroupEdit(data.value.item)"><v-icon small>mdi-pencil</v-icon> 编辑</a>
                        <a class="span" @click="showApGroupDel(data.value.item)"><v-icon small>mdi-trash-can-outline</v-icon> 删除</a>
                    </template>
                    <!-- action -->
                </material-table>
                <!-- Ap组列表 -->
                <material-dialog ref="apgroup_add" formTitle="添加AP组" @handleSave="doApgroupAdd">
                    <template slot="content">
                        <v-form ref="apgroup_add_form" lazy-validation>
                            <v-text-field v-model="ap_group.add_dialog.data.apgroup_name" label="组名"></v-text-field>
                            <v-autocomplete
                                v-model="ap_group.add_dialog.data.group_id"
                                @change="handleAddGroupChange" 
                                :items="ap_group.group_list"
                                label="集团"
                                placeholder="请选择所属集团"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.add_dialog.data.shop_id"
                                :items="ap_group.add_dialog.shop_list"
                                label="场所"
                                placeholder="请选择所属场所"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.add_dialog.data.authzone_id"
                                :items="ap_group.authzone_item"
                                label="认证域"
                                placeholder="请选择认证域"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.add_dialog.data.nas_id"
                                :items="ap_group.nas_item"
                                label="3A客户端"
                                placeholder="请选择3A客户端"
                                item-text="nasid"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                        </v-form>
                    </template>
                </material-dialog>
                <!-- Ap组添加框 -->
                <material-dialog ref="apgroup_edit" formTitle="修改AP组" @handleSave="doApgroupEdit">
                    <template slot="content">
                        <v-form ref="apgroup_edit_form" lazy-validation>
                            <v-text-field v-model="ap_group.edit_dialog.data.apgroup_name" label="组名"></v-text-field>
                            <v-autocomplete
                                v-model="ap_group.edit_dialog.data.group_id"
                                @change="handleEditGroupChange" 
                                :items="ap_group.group_list"
                                label="集团"
                                placeholder="请选择所属集团"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.edit_dialog.data.shop_id"
                                :items="ap_group.edit_dialog.shop_list"
                                label="场所"
                                placeholder="请选择所属场所"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.edit_dialog.data.authzone_id"
                                :items="ap_group.authzone_item"
                                label="认证域"
                                placeholder="请选择认证域"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="ap_group.edit_dialog.data.nas_id"
                                :items="ap_group.nas_item"
                                label="3A客户端"
                                placeholder="请选择3A客户端"
                                item-text="nasid"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                        </v-form>
                    </template>
                </material-dialog>
                <!-- Ap组修改框 -->
                <material-dialog ref="apgroup_del" formTitle="删除AP组" @handleSave="doApGroupDel">
                    <template slot="content">
                        确认是否删除？<span style="color:red;">Ap组下所属Ap将被删除</span>
                    </template>
                </material-dialog>
                <!-- Ap组删除框 -->
            </v-col>
        </v-row>
        <!-- AP组 -->
        <v-row>
            <v-col>
                <material-table
                    ref="ap_list"
                    title="Ap列表"
                    :url="ap.table.api"
                    :columns="ap.table.headers"
                    :data="ap.table.list"
                    :filter="ap.table.filter"
                    :searchValue="ap.table.searchValue"
                >
                    <template slot="title">
                        <v-btn color="primary" @click="searchTable('ap_list')">搜索</v-btn>
                        <v-btn color="primary" class="ml-5" @click="showDialog('ap_add')">添加Ap</v-btn>
                        <v-btn color="primary" class="ml-5" :loading="ap.table.button.download_ap_template_loading" @click="downloadApimportTemplate()">下载模板</v-btn>
                        <v-btn color="primary" class="ml-5" @click="showDialog('ap_import')">导入模板</v-btn>
                    </template>
                    <template slot="body">
                        <v-card-subtitle>
                            <v-row>
                                <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="ap.table.filter.apmac"
                                        placeholder="请输入Apmac"
                                        label="Apmac"
                                        append-outer-icon="search"
                                        @click:append-outer="searchTable('ap_list')"
                                        ></v-text-field>
                                </v-col>
                                <!-- Apmac -->
                                <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0"> 
                                    <v-autocomplete
                                        v-model="ap.table.filter.apgroup_id"
                                        :items="ap.apgroup_list"
                                        label="Ap组列表"
                                        placeholder="请选择Ap组"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        append-outer-icon="search"
                                        @click:append-outer="searchTable('ap_list')"
                                    ></v-autocomplete>
                                </v-col>
                                <!-- Apmac -->
                            </v-row>
                        </v-card-subtitle>
                        <!-- 选择框 -->
                    </template>
                    <template slot="action" slot-scope="data">
                        <a class="span" @click="showApEdit(data.value.item)"><v-icon small>mdi-pencil</v-icon> 编辑</a>
                        <a class="span" @click="showApDel(data.value.item)"><v-icon small>mdi-trash-can-outline</v-icon> 删除</a>
                    </template>
                </material-table>
                <!-- Ap组列表 -->
                <material-dialog ref="ap_add" formTitle="添加AP" @handleSave="doApAdd">
                    <template slot="content">
                        <v-form ref="ap_add_form" lazy-validation>
                            <v-text-field v-model="ap.add_dialog.data.apmac" label="Mac地址"></v-text-field>
                            <v-autocomplete
                                v-model="ap.add_dialog.data.apgroup_id"
                                :items="ap.apgroup_list"
                                label="Ap组"
                                placeholder="请选择Ap组"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-text-field v-model="ap.add_dialog.data.remark" label="备注"></v-text-field>
                        </v-form>
                    </template>
                </material-dialog>
                <!-- Ap添加框 -->
                <material-dialog ref="ap_edit" formTitle="添加AP" @handleSave="doApEdit">
                    <template slot="content">
                        <v-form ref="ap_edit_form" lazy-validation>
                            <v-text-field v-model="ap.edit_dialog.data.apmac" label="Mac地址"></v-text-field>
                            <v-autocomplete
                                v-model="ap.edit_dialog.data.apgroup_id"
                                :items="ap.apgroup_list"
                                label="Ap组"
                                placeholder="请选择Ap组"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-autocomplete>
                            <v-text-field v-model="ap.edit_dialog.data.remark" label="备注"></v-text-field>
                        </v-form>
                    </template>
                </material-dialog>
                <!-- Ap修改框 -->
                <material-dialog ref="ap_del" formTitle="删除AP" @handleSave="doApDel">
                    <template slot="content">
                        确认是否删除信息？
                    </template>
                </material-dialog>
                <!-- Ap删除框 -->
                <material-dialog ref="ap_import" formTitle="导入AP" @handleSave="doApImport">
                    <template slot="content">
                        <v-form ref="ap_import_form" lazy-validation>
                            <v-file-input
                                v-model="ap.import_dialog.data.file"
                                accept=".xlsx,.xls"
                                label="选择你要导入的文件">
                            </v-file-input>
                        </v-form>
                    </template>
                </material-dialog>
                <!-- Ap导入 -->
            </v-col>
        </v-row>
        <!-- AP列表 -->
    </v-container>
</template>
<script>
import axios from 'axios'
import api from '@/api.js'
import { Notification } from 'element-ui'
export default {
    data() {
        return {
            ap_group: {
                table: {
                    api:api.device.apGroupList,
                    searchValue:0,
                    headers: [
                        { text: '组名', value: 'name' },
                        { text: '集团名', value: 'group_name' },
                        { text: '场所名', value: 'shop_name' },
                        { text: '认证域名', value: 'authzone_name' },
                        { text: '操作', value: 'action'}
                    ],
                    list: [],
                    filter: {
                        apgroup_name: "",
                        group_id: null,
                        shop_id: null
                    },
                    shop_list:[]
                },
                group_list:[],
                authzone_item:[],
                nas_item:[],
                add_dialog: {
                    shop_list:[],
                    data:{
                        apgroup_name: "",
                        group_id: null,
                        shop_id: null,
                        authzone_id: null,
                        nas_id:null,
                    }
                },
                edit_dialog: {
                    shop_list:[],
                    data:{
                        apgroup_id: null,
                        apgroup_name: "",
                        group_id: null,
                        shop_id: null,
                        authzone_id: null,
                        nas_id:null,
                    }
                },
                del_dialog: {
                    data: {
                        apgroup_id: null
                    }
                }
            },
            ap: {
                table: {
                    button:{
                        download_ap_template_loading: false
                    },
                    api:api.device.apList,
                    searchValue:0,
                    headers: [
                        { text: 'Mac地址', value: 'mac' },
                        { text: 'Ap组名', value: 'apgroup_name' },
                        { text: '备注', value: 'remark' },
                        { text: '操作', value: 'action'}
                    ],
                    list: [],
                    filter: {
                        apmac: "",
                        apgroup_id: null,
                    }
                },
                add_dialog: {
                    data:{
                        apmac: "",
                        remark: "",
                        apgroup_id: null,
                    }
                },
                edit_dialog: {
                    data:{
                        ap_id: null,
                        apmac: "",
                        remark: "",
                        apgroup_id: null,
                    }
                },
                del_dialog: {
                    data: {
                        ap_id: null
                    }
                },
                import_dialog: {
                    data: {
                        file: null
                    }
                },
                apgroup_list:[],
            }
        }
    },
    mounted() {
        this.getGroupList()
        this.getAllApGroupList()
        this.getAuthzoneList()
        this.getNasList()
    },
    methods : {
        /**
         * 通用函数
         */
        showDialog(ref_name){
            this.$refs[ref_name].showDialog()
        },
        closeDialog(ref_name){
            this.$refs[ref_name].closeDialog()
        },
        reflushTable(ref_name){
            if(ref_name == "apgroup_list")
            {
                this.getAllApGroupList()
            }
            this.$refs[ref_name].getDataFromApi()
        },
        searchTable(ref_name){
            this.$refs[ref_name].searchTable()
        },
        /**
         * 显示Dialog
         */
        showApGroupEdit(item){
            this.ap_group.edit_dialog.data = {
                apgroup_id: item.id,
                apgroup_name: item.name,
                group_id: item.group_id,
                shop_id: item.shop_id,
                authzone_id: item.authzone_id,
                nas_id: item.nas_id,
            }
            this.handleEditGroupChange()
            this.showDialog("apgroup_edit")
        },
        showApGroupDel(item){
            this.ap_group.del_dialog.data = {
                apgroup_id: item.id
            }
            this.showDialog("apgroup_del")
        },
        showApEdit(item) {
            this.ap.edit_dialog.data = {
                ap_id: item.id,
                apmac: item.mac,
                remark: item.remark,
                apgroup_id: item.group_id
                
            }
            this.showDialog("ap_edit")
        },
        showApDel(item) {
            this.ap.del_dialog.data = {
                ap_id: item.id
            }
            this.showDialog("ap_del")
        },
        /**
         * 获取选择列表
         */
        getGroupList(){
            this.$http.post(api.rbac.groupListUrl).then(resp => {
                if(resp.errno !== 0)
                    return
                this.ap_group.group_list = resp.data
            }).finally(resp => {})
        },
        getShopList(groupID){
            let _this = this
            let pagesize = -1
            let pagenum = 1
            return new Promise((resolve,reject) => {
                _this.$http.post(api.rbac.shopListUrl,{
                    pagesize: pagesize,
                    pagenum: pagenum,
                    groupID: groupID
                }).then(resp => {
                    if(resp.errno !== 0)
                        return
                    resolve(resp.data);
                }).finally(resp => {})
            })
        },
        getAuthzoneList(){
            this.$http.post(api.portal.acListUrl,{pagesize:-1,pagenum: 1}).then(resp => {
                if(resp.errno !== 0)
                    return
                this.ap_group.authzone_item = resp.data.list
            }).finally(resp => {})
        },
        getAllApGroupList(){
           this.$http.post(api.device.apGroupList,{pageSize:-1,currentPage:1}).then(resp => {
                if(resp.errno !== 0)
                    return
                this.ap.apgroup_list = resp.data.list
            }).finally(resp => {}) 
        },
        getNasList(){
            this.$http.post(api.aaa.clientList,{pagesize:0,pagenum: 1}).then(resp => {
                if(resp.errno !== 0)
                    return
                this.ap_group.nas_item = resp.data.list
            }).finally(resp => {})
        },
        /**
         * Ap组
         */
        handleAddGroupChange(val){
            this.getShopList(val).then((res) => {
                this.ap_group.add_dialog.shop_list = res.list
            })
        },
        doApgroupAdd(){
            this.$http.post(api.device.addApGroup, this.ap_group.add_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.apgroup_add_form.reset()
                this.reflushTable("apgroup_list")
                this.closeDialog("apgroup_add")
            }).finally(resp => {})
        },
        handleEditGroupChange(){
            let group_id_checked = this.ap_group.edit_dialog.data.group_id
            if(typeof group_id_checked == 'undefined' || group_id_checked == null)
            {
                this.ap_group.edit_dialog.data.shop_id = null
                this.ap_group.edit_dialog.shop_list = []
            }
            else
            {
                // 获取shoplist
                this.getShopList().then((res) => {
                    this.ap_group.edit_dialog.shop_list = res.list
                })
            }
        },
        doApgroupEdit(){
            this.$http.post(api.device.editApGroup, this.ap_group.edit_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                this.reflushTable("apgroup_list")
                this.reflushTable("ap_list")
                this.closeDialog("apgroup_edit")
            }).finally(resp => {})
        },
        doApGroupDel(){
          var url = api.device.delApGroup + '?id=' + this.ap_group.del_dialog.data.apgroup_id;
          this.$http.get(url).then(resp => {
            if(resp.errno !== 0)
              return
            Notification({
              type: 'success',
              message: this.$vuetify.lang.t('$vuetify.common.success')
            });
            this.reflushTable("apgroup_list")
            this.reflushTable("ap_list")
            this.closeDialog("apgroup_del")
            this.$emit('handleSearch');
          }).finally(resp => {})
        },
        handleSearchGroupChange(val){
            // 获取shoplist
            this.getShopList(val).then((res) => {
                this.ap_group.table.shop_list = res.list
            })
        },
        /**
         * Ap
         */
        doApAdd(){
            this.$http.post(api.device.addAp, this.ap.add_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.ap_add_form.reset()
                this.reflushTable("ap_list")
                this.closeDialog("ap_add")
            }).finally(resp => {})
        },
        doApEdit(){
            this.$http.post(api.device.editAp, this.ap.edit_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                this.reflushTable("ap_list")
                this.closeDialog("ap_edit")
            }).finally(resp => {})
        },
        doApDel(){
          var url = api.device.delAp + '?id=' + this.ap.del_dialog.data.ap_id;
          this.$http.get(url).then(resp => {
            if(resp.errno !== 0)
              return
            Notification({
              type: 'success',
              message: this.$vuetify.lang.t('$vuetify.common.success')
            });
            this.reflushTable("ap_list")
            this.closeDialog("ap_del")
          }).finally(resp => {})
        },
        /* 下载导入模板 */
        downloadApimportTemplate(){
            axios({
                method: 'POST',
                url: api.device.getApimportTemplateUrl,
                responseType: 'blob'
            }).then(response => {
                if(response.status != 200 || !response.data)
                {
                    Notification({
                        type: 'warning',
                        message: "导出失败"
                    });
                    return
                }
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', 'Ap列表模板.xlsx')
                
                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                Notification({
                    type: 'warning',
                    message: "导出失败"
                });
                return
            }).finally(resp => {
                this.authdetail_list.export_button.loading = false
            })
        },
        /* 导入Ap */
        doApImport(){
            var formData = new FormData()
            formData.append('apimport_template', this.ap.import_dialog.data.file)
            this.$http.post(api.device.importAp, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
            }).then(resp => {
                if(resp.errno != '0')
                    return false;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                this.$refs.ap_import_form.reset()
                this.reflushTable("ap_list")
                this.closeDialog("ap_import")
            }).finally(resp => {})
        }
    }
}
</script>
