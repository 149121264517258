<template> 
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                        </v-toolbar-title>
                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                        <v-btn color="primary" dark  @click="search">
                            <span>
                                {{$vuetify.lang.t('$vuetify.common.search')}}
                            </span>
                        </v-btn>
                        <!-- 搜索按钮 -->
                        <v-btn color="primary ml-3" dark  @click="fresh">
                            <span>
                                {{$vuetify.lang.t('$vuetify.refresh')}}
                            </span>
                        </v-btn>
                        <!-- 刷新按钮 -->
                    </v-toolbar>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-select
                                    :items="group"
                                    v-model="groupVal"
                                    item-value="id" 
                                    item-text="name"
                                    clearable
                                    :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                                    :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                    @change="changeGroup"
                                ></v-select>
                            </v-col>
                            <!-- 选择集团 -->
                            <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-select
                                    :items="shop"
                                    v-model="shopVal"
                                    item-value="id" 
                                    item-text="name"
                                    clearable
                                    @change="changeShop"
                                    :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                                    :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                ></v-select>
                            </v-col>
                            <!-- 选择场所 -->
                            <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-select
                                    :items="authzones"
                                    v-model="authzone"
                                    item-value="id" 
                                    item-text="name"
                                    clearable
                                    :label="$vuetify.lang.t('$vuetify.portalMac.pick_auth_name')"
                                    :placeholder="$vuetify.lang.t('$vuetify.portalMac.auth_name')"
                                ></v-select>
                            </v-col>
                            <!-- 选择认证域名 -->
                            <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="date"
                                            :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date"
                                    range
                                    scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- 选择时间段 -->
                        </v-row>
                    </v-card-subtitle>
                    <!-- 选择框 -->
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <material-stats-card
					color="cyan lighten-2"
					icon="mdi-access-point-network "
					:title="$vuetify.lang.t('$vuetify.dashboard.firstTitle1')"
					:value="firstValue1"
					sub-icon="mdi-tag"
					:sub-text="$vuetify.lang.t('$vuetify.dashboard.firstText1')"
				/>
            </v-col>
			<v-col cols="12" md="3">
				<material-stats-card
					color="blue lighten-2"
					icon="mdi-access-point"
					:title="$vuetify.lang.t('$vuetify.dashboard.firstTitle2')"
					:value="firstValue2"
					sub-icon="mdi-tag"
					:sub-text="$vuetify.lang.t('$vuetify.dashboard.firstText2')"
				/>
			</v-col>
			<v-col cols="12" md="3">
				<material-stats-card
					color="deep-purple lighten-2"
					icon="mdi-account-group-outline"
					:title="$vuetify.lang.t('$vuetify.dashboard.firstTitle3')"
					:value="firstValue3"
					sub-icon="mdi-tag"
					:sub-text="$vuetify.lang.t('$vuetify.dashboard.firstText3')"
				/>
			</v-col>
			<v-col cols="12" md="3">
				<material-stats-card
					color="indigo lighten-2"
					icon="mdi-devices"
					:title="$vuetify.lang.t('$vuetify.dashboard.firstTitle4')"
					:value="firstValue4"
					sub-icon="mdi-tag"
					:sub-text="$vuetify.lang.t('$vuetify.dashboard.firstText4')"
				/>
			</v-col>
        </v-row>
        <!-- 用户认证趋势图 -->
        <v-row>
            <v-col cols="12" md="12">
                <v-card
                class="pa-2"
                outlined
                tile
                >
                <v-card-title style="font-size: 16px;
                    color: #9AA4AF;
                    font-weight: normal;
                    padding: 7px 0 10px 23px;
                    pointer-events: none;">
                {{$vuetify.lang.t('$vuetify.dashboard.secondLineTitle')}}
                    
                </v-card-title>

                    <v-row
                        align="center"
                        class="mx-0"
                    >
                        <div id="myChart2" :style="{width: '100%', height: '300px',top:'0'}"></div>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>

</template>

<script>
import Echarts from 'echarts'
import countTo from 'vue-count-to'
import * as api from '../../api'

export default {
    components: { countTo },
    data () {
        return {
            firstValue1: '',
            firstValue2: '',
            firstValue3: '',
            firstValue4: '',
            toggle_exclusive: 2,
            chart2_series: [],
            
            subIcon: undefined,
            subIconColor: undefined,
            subTextColor: undefined,
            subText: undefined,
            color: 'red',

            count1_1: 0,
            count1_2: 0,
            top3: [],
            count2_1: 0,
            unit: 'B',
            count2_2: 0,
            count2_3: 0,
            count4_1: 0,
            Total:10,

            icon: 'mdi-bell-outline',
            warning_smallValue: null,
            warning_subIcon: 'mdi-alert',
            warning_value: 0,
            gaugeData: [],

            chart2: null,

            date: [this.getBeforeDate(1), new Date().toISOString().slice(0, 10)],
            menu: false,
            group: [],
            shop: [],
            authzones: [],
            groupVal: 0,
            shopVal: 0,
            authzone: 0,
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.drawPart1()
            this.drawPart2()
            this.initGroupShop()
        })
        
        window.onresize = () => {
            this.chart2.resize();
        }

        
    },
    methods: {
        bytesToSize(bytes) {
            if (bytes === 0) return '0 B';
            var k = 1000, // or 1024
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
        
            return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + (i>0?sizes[i]:'');
        },
        drawPart1(){
            var postData = {
                "authzone":this.authzone,
                "group_id":this.groupVal,
                "shop_id":this.shopVal,
                "time_range":this.date,
            }
            this.$http.post(api.default.dashboard.index,postData).then(resp => {
                if(resp){

                    this.firstValue1= resp.data.gateway_on + '|' + resp.data.gateway_all
                    this.firstValue2= resp.data.user + ''
                    this.firstValue3= resp.data.user_max + ''
                    this.firstValue4= resp.data.register_count + ''
                }else{
                    this.firstValue1= '/'
                    this.firstValue2= "/"
                    this.firstValue3= "/"
                    this.firstValue4= "/"
                }

            }).finally(resp => {
                this.loading = false
            })
            
        },
        drawPart2(){
            var postData = {
                "authzone":this.authzone,
                "group_id":this.groupVal,
                "shop_id":this.shopVal,
                "time_range":this.date,
            }
            let myChart = Echarts.init(document.getElementById('myChart2'))
            this.chart2 = myChart

            this.$http.post(api.default.dashboard.dayauth,postData).then(resp => {
                if(!resp) return
                let data = []
                let xAxis = []
                if(resp.data.length){
                    for (let index = 0; index < resp.data.length; index++) {
                        data.push(parseInt(resp.data[index]['count']))
                        xAxis.push(resp.data[index]['date'])
                    }
                    myChart.setOption({
                        backgroundColor: '#FFF',
                        grid: {
                            top: '9%',
                            bottom: '19%',
                            left: '6%',
                            right: '4%'
                        },
                        tooltip: {
                            trigger: 'axis',
                            label: {
                                show: true
                            }
                        },
                        color:['#7986CB','#81C784','#64B5F6','#4DB6AC','#4DD0E1','#AED581','#9575CD'],
                        legend: {
                            icon: "circle",
                            data: ['总用户','新用户','老用户']
                        },
                        xAxis: {
                            boundaryGap: true, //默认，坐标轴留白策略
                            axisLine: {
                                show: false
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false,
                                alignWithLabel: true
                            },
                            data: xAxis
                        },
                        yAxis: {
                            axisLine: {
                                show: false
                            },
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'rgba(33,148,246,0.2)'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: 'rgb(245,250,254)'
                                }
                            }
                        },
                        series:  [{
                            type: 'line',
                            name: this.$vuetify.lang.t('$vuetify.dashboard.all'),
                            data: data,
                            label:{
                                show: true
                            }
                        },
                        ]
                        
                    })
                }

            }).finally(resp => {
                this.loading = false
            })
           
        },

        changeGroup(groupid){
            if(groupid == null){
                this.groupVal = null
                this.shopVal = null
            }
            this.fetchShopList(groupid)
            this.fetchAuthzoneList()
            this.groupID = groupid
        },
        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.shopVal = null
            this.fetchAuthzoneList()
            this.shopID = shopid
        },
        /* 获取认证域列表 */
        fetchAuthzoneList(){
            const postData = {
                group_id: this.groupVal,
                shop_id: this.shopVal
            }
            this.$http.post(api.default.portal.zonenameListUrl,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authzones = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },
        fresh(){
            this.drawPart1()
            this.drawPart2()
        },
        search(){
            this.drawPart1()
            this.drawPart2()
        },

        initGroupShop(){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        },
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}

.count {
    font-size: 25px;
    color: #5C6269;
    font-weight: 600;
}
.count_white {
    font-size: 35px;
    color: #fff;
    font-weight: 400;
}
.font-weight-medium {
    color: #5C6269;
    margin-bottom: -35px;
}
.font-weight-medium-white {
    color: #fff;
    margin-bottom: -35px;
}
.font-divider {
    margin-bottom: -35px;
}

.blue_title{
    width: 4px;
    height: 12px;
    display: inline-block;
    background:#4477FF;
}

.blue_top1 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#4477FF;
}
.blue_top2 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#3BA6EE;
}
.blue_top3 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#21D1E3;
}
.blue_top4 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#21D1E3;
}
.blue_top5 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#21D1E3;
}

.red_top1 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#F15251;
}
.red_top2 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#E98368;
}
.red_top3 {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
    background:#ECDB63;
}


</style>

<style lang="scss">
  .v-card--material-chart {
    .v-card--material__header {
      .ct-label {
        color: inherit;
        opacity: .7;
        font-size: 0.975rem;
        font-weight: 100;
      }

      .ct-grid{
        stroke: rgba(255, 255, 255, 0.2);
      }
      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut {
          stroke: rgba(255,255,255,.8);
      }
      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area {
          fill: rgba(255,255,255,.4);
      }
    }
  }
  .flow_name {
    font-size: 12px;
    color: #646A70;
    margin-right: 15px;
    width: 27px;
    display: inline-block;
}
</style>