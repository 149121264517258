<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.role.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name"
                                        @change="data.name=formSpaces(data.name)" 
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        :items="group"
                                        :label="$vuetify.lang.t('$vuetify.userManage.role.table.groupName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.groupID"
                                        :rules="rules.groupID" 
                                        dense
                                        outlined
                                    ></v-select>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

	props: {
		group: Array
	},

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.userManage.role.insertBtn'),

            loading: false,

            dialog: false,

            data: {
                name: null,
                groupID: null,
            },

            rules: {
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.role.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.role.rules.name')
                ],
                groupID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.role.rules.groupID')
                ],
            }
            
        }
    },

    methods: {
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },
        handleOpen () {
            this.dialog = true;
        },

        handleReset () {
            this.data = {
                name: null,
                groupID: null,
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.post(api.default.rbac.roleAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false
                    this.handleClose();
                })
            }
        }

    }
}
</script>

<style scoped>



</style>