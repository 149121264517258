<template>
    <div>
        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    <v-btn color="primary" @click="handleAdd">
                        {{ insertTitle[tab] }}
                    </v-btn>
                </v-toolbar>
            </div>
        </v-card>
    </div>
</template>

<script>

export default {

    props: {
        title : String,
        tab: Number
    },

    data () {
        return {
            insertTitle: [
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.province'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.city'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.insertBtn.area')
            ]
        }
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-date {
    width: 245px;
    margin-right: 10px;
    color: #000;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}
</style>