<template>
    
    <v-container fill-height fluid grid-list-xl>
        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <v-row>
                        <v-col cols="12">
                            <v-toolbar :flat="true">
                                <v-toolbar-title>
                                    <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                </v-toolbar-title>

                                <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                                
                                <v-btn color="primary" dark  @click="search">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.common.search')}}
                                    </span>
                                </v-btn>
                                <!-- 搜索按钮 -->
                                <v-btn color="primary ml-3" dark  @click="fresh">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.refresh')}}
                                    </span>
                                </v-btn>
                                <!-- 刷新按钮 -->
                                <v-btn color="primary ml-3" dark :loading="authdetail_list.export_button.loading" @click="dataExport">
                                    <span>
                                        导出
                                    </span>
                                </v-btn>
                                <!-- 导出按钮 -->
                            </v-toolbar>
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="group"
                                            v-model="groupVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                            @change="changeGroup"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择集团 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="shop"
                                            v-model="shopVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            @change="changeShop"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择场所 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="authzones"
                                            v-model="authzone"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_auth_name')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.auth_name')"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择认证域名 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="authtypes"
                                            v-model="authtype"
                                            item-value="type_id" 
                                            item-text="type_name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_type_name')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.type_name')"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择认证类型 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="devicestatus"
                                            v-model="status"
                                            item-value="status" 
                                            item-text="label"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_status')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.status')"
                                        ></v-select>
                                    </v-col>
                                    <!-- 选择在线状态 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="phone"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_phone')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.phone')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入手机号 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="username"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_username')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.username')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入工号/账号 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="client_mac"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_mac')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.mac')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入Mac -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="client_ip"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_ip')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.ip')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入Ip -->
                                    <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="date"
                                                    :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                                    prepend-icon="event"
                                                    readonly
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="date"
                                            range
                                            scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- 选择时间段 -->
                                </v-row>
                            </v-card-subtitle>
                            <!-- 选择框 -->
                        </v-col>
                    </v-row>
                </v-card>
                <!-- 搜索card -->
            </v-col>
            <!-- 搜索框 -->
            <v-col cols="12" md="12">
                <material-table
                    :title="title"
                    :columns="columns"
                    :data="data"
                    :currentPage="page"
                    :url = "url"
                    method = "post"
                    :filter = "filter"
                    :searchValue= "searchValue"
                    v-on:searchValue="setSearchValue">
                    <template slot="action" slot-scope="propData">
                        <a v-if="propData.value.item.status === '在线'" class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="offlineDev(propData.value.item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.offline')}}
                            </v-icon>
                        </a>
                    </template>
                </material-table>
            </v-col>
            <!-- table -->
            <v-col cols="12" md="12">
                <div id="chart" :style="{width: '100%', height: '300px'}"></div>
            </v-col>
            <!-- 认证chart -->
        </v-row>

    </v-container>

</template>

<script>
import axios from 'axios'
import bus from '../../utils/bus.js'
import Echarts from 'echarts'
import * as api from '../../api'
import { Notification } from 'element-ui';
// import CsvExport from './../../utils/CsvExport'
export default {
    data () {
      return {
            authdetail_list:{
                export_button:{
                    loading: false
                }
            },

            group: [],
            shop: [],
            authzones: [],
            authtypes: [],
            devicestatus: [
                {label:this.$vuetify.lang.t('$vuetify.portalMac.online'),status:1},
                {label:this.$vuetify.lang.t('$vuetify.portalMac.offline'),status:0}
            ],
            device: [],

            groupVal: null,
            shopVal: null,
            status: null,
            type: 'online',
            authtype: null,
            authzone: null,
            phone: "",
            client_mac: "",
            client_ip: "",
            username:"",
            groupID: '',
            shopID: '',
            deviceID: '',
            searchValue: 0,
            page:1,


            date: [this.getBeforeDate(0), new Date().toISOString().substr(0, 10)],
            menu: false,
            dialog: false,
            title: this.$vuetify.lang.t('$vuetify.portalMac.tableName'),

            url: api.default.data.authList,
            filter: {
                timeRange: [this.getBeforeDate(0) + ' 00:00:00', new Date().toISOString().substr(0, 10) + ' 23:59:59'],
                group_id: null,
                shop_id: null,
                status: null,
                type: 'online',
                authtype: null,
                authzone: null,
            },
          
            columns: [
                { text: this.$vuetify.lang.t('$vuetify.portalMac.id'), value: 'id' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.identity'), value: 'identity' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.mac'), value: 'mac' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.ip'), value: 'ip_address' },
                // { text: this.$vuetify.lang.t('$vuetify.portalMac.browser'), value: 'browser' },
                // { text: this.$vuetify.lang.t('$vuetify.portalMac.device'), value: 'device' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.type_name'), value: 'type_name' },
                // { text: this.$vuetify.lang.t('$vuetify.portalMac.platform'), value: 'platform' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.auth_name'), value: 'auth_name' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.upflow'), value: 'upflow' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.downflow'), value: 'downflow' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.usetime'), value: 'usetime' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.status'), value: 'status' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.created_at'), value: 'created_at' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.updated_at'), value: 'updated_at' },
                { text: this.$vuetify.lang.t('$vuetify.portalMac.action'), value: 'action' },
            ],
            data: [
            ],
            editedIndex: -1,
            editedItem: {
                name: '',
                calories: 0,
                fat: 0,
                carbs: 0,
                protein: 0,
            },
            defaultItem: {
            },
        
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$vuetify.lang.t('$vuetify.portalMac.newItem') : this.$vuetify.lang.t('$vuetify.portalMac.editItem')
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchTable()
            this.drawChart()
            this.initGroupShop()
            this.fetchAuthtypeList()
            this.fetchAuthzoneList()
        })
    },
    methods: {
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
            }
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        /* 获取认证类型列表 */
        fetchAuthtypeList(){
            this.$http.post(api.default.data.authtypeList).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authtypes = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        /* 获取认证域列表 */
        fetchAuthzoneList(){
            const postData = {
                group_id: this.groupVal,
                shop_id: this.shopVal
            }
            this.$http.post(api.default.portal.zonenameListUrl,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authzones = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            if(groupid == null){
                this.groupVal = null
                this.shopVal = null
            }
            this.fetchShopList(groupid)
            this.fetchAuthzoneList()
            this.groupID = groupid
        },
        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.shopVal = null
            this.fetchAuthzoneList()
            this.shopID = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },
        fresh(){
            this.fetchTable()
            this.searchValue = 1
            this.drawChart()
        },
        search(){
            this.page = 1
            this.fetchTable()
            this.searchValue = 1
            this.drawChart()
        },

        fetchTable(){
            this.filter = {
                timeRange: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                group_id:this.groupID,
                shop_id:this.shopID,
                status: this.status,
                type: this.type,
                authtype: this.authtype,
                authzone: this.authzone,
                phone: this.phone,
                username: this.username,
                client_mac: this.client_mac,
                client_ip: this.client_ip,
            }
        },

        dataExport(){
            this.authdetail_list.export_button.loading = true
            let filter = {
              filter: {
                timeRange: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                group_id: this.groupID,
                shop_id: this.shopID,
                status: this.status,
                type: this.type,
                authtype: this.authtype,
                authzone: this.authzone,
                phone: this.phone,
                username: this.username,
                client_mac: this.client_mac,
                client_ip: this.client_ip,
              }
            }
            axios({
                method: 'POST',
                url: api.default.data.exportAuthdetail,
                data: filter,
                responseType: 'blob'
            }).then(response => {
                if(response.status != 200 || !response.data)
                {
                    Notification({
                        type: 'warning',
                        message: "导出失败"
                    });
                    return
                }
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '认证记录.xlsx')
                
                document.body.appendChild(link)
                link.click()
                // this.download(response)
            }).catch((error) => {
                Notification({
                    type: 'warning',
                    message: "导出失败"
                });
                return
            }).finally(resp => {
                this.authdetail_list.export_button.loading = false
            })
        },

        drawChart(data,yAxisData) {
            return false
            let myChart = Echarts.init(document.getElementById('chart'))
            this.$http.post(api.default.dashboard.dayauth,this.filter).then(resp => {
                if(!resp) return
                let data = []
                let xAxis = []
                if(resp.data.length){
                    for (let index = 0; index < resp.data.length; index++) {
                        data.push(parseInt(resp.data[index]['count']))
                        xAxis.push(resp.data[index]['date'])
                    }
                    myChart.setOption({
                        title: {
                            show: true,
                            text: this.$vuetify.lang.t('$vuetify.portalMac.chartTitle'),
                            x:'center',
                            top: 0,
                            textStyle: {
                                color: '#414957',
                                fontSize: 16,
                                fontFamily: '"Microsoft Yahei","微软雅黑"',
                            },
                        },
                        backgroundColor: '#FFF',
                        grid: {
                            top: '14%',
                            bottom: '19%',
                            left: '6%',
                            right: '4%'
                        },
                        tooltip: {
                            trigger: 'axis',
                            label: {
                                show: true
                            }
                        },
                        color:['#7986CB','#81C784','#64B5F6','#4DB6AC','#4DD0E1','#AED581','#9575CD'],
                        legend: {
                            icon: "circle",
                            x: 'center',
                            y: 'bottom',
                            data: ['总用户','新用户','老用户']
                        },
                        xAxis: {
                            boundaryGap: true, //默认，坐标轴留白策略
                            axisLine: {
                                show: false
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false,
                                alignWithLabel: true
                            },
                            data: xAxis
                        },
                        yAxis: {
                            axisLine: {
                                show: false
                            },
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'rgba(33,148,246,0.2)'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: 'rgb(245,250,254)'
                                }
                            }
                        },
                        series:  [{
                            type: 'line',
                            name: this.$vuetify.lang.t('$vuetify.dashboard.all'),
                            data: data
                        },
                        ]
                        
                    })
                }

            }).finally(resp => {
                this.loading = false
            })
        },

        offlineDev(item){
            const postData = {
                id: item.id
            }
            this.$http.post(api.default.data.offlineDevice,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.fetchTable()
                this.searchValue = 1
            }).finally(resp => {
                this.loading = false
            })
        },

        editItem (item) {
            this.$router.push('/data/auth_record/'+item.id)
            // this.editedIndex = this.desserts.indexOf(item)
            // this.editedItem = Object.assign({}, item)
            // this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        }
    }
}
</script>

<style scoped>
.v-card__subtitle
{
    padding-top: 0px;
}
.v-toolbar
{
    margin-bottom: 0px;
}

</style>