<template>
    <v-container fill-height fluid grid-list-xl>
        <v-row>
            <v-col cols="2" sm="2" md="2">
                <v-text-field class="mt-0" 
                v-model="mac" 
                :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.mac')" 
                :label="$vuetify.lang.t('$vuetify.gateway.label.mac')" dense 
                append-outer-icon="search" 
                @click:append-outer="search()"></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field class="mt-0" 
                v-model="identity" 
                :placeholder="$vuetify.lang.t('$vuetify.portalMac.identity')" 
                :label="$vuetify.lang.t('$vuetify.portalMac.identity')" dense 
                append-outer-icon="search" 
                @click:append-outer="search()"></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field class="mt-0" 
                v-model="applist" 
                :placeholder="$vuetify.lang.t('$vuetify.appfilter.applist')" 
                :label="$vuetify.lang.t('$vuetify.appfilter.applist')" dense 
                append-outer-icon="search" 
                @click:append-outer="search()"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <material-table
                    :title="title"
                    :columns="columns"
                    :data="data"
                    :currentPage="page"
                    :url = "url"
                    method = "post"
                    :filter = "filter"
                    :searchValue= "searchValue"
                    v-on:searchValue="setSearchValue">
                    <template slot="title">
                        <v-btn color="primary ml-3" dark :loading="exportLoading" @click="exportExcel">
                            <span>
                                {{$vuetify.lang.t('$vuetify.common.export')}}
                            </span>
                        </v-btn>
                    </template>
                    <template slot="action" slot-scope="propData">
                        <a class="span" @click="showAppInfo(propData.value.item)">
                            <v-icon small>mdi-eye-outline</v-icon>{{$vuetify.lang.t('$vuetify.appfilter.showinfo')}}
                        </a>
                        <a class="span" @click="exportAppInfo(propData.value.item)">
                            <v-icon small>mdi-export-variant</v-icon>{{$vuetify.lang.t('$vuetify.common.export')}}
                        </a>
                    </template>
                </material-table>
            </v-col>
        </v-row>
        <el-dialog title="app" :visible.sync="dialogShowInfo" :before-close="handleclose" width="70%" append-to-body>
            <Appfilter :app_visit_view="app_visit_view" :app_class_view="app_class_view" :tableData="app_tableData"/>
        </el-dialog>
    </v-container>
</template>

<script>
import * as api from '../../api'
import Appfilter from '../Appfilter/AppfilterInfo.vue'
import exportExcel from '../../utils/export'

export default {
    props: ['gatewayId'],
    data () {
      return {
            app_visit_view:[],
            app_class_view:[],
            app_tableData:[],
            dialogShowInfo:false,
            title: this.$vuetify.lang.t('$vuetify.appfilter.tableName'),
            columns: [
            { text: this.$vuetify.lang.t('$vuetify.portalMac.identity'), value: 'auth_info' },
                { text: this.$vuetify.lang.t('$vuetify.appfilter.hostname'), value: 'hostname' },
                { text: this.$vuetify.lang.t('$vuetify.appfilter.mac'), value: 'mac' },
                { text: this.$vuetify.lang.t('$vuetify.appfilter.ip'), value: 'ip' },
                { text: this.$vuetify.lang.t('$vuetify.appfilter.applist'), value: 'applist' },
                { text: this.$vuetify.lang.t('$vuetify.appfilter.updated_at'), value: 'updated_at' },
                { text: this.$vuetify.lang.t("$vuetify.apiManagement.action"), value: "action" },
            ],
            data:[],
            page:1,
            url: api.default.appfilter.getappfilterlist,
            filter: {
                "gateway_id":this.gatewayId,
            },

            mac:"",
            identity:"",
            applist:"",
            searchValue: 0,
            exportLoading:false,
      }
    },
    components: {
        Appfilter,
    },
    watch: {
        gatewayId(){
            this.search()
        },
    },
    methods: {
        search(){
            this.page = 1
            this.fetchTable()
            this.searchValue = 1
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchTable(){
            this.filter = {
                gateway_id:this.gatewayId,
                mac:this.mac,
                identity:this.identity,
                applist:this.applist,
            }
        },

        showAppInfo(val){
            this.app_visit_view = JSON.parse(val.dev_visit_time)
            this.app_class_view = JSON.parse(val.app_class_visit_time)
            this.app_tableData = JSON.parse(val.dev_visit_list)
            this.dialogShowInfo=true
            return;
        },
        handleclose(){
            this.dialogShowInfo=false
        },
        exportExcel() {
            this.exportLoading = true;
            var postData = {
                filter:this.filter,
            }
            this.$http.post(api.default.appfilter.getappfilterlist,postData).then(resp => {
                if(resp.errno == 0){
                    if(resp.data.list.length>0){
                        const titleArr = [
                            "身份信息",
                            "主机名称",
                            "mac",
                            "ip",
                            "常用应用",
                            "更新时间"
                        ]
                        const exportdata = []
                        for (let i of resp.data.list) {
                            let tempdata = {
                                "auth_info": i.auth_info,
                                "hostname": i.hostname,
                                "mac": i.mac,
                                "ip": i.ip,
                                "applist": i.applist,
                                "updated_at": i.updated_at,
                            }
                            exportdata.push(tempdata)
                        }
                        exportExcel(exportdata, '用户列表', titleArr, 'sheetName');
                    }else{
                        Notification({
                            type: 'info',
                            message: "无数据"
                        });
                    }
                }
                this.exportLoading = false;
            })
        },
        exportAppInfo(val){
            var list  = JSON.parse(val.dev_visit_list)
            const titleArr = [
                "App名称",
                "开始时间",
                "停止时间",
                "访问时间",
                "过滤状态"
            ]
            const exportdata = []
            for (let i of list) {
                let tempdata = {
                    "appname": i.appname,
                    "first_time": i.first_time,
                    "latest_time": i.latest_time,
                    "total_time": this.get_display_time(i.total_time),
                    "latest_action": i.latest_action==0?"未过滤":"过滤"
                }
                exportdata.push(tempdata)
            }
            exportExcel(exportdata, val.mac+"_"+val.updated_at, titleArr, 'sheetName');
        },
        get_display_time(total_time) {
            var hour = parseInt(total_time / 3600);
            var seconds = total_time % 3600;
            var min = parseInt(seconds / 60)
            var seconds2 = seconds % 60;
            var total_time_str;

            if (hour > 0)
                total_time_str = hour + "小时" + min + "分"
            else {
                if (min == 0 && seconds2 != 0)
                    min = 1;
                total_time_str = min + "分"
            }
            return total_time_str;
        },
    }
}
</script>

<style scoped>
.v-card__subtitle
{
    padding-top: 0px;
}
.v-toolbar
{
    margin-bottom: 0px;
}

</style>