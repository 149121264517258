<template> 
    <div class="main_lay">
        <el-dialog title="Mwan List" :visible.sync="dialogShowMwan" :before-close="handleclose" width="42%">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="mwan在离线记录" name="first">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <el-form
                        :inline="true" 
                        ref="form" size="small">
                        <el-form-item label="时间范围">
                            <el-date-picker
                            v-model="daterange"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            clearable>
                            </el-date-picker>
                        </el-form-item>
                            <el-form-item >
                                <el-button type="primary" size="small" style="float: right;" @click="list(true)">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%">
                        <el-table-column
                        prop="infname"
                        label="网口名称">
                        </el-table-column>
                        <el-table-column
                        label="状态"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{showstatus(scope.row)}}
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="created_at"
                        label="时间">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="text-align:center;margin:10px 0;"
                        @size-change="list(true)" 
                        @current-change="list(false)" 
                        :current-page.sync="currentPage" 
                        :page-size.sync="pageSize" 
                        layout="total, sizes, prev, pager, next, jumper" 
                        :total="localtotal">
                    </el-pagination>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="SIMCARD" name="second">
                <el-tabs tab-position="left" v-model = "simcardvalue" >
                    <el-tab-pane 
                        v-for="(item, index) in simcard"
                        :key="item.simcard"
                        :label="item.simcard"
                        :name="item.simcard">
                        <el-descriptions :column="1" border>
                            <el-descriptions-item label="蜂窝接口">
                                <el-tag v-if="item.dialstate==2" type="success">拨号成功</el-tag>
                                <el-tag v-else-if="item.dialstate==0"  type="info">未插卡</el-tag>
                                <el-tag v-else-if="item.dialstate==1"  type="warning">拨号中</el-tag>
                                <el-tag v-else  type="danger">拨号失败</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="IMSI">{{ item.imsi }}</el-descriptions-item>
                            <el-descriptions-item label="IMEI" >{{ item.imei }}</el-descriptions-item>
                            <el-descriptions-item label="ICCID">{{ item.iccid }}</el-descriptions-item>
                            <el-descriptions-item label="服务提供商">{{ item.provider }}</el-descriptions-item>
                            <el-descriptions-item label="上线时间">{{ showtime(item.uptime)}}</el-descriptions-item>
                            <el-descriptions-item label="信号强度">{{ item.rssi }} dBm</el-descriptions-item>
                            <el-descriptions-item label="流量">上行流量:{{ toBytes(item.tx_bytes) }} / 下行流量:{{ toBytes(item.rx_bytes) }}</el-descriptions-item>
                            <el-descriptions-item label="蜂窝速度">
                                <el-descriptions :column="2" border>
                                    <el-descriptions-item label="时间间隔">30秒</el-descriptions-item>
                                    <el-descriptions-item label="时间间隔">五分钟</el-descriptions-item>
                                    <el-descriptions-item label="接收速度">{{item.rxspeed_30}}</el-descriptions-item>
                                    <el-descriptions-item label="接收速度">{{item.rxspeed_300}}</el-descriptions-item>
                                    <el-descriptions-item label="发送速度">{{item.txspeed_30}}</el-descriptions-item>
                                    <el-descriptions-item label="发送速度">{{item.txspeed_300}}</el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="流量统计" name="fourth">
                <el-card class="box-card">
                    <el-form
                    :inline="true" 
                    ref="form" size="small">
                    <el-form-item label="时间范围">
                        <el-date-picker
                        v-model="netdaterange"
                        :type="datetype"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item >
                        <el-button type="primary" size="small" style="float: right;" @click="getbytesinfo">搜索</el-button>
                    </el-form-item>
                    <el-form-item >
                        <el-select v-model="nettype" placeholder="请选择" style="width: 70px;" @change="changetype">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-form>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <el-descriptions title="总和" :column="1">
                            <el-descriptions-item 
                                v-for="(item, index) in linelist"
                                :key="item.infname"
                                :label="item.infname">
                                <el-descriptions :column="2">
                                    <el-descriptions-item label="上行流量">{{toBytes(item.sum_tx_bytes)}}</el-descriptions-item>
                                    <el-descriptions-item label="下行流量">{{toBytes(item.sum_rx_bytes)}}</el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div id="main1" :style="{width: '650px', height: '300px',top:'0'}"></div>
                </el-card>
            </el-tab-pane>
        </el-tabs>
        </el-dialog>
    </div>
</template>
<script>
import Echarts from 'echarts'
import * as api from './../../../api'

export default {
    data () {
        return {
            dialogShowMwan:false,
            activeName:"first",
            gatewayId:"",
            simcard:[],
            simcardvalue:"",
            datetype:"daterange",
            tableData:[],
            localtotal:0,
            // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 10,
            daterange:[],
            options:[
                {
                    value: 1,
                    label: '日'
                }, {
                    value: 2,
                    label: '月'
                }
            ],
            nettype:1,
            form:{
                gateway_id:"",
                pagesize:10,
                pagenum:1,
                start_time:"",
                stop_time:"",
            },
            netdaterange: [this.getBeforeDate(7), new Date().toISOString().slice(0, 10)],
            xdate:[],
            linelist:[],
        }
    },
    methods: {
        open(gatewayId,simcard){
            this.gatewayId = gatewayId
            this.simcard = simcard
            this.simcardvalue = simcard[0].simcard
            this.dialogShowMwan=true
            this.list(true)
            this.getbytesinfo()
        },
        list(val){
            if(val){
                this.currentPage=1
            }
            this.form.gateway_id=this.gatewayId
            this.form.pagenum=this.currentPage
            this.form.pagesize=this.pageSize
            if (this.daterange==null){
                this.form.start_time = ""
                this.form.stop_time = ""
            }else{
                this.form.start_time = this.daterange[0]
                this.form.stop_time = this.daterange[1]
            }

            let that = this
            this.$http.post(api.default.gateway.mwanlog,this.form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    that.localtotal = res.data.total
                    that.tableData = res.data.list
                }
            })
        },
        handleclose(){
            this.dialogShowMwan=false
        },
        showstatus(row){
            switch (row.status) {
                case 1:
                    return "上线"

                case 2:
                    return "离线"
                
                case 3:
                    return "未启用"

                default:
                    break;
            }
        },
        handleClick(tab){
            switch (tab.index) {
                case "2":
                    this.getbytesinfo()
                    break;
            
                default:
                    break;
            }
        },
        changetype(val){
            switch (val) {
                case 1:
                    this.netdaterange = [this.getBeforeDate(7), new Date().toISOString().slice(0, 10)],
                    this.datetype = "daterange"
                    break;

                case 2:
                    this.netdaterange = [new Date(new Date().getFullYear(), 0).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
                    this.datetype = "monthrange"
                    break;
            
                default:
                    break;
            }
        },
        getbytesinfo(){
            var form = {
                gateway_id:this.gatewayId,
                start_time:this.netdaterange[0],
                stop_time:this.netdaterange[1],
                type:this.nettype,
            }
            const date = new Date(this.netdaterange[1].slice(0, 4), parseInt(this.netdaterange[1].slice(5, 7)), 1);
            
            if (this.nettype == 2) {
                form.stop_time = date.toISOString().slice(0, 10)
            }

            let that = this
            this.$http.post(api.default.gateway.mwannetlist,form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    that.xdate=res.data.xdate
                    that.linelist=res.data.linelist
                    that.display_Bytes()
                }
            })
        },
        showtime(uptime){
            var d = 0
            var h = 0 
            var m = 0
            var s = 0
            d = Math.floor(uptime / (24*60*60))
            h = Math.floor((uptime-(d*24*60*60)) / (60*60))
            m = Math.floor((uptime-(d*24*60*60)-(h*60*60)) / 60)
            s = Math.floor(uptime-(d*24*60*60)-(h*60*60)-(m*60))
            if (d !=0){
                return d + "天 "+ h +" 小时" + m + " 分钟" + s + " 秒"
            }else if (h !=0){
                return h +" 小时" + m + " 分钟" + s + " 秒"
            }else if (m !=0){
                return m + " 分钟"+ s + " 秒"
            }else if (s !=0){
                return  s + " 秒"
            }else{
                return "-"
            }
        },
        toBytes(bytes){
            var mbyte = 1048576
            var outstring = "0.00 KB"
            var kbytesfloat = bytes/(mbyte/1024)
            if (kbytesfloat < 1024 ){
                return  (kbytesfloat).toFixed(2)+" KB"
            }
            var mbytesfloat = bytes / mbyte
            if (mbytesfloat > 1024) {
                var gbytesfloat = mbytesfloat / 1024
                outstring = (gbytesfloat).toFixed(2)+" G"
            } else {
                outstring = (mbytesfloat).toFixed(2)+" MB"
            }
            return outstring
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        },
        display_Bytes(){
            var series = []
            this.linelist.forEach(e => {
                var rxtemp = {
                    name:e.infname+"-下载",
                    data: e.rx_bytes,
                    type: 'line',
                    areaStyle: {},
                    emphasis: {
                        label:{
                            show:true
                        }
                    }
                }
                var txtemp = {
                    name: e.infname+"-上传",
                    data: e.tx_bytes,
                    type: 'line',
                    areaStyle: {},
                    emphasis: {
                        label:{
                            show:true
                        }
                    }
                }
                series.push(rxtemp)
                series.push(txtemp)
            })
            var myChart = Echarts.init(document.getElementById('main1'));
            let option = {
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    }
                },
                legend:{
                    show:true
                },
                xAxis: {
                    data: this.xdate,
                },
                yAxis: {
                    axisLabel:{
                        type:'value',
                        formatter:'{value}MB'
                    }
                },
                series: series,
            };
            myChart.setOption(option);
        },
    }
}
</script>