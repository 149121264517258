<template>
    <div>
        <v-row justify="center">
            <material-dialog ref="apgroup_set" formTitle="设置Ap组" @handleSave="handleSave">
                <template slot="content">
                    <v-form ref="apgroup_set_form" lazy-validation>
                        <v-combobox
                            v-model="data.apgroup"
                            :items="apgroup_list"
                            item-text="name"
                            item-value="id"
                            label="请选择通过此认证域认证的AP组"
                            multiple
                        ></v-combobox>
                    </v-form>
                </template>
            </material-dialog>
            <!-- Ap组添加框 -->
            <!-- <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-combobox
                            v-model="select"
                            :items="items"
                            label="Select a favorite activity or create a new one"
                            multiple
                            ></v-combobox>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-textarea
                                        outlined
                                        name="input-7-4"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.apMac')" 
                                        v-model="data.apmac"
                                    ></v-textarea>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->
        </v-row>
    </div>
</template>

<script>
import api from '@/api.js'
import { Notification } from 'element-ui';

export default {

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.portalManage.ac.apmacBtn'),

            loading: false,

            dialog: false,

            authzone_info: {},

            apgroup_list: [],

            data: {
                apgroup: []
            },
        }
    },

    methods: {
        handleOpen (item) {
            let data   = this.newObject(item)
            this.authzone_info = data
            this.getApGroups()
            this.getAllApGroups()
            this.$refs['apgroup_set'].showDialog()
            this.dialog = true;
        },

        getApGroups (){
            this.$http.post(api.portal.getApgroups, {
                authzone_id: this.authzone_info.id
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp || resp.errno != 0) return;
                this.data.apgroup_list = []
                this.data.apgroup = resp.data.list
            })
        },

        getAllApGroups (){
            this.$http.post(api.device.apGroupList,{pageSize:-1, currentPage:1, filter:{group_id:this.authzone_info.groupID, shop_id:this.authzone_info.shopID}}).then(resp => {
                if(resp.errno !== 0)
                    return
                this.apgroup_list = resp.data.list
            }).finally(resp => {}) 
        },

        handleSave () {
            let ids = []
            for (let index = 0; index < this.data.apgroup.length; index++) {
                ids.push(this.data.apgroup[index]['id'])
            }

            this.loading = true;
            this.$http.post(api.portal.setApgroups, {
                id: this.authzone_info.id,
                group_ids: ids
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.$emit('handleSearch');
            }).finally(resp => {
                this.loading = false;
                this.$refs['apgroup_set'].closeDialog()
            })
        },

        newObject (item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        }

    }
}
</script>