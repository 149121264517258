<template> 
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="6">
                <v-card
                class="pa-2" outlined tile >
                <v-card-title style="font-size: 16px;
                    color: #9AA4AF;
                    font-weight: normal;
                    padding: 7px 0 10px 23px;
                    pointer-events: none;">
                </v-card-title>
                    <v-row align="center" class="mx-0" >
                        <div id="main" :style="{width: '100%', height: '300px',top:'0'}"></div>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card
                class="pa-2" outlined tile >
                <v-card-title style="font-size: 16px;
                    color: #9AA4AF;
                    font-weight: normal;
                    padding: 7px 0 10px 23px;
                    pointer-events: none;">
                </v-card-title>
                    <v-row align="center" class="mx-0" >
                        <div id="main2" :style="{width: '100%', height: '300px',top:'0'}"></div>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card
                class="pa-2" outlined tile >
                <v-card-title style="font-size: 16px;
                    color: #9AA4AF;
                    font-weight: normal;
                    padding: 7px 0 10px 23px;
                    pointer-events: none;">
                </v-card-title>
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            prop="appname"
                            label="App名称">
                        </el-table-column>
                        <el-table-column
                            prop="first_time"
                            label="开始时间">
                        </el-table-column>
                        <el-table-column
                            label="访问时间">
                            <template slot-scope="scope">
                                {{ get_display_time(scope.row.total_time) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="过滤状态">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.latest_action==0">未过滤</el-tag>
                                <el-tag type="info" v-else>过滤</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Echarts from 'echarts'

export default {
    props: ['app_visit_view', 'app_class_view','tableData'],
    data () {
        return {
        }
    },
    watch: {
        app_visit_view(){
            this.display_app_visit_view()
        },
        app_class_view(){
            this.display_app_class_view()
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.display_app_visit_view()
            this.display_app_class_view()
        })
    },
    methods: {
        get_display_time(total_time) {
            var hour = parseInt(total_time / 3600);
            var seconds = total_time % 3600;
            var min = parseInt(seconds / 60)
            var seconds2 = seconds % 60;
            var total_time_str;

            if (hour > 0)
                total_time_str = hour + "小时" + min + "分"
            else {
                if (min == 0 && seconds2 != 0)
                    min = 1;
                total_time_str = min + "分"
            }
            return total_time_str;
        },

        display_app_visit_view() {
            var data = this.app_visit_view
            var local=this
            var myChart = Echarts.init(document.getElementById('main2'));
            var m2R2Data = new Array()
            var total_time = 0
            for (var i = 0; i < data.length; i++) {
                var dev_obj = data[i];
                var m2_obj = {};
                m2_obj.value = dev_obj.visit_time;
                m2_obj.legendname = dev_obj.app_id;

                var tmp_time = this.get_display_time(dev_obj.visit_time);

                m2_obj.name = dev_obj.app_id + "  " + tmp_time;
                total_time += dev_obj.visit_time
                m2R2Data.push(m2_obj);
            }

            var total_time_str = this.get_display_time(total_time);
            let option = {
                title: [
                    {
                        text: "App 时间统计",
                        textStyle: {
                            fontSize: 16,
                            color: "black"
                        },
                        left: "2%"
                    },
                    {
                        text: '',
                        subtext: total_time_str,
                        textStyle: {
                            fontSize: 15,
                            color: "black"
                        },
                        subtextStyle: {
                            fontSize: 15,
                            color: 'black'
                        },
                        textAlign: "center",
                        x: '34.5%',
                        y: '44%',
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (parms) {
                        var total_time = local.get_display_time(parms.data.value);
                        var str = parms.seriesName + "</br>" +
                            parms.marker + "" + parms.data.legendname + "</br>" +
                            "访问时间: " + total_time + "</br>" +
                            "占比: " + parms.percent + "%";
                        return str;
                    }
                },
                legend: {
                    type: "scroll",
                    orient: 'vertical',
                    left: '70%',
                    align: 'left',
                    top: 'middle',
                    textStyle: {
                        color: '#8C8C8C'
                    },
                    height: 250
                },
                series: [
                    {
                        name: "访问时间",
                        type: 'pie',
                        center: ['35%', '50%'],
                        radius: ['58%', '70%'],
                        clockwise: false,
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 2,
                            borderColor: "#fff",
                            borderWidth: 1,
                        },

                        label: {
                            normal: {
                                show: true,
                                position: 'outter',
                                formatter: function (parms) {
                                    return parms.data.legendname
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 8,
                                length2: 7,
                                smooth: true,
                            }
                        },
                        data: m2R2Data
                    }
                ]
            };
            myChart.setOption(option);
        },
        display_app_class_view() {
            var data = this.app_class_view
            var local=this
            var myChart = Echarts.init(document.getElementById('main'));
            var m2R2Data = new Array()
            var total_time = 0
            for (var i = 0; i < data.length; i++) {
                var dev_obj = data[i];
                if (dev_obj.visit_time == 0)
                    continue;

                var m2_obj = {};
                m2_obj.value = dev_obj.visit_time;
                m2_obj.legendname = dev_obj.name;

                var tmp_time = this.get_display_time(dev_obj.visit_time);

                m2_obj.name = dev_obj.name + "  " + tmp_time;
                total_time += dev_obj.visit_time
                m2R2Data.push(m2_obj);
            }

            var total_time_str = this.get_display_time(total_time);

            let option = {
                title: [
                    {
                        text: "App分类时间统计",
                        textStyle: {
                            fontSize: 16,
                            color: "black"
                        },
                        left: "2%"
                    },
                    {
                        text: '',
                        subtext: total_time_str,
                        textStyle: {
                            fontSize: 15,
                            color: "black"
                        },
                        subtextStyle: {
                            fontSize: 15,
                            color: 'black'
                        },
                        textAlign: "center",
                        x: '34.5%',
                        y: '44%',
                    }],
                tooltip: {
                    trigger: 'item',
                    formatter: function (parms) {
                        var total_time = local.get_display_time(parms.data.value);
                        var str = parms.seriesName + "</br>" +
                            parms.marker + "" + parms.data.legendname + "</br>" + "访问时间: " + total_time + "</br>" +
                            "占比: " + parms.percent + "%";
                        return str;
                    }
                },
                legend: {
                    type: "scroll",
                    orient: 'vertical',
                    left: '70%',
                    align: 'left',
                    top: 'middle',
                    textStyle: {
                        color: '#8C8C8C'
                    },
                    height: 250
                },
                series: [
                    {
                        name: "访问时间",
                        type: 'pie',
                        center: ['35%', '50%'],
                        radius: ['58%', '70%'],
                        clockwise: false,
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 2,
                            borderColor: "#fff",
                            borderWidth: 1,
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'outter',
                                formatter: function (parms) {
                                    return parms.data.legendname
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 8,
                                length2: 7,
                                smooth: true,
                            }
                        },
                        data: m2R2Data
                    }
                ]
            };
            myChart.setOption(option);
        }
    }
}
</script>