<template>
    <div>
        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    <v-btn color="primary" @click="handleSearch">
                        <span style="color: #fff">
                            {{ $vuetify.lang.t('$vuetify.systemManage.searchBtn') }}
                        </span>
                    </v-btn>
                </v-toolbar>

                <div class="layout-search">
                    <v-row justify="start">
                        <div class="layout-date">
                            <v-menu
                                v-model="menuModel"
                                :close-on-content-click="false"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        outlined
                                        clearable
                                        readonly
                                        persistent-hint
                                        v-model="dateRangeText"
                                        :placeholder="$vuetify.lang.t('$vuetify.systemManage.syslog.table.createdAt')"
                                        prepend-icon="event"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dates" range no-title></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.systemManage.syslog.table.content')"
                                v-model="search.msg"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="logType"
                                :label="$vuetify.lang.t('$vuetify.systemManage.syslog.table.type.text')"
                                item-text="label"
                                item-value="value"
                                v-model="search.level"
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                    </v-row>
                </div>
            </div>

        </v-card>

    </div>
</template>

<script>

export default {

    data () {
        return {
            menuModel: false,
            dates: []
        }
    },

    props: {
        title : String,
        search: Object,
        logType: Array
    },

    computed: {
        dateRangeText:{
            get(){
                return this.dates.join(' ~ ')
            },
            set(v){
                if(v === null){
                    this.search.timedate = null;
                }
            }
        },
    },

    watch: {
        dates: {
            handler (newValue, oldValue) {
                if (newValue[1] !== oldValue[1] && newValue.length === 2) {
                    this.search.timedate = this.dates;
                    this.menuModel = false;
                }else{
                    this.search.timedate = null;
                }
            },
            deep: true,
        }
    },

    methods: {

        handleSearch () {
            this.$emit('handleSearch');
        }

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-date {
    width: 245px;
    margin-right: 10px;
    color: #000;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

</style>
