<template>
	<div id="Devices">
		<v-container fill-height fluid grid-list-xl class="app-container">
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table :showSelect="showSelect" :pages="params.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loading" :pageSize="params.pageSize" :total="params.total" :titleTable="titleTable" :headers="headers" :tableData='tableData'>
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.bindBtn')}}
							</a>
						</template>
					</v-table>
					<v-dialogs :theme="themeEdit" :dialog="dialogEdit" @dialogSure="handleEmitEdit">
						<v-col cols="12" sm="7" md="7">
							<v-text-field :rules="nameRule" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.name')" v-model="defaultItem.name" :label="$vuetify.lang.t('$vuetify.gateway.label.name')"></v-text-field>
						</v-col>
					</v-dialogs>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification  } from 'element-ui';
	export default {
		data() {
			return {
				showSelect: false,
				themeEdit: this.$vuetify.lang.t('$vuetify.gateway.btn.editTheme'),
				dialogEdit: false,
				titleTable: this.$vuetify.lang.t('$vuetify.gateway.title_waiting'),
				headers: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.pro'),
						align: 'left',
						value: 'provinceName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.city'),
						align: 'left',
						value: 'cityName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.area'),
						align: 'left',
						value: 'areaName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.company'),
						align: 'left',
						value: 'groupName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.place'),
						align: 'left',
						value: 'shopName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.mac'),
						align: 'left',
						value: 'mac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableData: [],
				defaultItem: {
					name: null,
					id:null,
				},
				nameRule: [v => !!v || this.$vuetify.lang.t('$vuetify.gateway.rule.name')],
				params: {
					pageSize: 1,
					pageNum: 1,
					total:0,
				},
				loading:true,
			}
		},
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.tobindlist();
		},
		methods: {
			tobindlist() {
				this.loading = true; 
				this.$http.post(api.default.gateway.deviceTobindlist,this.params).then(resp => {
					this.params.total = parseInt(resp.data.total);
					this.tableData = resp.data.data;
					this.loading = false;
				}).finally(resp => {})	
			},
			handlePage(data){
				this.params.pageNum = data;
				this.tobindlist();
			},
			handlePageSize(data){
				this.params.pageSize = data;
				this.tobindlist();
			},
			handleEmitEdit(params) {
				if(!params) return this.dialogEdit = !this.dialogEdit;
				if(!this.defaultItem.name||this.defaultItem.name=="") return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.gateway.rule.name')
				});
				this.$http.post(api.default.gateway.deviceBind,this.defaultItem).then(resp => {
					if(resp.errno == '0'){
						this.defaultItem.name = null;
						this.defaultItem.id = null;
						this.dialogEdit = false;
						this.tobindlist();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})	
			},
			showEdit(data) {
				this.defaultItem = data;
				this.dialogEdit = !this.dialogEdit;
			},
		}
	}
</script>