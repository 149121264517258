<template>
    
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="10">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="group"
                            v-model="groupVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                            @change="changeGroup"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="shop"
                            v-model="shopVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            @change="changeShop"
                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="device"
                            v-model="deviceVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            @change="changeDevice"
                            :label="$vuetify.lang.t('$vuetify.portalMac.pickWg')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date"
                                :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            range
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="1">
                        <v-btn color="primary" dark  @click="search" style="margin: 10px 0 0 0"><span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.search')}}</span></v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="12">
                <material-table
                    :title="title"
                    :columns="columns"
                    :data="data"
                    :url = "url"
                    method = "post"
                    :filter = "filter"
                    :searchValue= "searchValue"
                    v-on:searchValue="setSearchValue"
                >
                    <!-- <template v-slot:title>
                        <v-btn color="primary" @click="handleExport">
                            <span style="color: #fff;">
                                {{$vuetify.lang.t('$vuetify.common.export')}}
                            </span>
                        </v-btn>
                    </template> -->
                    <template v-slot:item.action="{ action }">
                        <a class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="editItem(action.value.item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.info')}}
                            </v-icon>
                        </a>
                    </template>
                </material-table>
                
            </v-col>
        </v-row>

    </div>

</template>

<script>
import bus from '../../utils/bus.js'
import Echarts from 'echarts'
import * as api from '../../api'
// import CsvExport from './../../utils/CsvExport'
export default {
    data () {
      return {
            group: [],
            shop: [],
            device: [],
            groupVal: null,
            shopVal: null,
            deviceVal: null,
            groupID: '',
            shopID: '',
            deviceID: '',
            searchValue: 0,


            date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            menu: false,
            dialog: false,
            title: this.$vuetify.lang.t('$vuetify.AppIdent.tableName'),
            filter: {
                timeRange: [new Date().toISOString().substr(0, 10) + ' 00:00:00', new Date().toISOString().substr(0, 10) + ' 23:59:59'],
                group_id: '',
                shop_id: '',
                device_id:  ''
            },

            url: api.default.data.appList,
            loading: false,
            range: [0, 10],
            data: [],
          
            columns: [
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.device_name'), value: 'device_name' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.name'), value: 'name' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.connection'), value: 'flowcnt' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.up'), value: 'bpsup' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.down'), value: 'bpsdown' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.d_up'), value: 'natbpsup' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.d_down'), value: 'natbpsdown' },
                { text: this.$vuetify.lang.t('$vuetify.AppIdent.count_flow'), value: 'all_flow' },
                // { text: this.$vuetify.lang.t('$vuetify.AppIdent.ten_flow'), value: 'last_flow' },
                // { text: this.$vuetify.lang.t('$vuetify.AppIdent.device_name'), value: 'device_name' },
                // { text: this.$vuetify.lang.t('$vuetify.AppIdent.day'), value: 'day' },
                // { text: this.$vuetify.lang.t('$vuetify.AppIdent.hour'), value: 'hour' },
            ],
            data: [
            ],
            editedIndex: -1,
            editedItem: {
                name: '',
                calories: 0,
                fat: 0,
                carbs: 0,
                protein: 0,
            },
            defaultItem: {
            },
        
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$vuetify.lang.t('$vuetify.portalMac.newItem') : this.$vuetify.lang.t('$vuetify.portalMac.editItem')
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchTable()
            this.initGroupShop()
        })
    },
    methods: {
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
            }
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchDeviceList(shopid){

            const postData = {
                pageSize: 1000,
                pageNum: 1,
                shopID: shopid
            }

            this.$http.post(api.default.gateway.deviceList,postData).then(resp => {
                
                this.device = resp.data.data

            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            this.fetchShopList(groupid)
            this.groupID = groupid
        },
        changeShop(shopid){
            this.fetchDeviceList(shopid)
            this.shopID = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },

        search(){
            this.searchValue = 1
            this.fetchTable()
        },

        fetchTable(){
            
            this.filter = {
                timeRange: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                group_id:this.groupID,
                shop_id:this.shopID,
                device_id: this.deviceID
            }
        },

        handleExport(){
            // CsvExport(this.headers,this.desserts,this.title);
        },
        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}

</style>
