<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <h6 style="color: #000;">
                                        {{ $vuetify.lang.t('$vuetify.userManage.user.table.username') + '：'+ data.username }}
                                    </h6>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.passwordDefault')" 
                                        v-model="password"
                                        required
                                        dense
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        required
                                        dense
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.contact')" 
                                        v-model="data.contact"
                                        :rules="rules.contact" 
                                        @change="data.contact=formSpaces(data.contact)"
                                        required
                                        dense
                                        outlined
                                    ></v-text-field>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleUpdate" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.update')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';
import sha1 from 'js-sha1'
import md5 from 'js-md5'

export default {

    props: {
        group: Array
    },

    data () {
      return {

            title: this.$vuetify.lang.t('$vuetify.userManage.user.editBtn'),

            loading: false,

            dialog: false,

            password: null,

            data: {
                username: null,
                name: null,
                contact: null,
                type: null,
                groupName: null,
                shopName: null,
            },

            rules: {
                username: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.username')
                ],
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.name')
                ],
                contact: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.contact'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.contact')
                ],
                type: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.type')
                ],
                bloc: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.bloc')
                ],
            }
            
      }
    },

    methods: {
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },
        handleOpen (item) {
            this.data = this.newObject(item);
            this.password = null;
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
            this.$refs.form.reset();
        },

        handleUpdate () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.data.password = this.password === null ? null : sha1(md5(this.password));
                this.$http.post(api.default.rbac.accountEditUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        newObject (item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        }

    }
}
</script>

<style scoped>



</style>