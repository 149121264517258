<template>
    <div>
        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    
                    <v-btn color="primary" @click="handleAdd">
                        {{ $vuetify.lang.t('$vuetify.systemManage.permission.insertBtn') }}
                    </v-btn>

                    <v-btn color="primary" @click="handleSearch">
                        {{ $vuetify.lang.t('$vuetify.systemManage.searchBtn') }}
                    </v-btn>

                </v-toolbar>

                <div class="layout-search">
                    <v-row justify="start">
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.systemManage.permission.table.name')"
                                v-model="search.name"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                    </v-row>
                </div>
            </div>

        </v-card>

    </div>
</template>

<script>

export default {

    props: {
        title : String,
        search: Object
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

        handleSearch () {
            this.$emit('handleSearch');
        }

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-date {
    width: 245px;
    margin-right: 10px;
    color: #000;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

#date .v-btn__content {
    color: #000;
}

</style>