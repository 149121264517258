<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog"
                      persistent
                      max-width="750px">
                <v-card>
                    <v-card-text>

                        <v-toolbar flat
                                   color="white">
                            <v-toolbar-title>
                                {{ data.name }}
                            </v-toolbar-title>
                            <v-divider class="mx-4"
                                       inset
                                       vertical></v-divider>

                            <v-btn color="primary"
                                   @click="handleAddRule">
                                添加规则
                            </v-btn>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-lightbulb
                                    </v-icon>
                                </template>
                                <span style="color:white">当认证设备满足指定的ip网段时，会调用对应模板策略</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-expansion-panels>
                            <v-expansion-panel v-for="(rule, i) in data.rules"
                                               :key="i">
                                <v-expansion-panel-header>
                                    <div>
                                        <v-icon color="primary"
                                                small
                                                @click="handleDelRule(i)">
                                            delete
                                        </v-icon>
                                        {{ '规则' + (i + 1) + "["+rule.gwate+"]" }}
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row justify="start">
                                        <div class="layout-select">
                                            <v-text-field 
                                                label="认证网段" 
                                                v-model="rule.gwate"
                                                dense
                                                required
                                                outlined
                                            ></v-text-field>
                                        </div>
                                        <div class="layout-select">
                                            <v-select :items="demos"
                                                label="模板策略"
                                                item-text="name"
                                                item-value="id"
                                                v-model="rule.scheduleId"
                                                dense
                                                solo></v-select>
                                        </div>
                                    </v-row>

                                    <v-row style="margin-top: -20px;">
                                        <v-chip v-for="(item,index) in rule.dates"
                                                :key="index"
                                                class="ma-2"
                                                color="primary"
                                                label>

                                            {{ getDateText(item) }}

                                            <v-icon small
                                                    @click="handleDelete(rule.dates, index)">
                                                delete
                                            </v-icon>

                                        </v-chip>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary"
                                   @click="handleClose">
                                {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                            </v-btn>
                            <v-btn color="primary"
                                   @click="handleSave"
                                   :loading="loading">
                                {{$vuetify.lang.t('$vuetify.portalManage.update')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from "../../../api";
import { Notification } from "element-ui";

export default {
    components: {
    },

    props: {
        
    },

    data() {
        return {
            title: this.$vuetify.lang.t(
                "$vuetify.portalManage.scheduler.rulesBtn"
            ),

            dialog: false,

            loading: false,
            demos: [],

            devices: [
                {
                    value: 0,
                    label: "手机",
                },
                // {
                //     value: 1,
                //     label: "平板",
                // },
                {
                    value: 2,
                    label: "电脑",
                },
            ],

            langs: [
                {
                    value: 0,
                    label: "中文",
                },
                {
                    value: 1,
                    label: "英文",
                },
            ],

            data: {
                id:0,
                name:"",
                rules: [
                    {
                        gwate:"",
                        scheduleId:0,
                    },
                ],
            },
        };
    },

    methods: {
        handleOpen(item) {
            this.data.id = item.id;
            this.data.name = item.name;
            if(item.rules != ""){
                this.data.rules = this.newObject(item.rules)
            }else{
                this.data.rules = []
            }
            this.getScheduler(item.groupID)
            this.dialog = true;
        },

        getScheduler(value){
            this.$http.post(api.default.portal.scheduleListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.demos = resp.data.list;
            })
        },

        handleClose() {
            this.dialog = false;
        },

        handleSave() {
            this.loading = true;
            this.$http
                .post(api.default.portal.acSetRulesUrl, {
                    id: this.data.id,
                    rules: JSON.stringify(this.data.rules),
                })
                .then((resp) => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: "success",
                        message: this.$vuetify.lang.t(
                            "$vuetify.userManage.success"
                        ),
                    });
                    this.$emit("handleSearch");
                    this.handleClose()
                })
                .finally((resp) => {
                    this.loading = false;
                });
        },

        handleDelRule(index) {
            this.data.rules.splice(index, 1);
        },

        handleAddRule() {
            if (this.demos.length <= 0) {
                Notification({
                    type: "error",
                    message: this.$vuetify.lang.t(
                        "无策略组"
                    ),
                });
                return false;
            }
            this.data.rules.push({
                gwate:"",
                scheduleId:0,
            });
        },

        handleDelete(item, index) {
            item.splice(index, 1);
        },

        newObject(item) {
            return JSON.parse(item);
        },
    },
};
</script>

<style scoped>
.layout-select {
    width: 200px;
    margin-right: 10px;
}
</style>