<template>
    <div>
        <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="(alert, index) in alertList"
                               :key="index"
                               @click="handelClickPanel(alert.group_id,index)">
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <v-col cols="2">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open">{{$vuetify.lang.t('$vuetify.alert.table.editing')}}</span>
                                    <span v-else>{{alert.name}}</span>
                                </v-fade-transition>
                            </v-col>
                            <v-col cols="4"
                                   class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open"> </span>
                                    <span v-else>{{alert.title }}</span>
                                </v-fade-transition>
                            </v-col>
                            <v-col cols="2"
                                   class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open"> </span>
                                    <span v-else>
                                        {{alert.group_name}}
                                    </span>
                                </v-fade-transition>
                            </v-col>
                            <v-col cols="2"
                                   class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open"> </span>
                                    <span v-else>
                                        {{alert.shop_name}}
                                    </span>
                                </v-fade-transition>
                            </v-col>
                            <v-col cols="2"
                                   class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open"> </span>
                                    <span v-else>
                                        <span v-if="alert.status"
                                              style="color:green">
                                            {{$vuetify.lang.t('$vuetify.alert.table.isActive')}}
                                        </span>
                                        <span v-else
                                              style="color:red">
                                            {{$vuetify.lang.t('$vuetify.alert.table.notActive')}}
                                        </span>
                                    </span>
                                </v-fade-transition>
                            </v-col>

                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="editForm"
                            lazy-validation>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field :label="$vuetify.lang.t('$vuetify.alert.table.name')"
                                              v-model="alert.name"
                                              :rules="rules.name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-select v-model="alert.email_id"
                                          :items="emailList"
                                          item-value="id"
                                          item-text="name"
                                          :rules="rules.email"
                                          :label="$vuetify.lang.t('$vuetify.alert.form.email')"></v-select>
                            </v-col>
                            <v-col cols="2"
                                   v-if="userType == 0">
                                <v-select v-model="alert.group_id"
                                          @change="handleGroupChange"
                                          class="mt-6"
                                          :rules="rules.group"
                                          :items="groupArr"
                                          item-value="id"
                                          item-text="name"
                                          :label="$vuetify.lang.t('$vuetify.email.table.group')"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="2"
                                   v-if="userType == 0 || userType == 1">
                                <v-select v-model="alert.shop_id"
                                          class="mt-6"
                                          :items="shopArr"
                                          item-value="id"
                                          item-text="name"
                                          :hint="$vuetify.lang.t('$vuetify.alert.tip.shopEmpty')"
                                          persistent-hint
                                          :label="$vuetify.lang.t('$vuetify.email.table.shop')"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="2">
                                <el-switch v-model="alert.status"
                                           :active-text="$vuetify.lang.t('$vuetify.alert.table.active')"
                                           :inactive-text="$vuetify.lang.t('$vuetify.alert.table.inactive')"
                                           active-color="#13ce66"
                                           inactive-color="#ff4949"
                                           style="float: right;margin-top: 20px;"></el-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-combobox v-model="alert.addresses"
                                            :label="$vuetify.lang.t('$vuetify.alert.form.addresses')"
                                            :hint="$vuetify.lang.t('$vuetify.alert.tip.addAddress')"
                                            persistent-hint
                                            multiple
                                            :rules="rules.addresses"
                                            chips>
                                    <template v-slot:selection="data">
                                        <v-chip :key="JSON.stringify(data.item)"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                :disabled="data.disabled"
                                                @click:close="data.parent.selectItem(data.item)">
                                            <v-avatar class="accent white--text"
                                                      left
                                                      v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                                            {{data.item }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="6">
                                <v-combobox v-model="alert.ccs"
                                            :label="$vuetify.lang.t('$vuetify.alert.form.ccs')"
                                            multiple
                                            :rules="rules.ccs"
                                            chips>
                                    <template v-slot:selection="data">
                                        <v-chip :key="JSON.stringify( data.item)"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                :disabled="data.disabled"
                                                @click:close="data.parent.selectItem(data.item)">
                                            <v-avatar class="accent white--text"
                                                      left
                                                      v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                                            {{ data.item }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field :label="$vuetify.lang.t('$vuetify.alert.form.fromName')"
                                              v-model="alert.from_name"
                                              :rules="rules.fromName">
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="$vuetify.lang.t('$vuetify.alert.form.title')"
                                              v-model="alert.title"
                                              :rules="rules.title">
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="$vuetify.lang.t('$vuetify.alert.form.content')"
                                              v-model="alert.content"
                                              :rules="rules.content">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1">
                                <v-btn color="#5cbbf6"
                                       dark
                                       elevation="3"
                                       large
                                       @click="handleBind(alert.id)">
                                    {{$vuetify.lang.t('$vuetify.alert.btn.bindGateway')}}
                                </v-btn>
                            </v-col>
                            <v-col cols="1">
                                <v-btn color="primary"
                                       elevation="3"
                                       large
                                       :loading="testBtnLoading"
                                       @click="sendTestMail(alert.id)">
                                    发送测试邮件
                                </v-btn>
                            </v-col>
                            <v-col cols="1"
                                   offset="8">
                                <v-btn color="success"
                                       elevation="3"
                                       large
                                       @click="alertEdit(index)">
                                    {{$vuetify.lang.t('$vuetify.alert.btn.save')}}
                                </v-btn>
                            </v-col>
                            <v-col cols="1">
                                <v-btn color="error"
                                       elevation="3"
                                       large
                                       @click="alertDel(alert.id)">
                                    {{$vuetify.lang.t('$vuetify.alert.btn.del')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import api from "../../../api";
import axios from "axios";
export default {
    props: {
        alertList: Array,
        userType: String,
        emailList: Array,
        groupArr: Array,
        shopArr: Array,
        rules: Object,
    },
    data() {
        return {
            panel: -1,
            testBtnLoading: false,
        };
    },
    methods: {
        setPanel(panel) {
            this.panel = panel;
        },
        handelClickPanel(groupID, index) {
            this.$emit("handelClickPanel", groupID, index);
        },
        handleGroupChange(event) {
            this.$emit("handleGroupChange", event);
        },
        alertEdit(index) {
            if (this.$refs.editForm[0].validate()) {
                this.$emit("alertEdit", index);
            }
        },
        alertDel(id) {
            this.$emit("alertDel", id);
        },
        handleBind(id) {
            this.$emit("handleBind", id);
        },
        sendTestMail(id) {
            this.testBtnLoading = true;
            axios({
                method: "POST",
                url: api.gateway.eamilTest,
                data: {
                    alertID: id,
                },
            })
                .then((res) => {
                    if (res.data == "success") {
                        this.$notify.success({
                            message: "测试邮件发送成功",
                        });
                    } else {
                        this.$notify.error({
                            message: "测试邮件发送失败",
                        });
                    }
                })
                .catch(() => {
                    this.$notify.error({
                        message: "测试邮件发送失败",
                    });
                })
                .finally(() => {
                    this.testBtnLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>