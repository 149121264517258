<template>
     <div>
            <v-row justify="center">
                <v-dialog v-model="dialog" persistent
                          max-width="750px">
                    <v-card>
                         <v-card-text>

                            <v-toolbar flat
                                color="white">
                                <v-toolbar-title>
                                    {{ data.name }}
                                </v-toolbar-title>
                                <v-divider class="mx-4"
                                           inset
                                           vertical></v-divider>

                                <v-btn color="primary"
                                       @click="handleAddRule">
                                    添加规则
                                </v-btn>
                            </v-toolbar>

                            <v-expansion-panels>
                                <v-expansion-panel v-for="(rule, i) in ruleList"
                                                   :key="i">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon color="primary"
                                                    small
                                                    @click="handleDelRule(i)">
                                                delete
                                            </v-icon>
                                            {{ '规则' + (i + 1) }}
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row justify="start">
                                            <v-col md="4">
                                                <v-text-field :label="'金额(元/月)'"
                                                        v-model="rule.price"
                                                        clearable
                                                        type="number"></v-text-field>
                                            </v-col>
                                           
                                            <v-col md="4">
                                                <v-text-field :label="'验收几个月后打折(单位:月)'"
                                                        v-model="rule.discount_after"
                                                        clearable
                                                        type="number"></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                                <v-text-field :label="'折扣'"
                                                        v-model="rule.discount"
                                                        clearable
                                                        type="number"></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row justify="start">
                                            <v-col>
                                                 <v-text-field
                                                        v-model="rule.date"
                                                        :label="$vuetify.lang.t('$vuetify.radacctRule.pickMonthRange')"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        @click="openChooseDateDialog(i, rule.date)"
                                                    />
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary"
                                       @click="handleClose">
                                    {{ $vuetify.lang.t('$vuetify.portalManage.close') }}
                                </v-btn>
                                <v-btn color="primary"
                                       @click="handleSave"
                                       :loading="loading">
                                    {{ $vuetify.lang.t('$vuetify.portalManage.update') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <rule-date ref="ruledate" @saveDate="saveDate"></rule-date>
            </v-row>
        </div>
</template>
<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';
import RuleDate from '@/views/Radacct/component/RuleDate.vue'
// import SchedulerRuleDate from "./SchedulerRuleDate.vue";

export default {
    components: {
        RuleDate
        // SchedulerRuleDate,
    },

    data() {
        return {

            title: this.$vuetify.lang.t('$vuetify.radacctRule.btn.editBtn'),

            loading: false,
            dialog: false,

            data: {
                id:0,
                name:null,
                rules:null
            },

            ruleList:[],
            curentDateIndx:0,
            curentDate:[null,null],

            showMenu:false,

            rules: {
                name: [
                    value => this.formSpaces(value) !== '' || this.$vuetify.lang.t('$vuetify.radacctRule.table.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.radacctRule.table.name')
                ],
            }
        }
    },

    methods: {
        formSpaces(str) {
            if (str !== null && str !== undefined) {
                let res = str.replace(/\s*/g, '')
                return res;
            }
        },

        handleOpen(item) {
            var data = this.newObject(item);
            if(data.rules!=""){
                var rules = JSON.parse(data.rules)
                rules.condition.forEach(item => {
                    this.ruleList.push({
                        id: item.id,
                        date: [item.start, item.end],
                        discount: item.discount,
                        discount_after: item.discount_after,
                        price: item.price/100,
                    })
                });
            }
            
            this.data.id = data.id
            this.data.name = data.name
            this.dialog = true;
        },

        handleClose() {
            this.ruleList = []
            this.dialog = false;
        },

        //点击打开选择日期的菜单
        openChooseDateDialog(i,date){
            this.$refs.ruledate.handleOpen(date)
            this.curentDateIndx = i
        },

        saveDate(item){
            this.ruleList[this.curentDateIndx]["date"] = item
        },
        
        //保存规则
        handleSave() {
            this.loading = true;

            var rules = {
                condition:[]
            }
            this.ruleList.forEach(val => {
                rules.condition.push({
                    id: val.id,
                    start: val.date[0], 
                    end: val.date[1],
                    discount: val.discount,
                    discount_after: val.discount_after,
                    price: val.price*100,
                })
            });

            this.data.rules = JSON.stringify(rules)

            this.$http.post(api.default.radacct.setRules, this.data).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.radacctRule.success')
                });
                this.$emit('handleSearch');
            }).finally(resp => {
                this.loading = false;
                this.handleClose();
            })
        },

        //删除规则
        handleDelRule(index) {
            this.ruleList.splice(index, 1);
        },

        //增加规则
        handleAddRule() {
            this.ruleList.push({
                id: this.ruleList.length+1,
                date:[null,null],      
                discount:0,
                discount_after:0,
                price:0,
            });
        },

        handleDelete(item, index) {
            item.splice(index, 1);
        },
       
        newObject(item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        },

        getRealPrice(rule){
            return rule.price * rule.discount/100
        },

        getRef(i){
            return "menu_"+i
        },

    }
}
</script>

<style scoped></style>