<template>
	<div id="Server">
		<v-container fill-height fluid grid-list-xl>
		<v-layout justify-center wrap>
			<v-flex md12>
				<v-table :titleTable="titleTable" :headers="headers" :tableData='tableData' :pages="params.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loading" :pageSize="params.pageSize" :total="params.total" >
					<template slot="select">
						<v-btn @click="showAdd" dark color="primary">
							{{$vuetify.lang.t('$vuetify.server.table.addServer')}}
						</v-btn>
					</template>
					<template slot="status" slot-scope="propData">
						<div class="circles" :class="propData.child.item.status ==1?'green':'red'"></div>
						<div class="txt">{{propData.child.item.txt}}</div>
					</template>
					<template slot="port1" slot-scope="propData">
						<v-col cols="12" sm="7" md="7">
							<v-text-field type="number" class="mt-6" :label="$vuetify.lang.t('$vuetify.server.label.port')" solo dense v-model="propData.child.item.chargePort"></v-text-field>
						</v-col>
					</template>
					<template slot="port2" slot-scope="propData">
						<v-col cols="12" sm="7" md="7">
							<v-text-field type="number" class="mt-6" :label="$vuetify.lang.t('$vuetify.server.label.port')" solo dense v-model="propData.child.item.tallyPort"></v-text-field>
						</v-col>
					</template>
					<template slot="action" slot-scope="propData">
						<a class="span" @click="showSave(propData.child.item)">
							<v-icon small>mdi-restore</v-icon>{{$vuetify.lang.t('$vuetify.server.btn.save')}}
						</a>
						<a class="span" @click="showRestart(propData.child.item)" v-if="propData.child.item.status == 1">
							<v-icon small>mdi-sync</v-icon>{{$vuetify.lang.t('$vuetify.server.btn.restart')}}
						</a>
						<a class="span" @click="showOpen(propData.child.item)" v-if="propData.child.item.status == 0">
							<v-icon small>mdi-check-bold</v-icon>{{$vuetify.lang.t('$vuetify.server.btn.open')}}
						</a>
						<a class="span" @click="showClose(propData.child.item)" v-if="propData.child.item.status == 1">
							<v-icon small>mdi-close-circle-outline</v-icon>{{$vuetify.lang.t('$vuetify.server.btn.close')}}
						</a>
						<a class="span" @click="showDelete(propData.child.item)">
							<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
						</a>
					</template>
				</v-table>
				<v-dialogs :theme="themeSave" :dialog="dialogSave" @dialogSure="handleEmitSave">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.save')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeRestart" :dialog="dialogRestart" @dialogSure="handleEmitRestart">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.restart')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeOpen" :dialog="dialogOpen" @dialogSure="handleEmitOpen">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.open')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeClose" :dialog="dialogClose" @dialogSure="handleEmitClose">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.close')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeDelete" :dialog="dialogDelete" @dialogSure="handleEmitDelete">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeAdd" :dialog="dialogAdd" @dialogSure="handleEmitAdd">
					<v-col cols="12" sm="8" md="8" v-if="dialogAdd">
                        <v-form
                            ref="form"
                            lazy-validation>
                            <v-text-field @change="addItem.name=formSpaces(addItem.name)" :rules="nameRule" v-model="addItem.name" :label="$vuetify.lang.t('$vuetify.server.table.radiusName')" :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.radiusName')"></v-text-field>
                            <v-text-field @change="addItem.addr=formSpaces(addItem.addr)" :rules="addressRule" v-model="addItem.addr" :label="$vuetify.lang.t('$vuetify.server.table.radiusAddress')" :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.radiusAddress')"></v-text-field>
                        </v-form>
					</v-col>
				</v-dialogs>
			</v-flex>
		</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification } from 'element-ui';
	export default {
		data() {
			return {
				themeAdd: this.$vuetify.lang.t('$vuetify.server.btn.addRadius'),
				themeDelete: this.$vuetify.lang.t('$vuetify.server.btn.delete'),
				themeSave: this.$vuetify.lang.t('$vuetify.server.btn.save'),
				themeRestart: this.$vuetify.lang.t('$vuetify.server.btn.restart'),
				themeOpen: this.$vuetify.lang.t('$vuetify.server.btn.open'),
				themeClose: this.$vuetify.lang.t('$vuetify.server.btn.close'),
				dialogAdd: false,
				dialogDelete: false,
				dialogSave: false,
				dialogRestart: false,
				dialogOpen: false,
				dialogClose: false,
				titleTable: this.$vuetify.lang.t('$vuetify.server.titleServer'),
				headers: [{
						text: this.$vuetify.lang.t('$vuetify.server.table.radiusName'),
						align: 'left',
						value: 'name',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.radiusAddress'),
						align: 'left',
						value: 'addr',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.radiusStatus'),
						align: 'left',
						value: 'status'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.radiusPort1'),
						align: 'left',
						value: 'chargePort',
						sortable: false
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.radiusPort2'),
						align: 'left',
						value: 'tallyPort',
						sortable: false
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableData: [],
				nameRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.radiusName'),
                    v => (v !== null) || this.$vuetify.lang.t('$vuetify.server.rule.radiusName')
                ],
				portRule: [v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.port')],
				addressRule:[
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.radiusAddress'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.radiusAddress')
                ],
				addItem:{},
				params:{
					pagesize: 10, // 每页返回记录数
					pagenum: 1, // 页数
					total:0,
				},
				loading:true,
			}
		},
		watch: {

		},
		props: [

		],
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.serverList();
		},
		methods: {
            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },
			handlePage(data){
				this.params.pagenum = data;
				this.serverList();
			},
			handlePageSize(data){
				this.params.pagesize = data;
				this.serverList();
			},
			serverList(){
				this.loading = true;
				this.$http.post(api.default.aaa.serverList,this.params).then(resp => {
					this.params.total = parseInt(resp.data.total);
					this.tableData = resp.data.list;
					for(var i=0;i<this.tableData.length;i++){
						this.tableData[i].txt = this.tableData[i].status == 0?this.$vuetify.lang.t('$vuetify.server.btn.close'):this.$vuetify.lang.t('$vuetify.server.btn.open');
					}
					this.loading = false;
				}).finally(resp => {})	
			},
			
			handleEmitAdd(params) {
				if(!params){
					this.addItem={}
					this.dialogAdd = !this.dialogAdd
					return false;
				}
				if(!this.addItem.name||this.addItem.name =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.server.rule.radiusName')
				});
				if(!this.addItem.addr||this.addItem.addr =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.server.rule.radiusAddress')
				});
				this.$http.post(api.default.aaa.serverAdd,this.addItem).then(resp => {
					if(resp.errno == '0'){
						this.dialogAdd = !this.dialogAdd;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitSave(params) {
				if(!params){
					this.addItem={};
					return this.dialogSave = !this.dialogSave;
				}
				if(!this.addItem.chargePort || this.addItem.chargePort ==""||!this.addItem.tallyPort||this.addItem.tallyPort==""){
					Notification({
						type: 'warning',
						message: this.$vuetify.lang.t('$vuetify.server.rule.port')
					});
					return this.dialogSave = !this.dialogSave;
				}
				var paramsdata = {
					id:parseInt(this.addItem.id),
					chargePort:parseInt(this.addItem.chargePort),
					tallyPort:parseInt(this.addItem.tallyPort) 
				}
				this.$http.post(api.default.aaa.serverSetport,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogSave = !this.dialogSave;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitRestart(params) {
				if(!params){
					this.addItem={};
					if(!params) return this.dialogRestart = !this.dialogRestart;
				}
				this.$http.post(api.default.aaa.serverRestart,{id:this.addItem.id}).then(resp => {
					if(resp.errno == '0'){
						this.dialogRestart = !this.dialogRestart;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitClose(params) {
				if(!params){
					this.addItem={};
					if(!params) return this.dialogClose = !this.dialogClose;
				}
				var paramsdata = {
					id:this.addItem.id,
					status:0
				};
				this.$http.post(api.default.aaa.serverStatus,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogClose = !this.dialogClose;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitOpen(params) {
				if(!params){
					this.addItem={};
					if(!params) return this.dialogOpen = !this.dialogOpen;
				}
				var paramsdata = {
					id:this.addItem.id,
					status:1
				};
				this.$http.post(api.default.aaa.serverStatus,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogOpen = !this.dialogOpen;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitDelete(params) {
				if(!params){
					this.addItem={};
					if(!params) return this.dialogDelete = !this.dialogDelete;
				}
				this.$http.get(api.default.aaa.serverDelete+'?id='+this.addItem.id).then(resp => {
					if(resp.errno == '0'){
						this.dialogDelete = !this.dialogDelete;
						this.addItem= {};
						this.serverList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			showAdd() {
				this.dialogAdd = !this.dialogAdd;
			},
			showSave(data) {
				this.addItem = data;
				this.dialogSave = !this.dialogSave;
			},
			showRestart(data) {
				this.addItem = data;
				this.dialogRestart = !this.dialogRestart;
			},
			showOpen(data) {
				this.addItem = data;
				this.dialogOpen = !this.dialogOpen;
			},
			showClose(data) {
				this.addItem = data;
				this.dialogClose = !this.dialogClose;
			},
			showDelete(data) {
				this.addItem = data;
				this.dialogDelete = !this.dialogDelete;
			},
		}
	}
</script>

<style language="scss">
	.green {
		background: greenyellow;
	}
	
	.red {
		background: red;
	}
	
	.circles {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		display: inline-block;
		float: left;
		margin-right: 4px;
	}
	
	.txt {
		height: 16px;
		line-height: 16px;
	}
</style>