<template>
    <v-container fill-height fluid grid-list-xl>
        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <v-row>
                        <v-col cols="12">
                            <v-toolbar :flat="true">
                                <v-toolbar-title>
                                    <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                </v-toolbar-title>

                                <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                                
                                <v-btn color="primary" dark  @click="search">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.common.search')}}
                                    </span>
                                </v-btn>
                                <!-- 搜索按钮 -->
                                <v-btn color="primary ml-3" dark  @click="fresh">
                                    <span>
                                        {{$vuetify.lang.t('$vuetify.refresh')}}
                                    </span>
                                </v-btn>
                                <!-- 刷新按钮 -->
                                <v-btn color="primary ml-3" dark :loading="authdetail_list.export_button.loading" @click="dataExport">
                                    <span>
                                        导出
                                    </span>
                                </v-btn>
                                <!-- 导出按钮 -->
                            </v-toolbar>
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="tempgroup"
                                            v-model="groupVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                            @change="changeGroup"
                                            @click="searchgroup"
                                        >
                                        <template v-slot:prepend-item>
                                            <v-text-field
                                                dense
                                                placeholder="搜索"
                                                v-model="groupname"
                                                outlined
                                            >
                                            <v-btn
                                                class="ma-2"
                                                slot="append"
                                                text
                                                icon
                                                small
                                                @click="searchgroup"
                                            >
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                            </v-text-field>
                                        </template>
                                        </v-select>
                                    </v-col>
                                    <!-- 选择集团 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="tempshop"
                                            v-model="shopVal"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            @change="changeShop"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                            @click="searchshop"
                                        >
                                        <template v-slot:prepend-item>
                                            <v-text-field
                                                dense
                                                placeholder="搜索"
                                                v-model="shopname"
                                                outlined
                                            >
                                            <v-btn
                                                class="ma-2"
                                                slot="append"
                                                text
                                                icon
                                                small
                                                @click="searchshop"
                                            >
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                            </v-text-field>
                                        </template>
                                        </v-select>
                                    </v-col>
                                    <!-- 选择场所 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-select
                                            :items="tempauthzones"
                                            v-model="authzone"
                                            item-value="id" 
                                            item-text="name"
                                            clearable
                                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_auth_name')"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.auth_name')"
                                            @click="searchauthzone"
                                        >
                                        <template v-slot:prepend-item>
                                            <v-text-field
                                                dense
                                                placeholder="搜索"
                                                v-model="authzonesname"
                                                outlined
                                            >
                                            <v-btn
                                                class="ma-2"
                                                slot="append"
                                                text
                                                icon
                                                small
                                                @click="searchauthzone"
                                            >
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                            </v-text-field>
                                        </template>
                                        </v-select>
                                    </v-col>
                                    <!-- 输入手机号 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="mac"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_mac')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.mac')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入Mac -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="ip"
                                            :placeholder="$vuetify.lang.t('$vuetify.portalMac.input_ip')"
                                            :label="$vuetify.lang.t('$vuetify.portalMac.ip')"
                                            append-outer-icon="search"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入Ip -->
                                    <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="date"
                                                    :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                                    prepend-icon="event"
                                                    readonly
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="date"
                                            range
                                            scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- 选择时间段 -->
                                </v-row>
                            </v-card-subtitle>
                            <!-- 选择框 -->
                        </v-col>
                    </v-row>
                </v-card>
                <!-- 搜索card -->
            </v-col>
            <!-- 搜索框 -->
            <v-col cols="12" md="12">
                <material-table
                    :title="title"
                    :columns="columns"
                    :data="data"
                    :currentPage="page"
                    :url = "url"
                    method = "post"
                    :filter = "filter"
                    :searchValue= "searchValue"
                    v-on:searchValue="setSearchValue">
                    <template slot="title">
                        <a class="span" @click="showConfig">
                            配置阈值
                        </a>
                    </template>
                </material-table>
            </v-col>
        </v-row>
        <el-dialog title="配置" :visible.sync="dialogShowInfo" :before-close="handleclose" width="40%">
            <v-col cols="12" sm="8" md="8">
                <v-form
                    ref="form"
                    lazy-validation>
                    <v-text-field v-model="addItem.client_alert_duration" label="持续时间(秒)"></v-text-field>
                    <v-text-field v-model="addItem.client_alert_rxbytes" label="上行速率(kb/s)"></v-text-field>
                    <v-text-field v-model="addItem.client_alert_txbytes" label="下行速率(kb/s)"></v-text-field>
                </v-form>
            </v-col>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="setconfig()">确 定</el-button>
            </span>
        </el-dialog>
    </v-container>
</template>

<script>
import * as api from '../../../api'
import exportExcel from '../../../utils/export'
import { returnSearch } from '../../../utils/common'
import { Notification } from 'element-ui';

export default {
    data () {
      return {
        
        dialogShowInfo:false,
        authdetail_list:{
            export_button:{
                loading: false
            }
        },
        group: [],
        shop: [],
        authzones: [],

        groupname:"",
        shopname:"",
        authzonesname:"",
        tempgroup:[],
        tempshop:[],
        tempauthzones:[],

        groupVal: null,
        shopVal: null,
        authzone: null,
        auth_info: "",
        mac: "",
        ip: "",
        groupID: 0,
        shopID: 0,
        searchValue: 0,
        page:1,


        date: [this.getBeforeDate(1), new Date().toISOString().substr(0, 10)],
        menu: false,
        dialog: false,
        title: "预警记录",

        url: api.default.clientrecord.listrecord,
        filter: {
            timeRange: [this.getBeforeDate(1) + ' 00:00:00', new Date().toISOString().substr(0, 10) + ' 23:59:59'],
            group_id: null,
            shop_id: null,
            authzone: null,
        },
        
        columns: [
            { text: this.$vuetify.lang.t('$vuetify.portalMac.identity'), value: 'auth_info' },
            { text: this.$vuetify.lang.t('$vuetify.portalMac.mac'), value: 'mac' },
            { text: this.$vuetify.lang.t('$vuetify.portalMac.ip'), value: 'ip' },
            { text: this.$vuetify.lang.t('$vuetify.userflow.maxoutrate'), value: 'max_rx_bytes' },
            { text: this.$vuetify.lang.t('$vuetify.userflow.maxinrate'), value: 'max_tx_bytes' },
            { text: this.$vuetify.lang.t('$vuetify.portalMac.auth_name'), value: 'auth_name' },
            { text: "累计时间", value: 'total_time' },
            { text: this.$vuetify.lang.t('$vuetify.portalMac.updated_at'), value: 'updated_at' },
            // { text: this.$vuetify.lang.t("$vuetify.apiManagement.action"), value: "action" },
        ],
        data: [
        ],
        addItem:{
            client_alert_duration:0,
            client_alert_rxbytes:0,
            client_alert_txbytes:0,
        }
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$vuetify.lang.t('$vuetify.portalMac.newItem') : this.$vuetify.lang.t('$vuetify.portalMac.editItem')
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchTable()
            this.initGroupShop()
            this.fetchAuthzoneList()
        })
    },
    methods: {
        /* 认证记录导出 */
        dataExport(){
            this.exportLoading = true;
            var postData = {
                filter:this.filter,
            }
            this.$http.post(api.default.clientrecord.listrecord,postData).then(resp => {
                if(resp.errno == 0){
                    if(resp.data.list.length>0){
                        const titleArr = [
                            "认证域",
                            "身份信息",
                            "mac",
                            "ip",
                            "最大上行速率",
                            "最大下行速率",
                            "累计时长",
                            "更新时间", 
                            "创建时间",
                        ]
                        const exportdata = []
                        for (let i of resp.data.list) {
                            let tempdata = {
                                "auth_name":i.auth_name,
                                "auth_info": i.auth_info,
                                "mac": i.mac,
                                "ip": i.ip,
                                "max_rx_bytes": i.max_rx_bytes,
                                "max_tx_bytes": i.max_tx_bytes,
                                "total_time": i.total_time,
                                "created_at": i.created_at,
                                "updated_at": i.updated_at,
                            }
                            exportdata.push(tempdata)
                        }
                        exportExcel(exportdata, '告警记录', titleArr, 'sheetName');
                    }else{
                        Notification({
                            type: 'info',
                            message: "无数据"
                        });
                    }
                }
                this.exportLoading = false;
            })
        },
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
            }
        },
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                this.group = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },
        /* 获取认证域列表 */
        fetchAuthzoneList(){
            const postData = {
                group_id: this.groupVal,
                shop_id: this.shopVal
            }
            this.$http.post(api.default.portal.zonenameListUrl,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authzones = resp.data
            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            if(groupid == null){
                this.groupVal = null
                this.shopVal = null
            }
            this.fetchShopList(groupid)
            this.fetchAuthzoneList()
            this.groupID = groupid
        },
        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.shopVal = null
            this.fetchAuthzoneList()
            this.shopID = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },
        fresh(){
            this.fetchTable()
            this.searchValue = 1
        },
        search(){
            this.page = 1
            this.fetchTable()
            this.searchValue = 1
        },

        fetchTable(){
            this.filter = {
                timeRange: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                group_id:this.groupID,
                shop_id:this.shopID,
                authzone: this.authzone,
                auth_info: this.auth_info,
                mac: this.client_mac,
                ip: this.client_ip,
            }
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        },
        showConfig(){
            this.$http.get(api.default.clientrecord.getcofnig).then(resp => {
                if(resp.errno != 0)
                    return;
                this.addItem = resp.data
            })
            this.dialogShowInfo=true
        },
        handleclose(){
            this.dialogShowInfo=false
        },
        setconfig(){
            this.$http.post(api.default.clientrecord.setcofnig,this.addItem).then(resp => {
                if(resp.errno == 0){
                    Notification({
                        type: 'success',
                        message: "成功"
                    });
                    this.handleclose()
                    return
                }
            })
        },
        searchgroup(){
            this.tempgroup = returnSearch(this.groupname,this.group)
        },
        searchshop(){
            this.tempshop = returnSearch(this.shopname,this.shop)
        },
        searchauthzone(){
            this.tempauthzones = returnSearch(this.authzonesname,this.authzones)
        },
    }
}
</script>

<style scoped>
.v-card__subtitle
{
    padding-top: 0px;
}
.v-toolbar
{
    margin-bottom: 0px;
}

</style>