<template>
    <div id="v-table">
        <v-data-table 
            :headers="columns" 
            :items="data" 
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            sort-by="calories"
            class="elevation-1"
        >

            <template v-slot:item.shopName="{ item }">
                {{ item.shopName === "" ? '全部场所' : item.shopName }}
            </template>

            <template v-slot:item.area="{ item }">
                {{ item.areaName === "" ? '/' : (item.provinceName + item.cityName + item.areaName) }}
            </template>

            <template v-slot:item.maccheckName="{ item }">
                {{ item.maccheckName === "" ? '/' : item.maccheckName }}
            </template>

            <template v-slot:item.phoneAllowListName="{ item }">
                {{ item.phoneAllowListName === "" ? '/' : item.phoneAllowListName }}
            </template>

            <!-- 3a客户端 -->
            <template v-slot:item.openAAA="{ item }">
                {{ item.openAaa === 1 ? '已开启' : '未开启' }}
            </template>
            
            <template v-slot:item.default_type="{ item }">
                <v-chip v-if="item.default_type == 'default'" class="ma-2" color="green" label text-color="white">
                    是
                </v-chip>
                <v-chip v-else class="ma-2" color="glay" label text-color="black">
                    否
                </v-chip>
                <!-- {{ item.default_type === null ? '/' : item.default_type }} -->
            </template>
            
            <template v-slot:item.action="{ item }">
                <slot name='action' :value='{ item }'></slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            options: {}
        }
    },

    props: {
        loading : Boolean,
        columns : Array,
        data    : Array,
        total   : Number,
        search  : Object
    },

    methods: {

        handleSearch () {
            this.search.pagesize = this.options.itemsPerPage === -1 ? 15 : this.options.itemsPerPage;
            this.search.pagenum  = this.options.page;
            this.$emit('handleSearch');
        }

    },

    watch: {

        options: {
            handler (newValue, oldValue) {
                if (oldValue.page !== undefined && newValue !== oldValue) {
                    this.handleSearch();
                }
            },
            deep: true,
        },
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    font-size: .875rem;
    height: 78px;  
}
 
#v-table .v-card--material__header {
    width: 175px;
}

</style>
