<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.identCode')" 
                                        v-model="data.gwid"
                                        :rules="rules.gwid" 
                                        @change="data.gwid=formSpaces(data.gwid)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        v-if="role === 0"
                                        :items="group"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.groupName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.groupID"
                                        :rules="rules.groupID" 
                                        @change="changeGroup"
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        v-if="role !== 2"
                                        :items="tempshop"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.shopName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.shopID"
                                        :rules="rules.shopID" 
                                        dense
                                        outlined
                                        @click="searchshop"
                                    >
                                        <template v-slot:prepend-item>
                                            <v-text-field
                                                dense
                                                placeholder="搜索"
                                                v-model="shopname"
                                                outlined
                                            >
                                            <v-btn
                                                class="ma-2"
                                                slot="append"
                                                text
                                                icon
                                                small
                                                @click="searchshop"
                                            >
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                            </v-text-field>
                                        </template>
                                    </v-select>
                                    <v-select
                                        :items="scheduler"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.scheduler')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.scheduleID"
                                        :rules="rules.scheduleID" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="smsNet"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.smsNet')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.smsgatewayID"
                                        :rules="rules.smsgatewayID" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="userAc"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.userAc')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.usercheckID"
                                        :rules="rules.usercheckID" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="smsNet"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.smsNetInter')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.smsgatewayinterID"
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="macAc"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.macAc')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.maccheckID"
                                        :rules="rules.usercheckID" 
                                        dense
                                        outlined
                                        clearable
                                    ></v-select>
                                    <v-select
                                        :items="phoneAllowList"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.phoneAllowList')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.phoneAllowListId"
                                        dense
                                        outlined
                                        clearable
                                    ></v-select>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.portalAddress')" 
                                        v-model="data.portal_address"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.portalKey')" 
                                        v-model="data.portal_key"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <!-- 3A认证 -->
                                    <v-switch  
                                    style="margin-top:-8px"
                                        v-model="openAaa" 
                                        label="开启3A认证"
                                        inset
                                        dense
                                    ></v-switch>
                                     <v-text-field 
                                        v-if="openAaa"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.nasid')" 
                                        v-model="data.nasId"
                                        :rules="rules.nasidRule" 
                                        @change="data.nasId=formSpaces(data.nasId)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        v-if="openAaa"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.nasip')" 
                                        v-model="data.nasIp"
                                        :rules="rules.nasipRule" 
                                        @change="data.nasIp=formSpaces(data.nasIp)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                      v-if="openAaa"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.psk')" 
                                        v-model="data.psk"
                                        :rules="rules.pskRule" 
                                        @change="data.psk=formSpaces(data.psk)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';
import {IP_REG} from '@/utils/common.js';
import { returnSearch } from '../../../utils/common'

export default {

    props: {
        role : Number,
        group: Array
    },

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.portalManage.ac.insertBtn'),

            loading: false,

            dialog: false,

            shop: [],
            scheduler: [],
            smsNet: [],
            userAc: [],
            macAc: [],
            phoneAllowList: [],
            shopname:"",
            tempshop: [],

            openAaa:false,
            data: {
                name: null,
                gwid: null,
                groupID: null,
                shopID: null,
                scheduleID: null, 
                smsgatewayID: null,
                smsgatewayinterID:null,
                usercheckID: null,
                maccheckID: null,
                phoneAllowListId: null,
                portal_address:null,
                portal_key:null,
                openAaa:0,  //开启aaa认证
                nasId:null,
                nasIp:null,
                psk:null,
            },

            rules: {
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.name')
                ],
                gwid: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.identCode'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.identCode')
                ],
                groupID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.groupName')
                ],
                shopID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.shopName')
                ],
                scheduleID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.scheduler')
                ],
                smsgatewayID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.smsNet')
                ],
                usercheckID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.ac.rules.userAc')
                ],
                nasidRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.client.rule.nasid'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.nasid')
                ],
                nasipRule:[
                    (v) => {
                        if(!IP_REG.test(v) && v){
                            return this.$vuetify.lang.t('$vuetify.client.rule.nasip')
                        }
                        return true
                    }
                ],
                pskRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.client.rule.psk'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.psk')
                ],
            }
            
        }
    },

    methods: {
        //格式化空格
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },

        handleOpen () {
            this.data.groupID = this.group[0].id === undefined ? null : this.group[0].id;
            this.changeGroup(this.data.groupID);
            this.dialog = true;
        },

        handleReset () {
            this.data = {
                name: null,
                gwid: null,
                groupID: null,
                shopID: null,
                scheduleID: null, 
                smsgatewayID: null,
                smsgatewayinterID:null,
                usercheckID: null,
                maccheckID: null,
                portal_address:null,
                portal_key:null,
                openAaa:0, 
                nasIp:null,
                psk:null,
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.data.shopID = this.data.shopID === 0 ? null : this.data.shopID;
                this.data.openAaa = this.openAaa?1:0                 
                this.$http.post(api.default.portal.acAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        changeGroup (value) {
            this.getShop(value);
            this.getScheduler(value);
            this.getSmsGateway(value);
            this.getRadcheck(value);
        }, 

        getShop (value) {
            this.$http.post(api.default.rbac.shopListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.shop = resp.data.list;
                this.shop.splice(0, 0, {
                    id: 0,
                    name: '所有场所'
                })
                this.data.shopID = 0;
                this.tempshop = this.shop
            })
        },

        getScheduler(value){
            this.$http.post(api.default.portal.scheduleListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.scheduler = resp.data.list;
                this.data.scheduleID = this.scheduler[0].id;
            })
        },

        getSmsGateway (value) {
            this.$http.post(api.default.sms.gatewayList, {
                pagenum : 1,
                pagesize: -1,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.smsNet = resp.data.list;
                this.data.smsgatewayID = this.smsNet[0].id;
            })
        },

        getRadcheck (value) {
            this.$http.post(api.default.aaa.radcheckListset, {
                pagenum : 1,
                pagesize: -1,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.userAc = resp.data.list;
                this.data.usercheckID = this.userAc[0].id;
                this.data.maccheckID = this.userAc[0].id;
                this.macAc = resp.data.list;
                this.phoneAllowList = resp.data.list;
            })
        },
        searchshop(){
            this.tempshop = returnSearch(this.shopname,this.shop)
        },

    }
}
</script>