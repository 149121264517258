<template>
	<div id="Client">
		<v-container fill-height fluid grid-list-xl class="app-container">
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table :titleTable="titleTable" :headers="headers" :tableData='tableData' :pages="params.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loading" :pageSize="params.pageSize" :total="params.total">
						<template slot="select">
							<v-btn @click="showAdd"  dark color="primary">
								{{$vuetify.lang.t('$vuetify.client.table.add')}}
							</v-btn>
						</template>
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}
							</a>
							<a class="span" @click="showDelete(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
							</a>
						</template>
					</v-table>
					<v-dialogs :theme="themeAdd" :dialog="dialogAdd" @dialogSure="handleEmitAdd">
						<v-col cols="12" sm="7" md="7">
							<v-text-field @change="defaultItem.nasid=formSpaces(defaultItem.nasid)" :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.nasid')" :rules="nasidRule" v-model="defaultItem.nasid" :label="$vuetify.lang.t('$vuetify.client.label.nasid')"></v-text-field>
							<v-text-field @change="defaultItem.psk=formSpaces(defaultItem.psk)"   :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.psk')" :rules="pskRule" v-model="defaultItem.psk" :label="$vuetify.lang.t('$vuetify.client.label.psk')"></v-text-field>
                            <v-text-field
                                :rules="nasipRule"
                                :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.nasip')"
                                v-model="defaultItem.nasip" 
                                :label="'NASIP'"
                            ></v-text-field>
							<!-- <v-select :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.base')" v-model="defaultItem.setID"  class="mt-7" :items="items" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.base')" dense></v-select> -->
							<v-select v-if="sessionType ==0 " :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.group')" @change="handleGroupChange" v-model="defaultItem.groupID" class="mt-7 w200" :items="itemsGroup" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.group')" dense></v-select>
							<!-- <v-select :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.case')"  v-model="defaultItem.shopID" v-if="sessionType == 1||sessionType ==0" class="mt-7 w200" :items="itemsPlace" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.case')" dense></v-select> -->
							<v-select
								:placeholder="$vuetify.lang.t('$vuetify.client.placeholder.authzone')"
								v-model="defaultItem.authzoneid"
								class="mt-7"
								:items="authzoneItems"
								item-value="id"
								item-text="name"
								:label="$vuetify.lang.t('$vuetify.client.table.authzone')"
								dense></v-select>
						</v-col>
					</v-dialogs>
					<v-dialogs :theme="themeEdit" :dialog="dialogEdit" @dialogSure="handleEmitEdit">
						<v-col cols="12" sm="7" md="7">
                            <v-text-field 
                                :rules="nasidRule" 
                                :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.nasid')" 
                                v-model="defaultItem.nasid" 
                                :label="$vuetify.lang.t('$vuetify.client.label.nasid')"
                                @change="defaultItem.nasid=formSpaces(defaultItem.nasid)" 
                            ></v-text-field>
                            <v-text-field 
                                :rules="pskRule"  
                                :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.psk')" 
                                v-model="defaultItem.psk" 
                                :label="$vuetify.lang.t('$vuetify.client.label.psk')"
                                @change="defaultItem.psk=formSpaces(defaultItem.psk)" 
                            ></v-text-field>
                            <v-text-field
                                :rules="nasipRule"
                                :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.nasip')"
                                v-model="defaultItem.nasip" 
                                :label="'NASIP'"
                            ></v-text-field>
                            <!-- <v-select :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.base')" v-model="defaultItem.setID"  class="mt-7" :items="items" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.base')" dense></v-select> -->
                            <v-select
								:placeholder="$vuetify.lang.t('$vuetify.client.placeholder.authzone')"
								v-model="defaultItem.authzoneid"
								class="mt-7"
								:items="authzoneItems"
								item-value="id"
								item-text="name"
								:label="$vuetify.lang.t('$vuetify.client.table.authzone')"
								dense></v-select>
						</v-col>
					</v-dialogs>
					<v-dialogs :theme="themeDelete" :dialog="dialogDelete" @dialogSure="handleEmitDelete">
						<v-col cols="12" sm="7" md="7">
							<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
						</v-col>
					</v-dialogs>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification } from 'element-ui';
    import {IP_REG} from '@/utils/common.js';
	export default {
		data() {
			return {
				themeAdd: this.$vuetify.lang.t('$vuetify.client.btn.add'),
				themeEdit: this.$vuetify.lang.t('$vuetify.client.btn.edit'),
				themeDelete: this.$vuetify.lang.t('$vuetify.client.btn.delete'),
				dialogAdd: false,
				dialogEdit: false,
				dialogDelete: false,
				titleTable: this.$vuetify.lang.t('$vuetify.client.title'),
				headers: [{
						text: this.$vuetify.lang.t('$vuetify.client.table.nasid'),
						align: 'left',
						value: 'nasid',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.client.table.psk'),
						align: 'left',
						value: 'psk'
					},
                    {
						text: this.$vuetify.lang.t('$vuetify.client.table.nasip'),
						align: 'left',
						value: 'nasip'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.client.table.authzone'),
						align: 'left',
						value: 'authzoneName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableData: [],
				items: [],
				itemsPlace:[],
				itemsGroup:[],
				authzoneItems:[],
				updateBase: null,
				defaultItem: {
					nasid: null,
					psk: null,
                    nasip:null,
					authzoneid: null,
				},
				nasidRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.client.rule.nasid'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.nasid')
                ],
				pskRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.client.rule.psk'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.psk')
                ],
                nasipRule:[
                    (v) => {
                        if(!IP_REG.test(v) && v){
                            return this.$vuetify.lang.t('$vuetify.client.rule.nasip')
                        }
                        return true
                    }
                ],
				baseRule: [v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.base')],
				caseRule: [v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.case')],
				groupRule: [v => !!v || this.$vuetify.lang.t('$vuetify.client.rule.group')],
				params:{
					pagesize: 10, // 每页返回记录数
					pagenum: 1, // 页数
					total:0,
				},
				loading:true,
				sessionType:sessionStorage.getItem('type')
			}
		},
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.clientList();
		},
		methods: {
            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },
			handleGroupChange(){
				// this.shopListUrl(0)
				this.getAuthzoneList()
			},
			handlePage(data){
				this.params.pagenum = data;
				this.clientList();
			},
			handlePageSize(data){
				this.params.pagesize = data;
				this.clientList();
			},
			getAuthzoneList(){
				this.$http.post(api.default.portal.zonenameListUrl,{group_id:this.defaultItem.groupID}).then(resp => {
					this.authzoneItems = resp.data;
				}).finally(resp => {})
			},
			shopListUrl(type){
				if(type == 0){
					var params = {
						pagesize:10000,
						pagenum: 1,
						groupID:this.defaultItem.groupID
					}
				}else if(type == 1){
					var params = {
						pagesize:10000,
						pagenum: 1
					}
				}
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
					this.itemsPlace = resp.data.list;
				}).finally(resp => {})
			},
			groupListUrl(){
				this.$http.post(api.default.rbac.groupListUrl).then(resp => {
					this.itemsGroup = resp.data;
				}).finally(resp => {})
			},
			clientList(){
				this.loading = true;
				this.$http.post(api.default.aaa.clientList,this.params).then(resp => {
					this.params.total = parseInt(resp.data.total);
					this.tableData = resp.data.list;
					this.loading = false;
				}).finally(resp => {})	
			},
			handleEmitAdd(params) {
				if(!params) return this.dialogAdd = !this.dialogAdd;
				if(!this.defaultItem.nasid||this.defaultItem.nasid =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.client.rule.nasid')
				});
				if(!this.defaultItem.psk||this.defaultItem.psk =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.client.rule.psk')
				});
				if(window.sessionStorage.getItem('type') == 0){
					if(!this.defaultItem.groupID||this.defaultItem.groupID =="")return Notification({
						type: 'warning',
						message: this.$vuetify.lang.t('$vuetify.client.rule.case')
					});
				}
				if(window.sessionStorage.getItem('type') == 1){
					if(!this.defaultItem.shopID||this.defaultItem.shopID =="")return Notification({
						type: 'warning',
						message: this.$vuetify.lang.t('$vuetify.client.rule.case')
					});
				}
				this.$http.post(api.default.aaa.clientAdd,this.defaultItem).then(resp => {
					if(resp.errno == '0'){
						this.dialogAdd = !this.dialogAdd;
						this.defaultItem= {};
						this.clientList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			handleEmitEdit(params) {
				if(!params) {
                    this.clientList();
                    return this.dialogEdit = !this.dialogEdit;
                }
				if(!this.defaultItem.nasid||this.defaultItem.nasid =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.client.rule.nasid')
				});
				if(!this.defaultItem.psk||this.defaultItem.psk =="")return Notification({
					type: 'warning',
					message: this.$vuetify.lang.t('$vuetify.client.rule.psk')
				});
				var paramsdata = {
					id:parseInt(this.defaultItem.id),
					nasid: this.defaultItem.nasid,
				    psk: this.defaultItem.psk,
                    nasip:this.defaultItem.nasip,
				  	authzoneid:this.defaultItem.authzoneid,
				}
				this.$http.post(api.default.aaa.clientEdit,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogEdit = !this.dialogEdit;
						this.defaultItem= {};
						
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {
                    this.clientList(); 
                })
			},
			handleEmitDelete(params) {
				if(!params) return this.dialogDelete = !this.dialogDelete;
				this.$http.get(api.default.aaa.clientDelete+'?id='+this.defaultItem.id).then(resp => {
					if(resp.errno=='0'){
						this.dialogDelete = !this.dialogDelete;
						this.clientList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			showAdd() {
				this.defaultItem = {};
				if(window.sessionStorage.getItem('type') == 0){this.groupListUrl();}
				// if(window.sessionStorage.getItem('type') == 1){this.shopListUrl(1);}
				// this.getAuthzoneList();
				this.dialogAdd = !this.dialogAdd;
			},
			showEdit(data) {
				this.defaultItem = data;
				this.getAuthzoneList();
				this.dialogEdit = !this.dialogEdit;
			},
			showDelete(data) {
				this.defaultItem = data;
				this.dialogDelete = !this.dialogDelete;
			}
		}
	}
</script>