import { render, staticRenderFns } from "./PermissionSearch.vue?vue&type=template&id=30c52b2c&scoped=true&"
import script from "./PermissionSearch.vue?vue&type=script&lang=js&"
export * from "./PermissionSearch.vue?vue&type=script&lang=js&"
import style0 from "./PermissionSearch.vue?vue&type=style&index=0&id=30c52b2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c52b2c",
  null
  
)

export default component.exports