<template>
	<div id="tables">
		<v-data-table :footer-props="footer_props" :headers="headers" class="elevation-1" :server-items-length="total" :items="tableData"  item-key="id" @update:page="handlePage" @update:items-per-page="handlePageSize" :page="pages" :items-per-page="pageSize" :loading="loading" :loading-text="$vuetify.lang.t('$vuetify.dataIterator.loadingText')">
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>{{titleTable}}</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<slot name="select"></slot>
					<v-spacer></v-spacer>
				</v-toolbar>
				<slot name="body"></slot>
			</template>
			<template v-slot:item.status="{ item }">
				<slot name='status' :child='{ item }'></slot>
			</template>
			<template v-slot:item.chargePort="{ item }">
				<slot name='port1' :child='{ item }'></slot>
			</template>
			<template v-slot:item.tallyPort="{ item }">
				<slot name='port2' :child='{ item }'></slot>
			</template>
			<template v-slot:item.tempclose="{ item }">
				<slot name='tempclose' :child='{ item }'></slot>
			</template>
			<template v-slot:item.action="{ item }">
				<slot name='action' :child='{ item }'></slot>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				footer_props:{
					showFirstLastPage: true,
					firstIcon: 'mdi-chevron-double-left',
					lastIcon: 'mdi-chevron-double-right',
					prevIcon: 'mdi-chevron-left',
					nextIcon: 'mdi-chevron-right'
				}
			}
		},
		props: ['titleTable', 'headers', 'tableData', 'pages', 'pageSize', 'total', 'loading'],
		methods: {
			handlePage(data) {
				this.$emit('page', data);
			},
			handlePageSize(data) {
				this.$emit('pageSize', data);
			},
		},
	}
</script>

<style language="scss">
	/* #tables .v-btn__content{
		color:#fff!important;
	} */
	#tables .theme--light.v-btn.v-btn--icon {
		color: rgba(0, 0, 0, .54)!important;
	}
	
	#tables .span {
		padding-right: 6px;
	}
	
	#tables .span i {
		color: #1976D2!important;
	}
	
	#tables table thead tr th {
		color: #333;
		font-size: 17px;
		font-weight: 300;
	}
	
	#tables .v-card--material__header {
		width: 175px;
	}
</style>