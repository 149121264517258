<template>
	<div id="devicelist">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-card style="width:100%;">
						<v-toolbar class="mb-0" :flat="true" dense>
							<v-toolbar-title class="font-weight-regular">
								<a @click="handleReturn">{{$vuetify.lang.t('$vuetify.gateway.equipment')}}</a>:
							</v-toolbar-title>
						</v-toolbar>
						<v-divider class="mb-10"></v-divider>
						<v-row>
							<div class="chartBox">
								<v-toolbar-title class="font-weight-regular ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.msg')}}｜
									<a class="span" @click="showNetlog()">
										<v-icon small>mdi-eye-outline</v-icon>查询网络使用统计
									</a>
								</v-toolbar-title>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.name')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.gatewaylist.name}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.mac')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.gatewaylist.mac}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.deviceId')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.status.deviceID}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.license')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.status.license}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.os')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.status.os}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.version')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.status.version}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										{{$vuetify.lang.t('$vuetify.gateway.table.status')}}:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										{{deviceListDetail.gatewaylist.online}}
									</v-col>
								</v-row>
								<v-divider class="mb-4 mt-4 ml-0 mr-0"></v-divider>
								<v-row>
									<v-col class="pt-0 pb-0" cols="4" sm="4" md="4">
										syslog:
									</v-col>
									<v-col class="pt-0 pb-0 pl-0" cols="8" sm="8" md="8">
										<a class="span" @click="syslogopen()">
											<v-icon small>mdi-eye-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.info')}}
										</a>
									</v-col>
								</v-row>
								<v-divider class="mb-2 mt-4 ml-0 mr-0"></v-divider>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.abnormal')}}:</v-toolbar-title>
								<v-divider class="mb-2 mt-4 ml-0 mr-0"></v-divider>
								{{$vuetify.lang.t('$vuetify.gateway.tableExpand.abnormal')}}:<span class="ml-5">
									<!--{{deviceListDetail.err.msg}}:{{deviceListDetail.err.msg}}-->
								</span>
								<v-divider class="mb-2 mt-2 ml-0 mr-0"></v-divider>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.memory')}}:</v-toolbar-title>
								<v-divider class="mb-2 mt-2 ml-0 mr-0"></v-divider>
								<span style="position: relative;">
									<v-progress-linear
									  background-color="light-green"
								      color="red" 
									  height="20" 
									  :value="deviceListDetail.status.memUsed" 
									  striped >
									</v-progress-linear>
									<span style="position: absolute;left: 10px;top:0;">{{deviceListDetail.status.memUsed}}%</span>
								</span>
								<v-divider class="mb-2 mt-2 ml-0 mr-0"></v-divider>
								<v-toolbar-title class="font-weight-regular mt-5 ">CPU:</v-toolbar-title>
								<span v-for="data,index in deviceListDetail.status.cpu">
									{{data.name}}&nbsp;{{$vuetify.lang.t('$vuetify.gateway.tableExpand.cpu')}}:&nbsp;{{(data.use).toFixed(2)*100}}%
									<span style="position: relative;">
										<v-progress-linear
										  background-color="light-green"
									      color="red"
									      height="20"
									      :value="data.use*100"
									      striped
									    ></v-progress-linear>
									</span>
								</span>
								<v-divider class="mb-2 mt-2 ml-0 mr-0"></v-divider>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.disk')}}:</v-toolbar-title>
									<v-data-table :loading="tableLoading" :headers="headersPans" class="elevation-1" :items="dessertsPans" :items-per-page="5"></v-data-table>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.progress')}}:</v-toolbar-title>
									<v-data-table  :loading="tableLoading" :headers="headersCommand" class="elevation-1" :items="dessertsCommand" :items-per-page="5"></v-data-table>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.bandwidth')}}:</v-toolbar-title>
									<v-data-table :loading="tableLoading" :headers="headersBand" class="elevation-1" :items="dessertsBand" :items-per-page="5"></v-data-table>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.listenPort')}}:</v-toolbar-title>
									<v-data-table :loading="tableLoading" :headers="headersPort" class="elevation-1" :items="dessertsPort" :items-per-page="5"></v-data-table>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.dhcpLeases')}}:</v-toolbar-title>
									<v-data-table :loading="tableLoading" :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1"></v-data-table>
								<v-toolbar-title class="font-weight-regular mt-5 ">{{$vuetify.lang.t('$vuetify.gateway.tableExpand.dhcpInterface')}}:</v-toolbar-title>
									<v-data-table :loading="tableLoading" :headers="headersIn" :items="dessertsIn" :items-per-page="5" class="elevation-1"></v-data-table>
							</div>
						</v-row>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
        <el-dialog title="NetLog" :visible.sync="dialogShowInfo" :before-close="handleclose" width="70%" append-to-body>
            <Netlog :gateway_id="id"/>
        </el-dialog>
		<el-dialog title="Syslog" :visible.sync="dialogSyslog" :before-close="syslogclose" width="30%" append-to-body>
			<el-date-picker
				v-model="datevalue"
				type="date"
				value-format="yyyy-MM-dd"
				placeholder="选择日期">
			</el-date-picker>
			<el-button type="primary" @click="getSyslog">查询</el-button>
            <el-dialog
				width="70%"
				title="Syslog"
				:visible.sync="innerVisible"
				append-to-body>
				
				<textarea
					disabled
					style="background-color: black;color: aliceblue; width: 100%;"
					outlined
					v-model="logtext"
					rows="15"
				></textarea>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="downSyslog()">下载</el-button>
				</div>
			</el-dialog>
        </el-dialog>
	</div>
</template>

<script>
	import * as api from './../../api'
	import Netlog from './components/netlog.vue'

	export default {
		components: {
			Netlog,
		},
		data() {
			return {
				dialogSyslog:false,
				innerVisible:false,
				dialogShowInfo:false,
				datevalue:"",
				logtext:"",
				id:this.$route.query.id,
				headersPans:[
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.diskname'),
						value: 'fs'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.diskposition'),
						value: 'mount'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.disktype'),
						value: 'type'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.useSpace'),
						value: 'used'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.availableSpace'),
						value: 'available'
					},
				],
				dessertsPans:[],
				tableLoading: false,
				dessertsCommand: [],
				headersCommand: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.pid'),
						value: 'pid'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.command'),
						value: 'name'
					},
				],
				dessertsBand: [],
				headersBand: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.bandname'),
						value: 'ifname'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.bandpocketS'),
						value: 's_packets'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.bandnumberS'),
						value: 's_bytes'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.bandpocketR'),
						value: 'r_packets'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.bandnumberR'),
						value: 'r_bytes'
					},
				],
				headers: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.name'),
						value: 'name'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.mac'),
						value: 'mac'
					},
					{
						text: 'Ip',
						value: 'ip'
					},
					{
						text: 'Pool',
						value: 'pool'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.dhcpTime'),
						value: 'time'
					},
				],
				desserts: [],
				headersIn: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.command'),
						value: 'name'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.mac'),
						value: 'HWaddr'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.status'),
						value: 'state'
					},
					{
						text: 'Inet6',
						value: 'inet6'
					},
					{
						text: 'Inet4',
						value: 'inet4'
					},
				],
				dessertsIn: [],
				dessertsPort: [],
				headersPort: [{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenName'),
						value: 'name'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenCmds'),
						value: 'cmds'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenPath'),
						value: 'exepath'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenType'),
						value: 'type'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenPortN'),
						value: 'port'
					},
				],
				headersPan:[
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenName'),
						value: 'name'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenCmds'),
						value: 'cmd'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenType'),
						value: 'type'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.tableExpand.listenPortN'),
						value: 'port'
					},
				],
				deviceListDetail:{
					name:null,
					status:{
						deviceID:null,
					}
				},
			}
		},
		mounted() {
			this.deviceGetDetail();
		},
		methods: {
			deviceGetDetail() {
				this.$http.get(api.default.gateway.deviceGetDetail+'?id='+parseInt(this.$route.query.id)).then(resp => {
					this.deviceListDetail = resp.data;
                    this.deviceListDetail.gatewaylist.online = this.deviceListDetail.gatewaylist.online == "1"?this.$vuetify.lang.t('$vuetify.gateway.statusUp'):this.$vuetify.lang.t('$vuetify.gateway.statusDown');
                    if(resp.data.status != null){
                        this.deviceListDetail.status.license = this.deviceListDetail.status.license == -1?this.$vuetify.lang.t('$vuetify.gateway.tableExpand.powerCancel'):this.$vuetify.lang.t('$vuetify.gateway.tableExpand.powerOk');
                        this.deviceListDetail.status.memFree = parseInt(this.deviceListDetail.status.mem.MemFree.split('kB')[0])
                        this.deviceListDetail.status.memTotal = parseInt(this.deviceListDetail.status.mem.MemTotal.split('kB')[0])
                        this.deviceListDetail.status.memUsed = (((this.deviceListDetail.status.memTotal - this.deviceListDetail.status.memFree)/this.deviceListDetail.status.memTotal)*100).toFixed(0);
                        this.dessertsPans = resp.data.status.disk;
                        this.dessertsCommand = resp.data.status.progress;
                        this.dessertsBand =  resp.data.status.bandwidth;
                        this.dessertsIn = resp.data.status.interface;
                        this.dessertsPort = resp.data.status.listen_port;
                        this.desserts = resp.data.status.dhcp.lease;
                    }
					if(this.dessertsIn != null){
						for(var i=0;i<this.dessertsIn.length;i++){
							for(var j in this.dessertsIn[i]){
								this.dessertsIn[i].name = j;
								this.dessertsIn[i].HWaddr = this.dessertsIn[i][j].HWaddr?this.dessertsIn[i][j].HWaddr:'-';
								this.dessertsIn[i].inet4 = this.dessertsIn[i][j].inet4?this.dessertsIn[i][j].inet4:'-';
								this.dessertsIn[i].inet6 = this.dessertsIn[i][j].inet6?this.dessertsIn[i][j].inet6:'-';
								this.dessertsIn[i].state = this.dessertsIn[i][j].state;
							}
						}
					}
					
					for(var i=0;i<this.dessertsPans.length;i++){
						this.dessertsPans[i].used = (this.dessertsPans[i].used/(1024*1024)).toFixed(4) + 'G';
						this.dessertsPans[i].available = (this.dessertsPans[i].available/(1024*1024)).toFixed(4) + 'G';
					}
					if(this.dessertsPort != null){
						for(var i=0;i<this.dessertsPort.length;i++){
							this.dessertsPort[i].name = this.dessertsPort[i].progress.name;
							this.dessertsPort[i].exepath = this.dessertsPort[i].progress.exepath;
							var cmds = '';
							var cmdsArr = this.dessertsPort[i].progress.cmds;
							for(var j=0;j<cmdsArr.length;j++){
								cmds = cmds +' '+cmdsArr[j];
							}
							this.dessertsPort[i].cmds = cmds;
						}
					}
					
				}).finally(resp => {})
			},
			handleReturn(data) {
				this.$router.push({
					path: '/gateway/devices'
				})
			},
			showNetlog(){
				this.dialogShowInfo=true
				return;
			},
			handleclose(){
				this.dialogShowInfo=false
			},

			syslogopen(){
				this.dialogSyslog=true
			},

			syslogclose(){
				this.dialogSyslog=false
			},
			getSyslog(){
				var postData = {
                	mac:this.deviceListDetail.gatewaylist.mac,
					sys_log_date:this.datevalue,
				}
				this.$http.post(api.default.gateway.showlog,postData).then(resp => {
					if(resp.errno == 0){
						this.logtext = resp.data
						this.innerVisible=true
					}
				})
			},
			downSyslog(){
				var url = api.default.gateway.downlog +"?mac="+this.deviceListDetail.gatewaylist.mac+"&sys_log_date="+this.datevalue
				window.location = url;
			}
		},
	}
</script>

<style>
	#devicelist .theme--light.v-btn.v-btn--icon {
		color: rgba(0, 0, 0, .54)!important;
	}
	
	#devicelist .span {
		padding-right: 6px;
	}
	
	#devicelist .span i {
		color: #1976D2!important;
	}
	
	#devicelist table thead tr th {
		color: #333;
		font-size: 17px;
		font-weight: 300;
	}
	
	#devicelist .v-card--material__header {
		width: 175px;
	}
	#devicelist .chartBox {
		width: 800px;
		margin: 0 auto;
		padding-bottom: 40px;
	}
</style>