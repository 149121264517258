<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        v-if="role === 0"
                                        :items="group"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.groupName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.group_id"
                                        :rules="rules.groupID" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="place"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.provinceName')"
                                        item-text="name"
                                        item-value="code"
                                        v-model="placeCode"
                                        :rules="rules.areaCode" 
                                        @change="getCity" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="city"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.cityName')"
                                        item-text="name"
                                        item-value="code"
                                        v-model="cityCode"
                                        :rules="rules.areaCode" 
                                        @change="getArea" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        :items="area"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.areaName')"
                                        item-text="name"
                                        item-value="code"
                                        v-model="data.areacode"
                                        :rules="rules.areaCode" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.contact')" 
                                        v-model="data.contact"
                                        :rules="rules.contact" 
                                        @change="data.contact=formSpaces(data.contact)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <!-- <v-select
                                        :items="rule"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.rule')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.radacct_id"
                                        dense
                                        outlined
                                    ></v-select> -->
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.radaccePrice')" 
                                        v-model="data.radacct_price"
                                        dense
                                        outlined
                                        type="number"
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.remark')" 
                                        v-model="data.remark"
                                        dense
                                        outlined
                                    ></v-text-field>
                                    <v-file-input
                                        v-model="file"
                                        :label="$vuetify.lang.t('$vuetify.userManage.site.table.attachment')"
                                        chips
                                        dense
                                    ></v-file-input>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

    props: {
        role : Number,
        group: Array,
        place: Array,
        rule: Array,
    },

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.userManage.site.insertBtn'),

            loading: false,

            dialog: false,

            placeCode: null,
            city: [],
            cityCode: null,
            area: [],

            data: {
                name: null,
                contact: null,
                areacode: null,
                group_id: null,
                radacct_price: null,
                remark: null,
                attachment: null,
            },
            file: null,

            rules: {
                name: [
                    value => this.formSpaces(value) !== '' || this.$vuetify.lang.t('$vuetify.userManage.site.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.site.rules.name')
                ],
                contact: [
                    value => this.formSpaces(value) !== '' || this.$vuetify.lang.t('$vuetify.userManage.site.rules.contact'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.site.rules.contact')
                ],
                areaCode: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.site.rules.areaCode')
                ],
                groupID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.site.rules.groupID')
                ]
            }
            
        }
    },

    methods: {

        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },

        handleOpen () {
            this.dialog = true;
        },

        handleReset () {
            this.placeCode = null
            this.cityCode = null
            this.data = {
                name: null,
                contact: null,
                areacode: null,
                group_id: null,
                radacct_id:null,
                remark:null,
                attachment:null,
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
    
                const formData = new FormData()
                if(this.file){
                    formData.append('file', this.file)
                }
                for (let i in this.data) {
                    if(i=='radacct_price'){
                        formData.append(i, this.data[i]*100);
                    }
                    formData.append(i, this.data[i]);
                }

                this.$http.post(api.default.rbac.shopAddUrl, formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        getCity (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.city = resp.data;
                this.cityCode = this.city[0].code;
                this.getArea(this.cityCode);
            })
        },

        getArea (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.area = resp.data;
                this.data.areacode = this.area[0].code;
            })
        }

    }
}
</script>

<style scoped>



</style>