<template>
    <div>
        <v-row justify="center">

            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">
                        <v-icon large color="red">warning</v-icon>
                        {{title}}
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="red darken-1" @click="handleDel" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.confirm')}}
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

    data () {
        return {
            dialog: false,
            loading: false,
            id: null,
            title: this.$vuetify.lang.t('$vuetify.userManage.delTooltip')
        }
    },

    methods: {

        handleOpen (item) {
            this.id = item.id;
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
        },

        handleDel () {
            this.loading = true;
            var url = api.default.rbac.roleDeleteUrl + '?id=' + this.id;
            this.$http.get(url).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.$emit('handleSearch');
            }).finally(resp => {
                this.loading = false;
                this.handleClose();
            })
        }

    }
}
</script>