<template>
    
    <div class="sdemo-layout">
        <demo-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title="title"
            :search ="search"
            :types="tpl.portal_type_list_orig"
            search_type="system"
        ></demo-search>

        <demo-data-table
            :type="0"
            @handleSearch="handleSearch"
            @handleEidt="handleEidt"
            @handleUpdate="handleUpdate"
            @handleDelete="handleDelete"
            :loading="loading"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >
        </demo-data-table>

        <material-dialog ref="tpl_add" formTitle="添加系统模板" @handleSave="doTplAdd">
            <template slot="content">
                <v-form ref="tpl_add_form" lazy-validation>
                    <v-text-field v-model="tpl.add_dialog.data.name" label="模板名" clearable></v-text-field>
                    <v-autocomplete
                        v-model="tpl.add_dialog.data.tpl_id"
                        :items="demos"
                        label="模板页面"
                        placeholder="请选择模板页面"
                        item-text="name"
                        item-value="id"
                        clearable
                    ></v-autocomplete>
                    <v-combobox
                        v-model="tpl.add_dialog.data.portal_type"
                        :items="tpl.portal_type_list"
                        chips
                        clearable
                        label="模板类型"
                        multiple
                    >
                    </v-combobox>
                </v-form>
            </template>
        </material-dialog>
        <!-- 添加模板 -->

        <del-demo 
            ref="sdel" 
            :type="0"
            @initialize="initialize"
            @handleSearch="handleSearch"
        ></del-demo>

        <material-dialog ref="tpl_edit" formTitle="编辑模板信息" @handleSave="doTplUpdate">
            <template slot="content">
                <v-form ref="tpl_edit_form" lazy-validation>
                    <v-text-field v-model="tpl.edit_dialog.data.name" label="模板名" clearable></v-text-field>
                    <v-combobox
                        v-model="tpl.edit_dialog.data.portal_type"
                        :items="tpl.portal_type_list"
                        chips
                        clearable
                        label="模板类型"
                        multiple
                    >
                    </v-combobox>
                </v-form>
            </template>
        </material-dialog>
        <!-- 编辑模板信息 -->
    </div>
</template>

<script>
import api from '@/api.js'
import DemoDataTable from './components/DemoDataTable.vue'
import DemoSearch from './components/DemoSearch.vue'
import AddDemo from './components/AddDemo.vue'
import DelDemo from './components/DelDemo.vue'
import { Notification } from 'element-ui'

export default {

    components: {
        DemoDataTable,
        DemoSearch,
        AddDemo,
        DelDemo
    },

    data () {
      return {
            tpl:{
                add_dialog:{
                    data:{
                        name:"",
                        tpl_id:null,
                        portal_type:[]
                    }
                },
                edit_dialog:{
                    data:{
                        name:"",
                        id:null,
                        portal_type:[]
                    }
                },
                portal_type_list:[],
                portal_type_list_orig:[]
            },

            title: this.$vuetify.lang.t('$vuetify.portalManage.demo.title.system'),

            role: parseInt(window.sessionStorage.getItem('type')),

            group: [],
            demos: [],

            data: [],

            loading: true,
            total  : 6,

            search: {
                pagesize : 8,
                pagenum  : 1,
                name     : null
            }
          
      }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
            this.getGroup();
            this.getDemo();
            this.getTypeList()
        },

        getTypeList(){
            this.$http.get(api.portal.getPortalTypes).then(resp => {
                if (!resp || resp.errno != 0) return;
                var arr = []
                resp.data.forEach(e => {
                    arr.push(e.name)
                })
                this.tpl.portal_type_list = arr
                this.tpl.portal_type_list_orig = resp.data
            })
        },

        getDemo () {
            this.$http.post(api.portal.sdemoListUrl, {
                pagenum : 1,
                pagesize: 1000
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.demos = resp.data.list;
            })
        },

        getGroup () {
            this.$http.post(api.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },

        getData () {
            this.loading = true;
            this.$http.post(api.portal.sdemoListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                this.data = resp.data.list;
            }).finally(resp => {
                this.loading = false;
            })
        },

        handleAdd () {
            this.$refs["tpl_add"].showDialog()
        },

        doTplAdd(){
            this.$http.post(api.portal.sdemoAddUrl, this.tpl.add_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                console.log(resp)
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.tpl_add_form.reset()
                this.initialize()
                this.$refs.tpl_add.closeDialog()
            }).finally(resp => {})
        },

        handleUpdate(item){
            this.tpl.edit_dialog.data.name = item.name
            this.tpl.edit_dialog.data.id = item.id
            var arr = []
            if(item.types.length > 0)
            {
                item.types.forEach(e => {
                    arr.push(e.name)
                })
            }
            this.tpl.edit_dialog.data.portal_type = arr
            this.$refs["tpl_edit"].showDialog()
        },
        
        doTplUpdate(){
            this.$http.post(api.portal.sdemoEditUrl, this.tpl.edit_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.tpl_edit_form.reset()
                this.initialize()
                this.$refs.tpl_edit.closeDialog()
            }).finally(resp => {})
        },

        handleEidt (item) {
            var url = '/admin/portal/editor/index?portal_id=' + item.id + '&role=tpl';
            window.open(url, '_blank');
        },

        handleDelete (item) {
            this.$refs.sdel.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }

    }
}
</script>

<style scoped>

.sdemo-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}
.portal-template-span{
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
}

</style>