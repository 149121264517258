<template>
    <div class="config-layout">
        <license :expireDate="license.expireDate"
                 :serialNum="license.serialNum"
                 :isAuth="license.isAuth"
                 :beforeUpload="beforeUpload"
                 :uploadSuccess="uploadSuccess"
                 :uploadError="uploadError"
                 :onProgress="onProgress"
                 :fileList="license.fileList"
                 :uploadLoading="license.uploadLoading"
                 :uploadLcsFileUrl="license.uploadLcsFileUrl"
                 @downloadLcr="downloadLcr"
                 @cancelLcs="license.isDialogShow = true"></license>
        <v-dialogs :theme="$vuetify.lang.t('$vuetify.license.cancel')"
                   :dialog="license.isDialogShow"
                   :loading="license.dialogLoading"
                   @dialogSure="handleEmitDelete">
            <v-col cols="12"
                   sm="7"
                   md="7">
                <span class="app-f16">{{$vuetify.lang.t('$vuetify.license.dialogText')}}</span>
            </v-col>
        </v-dialogs>
    </div>
</template>

<script>
import License from "./components/License.vue";
import { Notification } from "element-ui";
import * as api from "./../../api";
import { parseTime } from "../../utils/common.js";
import vDialogs from "./../../components/core/Dialog.vue";
export default {
    components: { License, vDialogs },
    data() {
        return {
            license: {
                isDialogShow: false,
                dialogLoading: false,
                uploadLcsFileUrl: api.default.system.uploadLcsFileUrl,
                fileList: [],
                isAuth: false,
                uploadLoading: false,
                serialNum: "",
                expireDate: "1970-01-01 00:00:00",
            },
        };
    },
    mounted() {
        this.getLcsStatus();
    },
    methods: {
        handleEmitDelete(params) {
            if (params) {
                this.license.dialogLoading = true;
                this.cancelLcs();
            } else {
                this.license.isDialogShow = false;
            }
        },
        onProgress() {
            this.license.uploadLoading = true;
        },
        getLcsStatus() {
            this.$http.get(api.default.system.getLcsStatusUrl).then((res) => {
                if (res.errno == 0) {
                    this.license.isAuth = res.data.isAuth;
                    this.license.serialNum = res.data.serialNum;
                    this.license.expireDate = parseTime(res.data.expireDate);
                }else{
                    this.license.isAuth = false;
                    this.license.serialNum = "";
                    this.license.expireDate = "";
                }
            });
        },
        downloadLcr() {
            window.location = api.default.system.getLcrFileUrl;
        },
        cancelLcs() {
            this.$http.get(api.default.system.cancelLcsUrl).then((res) => {
                if (res.errno == 0) {
                    this.license.dialogLoading = false;
                    this.license.isDialogShow = false;
                    Notification({
                        type: "success",
                        message: this.$vuetify.lang.t(
                            "$vuetify.common.success"
                        ),
                    });
                    this.getLcsStatus();
                }
            });
        },
        beforeUpload(file) {
            let name = file.name;
            let nameArray = name.split(".");
            let extension = nameArray[nameArray.length - 1];
            if (extension !== "lcs") {
                Notification({
                    type: "error",
                    message: this.$vuetify.lang.t("$vuetify.license.fail"),
                });
                return false;
            }
        },
        uploadSuccess(res) {
            if (res.errno == 0) {
                Notification({
                    type: "success",
                    message: this.$vuetify.lang.t("$vuetify.common.success"),
                });
            } else {
                Notification({
                    type: "error",
                    message: res.errmsg,
                });
            }
            this.getLcsStatus();
            this.license.uploadLoading = false;
        },
        uploadError(err) {
            Notification({
                type: "error",
                message: this.$vuetify.lang.t("$vuetify.license.error"),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.config-layout {
    margin: 0 10px;
}
</style>
