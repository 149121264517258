<template>
    <div>
        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    
                    <v-btn v-if="role !== 2" color="primary" @click="handleAdd">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.user.insertBtn') }}
                        </span>
                    </v-btn>
                    
                    <v-btn color="primary" @click="handleSearch">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.searchBtn') }}
                        </span>
                    </v-btn>
                </v-toolbar>

                <div class="layout-search">
                    <v-row justify="start">
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.userManage.user.table.username')"
                                v-model="search.username"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.userManage.user.table.name')"
                                v-model="search.name"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.userManage.user.table.contact')"
                                v-model="search.contact"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-select" v-if="role === 0">
                            <v-select
                                :items="tempgroup"
                                :label="$vuetify.lang.t('$vuetify.userManage.user.table.groupName')"
                                item-text="name"
                                item-value="id"
                                v-model="search.groupID"
                                @change="changeGroup"
                                @click="searchgroup" 
                                dense
                                solo
                                clearable>
                                <template v-slot:prepend-item>
                                    <v-text-field
                                        dense
                                        placeholder="搜索"
                                        v-model="groupname"
                                        outlined
                                    >
                                    <v-btn
                                        class="ma-2"
                                        slot="append"
                                        text
                                        icon
                                        small
                                        @click="searchgroup"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    </v-text-field>
                                </template>
                            </v-select>
                        </div>
                        <div class="layout-select" v-if="role === 0">
                            <v-select
                                :items="tempshop"
                                :label="$vuetify.lang.t('$vuetify.userManage.user.table.shopName')"
                                item-text="name"
                                item-value="id"
                                v-model="search.shopID"
                                @click="searchshop" 
                                dense
                                solo
                                clearable
                            >
                            <template v-slot:prepend-item>
                                <v-text-field
                                    dense
                                    placeholder="搜索"
                                    v-model="shopname"
                                    outlined
                                >
                                <v-btn
                                    class="ma-2"
                                    slot="append"
                                    text
                                    icon
                                    small
                                    @click="searchshop"
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                                </v-text-field>
                            </template>
                            </v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="place"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.provinceName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.provincecode"
                                @change="changeCity" 
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="city"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.cityName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.citycode"
                                @change="changeArea" 
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="area"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.areaName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.areacode"
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="status"
                                :label="$vuetify.lang.t('$vuetify.userManage.user.table.status.text')"
                                item-text="label"
                                item-value="value"
                                v-model="search.status"
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                    </v-row>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import * as api from '../../../api'
import { returnSearch } from '../../../utils/common'

export default {

    data () {
        return {
            shop: [],
            status: [
                {
                    value: 2,
                    label: this.$vuetify.lang.t('$vuetify.userManage.user.table.status.off')
                },{
                    value: 1,
                    label: this.$vuetify.lang.t('$vuetify.userManage.user.table.status.on')
                }
            ],
            city: [],
            area: [],
            groupname:"",
            tempgroup: Array,
            shopname:"",
            tempshop: Array,
        }
    },

    props: {
        title : String,
        role  : Number,
        group : Array,
        place : Array,
        search: Object
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

        handleSearch () {
            this.$emit('handleSearch');
        },

        changeGroup (value) {
            this.$http.post(api.default.rbac.shopListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.shop = resp.data.list;
                this.search.shopID = null;
            })
        },

        changeCity (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.city = resp.data;
                this.search.citydode = null;
                this.area = [];
                this.search.areacode = null;
            })
        },

        changeArea (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.area = resp.data;
                this.search.areacode = null;
            })
        },

        searchgroup(){
            this.tempgroup = returnSearch(this.groupname,this.group)
        },

        searchshop(){
            this.tempshop = returnSearch(this.shopname,this.shop)
        },

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-input {
    width: 100px;
    margin-right: 10px;
}

.layout-select {
    width: 150px;
    margin-right: 10px;
}

</style>