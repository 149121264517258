<template>
	<div id="Auth">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table :titleTable="titleBaseTable" :headers="headersBase" :tableData='tableDataBase' :pages="baseParams.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loadingBase" :pageSize="baseParams.pageSize" :total="baseParams.total">
						<template slot="select">
							<v-btn @click="showAddBase" dark color="primary">
								{{$vuetify.lang.t('$vuetify.server.table.addBase')}}
							</v-btn>
						</template>
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showAddAttribute(propData.child.item)">
								<v-icon small>mdi-plus</v-icon>{{$vuetify.lang.t('$vuetify.server.titleAddAttribute')}}</a>
							<a class="span" @click="showEditBase(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}</a>
							<a class="span" @click="dialogDeleteBase(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
						</template>
					</v-table>
                    <!-- 新增认证库 -->
					<v-dialogs :theme="themeBaseAdd" :dialog="dialogBaseAdd" @dialogSure="handleEmitBaseAdd">
                        <v-form
                            ref="handleEmitBaseAdd_form"
                            lazy-validation>
                            <v-col cols="12" sm="7" md="7" v-if="dialogBaseAdd">
                                <v-text-field 
                                    @change="defaultBaseItem.name=formSpaces(defaultBaseItem.name)" 
                                    :rules="nameRule" 
                                    v-model="defaultBaseItem.name" 
                                    :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.name')" 
                                    :label="$vuetify.lang.t('$vuetify.server.label.name')"
                                ></v-text-field>
                                <v-select v-model="defaultBaseItem.authType" :items="authType" item-value="id" :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.type')" item-text="name"></v-select>
                                <v-select v-model="defaultBaseItem.ldapID" :items="ldapList" item-value="id" item-text="ldap_name" v-show="defaultBaseItem.authType == 1" :placeholder="$vuetify.lang.t('$vuetify.ldap.table.host')"></v-select>
                                <v-select v-if="sessionType ==0 " :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.group')" @change="handleGroupChange"  v-model="defaultBaseItem.groupID" class="mt-7 w200" :items="itemsGroup" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.group')" dense></v-select>
                                <v-select :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.case')"  v-model="defaultBaseItem.shopID" v-if="sessionType == 1||sessionType ==0" class="mt-7 w200" :items="itemsPlace" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.client.label.case')" dense></v-select>
                            </v-col>
                        </v-form>
					</v-dialogs>
                    <!-- 认证库编辑 -->
					<v-dialogs :width="700" :theme="themeBaseEdit" :dialog="dialogBaseEdit" @dialogSure="handleEmitBaseEdit">
						<div v-if="dialogBaseEdit">
                            <v-form
                            ref="handleEmitBaseEdit_form"
                            lazy-validation>
                                <v-col cols="12" sm="7" md="7">
                                    <v-text-field 
                                    :rules="nameRule" 
                                    @change="editBaseItem.name=formSpaces(editBaseItem.name)"
                                    v-model="editBaseItem.name" 
                                    :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.name')" 
                                    :label="$vuetify.lang.t('$vuetify.server.label.name')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="7" md="7">
                                    <v-text-field 
                                    v-model="editBaseItem.type_name" 
                                    :placeholder="$vuetify.lang.t('$vuetify.client.placeholder.type')" 
                                    :label="$vuetify.lang.t('$vuetify.client.placeholder.type')"
                                    disabled>
                                    </v-text-field>
                                </v-col>
								<!-- 认证库名称 -->
                                <v-col cols="12" sm="7" md="7">
                                    <v-select v-model="editBaseItem.ldap_id" :items="ldapList" item-value="id" item-text="ldap_name" :placeholder="'认证库类型'" v-show="editBaseItem.ldap_id" label="Ldap Server名" ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <span>{{$vuetify.lang.t('$vuetify.server.label.baselist')}}:</span>
                                    <v-data-table fixed-header height="400" :headers="headersAttribute" :items="dessertsBase" hide-default-footer class="elevation-1 mt-2">
                                        <template v-slot:item.attr="{ item }">
                                            <v-text-field class="mt-6" 
                                                :label="$vuetify.lang.t('$vuetify.server.label.port')" 
                                                solo dense 
                                                :rules="attrRule"
                                                v-model="item.attr"
                                                @change="item.attr=formSpaces(item.attr)"
                                            ></v-text-field>
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <a class="span" @click="showDeleteAttribute(item)">
                                                <v-icon color="primary" small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-form>
						</div>
					</v-dialogs>

					<v-dialogs :theme="themeDelete" :dialog="dialogBaseDelete" @dialogSure="handleEmitBaseDelete">
						<v-col cols="12" sm="12" md="12">
							<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}<span style="color:red;">将删除对应的认证组和认证信息</span></span>
						</v-col>
					</v-dialogs>
				</v-flex>   
			</v-layout>
		</v-container>
		
		<v-container fill-height fluid grid-list-xl>
			<v-row>
				<v-col cols="12" md="12">
					<v-table
						:titleTable="authGroup.title"
						:headers="authGroup.headers"
						:tableData="authGroup.tableData"
						@pageSize="handleUserPageSize"
						@page="handleUserPage"
						:loading="authGroup.table.loading"
						:pages="authGroup.table.pageNum"
						:pageSize="authGroup.table.pageSize"
						:total="authGroup.table.total">
						<template slot="select">
							<v-col cols="2" sm="2" md="2">
								<v-select @change="fetchListGroup" v-model="baseInfo" :items="newTableDataBase" item-text="name" item-value="id" :label="$vuetify.lang.t('$vuetify.server.label.base')" class="mt-7" solo dense></v-select>
							</v-col>
							<v-btn @click="showAddGroup" dark color="primary">
								{{$vuetify.lang.t('$vuetify.server.group.add')}}
							</v-btn>
						</template>
						<!-- title -->
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showGroupAttribute(propData.child.item)">
								<v-icon small>mdi-plus</v-icon>{{$vuetify.lang.t('$vuetify.server.titleAddAttribute')}}</a>
							<a class="span" @click="showGroupEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}</a>
							<a class="span" @click="showDelGroup(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
						</template>
						<!-- action -->
					</v-table>	
				</v-col>
			</v-row>

			<v-dialogs :theme="themeAddAttribute" :dialog="authGroup.dialog.groupAttribute.show" @dialogSure="handleAddGroupAttribute">
				<v-form
					ref="addGroupForm"
					lazy-validation>
					<v-row :key="index" v-for="(data,index) in authGroup.dialog.groupAttribute.attrs">
						<v-col cols="5" sm="5" md="5" class="pb-0">
							<v-select
								:items="AttributeItems"
								item-text="label"
								item-value="label"
								v-model="data.keys"
								:label="$vuetify.lang.t('$vuetify.server.label.attr')"
								dense
							></v-select>
						</v-col>
						<v-col cols="5" sm="5" md="5" class="pb-0">
							<v-text-field 
							@change="data.value=formSpaces(data.value)" 
							:placeholder="$vuetify.lang.t('$vuetify.server.placeholder.attr')" 
							:label="$vuetify.lang.t('$vuetify.server.label.attrData')" 
							:rules="attrRule" 
							class="pt-0" 
							v-model="data.value"
							></v-text-field>
						</v-col>
						<v-col cols="1" sm="1" md="1" class="mt-2" v-if="index>0">
							<a @click="handleDelGroupAttr(index)"><v-icon color="primary" small>mdi-trash-can-outline</v-icon></a>
						</v-col>
					</v-row>
				</v-form>
				<v-btn block color="rgb(204, 204, 204)" style="font-size: 20px;" @click="handleAddGroupAttr">+</v-btn>
			</v-dialogs>
			<!-- 认证组 添加属性 -->
			<v-dialogs
				:theme="authGroup.dialog.editGroupAttribute.theme"
				:dialog="authGroup.dialog.editGroupAttribute.show"
				@dialogSure="handleEditGroupAttribute">
				<v-form
					ref="editGroupForm"
					lazy-validation>
					<v-col cols="12" sm="7" md="7">
						<v-text-field 
							@change="authGroup.dialog.editGroupAttribute.groupName=formSpaces(authGroup.dialog.editGroupAttribute.groupName)" 
							:rules="groupnameRule" 
							v-model="authGroup.dialog.editGroupAttribute.groupName" 
							:label="$vuetify.lang.t('$vuetify.server.label.groupName')">
						</v-text-field>
                        <v-text-field
							v-model="authGroup.dialog.editGroupAttribute.savemonth" 
							:label="$vuetify.lang.t('$vuetify.server.group.savemonth')"
                            type="number"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="12">
						<span>{{$vuetify.lang.t('$vuetify.server.label.baselist')}}:</span>
						<v-data-table fixed-header height="300" :headers="headersAttribute" :items="authGroup.dialog.editGroupAttribute.attrs" hide-default-footer class="elevation-1 mt-2">
							<template v-slot:item.attr="{ item }">
								<v-text-field 
									class="mt-6" 
									:label="$vuetify.lang.t('$vuetify.server.label.port')" 
									solo dense 
									:rules="attrRule" 
									v-model="item.attr"
									@change="item.attr=formSpaces(item.attr)"
								></v-text-field>
							</template>
							<template v-slot:item.action="{ item }">
								<a 
								class="span" 
								@click="showDeleteGroupAttribute(item)">
								<v-icon color="primary" small>mdi-trash-can-outline</v-icon>
								{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
							</template>
						</v-data-table>
					</v-col>
				</v-form>
			</v-dialogs>		
			<!-- 认证组 编辑属性 -->
			<v-dialogs :theme="themeDeleteAttribute" :dialog="authGroup.dialog.delGroupAttribute.show" @dialogSure="handleDeleteGroupAttribute">
				<v-col cols="12" sm="7" md="7">
					<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.titleDeleteAttribute')}}</span>
				</v-col>
			</v-dialogs>
			<!-- 编辑删除确认按钮 -->
			<v-dialogs :theme="themeDeleteAttribute" :dialog="authGroup.dialog.delGroup.show" @dialogSure="handleDelGroup">
				<v-col cols="12" sm="12" md="12">
					<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.titleDeleteAttribute')}}<span style="color:red;">将删除对应的认证信息</span></span>
				</v-col>
			</v-dialogs>
			<!-- 删除确认按钮 -->

			<v-dialogs :theme="themeAddGroup" :dialog="authGroup.dialog.addGroup.show" @dialogSure="handleAddGroup">
				<v-form
					ref="addGroupForm"
					lazy-validation>
					<v-col cols="12" sm="7" md="7">
						<v-text-field 
							:rules="groupnameRule" 
							v-model="authGroup.dialog.addGroup.form.name" 
							@change="authGroup.dialog.addGroup.form.name=formSpaces(authGroup.dialog.addGroup.form.name)"
							:label="$vuetify.lang.t('$vuetify.server.group.name')" 
						></v-text-field>
                        <v-text-field
							v-model="authGroup.dialog.addGroup.form.savemonth" 
							:label="$vuetify.lang.t('$vuetify.server.group.savemonth')"
                            type="number"
						></v-text-field>
					</v-col>
				</v-form>
			</v-dialogs>
			<!-- 新增认证组 -->
			
		</v-container>
		<!-- 认证组列表 -->

		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table
						:titleTable="titleTable"
						:headers="headers"
						:tableData='authInfo.tableData'
						:pages="authInfo.table.pages"
						@pageSize="handleUserPageSize"
						@page="handleUserPage"
						:loading="authInfo.table.loading"
						:pageSize="authInfo.table.pageSize"
						:total="authInfo.table.total">
						<template slot="select">
							<v-col cols="2" sm="2" md="2">
								<v-select
									@change="radcheckListuser"
									v-model="authInfo.group_id"
									:items="authGroup.tableData"
									item-text="name"
									item-value="id"
									:label="$vuetify.lang.t('$vuetify.server.label.base')"
									class="mt-7" solo dense></v-select>
							</v-col>
							<v-btn @click="showAdd" color="primary">
								{{$vuetify.lang.t('$vuetify.server.table.add')}}
							</v-btn>
							<v-btn :loading="authInfo.table.button.download_template_loading" @click="downloadTemplate" class="ml-5" color="primary">
								{{$vuetify.lang.t('$vuetify.server.template.download')}}
							</v-btn>
							<v-btn @click="showImportTemplate" class="ml-5" color="primary">
								{{$vuetify.lang.t('$vuetify.server.template.import')}}
							</v-btn>
							<v-btn class="ml-3" @click="radcheckListuser" dark color="primary">
								{{$vuetify.lang.t('$vuetify.refresh')}}
							</v-btn>
						</template>
						<template slot="body">
							<br>
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="authuser.search.username"
                                            :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.username')"
                                            :label="$vuetify.lang.t('$vuetify.server.table.username')"
                                            append-outer-icon="search"
											@click:append-outer="radcheckListuser()"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入用户名 -->
                                    <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                        <v-text-field
                                            v-model="authuser.search.remarks"
                                            :placeholder="$vuetify.lang.t('$vuetify.server.placeholder.remarks')"
                                            :label="$vuetify.lang.t('$vuetify.server.table.remarks')"
                                            append-outer-icon="search"
											@click:append-outer="radcheckListuser()"
                                            ></v-text-field>
                                    </v-col>
                                    <!-- 输入备注 -->
                                </v-row>
                            </v-card-subtitle>
                            <!-- 选择框 -->
						</template>
						<template slot="tempclose" slot-scope="propData">
							<v-switch
								color="primary"
								v-model="propData.child.item.isEnableSwitch"
								@change="changeUserEnable(propData.child.item)"
								></v-switch>
						</template>
						<template slot="action" slot-scope="propData">
							<a class="span" @click="showAttribute(propData.child.item)">
								<v-icon small>mdi-plus</v-icon>{{$vuetify.lang.t('$vuetify.server.titleAddAttribute')}}</a>
							<a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}</a>
							<a class="span" @click="showDelete(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
						</template>
					</v-table>
				</v-flex>
				
				<!-- 新增认证信息 -->
				<v-dialogs :theme="themeAdd" :dialog="dialogAdd" @dialogSure="handleEmitAdd">
					<v-form
						ref="handleEmitAdd_form"
						lazy-validation>
						<v-col cols="12" sm="7" md="7" v-if="dialogAdd">
							<v-text-field 
								:rules="usernameRule" 
								v-model="defaultItem.username" 
								@change="defaultItem.username=formSpaces(defaultItem.username)"
								:label="$vuetify.lang.t('$vuetify.server.label.username')" 
							></v-text-field>
							<v-text-field 
								v-model="defaultItem.password" 
								@change="defaultItem.password=formSpaces(defaultItem.password)" 
								:label="$vuetify.lang.t('$vuetify.server.label.password')"
							></v-text-field>
							<v-text-field 
								v-model="defaultItem.remarks" 
								@change="defaultItem.remarks=formSpaces(defaultItem.remarks)" 
								:label="$vuetify.lang.t('$vuetify.server.table.remarks')"
							></v-text-field>
						</v-col>
					</v-form>
				</v-dialogs>

					<v-dialogs :theme="$vuetify.lang.t('$vuetify.server.template.import')" :dialog="listuser.dialog.importTemplate.show" @dialogSure="importTemplateSure">
                        <v-form lazy-validation>
                            <v-col cols="12" sm="7" md="7">
                                <v-file-input
									v-model="listuser.dialog.importTemplate.file"
									accept=".xlsx,.xls"
									:label="$vuetify.lang.t('$vuetify.server.template.filechoose')"></v-file-input>
                            </v-col>
                        </v-form>
					</v-dialogs>
					<!-- 导入模板 -->
				<!-- 认证信息 编辑 -->
				<v-dialogs  :theme="themeEdit" :dialog="dialogEdit" @dialogSure="handleEmitEdit">
					<v-form
					ref="handleEmitEdit_form"
					lazy-validation>
						<v-col cols="12" sm="7" md="7">
							<v-text-field 
								@change="editItem.username=formSpaces(editItem.username)" 
								:rules="usernameRule" 
								v-model="editItem.username" 
								:label="$vuetify.lang.t('$vuetify.server.label.username')">
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="7" md="7">
							<v-text-field 
							v-model="editItem.password"
							@change="editItem.password=formSpaces(editItem.password)"  
							:label="$vuetify.lang.t('$vuetify.server.label.password')"
							></v-text-field>
							<span style="color:red;">(修改密码后将删除账号对应mac)</span>
						</v-col>
						<v-col cols="12" sm="7" md="7">
							<v-text-field 
							v-model="editItem.remarks"
							@change="editItem.remarks=formSpaces(editItem.remarks)"  
							:label="$vuetify.lang.t('$vuetify.server.table.remarks')"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="7" md="7">
							<v-menu
								ref="menu"
								v-model="authInfo.menu.edit_expire_at"
								:close-on-content-click="false"
								:return-value.sync="editItem.expire_at"
								transition="scale-transition"
								offset-y
								min-width="290px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="editItem.expire_at"
										:label="$vuetify.lang.t('$vuetify.server.table.expire_at')"
										readonly
										clearable
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<el-date-picker
                                    v-model="editItem.expire_at"
                                    type="datetime"
                                    placeholder="Select date and time"
									value-format="yyyy-MM-dd HH:mm:ss"
                                ></el-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="12" md="12">
							<span>{{$vuetify.lang.t('$vuetify.server.label.baselist')}}:</span>
							<v-data-table fixed-header height="300" :headers="headersAttribute" :items="desserts" hide-default-footer class="elevation-1 mt-2">
								<template v-slot:item.attr="{ item }">
									<v-text-field 
										class="mt-6" 
										:label="$vuetify.lang.t('$vuetify.server.label.port')" 
										solo dense 
										:rules="attrRule" 
										v-model="item.attr"
										@change="item.attr=formSpaces(item.attr)"
									></v-text-field>
								</template>
								<template v-slot:item.action="{ item }">
									<a 
									class="span" 
									@click="showDeleteUserAttribute(item)">
									<v-icon color="primary" small>mdi-trash-can-outline</v-icon>
									{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
								</template>
							</v-data-table>
						</v-col>
					</v-form>
				</v-dialogs>
				<v-dialogs :theme="themeDelete" :dialog="dialogDelete" @dialogSure="handleEmitDelete">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeDeleteAttribute" :dialog="dialogDeleteUserAttribute" @dialogSure="handleEmitDeleteUserAttribute">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.titleDeleteAttribute')}}</span>
					</v-col>
				</v-dialogs>
				<v-dialogs :theme="themeDeleteAttribute" :dialog="dialogDeleteAttribute" @dialogSure="handleEmitDeleteAttribute">
					<v-col cols="12" sm="7" md="7">
						<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.titleDeleteAttribute')}}</span>
					</v-col>
				</v-dialogs>

				<!-- 认证库 添加属性 -->
				<v-dialogs :theme="themeAddAttribute" :dialog="dialogAddAttribute" @dialogSure="handleEmitAddAttribute">
					<v-form
					ref="handleEmitAddAttribute_form"
					lazy-validation>
						<v-row v-if="dialogAddAttribute" :key="index" v-for="(data,index) in attrBaseArr">
							<v-col cols="5" sm="5" md="5" class="pb-0">
								<v-select
									:items="AttributeItems"
									item-text="label"
									item-value="label"
									v-model="data.keys"
									:label="$vuetify.lang.t('$vuetify.server.label.attr')"
									dense
								></v-select>
							</v-col>
							<v-col cols="5" sm="5" md="5" class="pb-0">
								<v-text-field 
								@change="data.value=formSpaces(data.value)" 
								:placeholder="$vuetify.lang.t('$vuetify.server.placeholder.attr')" 
								:label="$vuetify.lang.t('$vuetify.server.label.attrData')" 
								:rules="attrRule" 
								class="pt-0" 
								v-model="data.value"
								></v-text-field>
							</v-col>
							<v-col cols="1" sm="1" md="1" class="mt-2" v-if="index>0">
								<a @click="handleDelAttr(index)"><v-icon color="primary" small>mdi-trash-can-outline</v-icon></a>
							</v-col>
						</v-row>
					</v-form>
					<v-btn block color="rgb(204, 204, 204)" style="font-size: 20px;" @click="handleAddAttr">+</v-btn>
				</v-dialogs>

				<!-- 认证信息  添加属性 -->
				<v-dialogs :theme="themeAddAttribute" :dialog="dialogAddUserAttribute" @dialogSure="handleEmitAddUserAttribute">
					<v-form
					ref="handleEmitAddUserAttribute_form"
					lazy-validation>
						<v-row v-if="dialogAddUserAttribute" :key="index"  v-for="(data,index) in attrUserArr">
							<v-col cols="5" sm="5" md="5">
								<v-select
								:items="AttributeItems"
								item-text="label"
								item-value="label"
								v-model="data.keys"
								:label="$vuetify.lang.t('$vuetify.server.label.attr')"
								dense 
								></v-select>
							</v-col>
							<v-col cols="5" sm="5" md="5">
								<v-text-field 
									@change="data.value=formSpaces(data.value)"  
									:placeholder="$vuetify.lang.t('$vuetify.server.placeholder.attr')" 
									:label="$vuetify.lang.t('$vuetify.server.label.attrData')" 
									:rules="attrRule" 
									class="pt-0" 
									v-model="data.value"
								></v-text-field>
							</v-col>
							<v-col cols="1" sm="1" md="1" class="mt-2" v-if="index>0">
									<a @click="handleDelUserAttr(index)"><v-icon color="primary" small>mdi-trash-can-outline</v-icon></a>
								</v-col>
						</v-row>
					</v-form>
					<v-btn block color="rgb(204, 204, 204)" style="font-size: 20px;" @click="handleAddUserAttr">+</v-btn>
				</v-dialogs>
			</v-layout>
		</v-container>
		<!-- 认证库信息列表 -->

		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table
						:titleTable="wechatAdmin.title"
						:headers="wechatAdmin.headers"
						:tableData='wechatAdmin.data'
						:pages="wechatAdmin.table.pageNum"
						@pageSize="handleUserPageSize"
						@page="handleUserPage"
						:loading="wechatAdmin.table.loading"
						:pageSize="wechatAdmin.table.pageSize"
						:total="wechatAdmin.table.total">
						<template slot="select">
							<v-col cols="2" sm="2" md="2">
								<v-select
									@change="handleBaseChange"
									v-model="wechatAdmin.authset"
									:items="wechatAdmin.authsets"
									item-text="name"
									item-value="id"
									:label="$vuetify.lang.t('$vuetify.server.label.base')"
									class="mt-7"
									solo
									dense></v-select>
								<!-- 认证库选择 -->
							</v-col>
							<v-btn @click="showWechatAdminAdd" dark color="primary">
								{{$vuetify.lang.t('$vuetify.server.wechatAdmin.add')}}
							</v-btn>
						</template>
						<template slot="action" slot-scope="propData">
							<!-- <a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}</a> -->
							<a class="span" @click="showDialogWechatAdminDelete(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}</a>
						</template>
					</v-table>

					<v-dialogs :theme="wechatAdmin.addname" :dialog="wechatAdmin.dialog.add.show" @dialogSure="showWechatAdminAdd">
                        <v-col cols="12" sm="12" md="12">
							<v-text-field
								v-model="wechatAdmin.remark"
								:label="$vuetify.lang.t('$vuetify.server.wechatAdmin.remark')"
								:rules="remarkRule"
								hide-details="auto"
								:placeholder="$vuetify.lang.t('$vuetify.server.placeholder.remarks')" 
							></v-text-field>
							<div class="qrcode" ref="qrCodeUrl"></div>
						</v-col>
					</v-dialogs>
					<!-- 添加授权员 -->

					<v-dialogs :theme="themeDelete" :dialog="dialogWechatAdminDelete" @dialogSure="wechatAdminDelete">
						<v-col cols="12" sm="7" md="7">
							<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
						</v-col>
					</v-dialogs>
					<!-- 删除 -->
				</v-flex>
			</v-layout>
		</v-container>

	</div>
</template>

<script>
	import axios from 'axios'
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import { attributes } from './../../plugins/attributes.js'
	import * as api from './../../api'
	import { Notification,MessageBox } from 'element-ui';
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
                ldapList:[],
                authType:[
                    {
                        id:0,
                        name:this.$vuetify.lang.t('$vuetify.client.type.local')
                    },
                    {
                        id:1,
                        name:this.$vuetify.lang.t('$vuetify.client.type.ldap')
                    }
                ],
				wechatAdmin:{
					title:this.$vuetify.lang.t('$vuetify.server.wechatAdmin.title'),
					addname:this.$vuetify.lang.t('$vuetify.server.wechatAdmin.add'),
					headers:[
						{
							text: this.$vuetify.lang.t('$vuetify.server.wechatAdmin.openid'),
							value: 'openid',
						},
						{
							text: this.$vuetify.lang.t('$vuetify.server.wechatAdmin.remark'),
							value: 'remark'
						},
						{
							text: this.$vuetify.lang.t('$vuetify.server.wechatAdmin.create_at'),
							value: 'create_at'
						},
						{
							text: this.$vuetify.lang.t('$vuetify.common.action'),
							value: 'action',
							sortable: false
						},
					],
					remark:"",
					data:[],
					authsets:[],
					authset:null,
					table:{
						pageNum:1,
						loading:false,
						pageSize:10,
						total:0
					},
					dialog:{
						add:{
							show:false,
							qrcode:null,
							listen_time:0,
							interval:null
						}
					},
					showQrcodeId:null
				},
				visitor:{
					title:this.$vuetify.lang.t('$vuetify.server.bevisitor.title'),
					addname:this.$vuetify.lang.t('$vuetify.server.bevisitor.add'),
					headers:[
						{
							text: this.$vuetify.lang.t('$vuetify.server.bevisitor.interviewee_name'),
							value: 'interviewee_name',
						},
						{
							text: this.$vuetify.lang.t('$vuetify.server.bevisitor.telnum'),
							value: 'telnum',
						},
						{
							text: this.$vuetify.lang.t('$vuetify.server.bevisitor.mail'),
							value: 'mail',
						},
						{
							text: this.$vuetify.lang.t('$vuetify.server.bevisitor.company'),
							value: 'company',
						},
						{
							text: this.$vuetify.lang.t('$vuetify.common.action'),
							value: 'action',
							sortable: false
						},
					],
					data:[],
					authsets:[],
					authset:null,
					table:{
						pageNum:1,
						loading:false,
						pageSize:10,
						total:0
					},
					dialog:{
						add:{
							show:false,
							name:"",
							telnum:"",
							mail:"",
							company:"",
							interviewee_name:"",
						},
						importVisitorTemplate:{
							show:false,
							file:null
						}
					}
				},
				listuser:{
					dialog:{
						importTemplate:{
							show:false,
							file:null
						}
					}
				},
				switch:[],
				/* 认证组数据 */
				authGroup: {
					title: this.$vuetify.lang.t('$vuetify.server.group.title'),
					headers: [
						{
							text: this.$vuetify.lang.t('$vuetify.server.group.name'),
							value:"name",
						},
						{
							text: this.$vuetify.lang.t('$vuetify.common.action'),
							value:"action",
						}
					],
					table:{
						loading: false,
						pages: 1,
						pageSize: 10,
						total: 0
					},
					tableData:[],
					dialog:{
						addGroup:{
							show:false,
							form:{
								name:"",
                                savemonth:"0",
							}
						},
						groupAttribute:{
							show:false,
							attrs:[
								{
									keys:null,
									value:null,
								},
							],
							active_id:null,
						},
						editGroupAttribute:{
							theme:this.$vuetify.lang.t('$vuetify.server.group.editTitle'),
							show:false,
							groupName:"",
							attrs:[],
							active_id:null,
                            savemonth:"0",
						},
						delGroupAttribute:{
							show:false,
							data:null
						},
						delGroup:{
							show:false,
							active_id:null
						}
					}
				},
				authInfo: {
					group_id:null,
					table:{
						loading: false,
						pages: 1,
						pageSize: 10,
						total: 0,
						button:{
							download_template_loading:false
						}
					},
					tableData:[],
					menu:{
						edit_expire_at:false
					}
				},
				authuser: {
					search:{
						username:"",
						remarks:""
					}
				},
				themeAdd: this.$vuetify.lang.t('$vuetify.server.btn.add'),
				themeEdit: this.$vuetify.lang.t('$vuetify.server.btn.edit'),
				themeDelete: this.$vuetify.lang.t('$vuetify.server.btn.delete'),
				themeBaseAdd: this.$vuetify.lang.t('$vuetify.server.btn.addBase'),
				themeBaseEdit: this.$vuetify.lang.t('$vuetify.server.btn.editBase'),
				themeBaseDelete: this.$vuetify.lang.t('$vuetify.server.btn.delete'),
				themeDeleteAttribute:this.$vuetify.lang.t('$vuetify.server.titleDeleteAttribute'),
				themeAddAttribute:this.$vuetify.lang.t('$vuetify.server.titleAddAttribute'),
				themeAddGroup:this.$vuetify.lang.t('$vuetify.server.group.add'),
				dialogAdd: false,
				dialogEdit: false,
				dialogDelete: false,
				dialogBaseAdd: false,
				dialogBaseEdit: false,
				dialogBaseDelete: false,
				dialogDeleteAttribute:false,
				dialogDeleteUserAttribute:false,
				dialogAddAttribute:false,
				dialogAddUserAttribute:false,
				dialogWechatAdminDelete:false,
				titleTable: this.$vuetify.lang.t('$vuetify.server.title'),
				titleBaseTable: this.$vuetify.lang.t('$vuetify.server.titleBase'),
				headers: [{
						text: this.$vuetify.lang.t('$vuetify.server.table.username'),
						align: 'left',
						value: 'username',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.password'),
						align: 'left',
						value: 'password'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.remarks'),
						value: 'remarks'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.tempclose'),
						value: 'tempclose',
						sortable: false
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.disable_at'),
						value: 'disable_at'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.table.created_at'),
						value: 'created_at'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				headersBase: [{
						text: this.$vuetify.lang.t('$vuetify.server.table.name'),
						align: 'left',
						value: 'name',
					},
					{
						text: "集团",
						align: 'left',
						value: 'group_name',
					},
					{
						text: "场所",
						align: 'left',
						value: 'shop_name',
					},
                    {
                        text:"类型",
                        align:'left',
                        value:'type_name'
                    },
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableDataBase: [],
                newTableDataBase:[],
				tableData: [],
				AttributeItems:attributes.arr,
				headersAttribute: [{
						text: this.$vuetify.lang.t('$vuetify.server.label.attr'),
						align: 'left',
						value: 'name',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.server.label.attrData'),
						align: 'left',
						value: 'attr'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				
				defaultItem: {},
				editItem:{},
				deleteItem:{},
				desserts:[],
				dessertsBase: [],
				defaultBaseItem: {},
				editBaseItem:{},
				deleteBaseItem:{},
				deleteBaseAttribute:{},
				addBaseAttribute:{},
				addUserAttribute:{},
				deleteWechatAdminItem:{},
				nameRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.name'),
                    v => (v !== null) || this.$vuetify.lang.t('$vuetify.server.rule.name')
                ],
                usernameRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.username'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.username')
                ],
				passwordRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.password'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.password')
                ],
				attrRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.attr'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.attr')
				],
				groupnameRule: [
					v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.server.rule.groupname'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.groupname')
				],
				remarkRule: [
					v => !!v || this.$vuetify.lang.t('$vuetify.server.rule.remark'),
					v => v.length <= 8 || this.$vuetify.lang.t('$vuetify.server.rule.remarkLimit')
				],
				baseParams:{
					pagesize:10,
					pagenum:1,
					total:0
				},
				loadingBase:true,
				loadingUser:true,
				sessionType:sessionStorage.getItem('type'),
				itemsPlace:[],
				itemsGroup:[],
				baseAttributeKey:null,
				baseAttributeValue:null,
				baseInfo:null,
				userParams:{
					pagesize:10,
					pagenum:1,
					total:0,
					setID:null,
				},
				attrBaseArr:[
					{
						keys:null,
						value:null,
					},
				],
				attrUserArr:[
					{
						keys:null,
						value:null,
					},
				]
			}
		},
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.radcheckListset();
            this.getLdapList();
		},
		watch:{
			'wechatAdmin.authset' (newV, oldV){
				this.wechatAdmin.table.pageNum = 1
				this.fetchWechatAdmins()
			},
			'visitor.authset' (newV, oldV){
				this.visitor.table.pageNum = 1
				this.fetchVisitors()
			},
			'wechatAdmin.remark' (newV,oldV){
				if(!newV || newV.length >8){
					this.$refs.qrCodeUrl.innerHTML = ""
					this.clearCheckWechatAdmin()
				}else{
					var time = 500
					clearTimeout(this.wechatAdmin.showQrcodeId)
					this.wechatAdmin.showQrcodeId = setTimeout(()=>{
						this.showQrcode()
					},time)
				}
				
			},
			'wechatAdmin.dialog.add.show' (newV, oldV){
				if(newV === true)
				{
					// this.wechatAdmin.dialog.add.listen_time = Math.round(new Date() / 1000)
					// this.wechatAdmin.dialog.add.interval = setInterval(()=>{
					// 	this.checkNewWechatAdmin()
					// },1000)
				}
				else
				{
					// if(this.wechatAdmin.dialog.add.interval)
					// {
					// 	clearInterval(this.wechatAdmin.dialog.add.interval)
					// }
				}
			}
		},
		methods: {
			showDialogWechatAdminDelete(data){
				this.deleteWechatAdminItem = data;
				this.dialogWechatAdminDelete = !this.dialogBaseDelete;
			},
            getLdapList() {
                let params = {
                    pageNum:1,
                    pageSize:10000
                }
                this.$http
                    .post(api.default.aaa.ldapList, params)
                    .then((res) => {
                        if (res.errno == 0) {
                            this.ldapList = res.data.list;
                        }
                    })
                    .finally(() => {
                    });
            },
			// 下载被访客导入模板
			downloadVisitorTemplate(){
				this.$http.get(api.default.aaa.getDownloadVisitorTemplateUrl).then(resp => {
					if(resp.errno != '0')
						return false;
					window.location.href = resp.data.url
					Notification({
						type: 'success',
						message: this.$vuetify.lang.t('$vuetify.common.success')
					});
					this.fetchVisitors()
				}).finally(resp => {})
			},
			showImportVisitorTemplate(){
				this.visitor.dialog.importVisitorTemplate.show = true
			},
			closeImportVisitorTemplate(){
				this.visitor.dialog.importVisitorTemplate.show = false
			},
			importVisitorTemplateSure(status){
				if(status && this.visitor.dialog.importVisitorTemplate.file != null)
				{
					var formData = new FormData();
					formData.append('auth_visitor_template', this.visitor.dialog.importVisitorTemplate.file);
					formData.append('set_id', this.baseInfo);
					this.$http.post(api.default.aaa.importVisitorTemplate,formData,{
						headers: {'Content-Type': 'multipart/form-data'},
					}).then(resp => {
						if(resp.errno != '0')
							return false;
						this.fetchVisitors()
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
						// 上传成功后置空
						this.visitor.dialog.importVisitorTemplate.file = null
					}).finally(resp => {
					})
				}
				this.closeImportVisitorTemplate()
			},
			/* 监听是否有新建二维码授权员 */
			checkNewWechatAdmin(){
				const postData = {
					set_id : this.wechatAdmin.authset,
					remark: this.wechatAdmin.remark,
					listen_time:this.wechatAdmin.dialog.add.listen_time
				}
				this.$http.post(api.default.aaa.radcheckcheckWechatAdmin,postData).then(resp => {
					if(resp.errno != 0)
						return;
					this.wechatAdmin.dialog.add.show = false
					this.wechatAdmin.remark = ""
					Notification({
						type: 'success',
						message: this.$vuetify.lang.t('$vuetify.common.success')
					});
					this.fetchWechatAdmins()
				}).finally(resp => {})
			},
			/* 删除 */
			wechatAdminDelete(params){
				if(!params) return this.dialogWechatAdminDelete = !this.dialogWechatAdminDelete;
				var item = this.deleteWechatAdminItem
				const postData = {
					id : item.id
				}
				this.$http.post(api.default.aaa.radcheckWechatAdminDel,postData).then(resp => {
					if(resp.errno != 0)
						return;
					Notification({
						type:'success',
						message:this.$vuetify.lang.t('$vuetify.common.success')
					});
					this.fetchWechatAdmins();
				}).finally(resp => {
					this.dialogWechatAdminDelete = !this.dialogWechatAdminDelete;
				})
			},
			// 下载导入模板
			downloadTemplate(){
				this.authInfo.table.button.download_template_loading = true
				axios({
					method: 'POST',
					url: api.default.aaa.getDownloadTemplateUrl,
					responseType: 'blob'
				}).then(response => {
					if(response.status != 200 || !response.data)
					{
						Notification({
							type: 'warning',
							message: "导出失败"
						});
						return
					}
					let url = window.URL.createObjectURL(new Blob([response.data]))
					let link = document.createElement('a')
					link.style.display = 'none'
					link.href = url
					link.setAttribute('download', '认证信息导入模板.xlsx')
					
					document.body.appendChild(link)
					link.click()
				}).catch((error) => {
					Notification({
						type: 'warning',
						message: "导出失败"
					});
					return
				}).finally(resp => {
					this.authInfo.table.button.download_template_loading = false
				})
			},
			clearCheckWechatAdmin(){
				if(this.wechatAdmin.dialog.add.interval)
				{
					clearInterval(this.wechatAdmin.dialog.add.interval)
				}
			},
			/* 显示二维码 */
			showQrcode(){
				const postData = {
					set_id : this.wechatAdmin.authset,
					remark : this.wechatAdmin.remark
				}
				this.$http.post(api.default.aaa.radcheckGetQrcodeUrl,postData).then(resp => {
					if(resp.errno != 0){
						if(this.wechatAdmin.dialog.add.qrcode){
							this.clearCheckWechatAdmin()
							this.$refs.qrCodeUrl.innerHTML = ""
						}
						return;
					}
					if(this.wechatAdmin.dialog.add.qrcode){
						this.clearCheckWechatAdmin()
						this.$refs.qrCodeUrl.innerHTML = ""
					}
						
					this.wechatAdmin.dialog.add.qrcode = new QRCode(this.$refs.qrCodeUrl, {
						text: resp.data,
						colorDark: '#000000',
						colorLight: '#ffffff',
						//纠错等级为H时会导致text过长时无法显示二维码
						correctLevel: QRCode.CorrectLevel.Q
					})
					this.wechatAdmin.dialog.add.listen_time = Math.round(new Date() / 1000)
					this.wechatAdmin.dialog.add.interval = setInterval(()=>{
						this.checkNewWechatAdmin()
					},1000)
				}).finally(resp => {})
			},
			/* 获取二维码授权员列表 */
			showWechatAdminAdd(){
				this.wechatAdmin.dialog.add.show = !this.wechatAdmin.dialog.add.show
			},
			fetchWechatAdmins(){
				const postData = {
					set_id : this.wechatAdmin.authset,
					pagesize : this.wechatAdmin.table.pageSize,
					pagenum : this.wechatAdmin.table.pageNum
				}
				this.$http.post(api.default.aaa.radcheckWechatAdmins,postData).then(resp => {
					if(resp.errno != 0)
						return;
					this.wechatAdmin.data = resp.data.list
					//console.log('wechatAdmin.data',this.wechatAdmin.data)
					this.wechatAdmin.table.total = resp.data.total
				}).finally(resp => {})
			},
			visitorDelete(item){
				const postData = {
					id : item.id
				}
				this.$http.post(api.default.aaa.radcheckDelVisitor,postData).then(resp => {
					if(resp.errno != 0)
						return;
					Notification({
						type: 'success',
						message: this.$vuetify.lang.t('$vuetify.common.success')
					});
					this.fetchVisitors()
				}).finally(resp => {})
			},
			showVisitorAdd(){
				this.visitor.dialog.add.show = true
			},
			closeVisitorAdd(){
				this.visitor.dialog.add.show = false
			},
			handleVisitorAdd(status){
				if(status)
				{
					const postData = {
						set_id : this.wechatAdmin.authset,
						name:this.visitor.dialog.add.name,
						telnum:this.visitor.dialog.add.telnum,
						mail:this.visitor.dialog.add.mail,
						company:this.visitor.dialog.add.company,
						interviewee_name:this.visitor.dialog.add.interviewee_name
					}
					this.$http.post(api.default.aaa.radcheckAddVisitor,postData).then(resp => {
						if(resp.errno == '0'){
							this.fetchVisitors();
							Notification({
								type: 'success',
								message: this.$vuetify.lang.t('$vuetify.common.success')
							});
						}
					}).finally(resp => {})
				}
				this.closeVisitorAdd()
			},
			fetchVisitors(){
				// const postData = {
				// 	set_id : this.visitor.authset,
				// 	pagesize : this.visitor.table.pageSize,
				// 	pagenum : this.visitor.table.pageNum
				// }
				// this.$http.post(api.default.aaa.radcheckVisitors,postData).then(resp => {
				// 	if(resp.errno != 0)
				// 		return;
				// 	this.visitor.data = resp.data.data
				// 	this.visitor.table.total = resp.data.total
				// }).finally(resp => {})
			},
			showImportTemplate(){
				this.listuser.dialog.importTemplate.show = true
			},
			closeImportTemplate(){
				this.listuser.dialog.importTemplate.show = false
			},
			importTemplateSure(status){
				if(status && this.listuser.dialog.importTemplate.file != null)
				{
					var formData = new FormData();
					formData.append('auth_template', this.listuser.dialog.importTemplate.file);
					formData.append('set_id', this.baseInfo);
					formData.append('class_id', this.authInfo.group_id)
					this.$http.post(api.default.aaa.importTemplate,formData,{
						headers: {'Content-Type': 'multipart/form-data'},
					}).then(resp => {
						if(resp.errno != '0')
							return false;
						this.radcheckListuser()
						return Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});

					}).finally(resp => {
					})
					// 上传成功后置空
					this.listuser.dialog.importTemplate.file = null
				}
				this.closeImportTemplate()
			},
			changeUserEnable(item){
				var enable = 0
				switch (item.isEnable) {
					case 1:
						enable = 0
						break;
					case 0:
						enable = 1
						break;
					default:
						break;
				}
				const postData = {
					id: item.id,
					isEnable:enable
				}
				this.$http.post(api.default.aaa.radcheckSetUserEnable,postData).then(resp => {
					if(resp.errno !== 0)
						return;
					this.radcheckListuser()
				}).finally(resp => {})
			},
			/* add group */
			showAddGroup(){
				this.authGroup.dialog.addGroup.form.name = ""
                this.authGroup.dialog.addGroup.form.savemonth = "0"
				this.authGroup.dialog.addGroup.show = true
			},
			closeAddGroup(){
				this.authGroup.dialog.addGroup.show = false
			},
			handleAddGroup(status){
				if(!status) return this.closeAddGroup()
				if (this.$refs.addGroupForm.validate()) {
					const postData = {
						setID:this.baseInfo,
						name:this.authGroup.dialog.addGroup.form.name,
                        save_month:this.authGroup.dialog.addGroup.form.savemonth
					}
					this.$http.post(api.default.aaa.radcheckAddGroup,postData).then(resp => {
						if(resp.errno !== 0)
							return;
						this.fetchListGroup()
						this.closeAddGroup()
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}).finally(resp => {})
				}
			},
			/* 修改认证组属性 */
			handleEditGroupAttribute(status){
				if(status)
				{
					if (this.$refs.editGroupForm.validate()) {
						/* 生成attrs obj */
						var obj = {}
						for (const element of this.authGroup.dialog.editGroupAttribute.attrs) {
							if(element.name == null)
							{
								return Notification({
									type: 'warning',
									message: this.$vuetify.lang.t('$vuetify.server.rule.attr')
								});
							}
							if(element.attr == null){
								return Notification({
									type: 'warning',
									message: this.$vuetify.lang.t('$vuetify.server.rule.attrValue')
								});
							}
							obj[element.name] = element.attr;
						}
						const postData = {
							id:this.authGroup.dialog.editGroupAttribute.active_id,
							name:this.authGroup.dialog.editGroupAttribute.groupName,
                            save_month:this.authGroup.dialog.editGroupAttribute.savemonth,
							attr:JSON.stringify(obj)
						}
						// 请求删除属性接口
						this.$http.post(api.default.aaa.radcheckEditGroup,postData).then(resp => {
							if(resp.errno !== 0)
								return;
							this.fetchListGroup()
							Notification({
								type: 'success',
								message: this.$vuetify.lang.t('$vuetify.common.success')
							});
						}).finally(resp => {})
					}
				}
				this.closeGroupEdit()
			},
			showGroupEdit(item){
				var attrs = [];
				if(item.attr!=""){
					var dessertsBase = JSON.parse(item.attr);
					for(var i in dessertsBase){
						attrs.push({name:i,attr:dessertsBase[i]})
					}
				}
				this.authGroup.dialog.editGroupAttribute.active_id = item.id
				this.authGroup.dialog.editGroupAttribute.groupName = item.name
				this.authGroup.dialog.editGroupAttribute.attrs = attrs
                this.authGroup.dialog.editGroupAttribute.savemonth =item.save_month
				this.authGroup.dialog.editGroupAttribute.show = true
			},
			closeGroupEdit(){
				this.authGroup.dialog.editGroupAttribute.show = false
			},
			handleDelGroupAttr(index){
				return;
				if(index > 0)
					this.authGroup.dialog.groupAttribute.attrs.splice(index,1);
			},
			showDeleteGroupAttribute(data){
				this.authGroup.dialog.delGroupAttribute.data = data
				this.authGroup.dialog.delGroupAttribute.show = true;
			},
			closeDeleteGroupAttribute(){
				this.authGroup.dialog.delGroupAttribute.show = false;
			},
			handleDeleteGroupAttribute(status){
				if(status)
				{
					if(this.authGroup.dialog.delGroupAttribute.data.name == null)
					{
						return Notification({
							type: 'warning',
							message: this.$vuetify.lang.t('$vuetify.server.rule.attr')
						});
					}
					if(this.authGroup.dialog.delGroupAttribute.data.attr == null)
					{
						return Notification({
							type: 'warning',
							message: this.$vuetify.lang.t('$vuetify.server.rule.attrValue')
						});
					}
					const postData = {
						id:this.authGroup.dialog.editGroupAttribute.active_id,
						key:this.authGroup.dialog.delGroupAttribute.data.name,
						value:this.authGroup.dialog.delGroupAttribute.data.attr,
					}
					// 请求删除属性接口
					this.$http.post(api.default.aaa.radcheckDelGroupAttr,postData).then(resp => {
						if(resp.errno !== 0)
							return;
						this.fetchListGroup()
						/* 刷新属性列表 */
						for(var i=0;i<this.authGroup.dialog.editGroupAttribute.attrs.length;i++){
							if(this.authGroup.dialog.editGroupAttribute.attrs[i].name == this.authGroup.dialog.delGroupAttribute.data.name){
								this.authGroup.dialog.editGroupAttribute.attrs.splice(i,1);
							}
						}
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}).finally(resp => {})
				}
				this.closeDeleteGroupAttribute()
			},
			/* 添加认证组属性 */
			showGroupAttribute(group){
				this.authGroup.dialog.addGroup.active_id = group.id
				this.authGroup.dialog.groupAttribute.attrs = [{
					keys:null,
					value:null,
				}]
				this.authGroup.dialog.groupAttribute.show = true
			},
			closeGroupAttribute(){
				this.authGroup.dialog.groupAttribute.attrs = [{
					keys:null,
					value:null,
				}]
				this.authGroup.dialog.groupAttribute.show = false
			},
			handleAddGroupAttr(){
				this.authGroup.dialog.groupAttribute.attrs.push({keys:null,value:null});
			},
			handleAddGroupAttribute(status){
				if(status)
				{
					/* 生成attrs obj */
					var obj = {}
					for (const element of this.authGroup.dialog.groupAttribute.attrs) {
						if(element.keys == null)
						{
							return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attr')
                            });
						}
						if(element.value == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attrValue')
                            });
						}
						obj[element.keys] = element.value;
					}
					const postData = {
						id: this.authGroup.dialog.addGroup.active_id,
						attrs: JSON.stringify(obj)
					}
					// 请求新增属性接口
					this.$http.post(api.default.aaa.radcheckAddGroupAttrs,postData).then(resp => {
						if(resp.errno !== 0)
							return;
						this.fetchListGroup()
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}).finally(resp => {})
					// console.log(this.authGroup.dialog.groupAttribute.attrs)
				}
				this.closeGroupAttribute()
			},
			/* 删除认证组 */
			showDelGroup(item){
				this.authGroup.dialog.delGroup.active_id = item.id
				this.authGroup.dialog.delGroup.show = true
			},
			closeDelGroup(){
				this.authGroup.dialog.delGroup.show = false
			},
			handleDelGroup(status){
				if(status)
				{
					var url = api.default.aaa.radcheckDelGroup+"?id="+this.authGroup.dialog.delGroup.active_id
					this.$http.get(url).then(resp => {
						if(resp.errno !== 0)
							return;
						this.fetchListGroup()
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}).finally(resp => {})
					// console.log(this.authGroup.dialog.delGroup.active_id)
					// const postData = {
					// 	setID:this.baseInfo,
					// 	name:this.authGroup.dialog.addGroup.form.name
					// }
					// this.$http.post(api.default.aaa.radcheckAddGroup,postData).then(resp => {
					// 	if(resp.errno !== 0)
					// 		return;
					// 	this.fetchListGroup()
					// }).finally(resp => {})
				}
				this.closeDelGroup()
			},
			/* 刷新认证组列表 */
			fetchListGroup(){
				const postData = {
					setID:this.baseInfo,
					pagesize:this.authGroup.table.pageSize,
					pagenum:this.authGroup.table.pages,
				}
				this.$http.post(api.default.aaa.radcheckListGroup,postData).then(resp => {
					if(resp.errno !== 0)
						return;
					this.authGroup.table.total = parseInt(resp.data.total)
					this.authGroup.tableData = resp.data.list
					this.authInfo.group_id = resp.data.list[0].id;
					this.radcheckListuser()
				}).finally(resp => {})
			},
			/* 获取认证库列表 */
			// radcheckListset(){
			// 	this.loadingBase = true;
			// 	this.$http.post(api.default.aaa.radcheckListset,this.baseParams).then(resp => {
			// 		if(resp.errno == '0'){
			// 			this.baseParams.total = parseInt(resp.data.total);
			// 			this.tableDataBase = resp.data.list;
			// 			this.loadingBase = false;
			// 			this.baseInfo = resp.data.list[0].id;
			// 			// this.radcheckListuser();
			// 		}
			// 	}).finally(resp => {})
			// },
            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },
			handleDelUserAttr(data){
				if(data > 0){
					this.attrUserArr.splice(data,1);
				}
			},
			handleDelAttr(data){
				if(data > 0){
					this.attrBaseArr.splice(data,1);
				}
			},
			handleAddUserAttr(){
				this.attrUserArr.push({keys:null,value:null});
			},
			handleAddAttr(){
				this.attrBaseArr.push({keys:null,value:null});
			},
			handleGroupChange(){
				this.shopListUrl(0);
			},
			handleEmitEdit(params) {
                if(!params) {
                    this.radcheckListuser();
                    return this.dialogEdit = !this.dialogEdit;
                }
                if (this.$refs.handleEmitEdit_form.validate()) {
                    if(this.editItem.username == ''||!this.editItem.username) return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.server.rule.username')
					});
                    var attrJson = {};
                    for(var i=0;i<this.desserts.length;i++){
                        attrJson[this.desserts[i].name] = this.desserts[i].attr;
                    }
                    attrJson = JSON.stringify(attrJson);
                    var paramsdata = {
                        id:this.editItem.id,
                        username:this.editItem.username,
						pasd:this.editItem.password,
						remarks:this.editItem.remarks,
						expire_at:this.editItem.expire_at,
                        attr:attrJson
                    }
                    this.$http.post(api.default.aaa.radcheckEdituser,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogEdit = !this.dialogEdit;
                            this.radcheckListuser();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			
			handleEmitDeleteUserAttribute(params) {
				if(!params) return this.dialogDeleteUserAttribute = !this.dialogDeleteUserAttribute;
				var paramsdata = {
					id:this.editItem.id,
					key:this.deleteUserAttribute.name,
					value:this.deleteUserAttribute.attr,
				};
				this.$http.post(api.default.aaa.radcheckDeluserattr,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogDeleteUserAttribute = !this.dialogDeleteUserAttribute;
						this.radcheckListuser();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
						for(var i=0;i<this.desserts.length;i++){
							if(this.desserts[i].name == this.deleteUserAttribute.name){
								this.desserts.splice(i,1);
							}
						}
					}
				}).finally(resp => {})
			},
			showDeleteUserAttribute(data){
				this.deleteUserAttribute = data;
				this.dialogDeleteUserAttribute = !this.dialogDeleteUserAttribute;
			},
			showEdit(data) {
				this.editItem = data;
				this.desserts = [];
				if(data.attr!=""){
					var dessertsBase = JSON.parse(data.attr);
					for(var i in dessertsBase){
						this.desserts.push({name:i,attr:dessertsBase[i]})
					}
				}
				this.dialogEdit = !this.dialogEdit;
			},
			handleEmitAddUserAttribute(params){
                if(!params) return this.dialogAddUserAttribute = !this.dialogAddUserAttribute;
                if (this.$refs.handleEmitAddUserAttribute_form.validate()) {
                    var obj = {};
                    for(var i=0;i<this.attrUserArr.length;i++){
                        if(this.attrUserArr[i].keys == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attr')
                            });
                        }
                        if(this.attrUserArr[i].value == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attrValue')
                            });
                        }
                        obj[this.attrUserArr[i].keys] = this.attrUserArr[i].value;
                    }
                    
                    var paramsdata = {
                        id:this.addUserAttribute.id,
                        attrs:JSON.stringify(obj)
                    }
                    this.$http.post(api.default.aaa.radcheckAdduserattrs,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogAddUserAttribute = !this.dialogAddUserAttribute;
                            this.attrUserArr = [{
                                keys:null,
                                value:null,
                            }]
                            this.radcheckListuser();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			
			showAttribute(data) {
				this.addUserAttribute = data;
				this.dialogAddUserAttribute = !this.dialogAddUserAttribute;
			},
			
			handleEmitDelete(params) {
				if(!params) return this.dialogDelete = !this.dialogDelete;
				this.$http.get(api.default.aaa.radcheckDeleteuser+"?id="+this.deleteItem.id).then(resp => {
					if(resp.errno == '0'){
						this.radcheckListuser();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
						this.dialogDelete = !this.dialogDelete;
					}
				}).finally(resp => {})
			},
			showDelete(data) {
				this.deleteItem = data;
				this.dialogDelete = !this.dialogDelete;
			},
			handleEmitAdd(params) {
                if(!params) return this.dialogAdd = !this.dialogAdd;
                if (this.$refs.handleEmitAdd_form.validate()) {
                    if(this.defaultItem.username == ''||!this.defaultItem.username) return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.server.rule.username')
                    });
                    // if(this.defaultItem.password == ''||!this.defaultItem.password) return Notification({
                    //     type: 'warning',
                    //     message: this.$vuetify.lang.t('$vuetify.server.rule.password')
                    // });
                    var paramsdata = {
                        username:this.defaultItem.username,
						password:this.defaultItem.password,
						remarks:this.defaultItem.remarks,
						setID:this.baseInfo,
						classID:this.authInfo.group_id
                    }
                    this.$http.post(api.default.aaa.radcheckAdduser,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogAdd = !this.dialogAdd;
                            this.radcheckListuser();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			showAdd() {
				this.defaultItem.username = null;
				this.defaultItem.password = null;
				this.defaultItem.remarks = null;
				this.dialogAdd = !this.dialogAdd;
			},
			handleBaseChange(){
				this.radcheckListuser();
			},
			handleUserPage(data){
				this.userParams.pagenum = data;
				this.radcheckListuser();
			},
			handleUserPageSize(data){
				this.userParams.pagesize = data;
				this.radcheckListuser();
			},
			radcheckListuser(){
				this.loadingUser = true;
				this.userParams.setID = this.baseInfo;
				this.userParams.classID = this.authInfo.group_id;
				this.userParams.username = this.authuser.search.username;
				this.userParams.remarks = this.authuser.search.remarks;
				this.$http.post(api.default.aaa.radcheckListuser,this.userParams).then(resp => {
					if(resp.errno == '0'){
						this.authInfo.table.total = parseInt(resp.data.total)
						this.authInfo.tableData = resp.data.list
						for (const user of resp.data.list) {
							if(user.isEnable=="0")
							{
								this.switch.push(user.id)
							}
						}
						// this.userParams.total = parseInt(resp.data.total);
						// this.tableData = resp.data.list;
						this.loadingUser = false;
					}
				}).finally(resp => {})
			},
			handleEmitBaseEdit(params) {
                if(!params) {
                    this.radcheckListset();
                    return this.dialogBaseEdit = !this.dialogBaseEdit;
                }
                if (this.$refs.handleEmitBaseEdit_form.validate()) {
                    if(this.editBaseItem.name == ''||!this.editBaseItem.name) return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.server.rule.name')
                    });
                    var attrJson = {};
                    for(var i=0;i<this.dessertsBase.length;i++){
                        attrJson[this.dessertsBase[i].name] = this.dessertsBase[i].attr;
                    }
                    attrJson = JSON.stringify(attrJson);
                    var paramsdata = {
                        id:this.editBaseItem.id,
						name:this.editBaseItem.name,
						aruba_xml_key:this.editBaseItem.aruba_xml_key,
						default_role:this.editBaseItem.default_role,
                        ldap_id:this.editBaseItem.ldap_id,
                        attr:attrJson
					}
                    this.$http.post(api.default.aaa.radcheckEditsetr,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogBaseEdit = !this.dialogBaseEdit;
                            this.radcheckListset();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			showAddAttribute(data){
				this.addBaseAttribute = data;
				this.dialogAddAttribute = !this.dialogAddAttribute;
			},
			handleEmitAddAttribute(params){
                if(!params) return this.dialogAddAttribute = !this.dialogAddAttribute;
                if (this.$refs.handleEmitAddAttribute_form.validate()) {
                    var obj = {};
                    for(var i=0;i<this.attrBaseArr.length;i++){
                        if(this.attrBaseArr[i].keys == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attr')
                            });
                        }
                        if(this.attrBaseArr[i].value == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.attrValue')
                            });
                        }
                        obj[this.attrBaseArr[i].keys] = this.attrBaseArr[i].value;
                    }
                    var paramsdata = {
                        id:this.addBaseAttribute.id,
                        attrs:JSON.stringify(obj)
                    }
                    this.$http.post(api.default.aaa.radcheckAddsetattrs,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogAddAttribute = !this.dialogAddAttribute;
                            this.attrBaseArr = [{name:null,value:null}];
                            this.radcheckListset();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			handleEmitDeleteAttribute(params){
				if(!params) return this.dialogDeleteAttribute = !this.dialogDeleteAttribute;
				var paramsdata = {
					id:this.editBaseItem.id,
					key:this.deleteBaseAttribute.name,
					value:this.deleteBaseAttribute.attr,
				};
				this.$http.post(api.default.aaa.radcheckDelsetattr,paramsdata).then(resp => {
					if(resp.errno == '0'){
						this.dialogDeleteAttribute = !this.dialogDeleteAttribute;
						this.radcheckListset();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
						for(var i=0;i<this.dessertsBase.length;i++){
							if(this.dessertsBase[i].name == this.deleteBaseAttribute.name){
								this.dessertsBase.splice(i,1);
							}
						}
					}
				}).finally(resp => {})
			},
			showDeleteAttribute(data){
				this.deleteBaseAttribute = data;
				this.dialogDeleteAttribute = !this.dialogDeleteAttribute;
			},
			showEditBase(data) {
				this.editBaseItem = data;
				this.dessertsBase = [];
				if(data.attr!=""){
					var dessertsBase = JSON.parse(data.attr);
					for(var i in dessertsBase){
						this.dessertsBase.push({name:i,attr:dessertsBase[i]})
					}
				}
				this.dialogBaseEdit = !this.dialogBaseEdit;
			},
			handleEmitBaseDelete(params) {
				if(!params) return this.dialogBaseDelete = !this.dialogBaseDelete;
				this.$http.get(api.default.aaa.radcheckDeleteset+'?id='+this.deleteBaseItem.id).then(resp => {
					if(resp.errno == '0'){
						this.dialogBaseDelete = !this.dialogBaseDelete;
						this.radcheckListset();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})
			},
			dialogDeleteBase(data) {
				this.deleteBaseItem = data;
				this.dialogBaseDelete = !this.dialogBaseDelete;
			},
			handleEmitBaseAdd(params) {
                if(!params) return this.dialogBaseAdd = !this.dialogBaseAdd;
                if (this.$refs.handleEmitBaseAdd_form.validate()) {
                    var paramsdata = {};
                    if(this.defaultBaseItem.name == ''||!this.defaultBaseItem.name) return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.server.rule.name')
                    });
                    paramsdata.name = this.defaultBaseItem.name;
                    if(this.sessionType == 0){
                        if(this.defaultBaseItem.groupID == ''||!this.defaultBaseItem.groupID) return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.server.rule.group')
                        });
                        paramsdata.groupID = this.defaultBaseItem.groupID;
                    }
                    if(this.sessionType == 0||this.sessionType == 1){
                        if(this.defaultBaseItem.shopID == ''||!this.defaultBaseItem.shopID) return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.server.rule.shop')
                        });
                        paramsdata.shopID = this.defaultBaseItem.shopID;
                    }

                    
                    if(this.defaultBaseItem.authType == null){
                        return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.server.rule.authType')
                        });
                    }
                    if(this.defaultBaseItem.authType == 1){
                        if(this.defaultBaseItem.ldapID == null){
                            return Notification({
                                type: 'warning',
                                message: this.$vuetify.lang.t('$vuetify.server.rule.ldap')
                            });
                        }
                        paramsdata.ldapID = this.defaultBaseItem.ldapID;
                    }else{
                        paramsdata.ldapID = null;
                    }

                    paramsdata.authType = this.defaultBaseItem.authType;
                    
                    this.$http.post(api.default.aaa.radcheckAddset,paramsdata).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogBaseAdd = !this.dialogBaseAdd;
                            this.radcheckListset();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})
                }
			},
			showAddBase() {
				
				if(this.sessionType == 0){
					this.groupListUrl();
				}else if(this.sessionType == 1){
					this.shopListUrl(1);
				}
				this.defaultBaseItem.name = null;
				this.defaultBaseItem.groupID = null;
				this.defaultBaseItem.shopID = null;
				this.dialogBaseAdd = !this.dialogBaseAdd;
			},
			radcheckListset(){
				this.loadingBase = true;
				this.$http.post(api.default.aaa.radcheckListset,this.baseParams).then(resp => {
					if(resp.errno == '0'){
						this.baseParams.total = parseInt(resp.data.total);
						this.tableDataBase = resp.data.list;
                        let newTableDataBase = [];
                        resp.data.list.forEach((value,index) => {
                            if(value.type_name == '本地认证库'){
                                newTableDataBase.push(value);
                            }
                        })
                        this.newTableDataBase = newTableDataBase;
						this.loadingBase = false;
						this.baseInfo = newTableDataBase[0].id;
						this.fetchListGroup();
						/* 二维码授权员 */
						this.wechatAdmin.authsets = newTableDataBase;
						this.wechatAdmin.authset = newTableDataBase[0].id;
						/* 访客 */
						this.visitor.authsets = newTableDataBase;
						this.visitor.authset = newTableDataBase[0].id;
					}
				}).finally(resp => {})
			},
			shopListUrl(type){
				if(type == 0){
					params = {
						pagesize:10000,
						pagenum: 1,
						groupID:this.defaultBaseItem.groupID
					}
				}else{
					var params = {pagesize:10000,pagenum: 1};
				}
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
					this.itemsPlace = resp.data.list;
				}).finally(resp => {})
			},
			groupListUrl(){
				this.$http.post(api.default.rbac.groupListUrl).then(resp => {
					this.itemsGroup = resp.data;
				}).finally(resp => {})
			},
			handlePage(data){
				this.baseParams.pagenum = data;
				this.radcheckListset();
			},
			handlePageSize(data){
				this.baseParams.pagesize = data;
				this.radcheckListset();
			},
		}
	}
</script>

<style language="scss">
	.v-input--selection-controls{
		margin-top: 0px;
	}
	.v-card__subtitle
	{
		padding-top: 0px;
	}
	.v-toolbar
	{
		margin-bottom: 0px;
	}

	.qrcode{
		width: 100%;
		margin-top:30px
	}
	.qrcode img{
		margin: 0 auto;
	}
</style>