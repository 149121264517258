<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.scheduler.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        :items="demos"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.scheduler.table.demo')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.defaultID"
                                        :rules="rules.defaultID" 
                                        dense
                                        outlined
                                    ></v-select>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleUpdate" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.portalManage.update')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

    props: {
        demos: Array,
        role: Number,
    },

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.portalManage.scheduler.editBtn'),

            dialog: false,

            loading: false,

            data: {
                name: null,
                defaultID: null
            },

            rules: {
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ],
                defaultID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ]
            }
            
        }
    },

    methods: {

        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },

        handleOpen (item) {
            this.data = this.newObject(item);
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
            this.$refs.form.reset();
        },

        handleUpdate () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.post(api.default.portal.scheduleEditUrl, {
                    id: this.data.id,
                    name: this.data.name,
                    rules: JSON.stringify({
                        defaultID: parseInt(this.data.defaultID),
                        condition: this.data.rules
                    })
                }).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        newObject (item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        },

    }
}
</script>

<style scoped>



</style>