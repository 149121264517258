<template>
    <div id="v-table">
        <v-data-table 
            :headers="columns" 
            :items="data" 
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            sort-by="calories"
            class="elevation-1"
        >

            <template v-slot:item.level="{ item }">
                {{ getType(item) }}
            </template>

            <template v-slot:item.timestamp="{ item }">
                {{ getLocalTime(item) }}
            </template>

        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            options: {}
        }
    },

    props: {
        loading : Boolean,
        columns : Array,
        data    : Array,
        total   : Number,
        search  : Object,
        logType : Array
    },

    methods: {

        getType (item) {
            var length = this.logType.length;
            for (var i = 0; i < length; i++) {
                if (this.logType[i].value === item.level) {
                    return this.logType[i].label;
                }
            }
        },

        getLocalTime(item) {     
            var date = new Date(parseInt(item.timestamp) * 1000);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            var d = date.getDate();
            var t = date.toTimeString().substr(0,8);
            return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + t;
        },

        handleSearch () {
            this.search.pagesize = this.options.itemsPerPage === -1 ? 15 : this.options.itemsPerPage;
            this.search.pagenum  = this.options.page;
            this.$emit('handleSearch');
        }

    },

    watch: {

        options: {
            handler (newValue, oldValue) {
                if (oldValue.page !== undefined && newValue !== oldValue) {
                    this.handleSearch();
                }
            },
            deep: true,
        },
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}
 
#v-table .v-card--material__header {
    width: 175px;
}

#v-table .v-btn__content {
    /* color: #000; */
}
</style>