<template>
    <div id="v-table">
        <v-data-table 
            :headers="columns" 
            :items="data" 
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
        >

            <template v-slot:item.type="{ item }">
                <span>{{ setTypeLabel(item) }}</span>
            </template>

            <template v-slot:item.status="{ item }">
                <span>{{ setStatusLabel(item) }}</span>
            </template>

            <template v-slot:item.shopName="{ item }">
                <span>{{ item.shopName === undefined ? '/' : item.shopName }}</span>
            </template>

            <template v-slot:item.groupName="{ item }">
                <span>{{ item.groupName === undefined ? '/' : item.groupName }}</span>
            </template>

            <template v-slot:item.action="{ item }">
                <slot name='action' :value='{ item }'></slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            options: {}
        }
    },

    props: {
        loading : Boolean,
        columns : Array,
        data    : Array,
        total   : Number,
        search  : Object,
    },

    methods: {

        setTypeLabel (item) {
            switch (item.type) {
                case 0:
                    return this.$vuetify.lang.t('$vuetify.userManage.user.table.type.admin');
                case 1:
                    return this.$vuetify.lang.t('$vuetify.userManage.user.table.type.group');
                case 2:
                    return this.$vuetify.lang.t('$vuetify.userManage.user.table.type.shop');
            }
        },

        setStatusLabel (item) {
            switch (item.status) {
                case 0:
                    return this.$vuetify.lang.t('$vuetify.userManage.user.table.status.off');
                case 1:
                    return this.$vuetify.lang.t('$vuetify.userManage.user.table.status.on');
            }
        },

        handleSearch () {
            this.search.pagesize = this.options.itemsPerPage === -1 ? 15 : this.options.itemsPerPage;
            this.search.pagenum  = this.options.page;
            this.$emit('handleSearch');
        }

    },

    watch: {

        options: {
            handler (newValue, oldValue) {
                if (oldValue.page !== undefined && newValue !== oldValue) {
                    this.handleSearch();
                }
            },
            deep: true,
        },
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}
 
#v-table .v-card--material__header {
    width: 175px;
}

#v-table .v-btn__content {
    /* color: #000; */
}
</style>