<template>
	<div id="ins">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-card>
						<v-stepper v-model="e1">
							<v-stepper-header>
								<v-stepper-step :complete="e1 > 1" step="1">{{$vuetify.lang.t('$vuetify.gateway.instructions.step1')}}</v-stepper-step>
								<v-divider></v-divider>
								<v-stepper-step :complete="e1 > 2" step="2">{{$vuetify.lang.t('$vuetify.gateway.instructions.step2')}}</v-stepper-step>
							</v-stepper-header>
							<v-stepper-items>
								<v-stepper-content step="1">
									<v-card class="mb-12" color="grey lighten-4" height="170px">
                                        <v-form
                                        ref="form"
                                        lazy-validation>
										<v-row>
											<v-col cols="4" sm="4" md="4" class="mx-auto mt-12">
												<v-text-field class="mt-0" 
                                                    v-model="defaultName" 
                                                    :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.name')" 
                                                    :label="$vuetify.lang.t('$vuetify.gateway.instructions.name')" 
                                                    dense
                                                    :rules="rules.defaultName"
                                                    @change="defaultName=formSpaces(defaultName)" 
                                                ></v-text-field>
											</v-col>
											<v-col cols="4" sm="4" md="4" class="mx-auto mt-12">
												<v-text-field class="mt-0" 
                                                    v-model="defaultCommand" 
                                                    :placeholder="$vuetify.lang.t('$vuetify.gateway.instructions.placeholder')" 
                                                    :label="$vuetify.lang.t('$vuetify.gateway.instructions.ins')" 
                                                    dense
                                                    :rules="rules.defaultCommand"
                                                    @change="defaultCommand=formSpaces(defaultCommand)" 
                                                ></v-text-field>
											</v-col>
										</v-row>
                                        </v-form>
									</v-card>
									<v-btn class="float-left" color="error" @click="handleReturn">
										{{$vuetify.lang.t('$vuetify.common.returnBtn')}}
									</v-btn>
									<v-btn class="float-right" color="primary" @click="handleNextOne">
										{{$vuetify.lang.t('$vuetify.gateway.instructions.next')}}
									</v-btn>
								</v-stepper-content>
								<v-stepper-content step="2">
									<v-card class="mb-12" color="grey lighten-4">
													<v-table @selectData="handleSelectKey" :titleTable="titleTable" :pages="params.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loading" :pageSize="params.pageSize" :total="params.total" :showSelect="true" :headers="headers" :tableData='tableData'>
										<template slot="select">
											<a @click="handleReturn">{{defaultName}}</a>
										</template>
									</v-table>
									</v-card>
									<v-btn class="float-left" color="error"  @click="e1 = 1">{{$vuetify.lang.t('$vuetify.gateway.instructions.pre')}}</v-btn>
									<v-btn class="float-right" color="primary" @click="handleFinished">
										{{$vuetify.lang.t('$vuetify.gateway.instructions.next')}}
									</v-btn>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification  } from 'element-ui';
	export default {
		data() {
			return {
				e1: 1,
				defaultName:null,
				defaultCommand:null,
				titleTable:this.$vuetify.lang.t('$vuetify.gateway.title'),
				headers:[
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.name'),
						align: 'left',
						value: 'name',
					},
				],
				tableData:[],
				loading:true,
				params: {
					pageSize: 10, // 每页返回记录数
					pageNum: 1, // 页数
					total:0,
                },
                rules:{
                    defaultName: [
                        value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.role.rules.name'),
                        value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.role.rules.name')
                    ],
                    defaultCommand: [
                        value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.role.rules.name'),
                        value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.role.rules.groupID')
                    ],
                },
				selectKey:[],
			}
		},
		props: [],
		components: {vTable},
		mounted() {
			this.deviceList();
		},
		methods: {
            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },
			handleNextOne(){
                if (this.$refs.form.validate()) {
                    if(!this.defaultName||this.defaultName == '')return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.gateway.rule.ins')
                    });
                    this.e1 = 2;
                }
			},
			handlePage(data){
				this.params.pageNum = data;
				this.deviceList();
			},
			handlePageSize(data){
				this.params.pageSize = data;
				this.deviceList();
			},
			deviceList() {
				this.loading = true; 
				if(this.params.pageSize==-1)this.params.pageSize=10000
				this.$http.post(api.default.gateway.deviceList,this.params).then(resp => {
					this.params.total = parseInt(resp.data.total);
					this.tableData = resp.data.data;
					this.loading = false;
				}).finally(resp => {})	
			},
			handleFinished(){
                var keys=[],params={};
                if(this.selectKey.length == 0)return Notification({
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.gateway.rule.device')
                });
                for(var i=0;i<this.selectKey.length;i++){
                    keys.push(parseInt(this.selectKey[i].id));
                }
                params = {
                    name:this.defaultName,
                    command:this.defaultCommand,
                    idlist:keys
                }
                this.$http.post(api.default.gateway.commandExcute,params).then(resp => {
                    if(resp.errno == '0'){
                        Notification({
                            type: 'success',
                            message: this.$vuetify.lang.t('$vuetify.common.success')
                        });
                        this.$router.push({path:'/gateway/remote_order'})
                    }
                }).finally(resp => {})	
			},
			handleSelectKey(data){
				this.selectKey = data;
			},
			handleReturn(data) {
				this.$router.push({
					path: '/gateway/remote_order'
				})
			},
		},
	}
</script>
</script>

<style>
	#ins .chartBox {
		width: 600px;
		margin: 0 auto;
		padding-bottom: 40px;
	}
</style>