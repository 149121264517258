<template> 
    <div class="main_lay">
        <v-toolbar-title class="font-weight-regular mt-5 ">
            <el-date-picker
            v-model="value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getNetLog()"
            value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
        </v-toolbar-title>
        <v-toolbar-title class="font-weight-regular mt-5 ">带宽速率:</v-toolbar-title>
        <v-card class="pa-2" outlined tile >
            <v-row align="center" class="mx-0" >
                <div id="main3" :style="{width: '100%', height: '300px',top:'0'}"></div>
            </v-row>
        </v-card>
        <v-toolbar-title class="font-weight-regular mt-5 ">网络连接数趋势:</v-toolbar-title>
        <v-card class="pa-2" outlined tile >
            <v-row align="center" class="mx-0" >
                <div id="main1" :style="{width: '100%', height: '300px',top:'0'}"></div>
            </v-row>
        </v-card>
        <v-toolbar-title class="font-weight-regular mt-5 ">流量使用趋势:</v-toolbar-title>
        <v-card class="pa-2" outlined tile >
            <v-row align="center" class="mx-0" >
                <div id="main2" :style="{width: '100%', height: '300px',top:'0'}"></div>
            </v-row>
        </v-card>
        <v-divider class="mb-2 mt-2 ml-0 mr-0"></v-divider>
    </div>
</template>
<script>
import Echarts from 'echarts'
import * as api from '../../../api'

export default {
    props: ['gateway_id'],
    data () {
        return {
            value1: [this.getBeforeDate(1)+" 23:59:59", new Date().toISOString().slice(0, 10)+" 23:59:59"],
            xdate:[],
            rx_bytes:[],
            tx_bytes:[],
            rx_bytes_rate:[],
            tx_bytes_rate:[],
            max_rx_bytes_rate:[],
            max_tx_bytes_rate:[],
            lp_tcp:[],
            lp_udp:[],
            lp_otr:[],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getNetLog()
        })
    },
    watch: {
        gateway_id(){
            this.getNetLog()
        },
    },
    methods: {
        getNetLog(){
            var postData = {
                "gateway_id":this.gateway_id,
                "start_time":this.value1[0],
                "stop_time":this.value1[1],
            }
            this.$http.post(api.default.gateway.netlog,postData).then(resp => {
                if(resp.errno != 0)
                    return;

                this.xdate=resp.data.xdate
                this.rx_bytes=resp.data.rx_bytes
                this.tx_bytes=resp.data.tx_bytes
                this.rx_bytes_rate=resp.data.rx_bytes_rate
                this.tx_bytes_rate=resp.data.tx_bytes_rate
                this.max_rx_bytes_rate=resp.data.max_rx_bytes_rate
                this.max_tx_bytes_rate=resp.data.max_tx_bytes_rate
                this.lp_tcp=resp.data.lp_tcp
                this.lp_udp=resp.data.lp_udp
                this.lp_otr=resp.data.lp_otr

                this.display_Net()
                this.display_Bytes()
                this.display_Bytes_Rate()
            })
        },
        display_Net(){
            var myChart = Echarts.init(document.getElementById('main1'));
            let option = {
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    }
                },
                legend:{
                    show:true
                },
                xAxis: {
                    data: this.xdate,
                },
                yAxis: {},
                series: [
                    {
                        name:"tcp连接",
                        data: this.lp_tcp,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"udp连接",
                        data: this.lp_udp,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"  其他连接",
                        data: this.lp_otr,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option);
        },
        display_Bytes(){
            var myChart = Echarts.init(document.getElementById('main2'));
            let option = {
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    }
                },
                legend:{
                    show:true
                },
                xAxis: {
                    data: this.xdate,
                },
                yAxis: {
                    axisLabel:{
                        type:'value',
                        formatter:'{value}MB'
                    }
                },
                series: [
                    {
                        name:"下载",
                        data: this.rx_bytes,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"上传",
                        data: this.tx_bytes,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option);
        },
        display_Bytes_Rate(){
            var myChart = Echarts.init(document.getElementById('main3'));
            let option = {
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    }
                },
                legend:{
                    show:true
                },
                xAxis: {
                    data: this.xdate,
                },
                yAxis: {
                    axisLabel:{
                        type:'value',
                        formatter:'{value}MB/S'
                    }
                },
                series: [
                    {
                        name:"平均下行速率",
                        data: this.rx_bytes_rate,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"平均上行速率",
                        data: this.tx_bytes_rate,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"最高下行速率",
                        data: this.max_rx_bytes_rate,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    },
                    {
                        name:"最高上行速率",
                        data: this.max_tx_bytes_rate,
                        type: 'line',
                        areaStyle: {},
                        emphasis: {
                            label:{
                                show:true
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option);
        },

        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        },
    }
}
</script>