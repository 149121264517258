<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog"
                      persistent
                      max-width="750px">
                <v-card>
                    <v-card-text>

                        <v-toolbar flat
                                   color="white">
                            <v-toolbar-title>
                                {{ data.name }}
                            </v-toolbar-title>
                            <v-divider class="mx-4"
                                       inset
                                       vertical></v-divider>

                            <v-btn color="primary"
                                   @click="handleAddRule">
                                添加规则
                            </v-btn>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-lightbulb
                                    </v-icon>
                                </template>
                                <span style="color:white">当认证设备满足指定的设备类型、设备语言、用户类型、时间等条件时，会跳转到对应的模板页面</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-expansion-panels>
                            <v-expansion-panel v-for="(rule, i) in data.rules"
                                               :key="i">
                                <v-expansion-panel-header>
                                    <div>
                                        <v-icon color="primary"
                                                small
                                                @click="handleDelRule(i)">
                                            delete
                                        </v-icon>
                                        {{ '规则' + (i + 1) + getDemoText(rule.pageID) }}
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row justify="start">
                                        <div class="layout-select">
                                            <v-select :items="demos"
                                                      label="portal模板"
                                                      item-text="name"
                                                      item-value="id"
                                                      v-model="rule.pageID"
                                                      dense
                                                      solo></v-select>
                                        </div>
                                        <div class="layout-select">
                                            <v-select :items="devices"
                                                      label="设备类型"
                                                      item-text="label"
                                                      item-value="value"
                                                      v-model="rule.device"
                                                      dense
                                                      solo></v-select>
                                        </div>
                                        <div class="layout-select">
                                            <v-select :items="langs"
                                                      label="语言种类"
                                                      item-text="label"
                                                      item-value="value"
                                                      v-model="rule.lang"
                                                      dense
                                                      solo></v-select>
                                        </div>
                                        <div class="layout-select">
                                            <v-select :items="userType"
                                                      label="用户类型"
                                                      item-text="label"
                                                      item-value="value"
                                                      v-model="rule.userType"
                                                      dense
                                                      solo></v-select>
                                        </div>
                                    </v-row>

                                    <v-row style="margin-top: -20px;">
                                        <v-chip v-for="(item,index) in rule.dates"
                                                :key="index"
                                                class="ma-2"
                                                color="primary"
                                                label>

                                            {{ getDateText(item) }}

                                            <v-icon small
                                                    @click="handleDelete(rule.dates, index)">
                                                delete
                                            </v-icon>

                                        </v-chip>
                                    </v-row>

                                    <v-btn color="primary"
                                           @click="handleAdd(rule.dates)">
                                        添加时间
                                    </v-btn>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary"
                                   @click="handleClose">
                                {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                            </v-btn>
                            <v-btn color="primary"
                                   @click="handleSave"
                                   :loading="loading">
                                {{$vuetify.lang.t('$vuetify.portalManage.update')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <scheduler-rule-date ref="date"></scheduler-rule-date>
        </v-row>
    </div>
</template>

<script>
import * as api from "../../../api";
import { Notification } from "element-ui";
import SchedulerRuleDate from "./SchedulerRuleDate.vue";

export default {
    components: {
        SchedulerRuleDate,
    },

    props: {
        demos: Array,
    },

    data() {
        return {
            title: this.$vuetify.lang.t(
                "$vuetify.portalManage.scheduler.rulesBtn"
            ),

            dialog: false,

            loading: false,

            devices: [
                {
                    value: 0,
                    label: "手机",
                },
                // {
                //     value: 1,
                //     label: "平板",
                // },
                {
                    value: 2,
                    label: "电脑",
                },
            ],

            langs: [
                {
                    value: 0,
                    label: "中文",
                },
                {
                    value: 1,
                    label: "英文",
                },
            ],

            data: {
                rules: [
                    {
                        pageID: 0,
                        userType: 0,
                        lang: 0,
                        device: 0,
                        dates: [],
                    },
                ],
            },

            userType: [
                {
                    value: 0,
                    label: "新用户",
                },
                {
                    value: 1,
                    label: "老用户",
                },
            ],
        };
    },

    methods: {
        handleOpen(item) {
            this.data = this.newObject(item);
            this.dialog = true;
        },

        handleClose() {
            this.dialog = false;
        },

        handleSave() {
            this.loading = true;
            this.$http
                .post(api.default.portal.scheduleSetRulesUrl, {
                    id: this.data.id,
                    rules: JSON.stringify({
                        defaultID: this.data.defaultID,
                        condition: this.data.rules,
                    }),
                })
                .then((resp) => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: "success",
                        message: this.$vuetify.lang.t(
                            "$vuetify.userManage.success"
                        ),
                    });
                    this.$emit("handleSearch");
                })
                .finally((resp) => {
                    this.loading = false;
                });
        },

        handleDelRule(index) {
            this.data.rules.splice(index, 1);
        },

        handleAddRule() {
            if (this.demos.length <= 0) {
                Notification({
                    type: "error",
                    message: this.$vuetify.lang.t(
                        "$vuetify.portalManage.scheduler.tip.nonePage"
                    ),
                });
                return false;
            }
            this.data.rules.push({
                pageID: this.demos[0].id,
                userType: 0,
                lang: 0,
                device: 0,
                dates: [],
            });
        },

        handleDelete(item, index) {
            item.splice(index, 1);
        },

        handleAdd(item) {
            this.$refs.date.handleOpen(item);
        },

        getDemoText(id) {
            var n = this.demos.length;
            for (var i = 0; i < n; i++) {
                if (id === this.demos[i].id) {
                    return " [" + this.demos[i].name + "]";
                }
            }
        },

        getDateText(item) {
            var data = item.value.split(",");
            if (item.name === "time") {
                return data[0] + " ~ " + data[1];
            } else {
                switch (item.type) {
                    case "day":
                        return "每日" + data[0] + " ~ " + data[1];
                    case "week":
                        var value = [
                            "每星期日",
                            "每星期一",
                            "每星期二",
                            "每星期三",
                            "每星期四",
                            "每星期五",
                            "每星期六",
                        ];
                        var index = parseInt(data[0]);
                        return value[index] + " " + data[1] + " ~ " + data[2];
                    case "month":
                        var value = "每月" + parseInt(data[0]) + "日";
                        return value + " " + data[1] + " ~ " + data[2];
                    case "year":
                        var date = data[0].split("-");
                        var value =
                            "每年" +
                            parseInt(date[0]) +
                            "月" +
                            parseInt(date[1]) +
                            "日";
                        return value + " " + data[1] + " ~ " + data[2];
                }
            }
        },

        newObject(item) {
            var data = JSON.stringify(item);
            return JSON.parse(data);
        },
    },
};
</script>

<style scoped>
.layout-select {
    width: 136px;
    margin-right: 10px;
}

.layout-time {
    width: 136px;
    margin-right: 10px;
    margin-top: -8px;
}
</style>