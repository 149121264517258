<template>
    
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="10">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="group"
                            v-model="groupVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                            @change="changeGroup"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="shop"
                            v-model="shopVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            @change="changeShop"
                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            :items="device"
                            v-model="deviceVal"
                            item-value="id" 
                            item-text="name"
                            clearable
                            @change="changeDevice"
                            :label="$vuetify.lang.t('$vuetify.portalMac.pickWg')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date"
                                :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            range
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date);changedate" >{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="1">
                        <v-btn color="primary" dark  @click="search" style="margin: 10px 0 0 0"><span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.search')}}</span></v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="12">
                <div id="chart1" :style="{width: '100%', height: '300px'}"></div>
            </v-col>
            <v-col cols="12" md="12">
                <div id="chart2" :style="{width: '100%', height: '300px'}"></div>
            </v-col>
            <!-- <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-menu
                            ref="menu1"
                            v-model="menustart"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="starttime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="starttime"
                                :label="$vuetify.lang.t('$vuetify.bandwidth.pickTimeStart')"
                                prepend-icon="access_time"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="menustart"
                            v-model="starttime"
                            :max="endtime"
                            full-width
                            @click:minute="$refs.menu1.save(starttime)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols='12' md='2'>
                        <v-menu
                            ref="menu2"
                            v-model="menuend"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="endtime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="endtime"
                                :min="starttime"
                                :label="$vuetify.lang.t('$vuetify.bandwidth.pickTimeEnd')"
                                prepend-icon="access_time"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="menuend"
                            v-model="endtime"
                            full-width
                            @click:minute="$refs.menu2.save(endtime)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols='12' md='1'>
                        <v-btn color="primary" dark   @click="timeSearch">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                    </v-col>
                    <div id="chart3" :style="{width: '100%', height: '300px'}"></div>
                </v-row>
            </v-col> -->
        </v-row>

    </div>

</template>

<script>
import bus from '../../utils/bus.js'
import Echarts from 'echarts'
import * as api from "../../api"
import { Notification } from 'element-ui';
// import CsvExport from './../../utils/CsvExport'
export default {
    data () {
      return {
            group: [],
            shop: [],
            device: [],
            groupVal: null,
            shopVal: null,
            deviceVal: null,
            groupID: '',
            shopID: '',
            deviceID: '',
            searchValue: 0,

            menu: false,

            date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],

            menuMonth: false,
            menuDay: false,
            menustart: false,
            menuend: false,
            starttime: null,
            endtime: null,
            loading: false,
        
      }
    },

    computed: {
      formTitle () {
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },
    mounted() {
        this.fetchGroupList()
        this.drawChart()
        this.initGroupShop()
        // this.drawTimeClockChart()
    },
    methods: {
        initGroupShop(){
            if(window.sessionStorage.getItem('type') == 2){
                this.fetchGroupList()
                this.fetchShopList()
                this.groupVal = window.sessionStorage.getItem('group_id')
                this.shopVal = window.sessionStorage.getItem('shop_id')
                
            }
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
               
                this.group = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list
                
            }).finally(resp => {
                this.loading = false
            })
            
        },
        fetchDeviceList(shopid){

            const postData = {
                pageSize: 1000,
                pageNum: 1,
                shopID: shopid
            }

            this.$http.post(api.default.gateway.deviceList,postData).then(resp => {
                
                this.device = resp.data.data

            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            this.fetchShopList(groupid)
            this.groupID = groupid
        },
        changeShop(shopid){
            this.fetchDeviceList(shopid)
            this.shopID = shopid
        },
        changeDevice(deviceid){
            this.deviceID = deviceid
        },

        changedate(val){
        },
        search(){
            this.drawChart()
        },
        
        timeSearch() {
            this.drawTimeClockChart()
        },
        timpstapChange(unixtimestamp){
            var unixtimestamp = new Date(unixtimestamp);
            var year = 1900 + unixtimestamp.getYear();
            var month = "0" + (unixtimestamp.getMonth() + 1);
            var date = "0" + unixtimestamp.getDate();
            var hour = "0" + unixtimestamp.getHours();
            var minute = "0" + unixtimestamp.getMinutes();
            var second = "0" + unixtimestamp.getSeconds();
            return  year + "-" + month.substring(month.length-2, month.length)  + "-" + date.substring(date.length-2, date.length)
                + " " +  hour.substring(hour.length-2, hour.length) + ":"
                + minute.substring(minute.length-2, minute.length) + ":"
                + second.substring(second.length-2, second.length);
        },
        en_zh(en){
            switch (en) {
                case 'unknown':
                    return '未知流量'
                    break;
                case 'general':
                    return '常用协议'
                    break;
                case 'p2p':
                    return 'P2P下载'
                    break;
                case 'nettv':
                    return '网络电视'
                    break;
                case 'im':
                    return '即时通信'
                    break;
                case 'stream':
                    return '流媒体'
                    break;
                case 'voip':
                    return '网络电话'
                    break;
                case 'game':
                    return '网络游戏'
                    break;
                case 'stock':
                    return '股票交易'
                    break;
                case 'database':
                    return '数据库'
                    break;
                case 'other':
                    return '其他'
                    break;
                case 'httpgroup':
                    return 'HTTP协议'
                    break;
                case 'mobile':
                    return '移动应用'
                    break;
                case 'custom':
                    return '自定义协议'
                    break;
            
                default:
                    break;
            }
        },
        drawChart() {

            let myChart1 = Echarts.init(document.getElementById('chart1'))
            let myChart2 = Echarts.init(document.getElementById('chart2'))
            let title1 =  this.$vuetify.lang.t('$vuetify.bandwidth.chartTitle1')
            let title2 =  this.$vuetify.lang.t('$vuetify.bandwidth.chartTitle2')
            
            const postData = {
                filter:{
                    timeRange: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                    device_id: this.deviceID
                }
            }
            this.$http.post(api.default.data.bindwidthup,postData).then(resp => {
                if(!resp) return

                let xAxis1 = []
                let legendData1 = []
                let series1 = []
                
                if(resp.data.rows){
                    const rows = resp.data.rows

                    if(rows.length > 0){
                        const legs = rows[0]['data']
                        for (let index = 0; index < legs.length; index++) {
                            const time = this.timpstapChange(legs[index]['x'])
                            xAxis1.push(time)
                        }

                        for (let index = 0; index < rows.length; index++) {
                            const dataArr = []
                            const name = this.en_zh(rows[index]['name'])
                            rows[index]['data'].forEach(element => {
                                dataArr.push(parseInt(element['y']))
                            });
                            series1.push({
                                type: 'line',
                                name: name,
                                data: dataArr
                            })
                            legendData1.push(name)
                        }
                    }else{
                        Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.common.noData')
                        });
                        xAxis1 = []
                        series1.push({
                            type: 'line',
                            name: '',
                            data: []
                        })
                        legendData1 = []
                    }
                }

                this.setChart_co(myChart1,title1,legendData1,xAxis1,series1,this.$vuetify.lang.t('$vuetify.common.flowdw'))
            }).finally(resp => {
                this.loading = false
            })


            this.$http.post(api.default.data.bindwidthdown,postData).then(resp => {
                if(!resp) return
                
                let xAxis2 = []
                let legendData2 = []
                let series2 = []

                if(resp.data.rows){
                    const rows = resp.data.rows

                    if(rows.length > 0){
                        const legs = rows[0]['data']
                        for (let index = 0; index < legs.length; index++) {
                            const time = this.timpstapChange(legs[index]['x'])
                            xAxis2.push(time)
                        }

                        for (let index = 0; index < rows.length; index++) {
                            const dataArr = []
                            const name = this.en_zh(rows[index]['name'])
                            rows[index]['data'].forEach(element => {
                                dataArr.push(parseInt(element['y']))
                            });
                            series2.push({
                                type: 'line',
                                name: name,
                                data: dataArr
                            })
                            legendData2.push(name)
                        }
                    }else{
                        Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.common.noData')
                        });
                        xAxis2 = []
                        series2.push({
                            type: 'line',
                            name: '',
                            data: []
                        })
                        legendData2 = []
                    }
                }
                this.setChart_co(myChart2,title2,legendData2,xAxis2,series2,this.$vuetify.lang.t('$vuetify.common.flowdw'))
            }).finally(resp => {
                this.loading = false
            })

            
        },

        drawTimeClockChart(){
            const postData = {
                timeRange: [],
                group_id:this.groupID,
                shop_id:this.shopID,
                device_id: this.deviceID
            }
            let myChart3 = Echarts.init(document.getElementById('chart3'))
            let title3 =  this.$vuetify.lang.t('$vuetify.bandwidth.chartTitle3')
            this.$http.post(api.default.data.bindwidthHour,postData).then(resp => {
                
                let series = []
                let xAxis = []
                
                let arr = resp.data
                let updata = []
                let downdata = []
                for (let index = 0; index < arr.length; index++) {
                    xAxis.push(arr[index]['date'] + ':00:00')
                    
                    let uptemp = parseInt(arr[index]['up'].replace("G",""))
                    let downtemp = parseInt(arr[index]['down'].replace("G",""))
                    updata.push(uptemp)
                    downdata.push(downtemp)
                    series = [{
                        type: 'line',
                        name: '上行',
                        data: updata
                    },{
                        type: 'line',
                        name: '下行',
                        data: downdata
                    }]
                }
                this.setChart(myChart3,title3,xAxis,series)

            }).finally(resp => {
                this.loading = false
            })
        },

        setChart_co(myChart,title,legendData,xAxis,series,dw){
            function bytesToSize(bytes) {
                if (bytes === 0) return '0 B';
                var k = 1000, // or 1024
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
            
                return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + (i>0?sizes[i]:'');
            }
            myChart.clear()

            myChart.setOption({
                title: {
                    show: true,
                    text: title,
                    x:'center',
                    top: 0,
                    textStyle: {
                        color: '#414957',
                        fontSize: 16,
                        fontFamily: '"Microsoft Yahei","微软雅黑"',
                    },
                },
                backgroundColor: '#FFF',
                grid: {
                    top: '12%',
                    bottom: '22%',
                    left: '6%',
                    right: '4%'
                },
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    },
                    formatter: function (params) {
                        let res = ''
                        let time = ''
                        for (let index = 0; index < params.length; index++) {
                            time = params[index].name
                            res += params[index].seriesName +":"+bytesToSize(params[index].data)+ '<br/>';
                        }
                        return time +"<br/>"+ res
                    }
                },
                color:['#7986CB','#81C784','#64B5F6','#4DB6AC','#4DD0E1','#AED581','#9575CD'],
                legend: {
                    x: 'center', // 'center' | 'left' | {number},
                    y: 'bottom', // 'center' | 'bottom' | {number}
                    icon: "circle",
                    data: legendData
                },
                xAxis: {
                    boundaryGap: true, //默认，坐标轴留白策略
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    data: xAxis
                },
                yAxis: {
                    name:  dw,
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(33,148,246,0.2)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: 'rgb(245,250,254)'
                        }
                    },
                    axisLabel:{
                        formatter: function (value,index) {
                            if(dw == 'flowcnt'){
                                return value
                            }else{
                                return bytesToSize(value);
                            }
                            
                        }
                    }
                    
                },
                series:  series
                
            })
        },

        setChart(myChart,title,xAxisData,data) {
            myChart.setOption({
                title: {
                    show: true,
                    text: title,
                    x:'center',
                    top: 0,
                    textStyle: {
                        color: '#414957',
                        fontSize: 16,
                        fontFamily: '"Microsoft Yahei","微软雅黑"',
                    },
                },
                backgroundColor: '#FFF',
                color:['#7986CB','#81C784','#64B5F6','#4DB6AC','#4DD0E1','#AED581','#9575CD'],
                grid: {
                    top: '9%',
                    bottom: '19%',
                    left: '7%',
                    right: '4%'
                },
                tooltip: {
                    trigger: 'axis',
                    label: {
                        show: true
                    }
                },
                xAxis: {
                    boundaryGap: true, //默认，坐标轴留白策略
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    data: xAxisData
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(33,148,246,0.2)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: 'rgb(245,250,254)'
                        }
                    }
                },
                series: data
                
            })
        },

        handleExport(){
            // CsvExport(this.headers,this.desserts,this.title);
        },
        initialize() {

        },
        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}

</style>