<template>
    <div id="Email">

        <v-container fill-height
                     fluid
                     grid-list-xl
                     class="app-container">
            <v-layout justify-center
                      wrap>
                <v-flex md12>
                    <v-card>
                        <v-row>
                            <v-col cols="12">
                                <v-toolbar :flat="true">
                                    <v-toolbar-title>
                                        <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                    </v-toolbar-title>

                                    <v-divider class="mr-4 ml-4"
                                               inset
                                               vertical></v-divider>

                                    <v-btn color="primary ml-3"
                                           dark
                                           @click="emailList">
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.search')}}
                                        </span>
                                    </v-btn>
                                    <v-btn color="primary ml-3"
                                           @click="reset"
                                           dark>
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.reset')}}
                                        </span>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-subtitle>
                                    <v-row>
                                        <!-- 选择集团 -->
                                        <v-col md="2"
                                               v-if="userType == 0">
                                            <v-select :items="groupArr"
                                                      v-model="params.groupID"
                                                      item-value="id"
                                                      item-text="name"
                                                      clearable
                                                      @click:clear="handelGroupClear"
                                                      @input="handleGroupChange"
                                                      :label="$vuetify.lang.t('$vuetify.email.table.group')"></v-select>
                                        </v-col>
                                        <!-- 选择场所 -->
                                        <v-col md="2"
                                               v-if="userType == 0 || userType == 1">
                                            <v-select :items="shopArr"
                                                      v-model="params.shopID"
                                                      item-value="id"
                                                      item-text="name"
                                                      clearable
                                                      :label="$vuetify.lang.t('$vuetify.email.table.shop')"></v-select>
                                        </v-col>
                                        <v-col md="2">
                                            <v-text-field v-model="params.emailname"
                                                          :label="$vuetify.lang.t('$vuetify.email.table.name')"
                                                          append-outer-icon="search"
                                                          clearable></v-text-field>

                                        </v-col>
                                        <v-col md="2">
                                            <v-text-field v-model="params.username"
                                                          :label="$vuetify.lang.t('$vuetify.email.table.username')"
                                                          append-outer-icon="mdi-email"
                                                          clearable></v-text-field>

                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-flex>
                <v-flex md12>
                    <v-table :headers="headers"
                             :titleTable="titleTable"
                             :tableData="tableData"
                             :total="total"
                             @pageSize="handlePageSize"
                             @page="handlePage"
                             :loading="loading">
                        <template slot="select">
                            <v-btn dark
                                   color="primary"
                                   @click="dialogAdd = true">
                                {{$vuetify.lang.t('$vuetify.email.btn.add')}}
                            </v-btn>
                        </template>
                        <template slot="action"
                                  slot-scope="propData">
                            <a class="span"
                               @click="showEdit(propData.child.item)">
                                <v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}
                            </a>
                            <a class="span"
                               @click="showDelete(propData.child.item)">
                                <v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
                            </a>
                        </template>
                    </v-table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialogs :theme="$vuetify.lang.t('$vuetify.email.btn.add')"
                   :dialog="dialogAdd"
                   @dialogSure="handleEmitAdd">
            <v-form ref="addForm"
                    lazy-validation>
                <v-text-field v-model="addForm.name"
                              :label="$vuetify.lang.t('$vuetify.email.table.name')"
                              :rules="rules.name"></v-text-field>
                <v-text-field v-model="addForm.host"
                              :label="$vuetify.lang.t('$vuetify.email.placeholder.host')"
                              :rules="rules.host"></v-text-field>
                <v-text-field v-model="addForm.port"
                              :label="$vuetify.lang.t('$vuetify.email.placeholder.port')"
                              :rules="rules.port"></v-text-field>
                <v-text-field v-model="addForm.username"
                              :label="$vuetify.lang.t('$vuetify.email.table.username')"
                              :rules="rules.username"></v-text-field>
                <v-text-field v-model="addForm.password"
                              :label="$vuetify.lang.t('$vuetify.email.table.password')"
                              :rules="rules.password"></v-text-field>
                <v-select v-if="userType == 0"
                          v-model="addForm.groupID"
                          @change="handleGroupChange"
                          class="mt-6"
                          :items="groupArr"
                          item-value="id"
                          item-text="name"
                          :label="$vuetify.lang.t('$vuetify.email.table.group')"
                          :rules="rules.group"
                          dense></v-select>
                <v-select v-if="userType == 0 || userType == 1"
                          v-model="addForm.shopID"
                          class="mt-6"
                          :items="shopArr"
                          item-value="id"
                          item-text="name"
                          :label="$vuetify.lang.t('$vuetify.email.table.shop')"
                          :rules="rules.shop"
                          dense></v-select>
            </v-form>
        </v-dialogs>

        <v-dialogs :theme="$vuetify.lang.t('$vuetify.email.btn.delete')"
                   :dialog="dialogDelete"
                   @dialogSure="handleEmitDelete">
            <span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
        </v-dialogs>

        <v-dialogs :theme="$vuetify.lang.t('$vuetify.email.btn.edit')"
                   :dialog="dialogEdit"
                   @dialogSure="handleEmitEdit">
            <v-col cols="12"
                   sm="7"
                   md="7">
                <v-form ref="editForm"
                        lazy-validation>
                    <v-text-field v-model="editForm.name"
                                  :label="$vuetify.lang.t('$vuetify.email.table.name')"
                                  :rules="rules.name"></v-text-field>
                    <v-text-field v-model="editForm.host"
                                  :label="$vuetify.lang.t('$vuetify.email.table.host')"
                                  :rules="rules.host"></v-text-field>
                    <v-text-field v-model="editForm.port"
                                  :label="$vuetify.lang.t('$vuetify.email.table.port')"
                                  :rules="rules.port"></v-text-field>
                    <v-text-field v-model="editForm.username"
                                  :label="$vuetify.lang.t('$vuetify.email.table.username')"
                                  :rules="rules.username"></v-text-field>
                    <v-text-field v-model="editForm.password"
                                  :label="$vuetify.lang.t('$vuetify.userManage.user.table.passwordDefault')"
                                  :type="pwdShow ? 'text' : 'password'"
                                  :append-icon="pwdShow ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="pwdShow = !pwdShow"></v-text-field>
                </v-form>
            </v-col>
        </v-dialogs>
    </div>
</template>

<script>
import vDialogs from "./../../components/core/Dialog.vue";
import vTable from "./components/Table.vue";
import { Notification } from "element-ui";
import * as api from "./../../api";
import { EMAIL_REG, PORT_REG } from "../../utils/common";
export default {
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.name"),
                    value: "name",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.host"),
                    value: "host",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.port"),
                    value: "port",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.username"),
                    value: "username",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.group"),
                    value: "groupName",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.shop"),
                    value: "shopName",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.common.action"),
                    value: "action",
                },
            ],
            titleTable: this.$vuetify.lang.t("$vuetify.email.table.title"),
            tableData: [],
            params: {
                pageSize: 10,
                pageNum: 1,
                groupID: null,
                shopID: null,
                emailname: "",
                username: "",
            },
            total: 0,
            pwdShow: false,
            loading: false,
            dialogAdd: false,
            dialogDelete: false,
            dialogEdit: false,
            addForm: {
                name: "",
                host: "",
                port: "",
                username: "",
                password: "",
                shopID: 0,
                groupID: 0,
            },
            editForm: {
                id: "",
                name: "",
                host: "",
                port: "",
                username: "",
                password: "",
            },
            selectedID: "",
            groupArr: [],
            shopArr: [],
            userType: sessionStorage.getItem("type"),
            rules: {
                name: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.name"),
                ],
                host: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.host"),
                ],
                port: [
                    (value) => {
                        let result = PORT_REG.test(value);
                        return (
                            result ||
                            this.$vuetify.lang.t("$vuetify.email.rules.port")
                        );
                    },
                ],
                username: [
                    (value) => {
                        let result = EMAIL_REG.test(value);
                        return (
                            result ||
                            this.$vuetify.lang.t(
                                "$vuetify.email.rules.username"
                            )
                        );
                    },
                ],
                password: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.password"),
                ],
                group: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.group"),
                ],
                shop: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.shop"),
                ],
            },
        };
    },
    mounted() {
        if (this.userType == 0) {
            this.getGroupList();
        } else if (this.userType == 1) {
            this.getShopList();
        }
        this.emailList();
    },
    components: {
        vDialogs,
        vTable,
    },
    methods: {
        reset() {
            this.params.groupID = null;
            this.params.shopID = null;
            this.params.emailname = null;
            this.params.username = null;
            this.emailList();
        },
        handelGroupClear() {
            this.params.shopID = null;
            this.shopArr = [];
        },
        handleEmitEdit(params) {
            if (!params) {
                this.dialogEdit = false;
            } else {
                this.emailEdit();
            }
        },
        handleEmitDelete(params) {
            if (!params) {
                this.dialogDelete = false;
            } else {
                this.$http
                    .get(api.default.gateway.emailDelete+'?id='+this.selectedID)
                    .then((resp) => {
                        if (resp.errno == 0) {
                            this.selectedID = null;
                            this.dialogDelete = false;
                            this.emailList();
                            Notification({
                                type: "success",
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    });
            }
        },
        showEdit(e) {
            this.editForm.name = e.name;
            this.editForm.host = e.host;
            this.editForm.port = e.port;
            this.editForm.id = e.id;
            this.editForm.username = e.username;
            this.dialogEdit = true;
        },
        showDelete(e) {
            this.selectedID = e.id;
            this.dialogDelete = true;
        },
        handleGroupChange(event) {
            this.getShopList(event);
        },
        getShopList(groupID) {
            var params = { pagesize: 10000, pagenum: 1 };
            if (groupID) {
                params.groupID = groupID;
            }
            this.$http
                .post(api.default.rbac.shopListUrl, params)
                .then((resp) => {
                    this.shopArr = resp.data.list;
                })
                .finally((resp) => {});
        },
        getGroupList() {
            this.$http
                .post(api.default.rbac.groupListUrl)
                .then((resp) => {
                    this.groupArr = resp.data;
                })
                .finally((resp) => {});
        },
        emailEdit() {
            if (this.$refs.editForm.validate()) {
                this.$http
                    .post(api.default.gateway.emailEdit, this.editForm)
                    .then((resp) => {
                        if (resp.errno == 0) {
                            this.dialogEdit = false;
                            this.$refs.editForm.reset();
                            this.emailList();
                            Notification({
                                type: "success",
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    });
            }
        },
        emailAdd() {
            if (this.$refs.addForm.validate()) {
                this.$http
                    .post(api.default.gateway.emailAdd, this.addForm)
                    .then((resp) => {
                        if (resp.errno == 0) {
                            this.dialogAdd = false;
                            this.$refs.addForm.reset();
                            this.emailList();
                            Notification({
                                type: "success",
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    });
            }
        },
        formSpaces(str) {
            if (str !== null && str !== undefined && typeof(str) == 'string') {
                let res = str.replace(/\s*/g, "");
                return res;
            }
        },
        handleEmitAdd(params) {
            if (!params) {
                this.dialogAdd = false;
            } else {
                this.emailAdd();
            }
        },
        handlePageSize(data) {
            this.params.pageSize = data;
            this.emailList();
        },
        handlePage(data) {
            this.params.pageNum = data;
            this.emailList();
        },
        emailList() {
            this.loading = true;
            this.$http
                .post(api.default.gateway.emailList, this.params)
                .then((resp) => {
                    this.total = parseInt(resp.data.total);
                    if (resp.data.list == null){
                      this.tableData = [];
                    }else{
                      this.tableData = resp.data.list;
                    }
                    this.loading = false;
                })
                .finally((res) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
</style>