<template>
    <div>
        <material-dialog ref="tpl_edit" formTitle="删除模板" @handleSave="handleDel">
            <template slot="content">
                {{title}}
            </template>
        </material-dialog>
        <!-- 编辑模板信息 -->
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

    props: {
        type: Number
    },

    data () {
        return {
            loading: false,
            dialog: false,
            id: null,
            title: this.$vuetify.lang.t('$vuetify.portalManage.delTooltip')
        }
    },

    methods: {

        handleOpen (item) {
            this.id = item.id;
            this.dialog = true;
            this.$refs.tpl_edit.showDialog()
        },

        handleClose () {
            this.dialog = false;
            this.$refs.tpl_edit.closeDialog()
        },

        handleDel () {
            this.loading = true;
            var url = this.type === 0 ? api.default.portal.sdemoDeleteUrl : api.default.portal.udemoDeleteUrl;
            url = url + '?id=' + this.id;
            this.$http.get(url).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.$emit('initialize')
                this.$emit('handleSearch');
            }).finally(resp => {
                this.loading = false;
                this.handleClose();
            })
        }

    }
}
</script>