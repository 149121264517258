<template>

    <div class="udemo-layout">
        <demo-search @handleAdd="handleAdd" @handleSearch="handleSearch" :title="title" :search="search"
            :types="tpl.portal_type_list_orig" search_type="user"></demo-search>

        <demo-data-table :type="1" @handleSearch="handleSearch" @handleCopy="handleCopy" @handleEidt="handleEidt"
            @handleUpdate="handleUpdate" @handleDelete="handleDelete" :loading="loading" :data="data" :total="total"
            :search="search">
        </demo-data-table>

        <!-- <add-demo 
            ref="uadd" 
            :type="1"
            :demos="demos"
            :role="role"
            :group="group"
            @handleSearch="handleSearch"
        ></add-demo> -->

        <material-dialog ref="tpl_add" formTitle="添加系统模板" @handleSave="doTplAdd">
            <template slot="content">
                <v-form ref="tpl_add_form" lazy-validation>
                    <v-text-field v-model="tpl.add_dialog.data.name" label="模板名" clearable></v-text-field>
                    <v-autocomplete v-model="tpl.add_dialog.data.tpl_id" :items="demos" label="模板页面"
                        placeholder="请选择模板页面" item-text="name" item-value="id" clearable></v-autocomplete>
                    <v-combobox v-model="tpl.add_dialog.data.portal_type" :items="tpl.portal_type_list" chips clearable
                        label="模板类型" multiple>
                    </v-combobox>
                    <v-select :items="tpl.add_dialog.group_list" v-model="tpl.add_dialog.data.group_id" item-value="id"
                        item-text="name" clearable :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                        :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')" @change="changeGroup"></v-select>
                    <!-- 选择集团 -->
                    <v-select :items="tpl.add_dialog.shop_list" v-model="tpl.add_dialog.data.shop_id" item-value="id"
                        item-text="name" clearable @change="changeShop"
                        :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                        :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"></v-select>
                    <!-- 选择场所 -->
                </v-form>
            </template>
        </material-dialog>
        <!-- 添加模板 -->

        <del-demo ref="udel" :type="1" @handleSearch="handleSearch"></del-demo>

        <material-dialog ref="tpl_edit" formTitle="编辑模板信息" @handleSave="doTplUpdate">
            <template slot="content">
                <v-form ref="tpl_edit_form" lazy-validation>
                    <v-text-field v-model="tpl.edit_dialog.data.name" label="模板名" clearable></v-text-field>
                    <v-combobox v-model="tpl.edit_dialog.data.portal_type" :items="tpl.portal_type_list" chips clearable
                        label="模板类型" multiple>
                    </v-combobox>
                    <v-text-field v-model="tpl.edit_dialog.data.group_name" disabled label="集团名"></v-text-field>
                    <v-text-field v-model="tpl.edit_dialog.data.shop_name" disabled label="场所名"></v-text-field>
                </v-form>
            </template>
        </material-dialog>
        <!-- 编辑模板信息 -->
        <el-dialog title="请输入新的模板名称" :visible.sync="centerDialogVisible" width="30%">
            <el-form ref="form" :model="CopyFormData" label-width="80px">
                <el-form-item label="模板名称">
                    <el-input placeholder="请输入" v-model="CopyFormData.CopyInput" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ConfirmCopyBtn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api.js'
import DemoDataTable from './components/DemoDataTable.vue'
import DemoSearch from './components/DemoSearch.vue'
import AddDemo from './components/AddDemo.vue'
import DelDemo from './components/DelDemo.vue'
import { Notification } from 'element-ui'

export default {

    components: {
        DemoDataTable,
        DemoSearch,
        AddDemo,
        DelDemo,
    },

    data () {
      return {
            tpl:{
                add_dialog:{
                    data:{
                        name:"",
                        id:null,
                        group_id:null,
                        shop_id:null,
                        portal_type:[]
                    },
                    group_list:[],
                    shop_list:[]
                },
                edit_dialog:{
                    data:{
                        name:"",
                        id:null,
                        group_name:"",
                        shop_name:"",
                        portal_type:[]
                    }
                },
                portal_type_list:[],
                portal_type_list_orig:[]
            },

            title: this.$vuetify.lang.t('$vuetify.portalManage.demo.title.user'),

            role: parseInt(window.sessionStorage.getItem('type')),

            demos: [],

            data: [],

            loading: true,
            total  : 6,

            search: {
                pagesize : 8,
                pagenum  : 1,
                name     : null
            },
          centerDialogVisible: false,
          CopyFormData: {
            CopyInput:"",
          },
          FormData: {
              name: "",
              id: null,
              group_id: null,
              shop_id: null,
              portal_type: [],
          },
      }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
            this.getDemo();
            this.fetchGroupList();
            this.getTypeList()
        },

        getTypeList() {
            this.$http.get(api.portal.getPortalTypes).then(resp => {
                if (!resp || resp.errno != 0) return;
                var arr = []
                resp.data.forEach(e => {
                    arr.push(e.name)
                })
                this.tpl.portal_type_list = arr
                this.tpl.portal_type_list_orig = resp.data
            })
        },

        getDemo() {
            this.$http.post(api.portal.sdemoListUrl, {
                pagenum : 1,
                pagesize: 1000
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.demos = resp.data.list;
            })
        },

        getData () {
            this.loading = true;
            this.$http.post(api.portal.udemoListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                this.data = resp.data.list;
            }).finally(resp => {
                this.loading = false;
            })
        },

        handleAdd () {
            this.$refs["tpl_add"].showDialog()
        },
        handleCopy(item) {
            this.centerDialogVisible = true
            this.FormData.id = item.id
            this.FormData.group_id = item.group_id
            this.FormData.shop_id = item.shop_id
            for (let i of item.types) {
                this.FormData.portal_type.push(i.name)
            }
        },
        ConfirmCopyBtn() {
            if (this.CopyFormData.CopyInput != "") {
                this.FormData.name = this.CopyFormData.CopyInput
                this.$http.post(api.portal.udemoCopyAddUrl, this.FormData).then(resp => {
                    if (resp.errno !== 0)
                        return
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.common.success')
                    });
                    this.initialize()
                    this.$refs.tpl_add.closeDialog()
                }).finally(resp => {
                    this.centerDialogVisible = false
                    this.CopyFormData.CopyInput = ""
                })
            } else {
                this.$message({
                    message: '新的模板名称不能为空',
                    type: 'warning'
                });
            }
        },
        handleEidt(item) {
            var url = '/admin/portal/editor/index?portal_id=' + item.id + '&role=page';
            window.open(url, '_blank');
        },

        handleDelete (item) {
            this.$refs.udel.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        },
        
        handleUpdate(item){
            this.tpl.edit_dialog.data.name = item.name
            this.tpl.edit_dialog.data.id = item.id
            this.tpl.edit_dialog.data.group_name = item.group_name
            if(item.shop_name == null)
            {
                this.tpl.edit_dialog.data.shop_name = "所有场所"
            }else
            {
                this.tpl.edit_dialog.data.shop_name = item.shop_name
            }
            var arr = []
            if(item.types.length > 0)
            {
                item.types.forEach(e => {
                    arr.push(e.name)
                })
            }
            this.tpl.edit_dialog.data.portal_type = arr
            this.$refs["tpl_edit"].showDialog()
        },

        doTplUpdate() {
            this.$http.post(api.portal.udemoEditUrl, this.tpl.edit_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.tpl_edit_form.reset()
                this.initialize()
                this.$refs.tpl_edit.closeDialog()
            }).finally(resp => {})
        },

        doTplAdd() {
            this.$http.post(api.portal.udemoAddUrl, this.tpl.add_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                console.log(resp)
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.tpl_add_form.reset()
                this.initialize()
                this.$refs.tpl_add.closeDialog()
            }).finally(resp => {})
        },

        fetchGroupList(){
            this.$http.post(api.rbac.groupListUrl).then(resp => {
                
                this.tpl.add_dialog.group_list = resp.data

            }).finally(resp => {

            })
        },
        
        changeGroup(groupid){
            if(groupid == null || typeof(groupid) == 'undefined'){
                this.tpl.add_dialog.data.group_id = null
                this.tpl.add_dialog.data.shop_id = null
                this.tpl.add_dialog.shop_list = []
            }else
            {
                this.fetchShopList(groupid)
            }
        },
        
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.rbac.shopListUrl,postData).then(resp => {
                
                this.tpl.add_dialog.shop_list = resp.data.list

            }).finally(resp => {
            })
        },

        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.tpl.add_dialog.data.shop_id = null
        },

    }
}
</script>

<style scoped>

.udemo-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}
.portal-template-span{
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
}

</style>