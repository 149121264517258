<template>
	<div id="Device">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-card>
						<v-row>
							<v-col cols="2" sm="2" md="2">
								<v-toolbar :flat="true">
									<v-toolbar-title>{{$vuetify.lang.t('$vuetify.device.selectTitle')}}</v-toolbar-title>
									<v-divider class="mr-0 ml-4" inset vertical></v-divider>
								</v-toolbar>
							</v-col>
							<v-col cols="5" sm="5" md="5">
								<v-row>
									<v-col cols="4" sm="4" md="4">
										<v-select @change="handleProChange" v-model="auditParams.provinceCode" class="mt-3" :items="itemsPro" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaPro')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaPro')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select @change="handleCityChange" v-model="auditParams.cityCode" class="mt-3" :items="itemsCity" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaCity')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaCity')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleAreaChange" v-model="auditParams.areaCode" class="mt-3" :items="itemsArea" item-value="code" item-text="name" :label="$vuetify.lang.t('$vuetify.gateway.label.areaArea')" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.areaArea')" dense></v-select>
									</v-col>
									
								</v-row>
								<v-row>
									<v-col cols="4" sm="4" md="4">
										<v-text-field class="mt-0" v-model="auditParams.name" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.name')" :label="$vuetify.lang.t('$vuetify.gateway.label.name')" dense  append-outer-icon="search" @click:append-outer="handleAreaChange()"></v-text-field>
									</v-col>
									<v-col cols="4" sm="4" md="4">
											<v-text-field class="mt-0" v-model="auditParams.mac" :placeholder="$vuetify.lang.t('$vuetify.gateway.placeholder.mac')" :label="$vuetify.lang.t('$vuetify.gateway.label.mac')"  @click:append-outer="handleAreaChange()" append-outer-icon="search" dense></v-text-field>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleAreaChange" v-model="auditParams.type" class="mt-0" :items="itemsType" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.type')" :label="$vuetify.lang.t('$vuetify.device.label.type')" dense></v-select>
										
									</v-col>
								</v-row>
								
								<v-row>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleAreaChange" v-model="auditParams.auditCount" class="mt-0" :items="itemsStatus3" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.status3')" :label="$vuetify.lang.t('$vuetify.device.label.status3')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select v-model="auditParams.sniffCount" class="mt-0" :items="itemsStatus4" item-value="value"  @change="handleAreaChange" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.status4')" :label="$vuetify.lang.t('$vuetify.device.label.status4')" dense></v-select>
									</v-col>
									<v-col cols="4" sm="4" md="4">
										<v-select  @change="handleAreaChange" v-model="auditParams.auditPlatformStatus" class="mt-0" :items="itemsStatus5" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.status5')" :label="$vuetify.lang.t('$vuetify.device.label.status5')" dense></v-select>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="2" sm="2" md="2">
								<v-select  v-if="sessionType == 0"   @change="handleGroupChange" v-model="auditParams.groupID" class="mt-6" :items="itemsCompany" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.device.label.company')" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.company')" dense></v-select>
								<v-select  @change="handleAreaChange" v-model="auditParams.baseInfoStatus" class="mt-6" :items="itemsStatus1" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.status1')" :label="$vuetify.lang.t('$vuetify.device.label.status1')" dense></v-select>
								<v-select  @change="handleAreaChange" v-model="auditParams.authCount" class="mt-6" :items="itemsStatus2" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.status2')" :label="$vuetify.lang.t('$vuetify.device.label.status2')" dense></v-select>
								
							</v-col>
							<v-col cols="2" sm="2" md="2">
								<v-select  v-if="sessionType == 1||sessionType == 0"  :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.place')"  @change="handleAreaChange" v-model="auditParams.shopID" class="mt-6" :items="itemsPlace" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.device.label.place')" dense></v-select>
							</v-col>
						</v-row>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-container fill-height fluid grid-list-xl class="app-container">
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table @selectData="handleSelectKey" :showSelect="showSelect" :titleTable="titleTable" :headers="headers" :tableData='tableData' :pages="auditParams.pageNum" @pageSize="handlePageSize" @page="handlePage" :loading="loading" :pageSize="auditParams.pageSize" :total="auditParams.total">
						<template slot="select">
							<v-btn @click="showAdd" dark color="primary">
								{{$vuetify.lang.t('$vuetify.device.table.add')}}
							</v-btn>
							
						</template>
						<template slot="action" slot-scope="propData">
							
							<a class="span" @click="showEdit(propData.child.item)">
								<v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}
							</a>
							<a class="span" @click="showDelete(propData.child.item)">
								<v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
							</a>
						</template>
					</v-table>
					<v-dialogs :theme="themeAdd" :dialog="dialogAdd" @dialogSure="handleEmitAdd">
                        <v-form
                        ref="handleEmitAdd_form"
                        lazy-validation>
                            <v-col cols="12" sm="7" md="7" v-if="dialogAdd">
                                <v-text-field 
                                    :rules="nameRule"  
                                    :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.name')" 
                                    v-model="defaultItem.name" 
                                    @change="defaultItem.name=formSpaces(defaultItem.name)" 
                                    :label="$vuetify.lang.t('$vuetify.device.label.name')"
                                ></v-text-field>
                                <v-text-field 
                                    :rules="macRule"  
                                    @change="defaultItem.mac=formSpaces(defaultItem.mac)" 
                                    :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.mac')" 
                                    v-model="defaultItem.mac" :label="$vuetify.lang.t('$vuetify.device.label.mac')"
                                ></v-text-field>
                                <v-select v-model="defaultItem.type" class="mt-4" :items="itemsType" item-value="value" item-text="label" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.type')" :label="$vuetify.lang.t('$vuetify.device.label.type')"  dense></v-select>
                                <v-select v-if="sessionType == 0" v-model="defaultItem.groupID" class="mt-3" :items="itemsCompany" item-value="id" @change="handleAddGroupChange" item-text="name" :label="$vuetify.lang.t('$vuetify.device.label.company')" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.company')" dense></v-select>
                                <v-select  v-if="sessionType == 1||sessionType == 0" :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.place')" v-model="defaultItem.shopID" class="mt-3" :items="itemsAddPlace" item-value="id" item-text="name" :label="$vuetify.lang.t('$vuetify.device.label.place')" dense></v-select>
                            </v-col>
                        </v-form>
					</v-dialogs>
					<v-dialogs :theme="themeEdit" :dialog="dialogEdit" @dialogSure="handleEmitEdit">
                        <v-form
                        ref="handleEmitEdit_form"
                        lazy-validation>
                            <v-col cols="12" sm="7" md="7">
                                <v-text-field 
                                :rules="nameRule"  
                                @change="editItem.name=formSpaces(editItem.name)" 
                                :placeholder="$vuetify.lang.t('$vuetify.device.placeholder.name')" 
                                v-model="editItem.name" 
                                :label="$vuetify.lang.t('$vuetify.device.label.name')"
                                ></v-text-field>
                            </v-col>
                        </v-form>
					</v-dialogs>
					<v-dialogs :theme="themeDelete" :dialog="dialogDelete" @dialogSure="handleEmitDelete">
						<v-col cols="12" sm="7" md="7">
							<span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
						</v-col>
					</v-dialogs>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	import { Notification } from 'element-ui';
	export default {
		data() {
			return {
				showSelect:false,
				themeAdd: this.$vuetify.lang.t('$vuetify.device.table.add'),
				themeEdit: this.$vuetify.lang.t('$vuetify.device.btn.edit'),
				themeDelete: this.$vuetify.lang.t('$vuetify.device.btn.delete'),
				dialogAdd: false,
				dialogEdit: false,
				dialogDelete: false,
				titleTable: this.$vuetify.lang.t('$vuetify.device.title'),
				headers: [
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.pro'),
						align: 'left',
						value: 'provinceName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.city'),
						align: 'left',
						value: 'cityName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.area'),
						align: 'left',
						value: 'areaName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.company'),
						align: 'left',
						value: 'groupName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.place'),
						align: 'left',
						value: 'shopName'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.name'),
						align: 'left',
						value: 'name',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.type'),
						align: 'left',
						value: 'type'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.mac'),
						align: 'left',
						value: 'mac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.status1'),
						align: 'left',
						value: 'base_info_status'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.status2'),
						align: 'left',
						value: 'auth_count'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.status3'),
						align: 'left',
						value: 'audit_count'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.status4'),
						align: 'left',
						value: 'sniff_count'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.status5'),
						align: 'left',
						value: 'audit_platform_status'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableData: [],
				defaultItem: {},
				editItem: {},
				deleteItem: {},
				nameRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.device.rule.name'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.device.rule.name')
                ],
				macRule: [
                    v => this.formSpaces(v)!='' || this.$vuetify.lang.t('$vuetify.device.rule.mac'),
                    v => !!v || this.$vuetify.lang.t('$vuetify.device.rule.mac')
                ],
				itemsPro: [],
				itemsCity: [],
				itemsArea: [],
				itemsCompany: [],
				itemsPlace: [],
				itemsAddPlace: [],
				itemsType: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.type1'),
						value: 1,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.type2'),
						value: 2,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.type3'),
						value: 3,
					},
				],
				itemsStatus1: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus0'),
						value: 0,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus1'),
						value: 1,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus2'),
						value: 2,
					}
				],
				itemsStatus2: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.null'),
						value: 0,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.big'),
						value: 1,
					},
				],
				itemsStatus3: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.null'),
						value: 0,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.big'),
						value: 1,
					},
				],
				itemsStatus4: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.null'),
						value: 1,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.big'),
						value: 2,
					},
				],
				itemsStatus5: [{
						label: this.$vuetify.lang.t('$vuetify.device.txt.status1'),
						value: 1,
					},
					{
						label: this.$vuetify.lang.t('$vuetify.device.txt.status2'),
						value: 0,
					},
				],
				selectKeyData: [],
				showExpand: true,
				auditParams:{
					pageSize: 10, // 每页返回记录数
				    pageNum: 1,   // 页数
				    provinceCode: null,  // 可选，省
				    cityCode: null,  // 可选，市
				    areaCode: null,    // 可选，区
				    groupID: null,   // 可选，集团ID
				    shopID: null, // 可选，场所ID
				    name: null, // 可选，名称
				    mac: null, // 可选，MAC
				    type: null,    // 可选，类型，1-单审计设备，2-单嗅探设备，3-审计嗅探设备
				    baseInfoStatus: null, // 可选，报备状态，0-未报备，1-报备中，2-已报备
				    authCount: null, // 可选，0-真实身份数为0，1-真实身份数大于0
				    auditCount: null, // 可选，0-虚拟身份数为0，1-虚拟身份数大于0
				    sniffCount: null, // 可选，0-嗅探数为0，1-嗅探数大于0
				    auditPlatformStatus: null,
				    total:0,
				},
				loading:true,
				sessionType:sessionStorage.getItem('type'),
			}
		},
		watch: {

		},
		props: [

		],
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.placeListUrl('pro');
			if(this.sessionType == '0'){
				this.groupListUrl();
			}else if(this.sessionType == '1'){
				var params = {pagesize:10000,pagenum:1};
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
					this.itemsPlace = resp.data.list;
					this.itemsAddPlace = resp.data.list;
				}).finally(resp => {})	
			}
			this.deviceList();
		},
		methods: {

            validateMac () {
                if(!this.defaultItem.mac){
                    Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.common.formatmac')
                    })
                    return 0
                }else{
                    let status = 1
                    let mac = this.defaultItem.mac.split("\n");
                    let maczz = /^[A-Fa-f\d]{2}[A-Fa-f\d]{2}[A-Fa-f\d]{2}[A-Fa-f\d]{2}[A-Fa-f\d]{2}[A-Fa-f\d]{2}$/
                    mac.forEach(element => {
                        if (!maczz.test(element)) {
                            status = 0
                            return
                        }
                    });
                    if(status == 0){
                        Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.common.formatmac')
                        })
                    }
                    return status
                }
                
            },

            formSpaces(str){
                if(str !== null && str !== undefined){
                    let res = str.replace(/\s*/g,'')
                    return res;
                }
            },

			shopListUrl(type){
				if(type == 'add'){
					var params = {pagesize:10000,pagenum:1,groupID:this.defaultItem.groupID};
				}else{
					var params = {pagesize:10000,pagenum:1,groupID:this.auditParams.groupID};
				}
				this.$http.post(api.default.rbac.shopListUrl,params).then(resp => {
					if(type == 'add'){
						this.itemsAddPlace = resp.data.list;
					}else{
						this.itemsPlace = resp.data.list;
					}
				}).finally(resp => {})	
			},
			groupListUrl(){
				this.$http.get(api.default.rbac.groupListUrl).then(resp => {
					this.itemsCompany = resp.data;
				}).finally(resp => {})	
			},
			handleAddGroupChange(){
				this.shopListUrl('add');
			},
			handleGroupChange(){
				this.shopListUrl();
				this.deviceList();
			},
			handleAreaChange(){
				this.deviceList();
			},
			handleCityChange(){
				this.placeListUrl('area',{areacode:this.auditParams.cityCode});
				this.deviceList();
			},
			handleProChange(){
				this.placeListUrl('city',{areacode:this.auditParams.provinceCode});
				this.deviceList();
			},
			placeListUrl(type,params){
				if(type=='pro'){
					params = {};
				}
				this.$http.post(api.default.system.placeListUrl,params).then(resp => {
					if(type=='pro'){
						this.itemsPro = resp.data;
					}else if(type=='city'){
						this.itemsCity = resp.data;
					}else if(type=='area'){
						this.itemsArea = resp.data;
					}
					
				}).finally(resp => {})	
			},
			showLook(data){
				this.$router.push({'path':'/net_safety/device_list'});
			},
			handleSelectKey(data) {
				this.selectKeyData = data;
			},
			handleEmitAdd(params) {
                if(!params) return this.dialogAdd = !this.dialogAdd;
                if (this.$refs.handleEmitAdd_form.validate()) {

                    if(this.validateMac()==0){
                        return
                    }
                    
                    if(!this.defaultItem.type||this.defaultItem.type =="")return Notification({
                        type: 'warning',
                        message: this.$vuetify.lang.t('$vuetify.device.rule.type')
                    });
                    if(this.sessionType == 0){
                        if(!this.defaultItem.groupID||this.defaultItem.groupID =="")return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.device.rule.group')
                        });
                        if(!this.defaultItem.shopID||this.defaultItem.shopID =="")return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.device.rule.shop')
                        });
                    }
                    if(this.sessionType == 1){
                        if(!this.defaultItem.shopID||this.defaultItem.shopID =="")return Notification({
                            type: 'warning',
                            message: this.$vuetify.lang.t('$vuetify.device.rule.shop')
                        });
                    }
                    this.$http.post(api.default.audit.deviceAdd,this.defaultItem).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogAdd = !this.dialogAdd;
                            this.deviceList();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})	
                }
			},
			
			
			showAdd() {
				this.defaultItem.name=null;
				this.defaultItem.mac=null;
				this.defaultItem.type=null;
				this.defaultItem.groupID=null;
				this.defaultItem.shopID=null;
				this.dialogAdd = !this.dialogAdd;
			},
			handleEmitEdit(params) {
				if(!params) {
                    this.deviceList();
                    return this.dialogEdit = !this.dialogEdit;
                }
                if (this.$refs.handleEmitEdit_form.validate()) {
                    var paramsData={
                        id: parseInt(this.editItem.id),
                            name: this.editItem.name,
                    };
                    this.$http.post(api.default.audit.deviceEdit,paramsData).then(resp => {
                        if(resp.errno == '0'){
                            this.dialogEdit = !this.dialogEdit;
                            this.deviceList();
                            Notification({
                                type: 'success',
                                message: this.$vuetify.lang.t('$vuetify.common.success')
                            });
                        }
                    }).finally(resp => {})	
                }
			},
			showEdit(data) {
				this.editItem = data;
				this.dialogEdit = !this.dialogEdit;
			},
			handleEmitDelete(params) {
				if(!params) return this.dialogDelete = !this.dialogDelete;
				this.$http.get(api.default.audit.deviceDelete+'?id='+this.deleteItem.id).then(resp => {
					if(resp.errno == '0'){
						this.dialogDelete = !this.dialogDelete;
						this.deviceList();
						Notification({
							type: 'success',
							message: this.$vuetify.lang.t('$vuetify.common.success')
						});
					}
				}).finally(resp => {})	
			},
			showDelete(data) {
				this.deleteItem = data;
				this.dialogDelete = !this.dialogDelete;
			},
			handlePage(data){
				this.auditParams.pagenum = data;
				this.deviceList();
			},
			handlePageSize(data){
				this.auditParams.pagesize = data;
				this.deviceList();
			},
			deviceList(){
				this.loading = true;
				this.$http.post(api.default.audit.deviceList,this.auditParams).then(resp => {
					this.auditParams.total = parseInt(resp.data.total);
					var tableData = resp.data.data;
					for(var i=0;i<tableData.length;i++){
						if(tableData[i].type == '1'){
							tableData[i].type = this.$vuetify.lang.t('$vuetify.device.txt.type1');
						}else if(tableData[i].type == '2'){
							tableData[i].type = this.$vuetify.lang.t('$vuetify.device.txt.type2');
						}else if(tableData[i].type == '3'){
							tableData[i].type = this.$vuetify.lang.t('$vuetify.device.txt.type3');
						}
						
						if(tableData[i].base_info_status == '0'){
							tableData[i].base_info_status = this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus0');
						}else if(tableData[i].base_info_status == '1'){
							tableData[i].base_info_status = this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus1');
						}else if(tableData[i].base_info_status == '2'){
							tableData[i].base_info_status = this.$vuetify.lang.t('$vuetify.device.txt.baseInfoStatus2');
						}
						
						if(tableData[i].audit_count == '0'){
							tableData[i].audit_count = this.$vuetify.lang.t('$vuetify.device.txt.null');
						}else if(tableData[i].audit_count == '1'){
							tableData[i].audit_count = this.$vuetify.lang.t('$vuetify.device.txt.big');
						}
						
						if(tableData[i].auth_count == '0'){
							tableData[i].auth_count = this.$vuetify.lang.t('$vuetify.device.txt.null');
						}else if(tableData[i].auth_count == '1'){
							tableData[i].auth_count = this.$vuetify.lang.t('$vuetify.device.txt.big');
						}
						
						
						if(tableData[i].sniff_count == '0'){
							tableData[i].sniff_count = this.$vuetify.lang.t('$vuetify.device.txt.null');
						}else if(tableData[i].sniff_count == '1'){
							tableData[i].sniff_count = this.$vuetify.lang.t('$vuetify.device.txt.big');
						}
						
						if(tableData[i].audit_platform_status == '0'){
							tableData[i].audit_platform_status = this.$vuetify.lang.t('$vuetify.device.txt.status2');
						}else if(tableData[i].audit_platform_status == '1'){
							tableData[i].audit_platform_status = this.$vuetify.lang.t('$vuetify.device.txt.status1');
						}
						
						
					}
					this.tableData = tableData;
					this.loading = false;
				}).finally(resp => {})	
			},
		}
	}
</script>

<style language="scss">
	.uploadbox {
		position: relative;
		font-size: 14px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		overflow: hidden;
		cursor: pointer;
	}
	
	.uploadbox .upload {
		width:64px;
		height: 36px;
		position: absolute;
		top: -8px;
		bottom: 0;
		left: -16px;
		right: 0;
		opacity: 0;
	}
	
	.add {
		display: block;
	}
</style>