<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title[tab] }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.systemManage.place.table.code')" 
                                        v-model="data.areacode"
                                        disabled
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.systemManage.place.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        required
                                    ></v-text-field>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.systemManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleUpdate" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.systemManage.update')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

    props: {
        tab: Number
    },

    data () {
        return {

            title: [
                this.$vuetify.lang.t('$vuetify.systemManage.place.editBtn.province'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.editBtn.city'),
                this.$vuetify.lang.t('$vuetify.systemManage.place.editBtn.area')
            ],

            loading: false,

            dialog: false,

            data: {
                areacode: null,
                name: null
            },

            rules: {
                areacode: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.systemManage.place.rules.code')
                ],
                name: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.systemManage.place.rules.name')
                ]
            }
            
        }
    },

    methods: {

        handleOpen (item) {
            this.data = this.newObject(item);
            this.dialog = true;
        },

        handleClose () {
            this.dialog = false;
            this.$refs.form.reset();
        },

        handleUpdate () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.post(api.default.system.placeEditUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        newObject (item) {
            var data = JSON.stringify(item);
            return JSON.parse(data)
        }

    }
}
</script>

<style scoped>



</style>