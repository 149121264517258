<template>
    <div>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                        </v-toolbar-title>
                        <v-divider class="mr-4 ml-4"
                                   inset
                                   vertical></v-divider>
                        <v-btn color="primary ml-3"
                               dark
                               @click="search">
                            <span>
                                {{$vuetify.lang.t('$vuetify.common.search')}}
                            </span>
                        </v-btn>
                        <v-btn color="primary ml-3"
                               @click="reset"
                               dark>
                            <span>
                                {{$vuetify.lang.t('$vuetify.common.reset')}}
                            </span>
                        </v-btn>
                    </v-toolbar>
                    <v-card-subtitle>
                        <v-row>
                            <!-- 选择集团 -->
                            <v-col md="2"
                                   v-if="userType == 0">
                                <v-select :items="groupArr"
                                          v-model="searchParams.groupID"
                                          item-value="id"
                                          item-text="name"
                                          clearable
                                          @input="handleGroupChange"
                                          :label="$vuetify.lang.t('$vuetify.email.table.group')"></v-select>
                            </v-col>
                            <!-- 选择场所 -->
                            <v-col md="2"
                                   v-if="userType == 0 || userType == 1">
                                <v-select :items="shopArr"
                                          v-model="searchParams.shopID"
                                          item-value="id"
                                          item-text="name"
                                          clearable
                                          :label="$vuetify.lang.t('$vuetify.email.table.shop')"></v-select>
                            </v-col>
                            <!-- <v-col md="2">
                                <v-text-field v-model="params.emailname"
                                              :label="$vuetify.lang.t('$vuetify.email.table.name')"
                                              append-outer-icon="search"
                                              clearable></v-text-field>

                            </v-col> -->
                        </v-row>
                    </v-card-subtitle>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        userType: String,
        groupArr: Array,
        shopArr: Array,
        searchParams: Object,
    },
    methods: {
        reset() {
            this.searchParams.groupID = 0;
            this.searchParams.shopID = 0;
            this.search();
        },
        search() {
            this.$emit("getLdapList");
        },
        handleGroupChange(event) {
            this.$emit("handleGroupChange", event);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>