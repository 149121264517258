<template>
    <div id="v-table">
        <v-data-table 
            :headers="columns" 
            :items="data" 
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            sort-by="calories"
            class="elevation-1"
            style="margin-bottom:50px"
        >
            <template v-slot:item.apis="{ item }">
                <div v-for="api in item.apis">
                    <v-chip label class="ma-2">
                        <span style="color: #000;font-weight: 400;">
                            {{ api }}
                        </span>
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.action="{ item }">
                <slot name='action' :value='{ item }'></slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            options: {}
        }
    },

    props: {
        loading : Boolean,
        columns : Array,
        data    : Array,
        total   : Number,
        search  : Object
    },

    methods: {

        handleSearch () {
            this.search.pagesize = this.options.itemsPerPage === -1 ? 15 : this.options.itemsPerPage;
            this.search.pagenum  = this.options.page;
            this.$emit('handleSearch');
        },

    },

    watch: {

        options: {
            handler (newValue, oldValue) {
                if (oldValue.page !== undefined && newValue !== oldValue) {
                    this.handleSearch();
                }
            },
            deep: true,
        },
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}
 
#v-table .v-card--material__header {
    width: 175px;
}

#v-table .v-btn__content {
    /* color: #000; */
}
</style>