<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="500px">
                <v-card>
                    <v-card-text>

                        <v-container>

                            <div style="margin-top: 10px;">
                                <el-button-group>
                                    <el-button 
                                        size="medium" 
                                        :type="datetype === 'time' ? 'primary' : ''" 
                                        @click="datetype = 'time'"
                                    >
                                        范围时间
                                    </el-button>
                                    <el-button 
                                        size="medium" 
                                        :type="datetype === 'multi' ? 'primary' : ''" 
                                        @click="datetype = 'multi'"
                                    >
                                        重复时间
                                    </el-button>
                                </el-button-group>
                            </div>

                            <div style="margin-top: 10px;" v-if="datetype === 'time'">
                                <el-date-picker
                                    v-model="time"
                                    type="datetimerange"
                                    range-separator="~"
                                    start-placeholder="Start Date"
                                    end-placeholder="End Date"
                                ></el-date-picker>
                            </div>

                            <div style="margin-top: 10px;" v-if="datetype === 'multi'">
                                <el-button-group>
                                    <el-button 
                                        size="small" 
                                        :type="multitype === 'day' ? 'primary' : ''" 
                                        @click="multitype = 'day'"
                                    >
                                        每天
                                    </el-button>
                                    <el-button 
                                        size="small" 
                                        :type="multitype === 'week' ? 'primary' : ''" 
                                        @click="multitype = 'week'"
                                    >
                                        每星期
                                    </el-button>
                                    <el-button 
                                        size="small" 
                                        :type="multitype === 'month' ? 'primary' : ''" 
                                        @click="multitype = 'month'"
                                    >
                                        每月
                                    </el-button>
                                    <el-button 
                                        size="small" 
                                        :type="multitype === 'year' ? 'primary' : ''" 
                                        @click="multitype = 'year'"
                                    >
                                        每年
                                    </el-button>
                                </el-button-group>

                                <div style="margin-top: 10px;" v-if="multitype === 'year'">
                                    <el-input v-model="mInit" placeholder="input: 09-11"/>
                                </div>

                                <div style="margin-top: 10px;" v-if="multitype !== 'day' && multitype !== 'year'">
                                    <el-select v-model="mInit">
                                        <el-option 
                                            v-for="(v,k) in multiDate"
                                            :key="k"
                                            :label="v.label"
                                            :value="v.value"
                                        />
                                    </el-select>
                                </div>

                                <div style="margin-top: 10px;">
                                    <el-time-picker
                                        is-range
                                        v-model="multi"
                                        range-separator="~"
                                        start-placeholder="Start Time"
                                        end-placeholder="End Time"
                                    ></el-time-picker>
                                </div>
                            </div>
                        </v-container>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="handleClose">
                                {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                            </v-btn>
                            <v-btn color="primary" @click="handleSave">
                                {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

export default {

    data () {
        return {

            dialog: false,

            data: null,

            datetype: 'time',

            multitype: 'day',

            time : [],

            mInit: null,
            multi: [new Date(), new Date()],
        }
    },

    computed: {
        multiDate () {
            var data = [];
            switch (this.multitype) {
                case 'week':
                    var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                    for (var i = 0; i < 7; i++) {
                        data[i] = {
                            label: week[i],
                            value: '' + i
                        }
                    }
                    break;
                case 'month': 
                    for (var i = 0; i < 31; i++) {
                        data[i] = {
                            label: (i + 1) + '日',
                            value: '' + (i + 1)
                        }
                    }
                    break;
            }
            this.mInit = null;
            return data;
        },
    },

    methods: {

        handleOpen (item) {
            this.data = item;
            this.dialog = true;
        },

        handleClose () {
            this.datetype = 'time';
            this.multitype = 'day';
            this.time = [];
            this.mInit = null;
            this.multi = [new Date(), new Date()];
            this.dialog = false;
        },

        handleSave () {
            if (this.datetype === 'time') {
                this.setTimeData();
            } else {
                this.setMultiData();
            }
            this.handleClose();
        },

        setTimeData () {
            this.data.push({
                name: 'time',
                type: null,
                value: this.getDate(this.time[0]) + ',' + this.getDate(this.time[1])
            });
        },

        setMultiData () {
            var mInit = this.mInit === null ? '' : (this.mInit + ',');
            this.data.push({
                name: 'multi',
                type: this.multitype,
                value: mInit + this.getTime(this.multi[0]) + ',' + this.getTime(this.multi[1])
            });
        },

        getDate (value) {
            var Y = value.getFullYear();
            var M = value.getMonth() + 1;
            var D = value.getDate();
            var h = value.getHours();
            var m = value.getMinutes();
            var s = value.getSeconds();
            return Y + '-' + (M < 10 ? '0' + M : M) + '-' + (D < 10 ? '0' + D : D) + ' ' + (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
        },

        getTime (value) {
            var h = value.getHours();
            var m = value.getMinutes();
            var s = value.getSeconds();
            return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
        }
    }
}
</script>