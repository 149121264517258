<template>
    <div>

        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    
                    <v-btn v-if="role !== 2" color="primary" @click="handleAdd">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.role.insertBtn') }}
                        </span>
                    </v-btn>
                    
                    <v-btn color="primary" @click="handleSearch"  v-if="role === 0">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.searchBtn') }}
                        </span>
                    </v-btn>
                </v-toolbar>

                <div class="layout-search"  v-if="role === 0">
                    <v-row justify="start">
                        <div class="layout-select">
                            <v-select
                                v-if="role === 0"
                                :items="group"
                                :label="$vuetify.lang.t('$vuetify.userManage.role.table.groupName')"
                                item-text="name"
                                item-value="id"
                                v-model="search.groupID"
                                @change="changeSelect"
                                dense
                                solo
                                clearable
                            ></v-select>
                        </div>
                    </v-row>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>

export default {

    data () {
        return {
            city: [],
            area: []
        }
    },

    props: {
        title : String,
        role  : Number,
        group : Array,
        search: Object
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

        handleSearch () {
            this.$emit('handleSearch');
        },

        changeSelect () {
            this.$emit('handleSearch');
        }

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

</style>