<template>
    
    <div>
        <v-row>
                <v-col xl="3" md="4" sm="6" xs="12" v-for="(item, index) in data" :key="index">
                    <portal-card
                        :item="item"
                        :search="search"
                        :loading="loading"
                        :type="type"
                        @handleSearch="handleSearch"
                        @handleCopy="handleCopy"
                        @handleEidt="handleEidt"
                        @handleUpdate="handleUpdate"
                        @handleDelete="handleDelete"
                    >
                    </portal-card>
                </v-col>
        </v-row>


  <div class="text-center" style="margin-bottom:20px">
    <v-pagination
      v-model="pagenum"
      :length="length"
      :total-visible="totalVisible"
      @input="handleSearch"
      style="margin-top:20px"
    ></v-pagination>
  </div>

    </div>
</template>

<script>

import PortalCard from './PortalCard.vue'

export default {

    data () {
        return {
            totalVisible: 6,
            pagesize: 8,
            pagenum: 1
        }
    },

    components: {
        PortalCard
    },

    props: {
        type   : Number,
        loading: Boolean,
        data   : Array,
        total  : Number,
        search : Object
    },

    computed: {
        length () {
            var value = Math.ceil(this.total / this.pagesize);
            return value;
        }   
    },

    methods: {

        handleSearch (value) {
            if (this.search.pagenum !== value) {
                this.search.pagesize = this.pagesize;
                this.search.pagenum  = value;
                this.$emit('handleSearch');
            }
        },
        handleCopy(item) { 
            this.$emit('handleCopy', item)
        },
        handleUpdate(item) {
           this.$emit('handleUpdate', item)
        },
        handleEidt(item) {
            this.$emit('handleEidt', item)
        },
        handleDelete(item){
            this.$emit('handleDelete', item)
        }
        
    }
}
</script>