<template>
    <div>

        <v-card class="mx-auto" outline>
            <div>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    
                    <v-btn v-if="role !== 2" color="primary" @click="handleAdd">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.site.insertBtn') }}
                        </span>
                    </v-btn>
                    
                    <v-btn color="primary" @click="handleSearch">
                        <span style="color:#fff">
                        {{ $vuetify.lang.t('$vuetify.userManage.searchBtn') }}
                        </span>
                    </v-btn>
                </v-toolbar>

                <div class="layout-search">
                    <v-row justify="start">
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.userManage.site.table.name')"
                                v-model="search.name"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-input">
                            <v-text-field
                                dense
                                :placeholder="$vuetify.lang.t('$vuetify.userManage.site.table.contact')"
                                v-model="search.contact"
                                outlined
                                clearable
                            ></v-text-field>
                        </div>
                        <div class="layout-select"  v-if="role === 0">
                            <v-select
                                v-if="role === 0"
                                :items="group"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.groupName')"
                                item-text="name"
                                item-value="id"
                                v-model="search.groupID"
                                dense
                                outlined
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="place"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.provinceName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.provincecode"
                                @change="changeCity" 
                                dense
                                outlined
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="city"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.cityName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.citycode"
                                @change="changeArea" 
                                dense
                                outlined
                                clearable
                            ></v-select>
                        </div>
                        <div class="layout-select">
                            <v-select
                                :items="area"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.areaName')"
                                item-text="name"
                                item-value="code"
                                v-model="search.areacode"
                                dense
                                outlined
                                clearable
                            ></v-select>
                        </div>

                        <div class="layout-select">
                            <v-select
                                :items="status"
                                :label="$vuetify.lang.t('$vuetify.userManage.site.table.status')"
                                item-text="name"
                                item-value="value"
                                v-model="search.radacct_status"
                                dense
                                outlined
                                clearable
                            ></v-select>
                        </div>

                    </v-row>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import * as api from '../../../api'

export default {

    data () {
        return {
            city: [],
            area: [],
        }
    },

    props: {
        title : String,
        role  : Number,
        group : Array,
        place : Array,
        search: Object,
        status: Array,
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

        handleSearch () {
            this.$emit('handleSearch');
        },

        changeCity (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.city = resp.data;
                this.search.citycode = null;
                this.area = [];
                this.search.areacode = null;
            })
        },

        changeArea (value) {
            this.$http.post(api.default.system.placeListUrl, {
                areacode: value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.area = resp.data;
                this.search.areacode = null;
            })
        }

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

</style>