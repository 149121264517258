<template>
	<div id="Devices">
		<v-container fill-height fluid grid-list-xl class="app-container">
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-table 					
						@selectData="handleSelectKey" 
						:showSelect="showSelect" 
						:titleTable="titleTable" 
						:headers="headers" 
						:tableData='tableData'>
						<template slot="select">
							<v-btn dark color="primary" @click="showAdd">
								{{$vuetify.lang.t('$vuetify.gateway.btn.remote')}}
							</v-btn>
						</template>
						<template slot="action" slot-scope="propData">
							<!--<a class="span" @click="showGo(propData.child.item)">
								<v-icon small>mdi-keyboard-tab</v-icon>{{$vuetify.lang.t('$vuetify.gateway.btn.used')}}
							</a>-->
							<a class="span" @click="showLook(propData.child.item)">
								<v-icon small>mdi-eye-outline</v-icon>{{$vuetify.lang.t('$vuetify.gateway.btn.look')}}
							</a>
						</template>
					</v-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vDialogs from './../../components/core/Dialog.vue'
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	export default {
		data() {
			return {
				showSelect:false,
				themeAdd:this.$vuetify.lang.t('$vuetify.gateway.btn.add'),
				titleTable: this.$vuetify.lang.t('$vuetify.gateway.title'),
				headers: [
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.name'),
						align: 'left',
						value: 'name'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.table.instructions'),
						align: 'left',
						value: 'command'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.common.action'),
						value: 'action',
						sortable: false
					},
				],
				tableData: [],
				defaultItem: {},
				handleSelectKey:[],
			}
		},
		watch: {

		},
		props: [

		],
		components: {
			vTable,
			vDialogs
		},
		mounted() {
			this.commandList();
		},
		methods: {
			commandList(){
				this.$http.get(api.default.gateway.commandList).then(resp => {
					this.tableData = resp.data
				}).finally(resp => {})	
			},
//			showGo(data){
//				this.$router.push({
//					path:'/gateway/instructions_add',
//					query:{
//						name:data.name,
//						command:data.command
//					}
//				})
//			},
			showAdd() {
				this.$router.push({'path':'/gateway/instructions_add'})
			},
			showLook(data){
				this.$router.push({
					path:'/gateway/instructions_info',
					query:{
						id:data.id,
						name:data.command
					}
				})
			},
		}
	}
</script>

<style language="scss">
	.uploadbox {
		position: relative;
		font-size: 14px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		overflow: hidden;
		cursor: pointer;
	}
	
	.uploadbox .upload {
		width:64px;
		height: 36px;
		position: absolute;
		top: -8px;
		bottom: 0;
		left: -16px;
		right: 0;
		opacity: 0;
	}
	
	.add {
		display: block;
	}
</style>