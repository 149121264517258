<template>
    <div>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{ $vuetify.lang.t('$vuetify.radacctStatistic.selectTitle') }}</span>
                        </v-toolbar-title>
                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                        <v-btn color="primary ml-3" dark @click="search">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.common.search') }}
                            </span>
                        </v-btn>
                        <v-btn color="primary ml-3" @click="reset" dark>
                            <span>
                                {{ $vuetify.lang.t('$vuetify.common.reset') }}
                            </span>
                        </v-btn>
                        <v-btn color="primary ml-3" dark @click="exportStatement"
                            :loading="loading">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.radacctStatistic.exportBtn') }}
                            </span>
                        </v-btn>
                    </v-toolbar>
                    <v-card-subtitle>
                        <v-row>
                            <!-- 选择集团 -->
                            <v-col md="2" v-if="userType == 0">
                                <v-select 
                                    :items="groupArr" 
                                    v-model="searchParams.group_id" 
                                    item-value="id" 
                                    item-text="name"
                                    clearable 
                                    @change="handleGroupChange"
                                    :label="$vuetify.lang.t('$vuetify.radacctStatistic.jt')"
                                    :placeholder="$vuetify.lang.t('$vuetify.radacctStatistic.pickjt')"></v-select>
                            </v-col>

                            <!-- 选择场所 -->
                            <v-col md="2" v-if="userType == 0 || userType == 1">
                                <v-select :items="shopArr" 
                                    v-model="searchParams.shop_id" 
                                    item-value="id" 
                                    item-text="name"
                                    :label="$vuetify.lang.t('$vuetify.radacctStatistic.placeName')"
                                    @change="handleShopChange"
                                    :placeholder="$vuetify.lang.t('$vuetify.radacctStatistic.pick_placename')"></v-select>
                            </v-col>

                            <!-- 选择查询时间段 -->
                            <v-col md="4" >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="searchDate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="searchDate"
                                            :label="$vuetify.lang.t('$vuetify.radacctStatistic.pickDateRange')"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="searchDate"
                                        range scrollable
                                        type="month"
                                        :show-current="true"
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">{{ $vuetify.lang.t('$vuetify.common.cancelBtn') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(searchDate)">{{ $vuetify.lang.t('$vuetify.common.sureBtn') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { parseTime } from '../../../utils/common';
import moment from 'moment';
export default {
    props: {
        userType: String,
        groupArr: Array,
        shopArr: Array,
        searchParams: Object,
        loading:Boolean,
    },
    data() {
        return {
            menu: false,
            modal: false,
            searchDate:[this.searchParams.search_start_date, this.searchParams.search_stop_date],
        };
    },

    methods: {
        reset() {
            this.searchParams.group_id = 0;
            this.searchParams.shop_id = 0;
            this.search();
        },
        search() {
            this.searchParams.search_start_date = this.searchDate[0]
            this.searchParams.search_stop_date = this.searchDate[1]
            this.$emit("getStatisticsData"); 
        },
        exportStatement(){
            this.$emit("exportStatement");
        },
        handleGroupChange(event) {
            this.searchParams.shop_id = 0;
            this.$emit("handleGroupChange", event);
        },
        handleShopChange(event) {
            this.$emit("handleShopChange", event);
        },
    },
};
</script>

<style lang="scss" scoped></style>