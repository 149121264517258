<template>
	<div id="Instructions">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
					<v-card>
						<v-table @selectData="handleSelectKey" :titleTable="titleTable" :headers="headers" :tableData='tableData'>
							<template slot="select">
								<a @click="handleReturn">{{this.$route.query.name}}</a>
							</template>
						</v-table>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
	</div>
</template>

<script>
	import vTable from './components/Table.vue'
	import * as api from './../../api'
	export default {
		data() {
			return {
				titleTable:this.$vuetify.lang.t('$vuetify.gateway.title_instructions'),
				headers:[
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.instructions.equipment'),
						align: 'left',
						value: 'gatewayName',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.gateway.instructions.status'),
						align: 'left',
						value: 'status'
					},
				],
				tableData:[],
			}
		},
		props: [],
		components: {
			vTable,
		},
		mounted(){
			this.commandGetdetail();
		},
		methods: {
			commandGetdetail(){
				this.$http.get(api.default.gateway.commandGetdetail+'?id='+this.$route.query.id).then(resp => {
					this.tableData = resp.data;
					for(var i=0;i<this.tableData.length;i++){
						if(this.tableData[i].status == '0'){
							this.tableData[i].status = this.$vuetify.lang.t('$vuetify.gateway.implementCancel');
						}else if(this.tableData[i].status == '1'){
							this.tableData[i].status = this.$vuetify.lang.t('$vuetify.gateway.implementOk');
						}
					}
				}).finally(resp => {})	
			},
			handleReturn(data){
				this.$router.push({path:'/gateway/remote_order'})
			},
			handleSelectKey(){
				
			}
		},
	}
</script>
</script>

<style>
#Instructions .chartBox {
	width: 600px;
	margin: 0 auto;
	padding-bottom: 40px;
}
</style>