<template>
    <v-container>
        <v-row justify="center">
            <v-col>
                <v-card>
                    <v-toolbar flat color="primary" dark>
                        <v-toolbar-title id="tp-toolbar-title">类型列表</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn class="ml-2" icon @click="showTypeAdd">
                            <v-icon>mdi-plus-thick</v-icon>
                        </v-btn>
                        <v-btn class="ml-2" icon @click="showTypeDels">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-chip-group v-model="del_dialog.data.ids" active-class="primary--text" multiple column>
                            <v-chip
                                @click:close="showTypeDel(tag)"
                                close
                                label
                                outlined
                                v-for="tag in tags"
                                :key="tag.id">
                                {{ tag.name }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <material-dialog ref="type_add" formTitle="添加模板类型" @handleSave="doTypeAdd">
            <template slot="content">
                <v-form ref="type_add_form" lazy-validation>
                    <v-text-field v-model="add_dialog.data.name" label="模板类型名" clearable></v-text-field>
                </v-form>
            </template>
        </material-dialog>
        <!-- 添加类型 -->
        <material-dialog ref="type_del" formTitle="删除模板类型" @handleSave="doTypeDel">
            <template slot="content">
                确认是否删除《{{del_dialog.single_data.name}}》这个类型？
            </template>
        </material-dialog>
        <!-- 删除类型 -->
        <material-dialog ref="type_dels" formTitle="删除所有选中模板类型" @handleSave="doTypeDels">
            <template slot="content">
                确认是否删除所有选中的类型？
            </template>
        </material-dialog>
        <!-- 删除所有选中类型 -->
    </v-container>
</template>

<script>
import api from '@/api.js'
import { Notification } from 'element-ui'

export default {

    components: {
    },

    data () {
      return {
          tags:[],
          add_dialog:{
              data:{
                  name:""
              }
          },
          del_dialog:{
              data:{
                  ids:[]
              },
              single_data:{
                  id:null,
                  name:""
              }
          }
      }
    },

    mounted : function() {
        this.getTags()
    },

    methods: {
        getTags(){
            this.$http.get(api.portal.getPortalTypes).then(resp => {
                if(resp.errno !== 0)
                    return
                this.tags = resp.data
            }).finally(resp => {})
        },
        showTypeDels(){
            this.$refs.type_dels.showDialog()
        },
        doTypeDels(){
            if (this.del_dialog.data.ids.length == 0) {
                Notification({
                    type: 'warning',
                    message: "无选中的模板类型"
                });
                return
            }
            var ids = []
            this.del_dialog.data.ids.forEach(e => {
                ids.push(this.tags[e].id)
            })
            this.$http.post(api.portal.delPortalType, {id:ids}).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.getTags()
                this.$refs.type_dels.closeDialog()
            }).finally(resp => {})
        },
        showTypeDel(tag){
            this.del_dialog.single_data = tag
            this.$refs.type_del.showDialog()
        },
        doTypeDel(){
            this.$http.post(api.portal.delPortalType, {id:[this.del_dialog.single_data.id]}).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.getTags()
                this.$refs.type_del.closeDialog()
            }).finally(resp => {})
        },
        showTypeAdd(){
            this.$refs.type_add.showDialog()
        },
        doTypeAdd(){
            this.$http.post(api.portal.addPortalType, this.add_dialog.data).then(resp => {
                if(resp.errno !== 0)
                    return
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.common.success')
                });
                // 重置表单内容
                this.$refs.type_add_form.reset()
                this.getTags()
                this.$refs.type_add.closeDialog()
            }).finally(resp => {})
        }
    }
}
</script>

<style scoped>
    #tp-toolbar-title{
        color: white;
    }
</style>