<template>
    
    <div class="user-layout">

        <user-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title ="title"
            :role  ="role"
            :group ="group"
            :place ="place"
            :search="search"
        ></user-search>

        <user-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >

            <template v-slot:action="action">
                <v-row justify="start">

                        <v-icon color="primary" small @click="handleStatus(action.value.item)">loop</v-icon>
                        <span class="table-btn-span" @click="handleStatus(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.userManage.user.statusBtn') }}
                        </span>
                        
                        <div class="mx-1"></div>

                        <v-icon color="primary" small @click="handleVlan(action.value.item)">edit</v-icon>
                        <span class="table-btn-span" @click="handleVlan(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.userManage.user.allowList') }}
                        </span>
                        
                        <div class="mx-1"></div>

                        <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                        <span class="table-btn-span" @click="handleEidt(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.userManage.editBtn') }}
                        </span>

                        <div class="mx-1"></div>

                        <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                        <span class="table-btn-span" @click="handleDelete(action.value.item)">
                            {{ $vuetify.lang.t('$vuetify.userManage.deleteBtn') }}
                        </span>
                
                </v-row>
            </template>
        </user-data-table>

        <add-user 
            ref="add" 
            :role="role" 
            :group="group"
            @handleSearch="handleSearch"
        ></add-user>

        <edit-user 
            ref="edit"
            :group="group"
            @handleSearch="handleSearch"
        ></edit-user>
        
        <edit-vlan 
            ref="vlan"
            :group="group"
            @handleSearch="handleSearch"
        ></edit-vlan>

        <del-user ref="del" @handleSearch="handleSearch"></del-user>
    </div>
</template>

<script>
import * as api from '../../api'
import { Notification } from 'element-ui';
import UserSearch from './components/UserSearch.vue'
import UserDataTable from './components/UserDataTable.vue'
import AddUser   from './components/AddUser.vue'
import EditUser  from './components/EditUser.vue'
import EditVlan  from './components/EditVlan.vue'
import DelUser   from './components/DelUser.vue'

export default {

    components: {
        UserSearch,
        UserDataTable,
        AddUser,
        EditUser,
        EditVlan,
        DelUser,
    },

    data () {
      return {

            role: parseInt(window.sessionStorage.getItem('type')),

            title: this.$vuetify.lang.t('$vuetify.userManage.user.title'),

            group: [],
            place: [],

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.username'),
                    value: 'username'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.name'),
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.contact'),
                    value: 'contact'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.type.text'),
                    value: 'type'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.status.text'),
                    value: 'status'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.roleName'),
                    value: 'roleName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.shopName'),
                    value: 'shopName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.groupName'),
                    value: 'groupName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.user.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],

            data: [],

            loading: false,
            total  : 0,

            search: {
                pagesize : 10,
                pagenum  : 1,
                username : null,
                name     : null,
                contact  : null,
                groupID  : null,
                shopID   : null,
                provincecode: null,
                citycode : null,
                areacode : null,
                status   : null
            }
          
      }
    },

    created () {
        this.initialize();
    },

    methods: {

        initialize () {
            this.getData();
            this.getGroup();
            this.getPlace();
        },

        getPlace () {
            this.$http.post(api.default.system.placeListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.place = resp.data;
            })
        },

        getGroup () {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.rbac.accountListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp);
                this.total = parseInt(resp.data.total);
                if (resp.data.list==null){
                  this.data  = [];
                }else{
                  this.data  = resp.data.list;
                }

            }).finally(resp => {
                this.loading = false;
            })
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleVlan(item){
            this.$refs.vlan.handleOpen(item)
        },

        handleStatus (item) {
            // item.status = item.status === '0' ? 1 : 0;
            this.$http.post(api.default.rbac.accountSetStatusUrl, item).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.handleSearch();
            }).finally(resp => {
                this.handleSearch();
            })
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }
    }
}
</script>

<style scoped>

.user-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>