<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <material-table
          ref="sdk_list"
          :title="sdk.table.title"
          :url="sdk.table.api"
          :columns="sdk.table.headers"
          :data="sdk.table.list"
          :filter="sdk.table.filter"
          :searchValue="sdk.table.searchValue"
        >
          <template slot="title">
            <v-btn color="primary" class="ml-5" @click="showScanDialog">
              检测列表新增
            </v-btn>
          </template>
          <!-- title -->
          <template slot="body"> </template>
          <!-- search -->
          <template slot="action" slot-scope="data">
            <a class="span" @click="showSdkDel(data.value.item)"
              ><v-icon small>mdi-trash-can-outline</v-icon> 删除</a
            >
          </template>
          <!-- action -->
        </material-table>
        <!-- 短信接口列表 -->
        <material-dialog
          ref="sdkscan"
          formTitle="检测列表新增"
          @handleSave="doScan"
        >
          <template slot="content"> 确认是否检测？ </template>
        </material-dialog>
        <!-- 检测确认框 -->
        <material-dialog
          ref="sdkdel"
          formTitle="删除确认框"
          @handleSave="doDel"
        >
          <template slot="content"> 确认是否删除？<span style="color:red;">使用本接口的短信网关将无法正常使用</span> </template>
        </material-dialog>
        <!-- 删除确认框 -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import bus from "../../utils/bus.js";
import Echarts from "echarts";
import api from "@/api.js";
import { Notification } from "element-ui";
// import CsvExport from './../../utils/CsvExport'
export default {
  data() {
    return {
      sdk: {
        table: {
          title: this.$vuetify.lang.t("$vuetify.apiManagement.tableName"),
          api: api.sms.sdkList,
          searchValue: 0,
          list: [],
          filter: {},
          headers: [
            {
              text: this.$vuetify.lang.t("$vuetify.apiManagement.name"),
              value: "name",
            },
            {
              text: this.$vuetify.lang.t("$vuetify.apiManagement.sdkVersion"),
              value: "version",
            },
            {
              text: "SDK标识",
              value: "ns",
            },
            {
              text: this.$vuetify.lang.t("$vuetify.apiManagement.action"),
              value: "action",
            },
          ],
        },
        del_dialog:{
          ns:""
        }
      },
    };
  },

  computed: {},
  watch: {},

  created() {},
  mounted() {
    this.$refs["sdk_list"].getDataFromApi();
  },
  methods: {
    /* 检测列表是否存在新增数据 */
    showScanDialog() {
      this.$refs["sdkscan"].showDialog();
    },
    doScan() {
      this.$http
        .get(api.sms.sdkListScan)
        .then((resp) => {
          if (resp.errno !== 0) return;
          Notification({
            type: "success",
            message: this.$vuetify.lang.t("$vuetify.common.success"),
          });
          this.$refs["sdk_list"].getDataFromApi()
          this.$refs["sdkscan"].closeDialog();
        })
        .finally((resp) => {});
    },
    showSdkDel(item) {
      this.sdk.del_dialog.ns = item.ns
      this.$refs["sdkdel"].showDialog();
    },
    doDel(){
      this.$http
        .get(api.sms.sdkDelete+"?ns="+this.sdk.del_dialog.ns)
        .then((resp) => {
          if (resp.errno !== 0) return;
          Notification({
            type: "success",
            message: this.$vuetify.lang.t("$vuetify.common.success"),
          });
          this.$refs["sdk_list"].getDataFromApi()
          this.$refs["sdkdel"].closeDialog();
        })
        .finally((resp) => {});
    }
  },
};
</script>

<style scoped>
.main_lay {
  margin: 0 24px;
  min-height: 90%;
  padding-bottom: 20px;
}
</style>