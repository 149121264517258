<template> 
    <div class="main_lay">
        <el-dialog title="Mwan Top" :visible.sync="dialogShowMwan" :before-close="handleclose" width="42%">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-form
                        :inline="true" 
                        ref="form" size="small">
                    <el-form-item label="时间范围">
                        <el-date-picker
                        @change="list(true)"
                        v-model="valuemonth"
                        type="month"
                        placeholder="选择月"
                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item >
                        <el-select v-model="bytestype" placeholder="请选择" style="width: 120px;" @change="list(true)">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-form>
                </div>
                <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%">
                    <el-table-column
                    prop="gateway_name"
                    label="设备名称">
                    </el-table-column>
                    <el-table-column
                    prop="infname"
                    label="网口名称">
                    </el-table-column>
                    <el-table-column
                    label="使用流量">
                    <template slot-scope="scope">
                        {{toBytes(scope.row.bytes)}}
                    </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="text-align:center;margin:10px 0;"
                    @size-change="list(true)" 
                    @current-change="list(false)" 
                    :current-page.sync="currentPage" 
                    :page-size.sync="pageSize" 
                    layout="total, sizes, prev, pager, next, jumper" 
                    :total="localtotal">
                </el-pagination>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
import * as api from './../../../api'

export default {
    data () {
        return {
            dialogShowMwan:false,
            tableData:[],
            bytestype:1,
            localtotal:0,
            // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 10,
            valuemonth:"",
            form:{
                pagesize:10,
                pagenum:1,
                start_time:"",
                stop_time:"",
                type:1
            },
            options:[
                {
                    value: 1,
                    label: '下行流量'
                }, {
                    value: 2,
                    label: '上行流量'
                }
            ],
        }
    },
    methods: {
        open(){
            this.dialogShowMwan=true
            var year = new Date().getFullYear()
            var mon = new Date().getMonth()+1
            this.valuemonth = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + "01";
            
            this.list(true)
        },
        list(val){
            if(val){
                this.currentPage=1
            }
            this.form.pagenum=this.currentPage
            this.form.pagesize=this.pageSize
            this.form.type = this.bytestype

            const date = new Date(this.valuemonth.slice(0, 4), parseInt(this.valuemonth.slice(5, 7)), 1);
            
            this.form.start_time = this.valuemonth
            this.form.stop_time = date.toISOString().slice(0, 10)

            let that = this
            this.$http.post(api.default.gateway.ordermwannetlist,this.form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    that.localtotal = res.data.total
                    that.tableData = res.data.list
                }
            })
        },
        handleclose(){
            this.dialogShowMwan=false
        },
        toBytes(bytes){
            var mbyte = 1048576
            var outstring = "0.00 KB"
            var kbytesfloat = bytes/(mbyte/1024)
            if (kbytesfloat < 1024 ){
                return  (kbytesfloat).toFixed(2)+" KB"
            }
            var mbytesfloat = bytes / mbyte
            if (mbytesfloat > 1024) {
                var gbytesfloat = mbytesfloat / 1024
                outstring = (gbytesfloat).toFixed(2)+" G"
            } else {
                outstring = (mbytesfloat).toFixed(2)+" MB"
            }
            return outstring
        },
    }
}
</script>