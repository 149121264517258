<template>
    <div id="Email">
        <v-container fill-height
                     fluid
                     grid-list-xl
                     class="app-container">
            <v-layout justify-center
                      wrap>
                <v-flex md12>
                    <ldap-search :userType="userType"
                                 :groupArr="groupArr"
                                 :shopArr="shopArr"
                                 :searchParams="searchParams"
                                 @handleGroupChange="handleGroupChange"
                                 @getLdapList="getLdapList"></ldap-search>
                </v-flex>
                <v-flex md12>
                    <v-table :headers="headers"
                             :titleTable="$vuetify.lang.t('$vuetify.ldap.table.title')"
                             :tableData="ldapList"
                             :total="total"
                             @pageSize="handlePageSize"
                             @page="handlePage"
                             :loading="loading">
                        <template slot="select">
                            <v-btn dark
                                   color="primary"
                                   @click="dialogAdd = true">
                                {{$vuetify.lang.t('$vuetify.ldap.btn.add')}}
                            </v-btn>
                        </template>
                        <template slot="action"
                                  slot-scope="propData">
                            <a class="span"
                               @click="showEdit(propData.child.item)">
                                <v-icon small>mdi-pencil</v-icon>{{$vuetify.lang.t('$vuetify.common.editBtn')}}
                            </a>
                            <a class="span"
                               @click="showDelete(propData.child.item)">
                                <v-icon small>mdi-trash-can-outline</v-icon>{{$vuetify.lang.t('$vuetify.common.deleteBtn')}}
                            </a>
                        </template>
                    </v-table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialogs :theme="$vuetify.lang.t('$vuetify.ldap.table.editTitle')"
                   :dialog="dialogEdit"
                   @dialogSure="handleEmitEdit">
            <v-col cols="12"
                   sm="7"
                   md="12">
                <v-form ref="editForm"
                        lazy-validation>
                    <v-text-field v-model="editForm.name"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.name')"
                                  :rules="rules.name"></v-text-field>
                    <v-text-field v-model="editForm.host"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.host')"
                                  :rules="rules.host"></v-text-field>
                    <v-text-field type="number" v-model="editForm.port"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.port')"
                                  :rules="rules.port"></v-text-field>
                    <v-text-field v-model="editForm.admin_dn"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.admin_dn')"
                                  :rules="rules.admin_dn"></v-text-field>
                    <v-text-field v-model="editForm.admin_pwd"
                                  :label="$vuetify.lang.t('$vuetify.userManage.user.table.passwordDefault')"></v-text-field>
                    <v-text-field v-model="editForm.base_dn"
                                  :label="'base_dn'"
                                  :rules="rules.base_dn"></v-text-field>
                    <v-text-field v-model="editForm.filter"
                                  :label="'filter'"
                                  :rules="rules.filter"></v-text-field>
                </v-form>
            </v-col>
        </v-dialogs>

        <v-dialogs :theme="$vuetify.lang.t('$vuetify.ldap.table.addTitle')"
                   :dialog="dialogAdd"
                   @dialogSure="handleEmitAdd">
            <v-col cols="12">
                <v-form ref="addForm"
                        lazy-validation>
                    <v-text-field v-model="addForm.name"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.name')"
                                  :rules="rules.name"></v-text-field>
                    <v-text-field v-model="addForm.host"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.host')"
                                  :rules="rules.host"></v-text-field>
                    <v-text-field v-model="addForm.port"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.port')"
                                  :rules="rules.port"></v-text-field>
                    <v-text-field v-model="addForm.admin_dn"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.admin_dn')"
                                  :rules="rules.admin_dn"></v-text-field>
                    <v-text-field v-model="addForm.admin_pwd"
                                  :label="$vuetify.lang.t('$vuetify.ldap.table.admin_pwd')"
                                  :rules="rules.admin_pwd"></v-text-field>
                    <v-text-field v-model="addForm.base_dn"
                                  :label="'base_dn'"
                                  :rules="rules.base_dn"></v-text-field>
                    <v-text-field v-model="addForm.filter"
                                  :label="'filter'"
                                  :rules="rules.filter"></v-text-field>
                    <v-select v-if="userType == 0"
                              v-model="addForm.groupID"
                              @change="handleGroupChange"
                              class="mt-6"
                              :items="groupArr"
                              item-value="id"
                              item-text="name"
                              :rules="rules.group"
                              :label="$vuetify.lang.t('$vuetify.email.table.group')"
                              dense></v-select>
                    <v-select v-if="userType == 0 || userType == 1"
                              v-model="addForm.shopID"
                              class="mt-6"
                              :items="shopArr"
                              item-value="id"
                              item-text="name"
                              :rules="rules.shop"
                              :label="$vuetify.lang.t('$vuetify.email.table.shop')"
                              dense></v-select>
                </v-form>
            </v-col>
        </v-dialogs>

        <v-dialogs theme="Ldap删除"
                   :dialog="dialogDelete"
                   @dialogSure="handleEmitDelete">
            <span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
        </v-dialogs>
    </div>
</template>

<script>
import api from "../../api";
import vDialogs from "./../../components/core/Dialog.vue";
import vTable from "./components/Table.vue";
import LdapSearch from "./components/LdapSearch.vue";
import { PORT_REG } from "../../utils/common";
export default {
    data() {
        return {
            selectedID: 0,
            dialogDelete: false,
            headers: [
                {
                    text: this.$vuetify.lang.t("$vuetify.ldap.table.name"),
                    value: "ldap_name",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.ldap.table.host"),
                    value: "host",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.ldap.table.port"),
                    value: "port",
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.email.table.group'),
                    value: "group_name",
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.email.table.shop'),
                    value: "shop_name",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.common.action"),
                    value: "action",
                },
            ],
            ldapList: [],
            searchParams: {
                pageSize: 10,
                pageNum: 1,
                groupID: 0,
                shopID: 0,
            },
            total: 0,
            loading: false,
            dialogEdit: false,
            dialogAdd: false,
            userType: sessionStorage.getItem("type"),
            groupArr: [],
            shopArr: [],
            editForm: {
                id: 0,
                name: "",
                host: "",
                port: "",
                admin_dn: "",
                admin_pwd: "",
                base_dn: "",
                filter: "",
            },
            addForm: {
                name: "",
                host: "",
                port: 0,
                admin_dn: "",
                admin_pwd: "",
                base_dn: "",
                filter: "",
                groupID: null,
                shopID: null,
            },
            rules: {
                name: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.name"),
                ],
                host: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.email.rules.host"),
                ],
                port: [
                    (value) => {
                        let result = PORT_REG.test(value);
                        return (
                            result ||
                            this.$vuetify.lang.t("$vuetify.email.rules.port")
                        );
                    },
                ],
                admin_dn: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.ldap.rules.admin_dn"),
                ],
                admin_pwd: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.ldap.rules.admin_pwd"),
                ],
                base_dn: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.ldap.rules.base_dn"),
                ],
                filter: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.ldap.rules.filter"),
                ],
                group: [
                    (value) =>
                        value != null ||
                        this.$vuetify.lang.t("$vuetify.email.rules.group"),
                ],
                shop: [
                    (value) =>
                        value != null ||
                        this.$vuetify.lang.t("$vuetify.email.rules.shop"),
                ],
            },
        };
    },
    components: {
        vTable,
        LdapSearch,
        vDialogs,
    },
    mounted() {
        if (this.userType == 0) {
            this.getGroupList();
        } else if (this.userType == 1) {
            this.getShopList();
        }
        this.getLdapList();
    },
    methods: {
        handleEmitDelete(params) {
            if (!params) {
                this.dialogDelete = false;
            } else {
                this.$http
                    .post(api.aaa.ldapDelete, { id: this.selectedID })
                    .then((res) => {
                        if (res.errno == 0) {
                            this.$notify.success({
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                            this.dialogDelete = false;
                            this.getLdapList();
                        }
                    });
            }
        },
        showDelete(e) {
            this.selectedID = e.id;
            this.dialogDelete = true;
        },
        formSpaces(str) {
            if (str !== null && str !== undefined) {
                let res = str.replace(/\s*/g, "");
                return res;
            }
        },
        handleEmitAdd(params) {
            if (!params) {
                this.dialogAdd = false;
            } else {
                this.ldapAdd();
            }
        },
        handleGroupChange(event) {
            this.getShopList(event);
        },
        handleEmitEdit(params) {
            if (!params) {
                this.dialogEdit = false;
            } else {
                this.ldapEdit();
            }
        },
        getShopList(groupID) {
            var params = { pagesize: 10000, pagenum: 1 };
            if (groupID) {
                params.groupID = groupID;
            }
            this.$http
                .post(api.rbac.shopListUrl, params)
                .then((resp) => {
                    this.shopArr = resp.data.list;
                })
                .finally((resp) => {});
        },
        getGroupList() {
            this.$http
                .post(api.rbac.groupListUrl)
                .then((resp) => {
                    this.groupArr = resp.data;
                })
                .finally((resp) => {});
        },
        ldapAdd() {
            if (this.$refs.addForm.validate()) {
                this.addForm.port = parseInt(this.addForm.port)
                this.$http.post(api.aaa.ldapAdd, this.addForm).then((res) => {
                    if (res.errno == 0) {
                        this.$notify.success({
                            message: this.$vuetify.lang.t(
                                "$vuetify.common.success"
                            ),
                        });
                        this.$refs.addForm.reset();
                        this.dialogAdd = false;
                        this.getLdapList();
                    }
                });
            }
        },
        ldapEdit() {
            if (this.$refs.editForm.validate()) {
                this.$http.post(api.aaa.ldapEdit, this.editForm).then((res) => {
                    if (res.errno == 0) {
                        this.$notify.success({
                            message: this.$vuetify.lang.t(
                                "$vuetify.common.success"
                            ),
                        });
                        this.dialogEdit = false;
                        this.getLdapList();
                    }
                });
            }
        },
        showEdit(event) {
            this.dialogEdit = true;
            this.editForm.id = event.id;
            this.editForm.name = event.ldap_name;
            this.editForm.host = event.host;
            this.editForm.port = event.port;
            this.editForm.admin_dn = event.admin_dn;
            this.editForm.base_dn = event.base_dn;
            this.editForm.filter = event.filter;
        },
        handlePageSize(data) {
            this.searchParams.pageSize = data;
            this.getLdapList();
        },
        handlePage(data) {
            this.searchParams.pageNum = data;
            this.getLdapList();
        },
        getLdapList() {
            this.loading = true;
            this.$http
                .post(api.aaa.ldapList, this.searchParams)
                .then((res) => {
                    if (res.errno == 0) {
                        this.ldapList = res.data.list;
                        this.total = parseInt(res.data.total);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>