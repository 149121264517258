<template>
    <v-card :loading="loading" class="portal-template-card">

        <v-card-text>
            <v-img :src="setImage(item)" class="white--text align-end portal-template-preview" height="300px"></v-img>
            <!-- 预览图 -->
        </v-card-text>

        <v-divider style="margin-top: 10px; border-color: #bebebe;"></v-divider>

        <v-card-title v-text="item.name"></v-card-title>

        <!-- <v-card-text>
            <p>所属集团：
                <span v-if="item.groupName">
                    {{item.groupName}}
                </span>
                <span v-else>
                    所有
                </span>
            </p>
            <p>所属场所：
                <span v-if="item.shopName">
                    {{item.shopName}}
                </span>
                <span v-else>
                    所有
                </span>
            </p>
        </v-card-text> -->

        <v-card-actions>
            <v-row style="margin:0px" justify="end">
                <v-icon color="primary" small @click="handleCopy(item)">edit</v-icon>
                <span class="table-btn-span portal-template-span" @click="handleCopy(item)">
                    {{ $vuetify.lang.t('$vuetify.portalManage.copyBtn') }}
                </span>
                <v-icon color="primary" small @click="handleUpdate(item)">edit</v-icon>
                <span class="table-btn-span portal-template-span" @click="handleUpdate(item)">
                    {{ $vuetify.lang.t('$vuetify.portalManage.editBtn') }}
                </span>

                <div class="mx-1"></div>

                <v-icon color="primary" small @click="handleEidt(item)">edit</v-icon>
                <span class="table-btn-span portal-template-span" @click="handleEidt(item)">
                    {{ $vuetify.lang.t('$vuetify.portalManage.designBtn') }}
                </span>

                <div class="mx-1"></div>

                <v-icon color="primary" small @click="handleDelete(item)">delete</v-icon>
                <span class="table-btn-span portal-template-span" @click="handleDelete(item)">
                    {{ $vuetify.lang.t('$vuetify.portalManage.deleteBtn') }}
                </span>

            </v-row>
        </v-card-actions>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="tag_show = !tag_show">
                模板类型
            </v-btn>

            <v-btn icon @click="tag_show = !tag_show">
                <v-icon>{{ tag_show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="tag_show">
                <v-divider></v-divider>

                <v-card-text>
                    <v-chip v-for="type in item.types" :key="type.id" color="primary" class="ma-2" label
                        outlined>{{type.name}}</v-chip>
                </v-card-text>
                <!-- 模板类型列表 -->
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>

export default {

    data () {
        return {
            tag_show:false
        }
    },

    props: {
        type   : Number,
        item   : Object,
        search : Object,
        loading: Boolean
    },

    methods: {
        setImage (item) {
            var type = this.type === 0 ? 'tpl' : 'page';
            var image = '/preview/' + type + '/' + item.id + '.file';
            return image;
        },
        handleCopy(item) { 
            this.$emit('handleCopy', item)
        },
        handleUpdate(item){
            this.$emit('handleUpdate', item)
        },
        handleEidt(item){
            this.$emit('handleEidt', item)
        },
        handleDelete(item){
            this.$emit('handleDelete', item)
        }
        
    }
}
</script>
<style scoped>
.portal-template-title{
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
    padding: 10px 0px;
}
.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}
.portal-template-span{
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
}
</style>