<template>
    <el-card class="box-card" style="width: 100%">
        <template #header>
            <div class="card-header">
                <h4 style="display:inline-block">终端top</h4>
                <el-button style="float:right" type="primary" @click="search">查询</el-button>
            </div>
            <el-form ref="form" size="small" :inline="true">
                <el-form-item label="集团">
                    <el-select
                        clearable
                        @visible-change="fetchGroupList"
                        @change="fetchShopList"
                        v-model="form.groupID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in grouplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="场所">
                    <el-select
                        clearable
                        v-model="form.shopID"
                        filterable 
                        placeholder="请选择">
                        <el-option
                            v-for="item in shoplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
                    <el-date-picker
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 300px"
                        v-model="form.Time_range"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="排序">
                    <el-select
                        v-model="form.order_by"
                        placeholder="请选择">
                        <el-option
                            v-for="item in ordelist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="top数">
                    <el-input-number v-model="form.top_times" :min="1" ></el-input-number>
                </el-form-item>
            </el-form>
        </template>
        <el-table ref="multipleTable" :data="tabledata" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="identity" label="身份信息"></el-table-column>
            <el-table-column prop="mac" label="终端mac"></el-table-column>
            <el-table-column prop="ip_address" label="IP地址"></el-table-column>
            <el-table-column prop="Upflow" label="上行流量"></el-table-column>
            <el-table-column prop="Downflow" label="下行流量"></el-table-column>
            <el-table-column prop="Usetime" label="上网时长"></el-table-column>
            <el-table-column prop="auth_name" label="认证域"></el-table-column>
            <el-table-column prop="created_at" label="认证时间"></el-table-column>
        </el-table>
    </el-card>
</template>
<script>
import * as api from '../../../api'

export default {
    data () {
      return {
        form:{
            shopID:null,
            groupID:null,
            order_by:null,
            Time_range:[],
            top_times:10,
        },
        shoplist:[],
        grouplist:[],
        tabledata:[],
        ordelist:[{
            id:1,
            name:"在线时长"
        },{
            id:2,
            name:"上行流量"
        },{
            id:3,
            name:"下行流量"
        }]
      }
    },
    methods: {
        fetchGroupList(val){
            if(!val){
                return
            }
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                this.grouplist = resp.data
            })
        },
        fetchShopList(groupid){
            this.form.shopID=null
            if(groupid==""){
                return
            }
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                this.shoplist = resp.data.list
            })
        },
        search(){
            if(this.form.groupID==""){
                this.form.groupID=null
            }
            if(this.form.shopID==""){
                this.form.shopID=null
            }
            this.$http.post(api.default.toplist.topclient,this.form).then(resp => {
                this.tabledata = resp.data
            })
        }
    }
}
</script>