<template>
    <div>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                        </v-toolbar-title>

                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                        
                        <v-btn color="primary" dark @click="handleAdd">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.portalManage.demo.insertBtn') }}
                            </span>
                        </v-btn>
                        <!-- 添加模板按钮 -->
                        
                        <v-btn color="primary ml-3" dark @click="handleSearch">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.portalManage.searchBtn') }}
                            </span>
                        </v-btn>
                        <!-- 搜索按钮 -->
                    </v-toolbar>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                <v-autocomplete
                                    v-model="search.types"
                                    :items="types"
                                    label="模板类型"
                                    placeholder="请选择模板类型"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    multiple
                                ></v-autocomplete>
                            </v-col>
                            <!-- 模板类型选择 -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-text-field
                                    :placeholder="$vuetify.lang.t('$vuetify.portalManage.demo.table.name')"
                                    v-model="search.name"
                                    append-outer-icon="search"
                                    @click:append-outer="handleSearch()"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <!-- 模板名称 -->
                            <v-col v-if="search_type == 'user'" cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-select
                                    :items="group"
                                    v-model="search.group_id"
                                    item-value="id" 
                                    item-text="name"
                                    clearable
                                    :label="$vuetify.lang.t('$vuetify.portalMac.jt')"
                                    :placeholder="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                                    @change="changeGroup"
                                ></v-select>
                            </v-col>
                            <!-- 选择集团 -->
                            <v-col v-if="search_type == 'user'" cols="12" xs="12" sm="4" md="2" class="pt-0 pb-0">
                                <v-select
                                    :items="shop"
                                    v-model="search.shop_id"
                                    item-value="id" 
                                    item-text="name"
                                    clearable
                                    @change="changeShop"
                                    :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"
                                    :placeholder="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                                ></v-select>
                            </v-col>
                            <!-- 选择场所 -->
                        </v-row>
                    </v-card-subtitle>
                    <!-- 选择框 -->
                </v-col>
            </v-row>
        </v-card>
        <!-- 搜索card -->
    </div>
</template>

<script>
import api from '@/api.js'

export default {
    data () {
      return {
        group:[],

        shop:[]
      }
    },

    props: {
        title : String,
        search: Object,
        types: Array,
        search_type: String
    },
    
    mounted: function(){
        this.fetchGroupList()
    },

    methods: {

        handleAdd () {
            this.$emit('handleAdd');
        },

        handleSearch () {
            this.$emit('handleSearch');
        },

        fetchGroupList(){
            this.$http.post(api.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data

            }).finally(resp => {

            })
        },

        changeGroup(groupid){
            if(groupid == null || typeof(groupid) == 'undefined'){
                this.search.group_id = null
                this.search.shop_id = null
                this.shop = []
            }else
            {
                this.fetchShopList(groupid)
            }
        },

        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.rbac.shopListUrl,postData).then(resp => {
                
                this.shop = resp.data.list

            }).finally(resp => {
                this.loading = false
            })
        },

        changeShop(shopid){
            if(typeof shopid == "undefined")
                this.search.shop_id = null
        },

    }
}
</script>

<style scoped>

.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-date {
    width: 245px;
    margin-right: 10px;
    color: #000;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

#date .v-btn__content {
    color: #000;
}

.v-card__subtitle
{
    padding-top: 0px;
}
.v-toolbar
{
    margin-bottom: 0px;
}

</style>