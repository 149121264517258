<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.portalManage.scheduler.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        :items="demos"
                                        :label="$vuetify.lang.t('$vuetify.portalManage.scheduler.table.demo')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.defaultID"
                                        :rules="rules.defaultID" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        v-if="role === 0"
                                        :items="group"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.groupName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.groupID"
                                        :rules="rules.groupID" 
                                        @change="getShop"
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        v-if="role !== 2"
                                        :items="shop"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.shopName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.shopID"
                                        dense
                                        outlined
                                    ></v-select>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';

export default {

	props: {
        demos: Array,
        role: Number,
        group: Array
	},

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.portalManage.scheduler.insertBtn'),

            dialog: false,

            loading: false,

            shop: [],

            data: {
                name: null,
                defaultID: null,
                groupID: null,
                shopID: null,
                rules: null,
            },

            rules: {
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ],
                defaultID: [
                    value => (value !== null && typeof value !== "undefined") || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ],
                groupID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ],
                shopID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.portalManage.scheduler.rules.name')
                ]
            }
            
        }
    },

    methods: {
        
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },

        handleOpen () {
            this.data.groupID = this.group[0].id;
            this.getShop(this.data.groupID);
            this.dialog = true;
        },

        handleReset () {
            this.data = {
                name: null,
                demoId: 0,
                groupID: null,
                shopID: null,
                rules: null
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.data.rules = JSON.stringify({
                    defaultID:  parseInt(this.data.defaultID), 
                    condition: []
                });
                this.data.shopID = this.data.shopID === 0 ? null : this.data.shopID;
                this.loading = true;
                this.$http.post(api.default.portal.scheduleAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        getShop (value) {
            this.$http.post(api.default.rbac.shopListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.shop = resp.data.list;
                this.shop.splice(0, 0, {
                    id: 0,
                    name: '所有场所'
                })
                this.data.shopID = 0;
            })
        }


    }
}
</script>