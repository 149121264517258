<template>
    
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="4">
                <!-- <v-card
                    class="mx-auto"
                    max-width="400"
                    > -->
                <material-card  id="role-box" class="v-card-profile ">
                    <v-avatar slot="offset" class="mx-auto d-block" size="100">
                        <v-img
                        :src="src"  
                        >
                        </v-img>
                    </v-avatar>
                    <v-card-title>
                    {{$vuetify.lang.t('$vuetify.personas.personas')}}
                    </v-card-title>

                    <v-expand-transition>
                    <div>
                        <v-divider></v-divider>

                        <v-list class="transparent">
                        <v-list-item
                            v-for="item in forecast"
                            :key="item.name"
                        >
                            <v-list-item-title>{{ item.name }}</v-list-item-title>

                            <!-- <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon> -->

                            <v-list-item-subtitle class="text-right">
                            {{ item.temp }}
                            </v-list-item-subtitle>
                        </v-list-item>
                        </v-list>
                    </div>
                    </v-expand-transition>
                </material-card>
                    

                    
                <!-- </v-card> -->
            </v-col>
            <v-col cols="12" md="8">
                <div id="chart" :style="{width: '100%', height: '600px'}"></div>
            </v-col>
        </v-row>

    </div>

</template>

<script>
import bus from '../../utils/bus.js'
import Echarts from 'echarts'
export default {
    data () {
      return {
            src: require('../../../public/static/img/logos/user1.jpg'),
            show: false,
            id: this.$route.params.id,
            forecast: [
                { name: 'Tuesday', temp: '24\xB0/12\xB0' },
                { name: 'Wednesday', temp: '22\xB0/14\xB0' },
                { name: 'Thursday', temp: '25\xB0/15\xB0' },
            ],
      }
    },

    computed: {
    },
    watch: {
    },

    created () {
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchPerson()
            this.draw()
        })
    },
    methods: {
        fetchPerson() {
            this.forecast = [
                { name: 'ID', temp: this.id },
                { name: this.$vuetify.lang.t('$vuetify.personas.phone'), temp: '13766663812' },
                { name: this.$vuetify.lang.t('$vuetify.personas.mac'), temp: '00:ff:00:ff:00:aa' },
                { name: this.$vuetify.lang.t('$vuetify.personas.ua'), temp: 'chrome...' },
                { name: this.$vuetify.lang.t('$vuetify.personas.authzone_name'), temp: 'aaa' },
                { name: this.$vuetify.lang.t('$vuetify.personas.ip'), temp: '192.168.1.1' },
                { name: this.$vuetify.lang.t('$vuetify.personas.created_at'), temp: '2020-01-01 00:01:00' },
            ]
        },
        draw() {

            let myChart = Echarts.init(document.getElementById('chart'))
            
            myChart.setOption({
                title: {
                    text: this.$vuetify.lang.t('$vuetify.personas.chartTitle'),
                    top: "top",
                    left: "left",
                    textStyle: {
                        color: '#000000'
                    }
                },
                backgroundColor: '#fff',

                tooltip: {},
                animationDurationUpdate: function(idx) {
                    // 越往后的数据延迟越大
                    return idx * 100;
                },
                animationEasingUpdate: 'bounceIn',
                color: ['#fff', '#fff', '#fff'],
                series: [{
                    type: 'graph',
                    layout: 'force',
                    force: {
                        repulsion: 300,
                        edgeLength: 100
                    },
                    roam: true,
                    label: {
                            normal: {
                                show: true,
                                position: 'inside',
                                formatter: '{b}',
                                fontSize: 30,
                                fontStyle: '600',
                            }
                        }
                        ,
                    data: [{
                        "name": "ICMP",
                        "value": 10000,
                        x: 500,
                        y: 56,
                        "symbolSize": 50,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "#FF8C00",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "#FF8C00",
                                "color": "#FF8C00"
                            }
                        }
                    }, {
                        "name": "无连接TCP",
                        "value": 6181,
                        x: 0,
                        y: 0,
                        "symbolSize": 50,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "#FF4500",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "#FF4500",
                                "color": "#FF4500",
                            }
                        },
                        
                    }, {
                        "name": "UDP下载及视频",
                        "value": 4386,
                        x: 0,
                        y: 0,
                        
                        "symbolSize": 50,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "rgb(165, 190, 198)",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "rgb(165, 190, 198)",
                                "color": "rgb(165, 190, 198)"
                            }
                        }
                    }, {
                        "name": "个人",
                        "value": 4055,
                        "symbol": this.src,
                        "symbolSize": 50,
                        x: 0,
                        y: 0,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "#4DFB37",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "#4DFB37",
                                "color": "#4DFB37"
                            }
                        }
                    }, {
                        "name": "远程桌面",
                        "value": 2467,
                        x: 0,
                        y: 0,
                        "symbolSize": 50,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "#00BFFF",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "#00BFFF",
                                "color": "#00BFFF"
                            }
                        }
                    }, {
                        "name": "WWW",
                        "value": 2244,
                        x: 0,
                        y: 0,
                        "symbolSize": 50,
                        "draggable": true,
                        "itemStyle": {
                            "normal": {
                                "borderColor": "#FFD700",
                                "borderWidth": 4,
                                "shadowBlur": 0,
                                "shadowColor": "#FFD700",
                                "color": "#FFD700"
                            }
                        }
                    }],
                    links:[{
                        "source": "UDP下载及视频",
                        "target": "个人"
                    },
                    {
                        "source": "WWW",
                        "target": "个人"
                    },
                    {
                        "source": "无连接TCP",
                        "target": "个人"
                    },
                    {
                        "source": "ICMP",
                        "target": "个人"
                    },
                    {
                        "source": "远程桌面",
                        "target": "个人"
                    }]
                }]


            })
            
            
        }
      
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}
 

</style>