<template>
    
    <div class="sdemo-layout">
        <scheduler-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title="title"
            :search ="search"
        ></scheduler-search>

        <scheduler-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >
            <template v-slot:action="action">
                <v-row justify="start">

                    <v-icon color="primary" small @click="handleRule(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleRule(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.scheduler.table.rule') }}
                    </span>

                    <div class="mx-1"></div>

                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.editBtn') }}
                    </span>

                    <div class="mx-1"></div>

                    <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.portalManage.deleteBtn') }}
                    </span>

                </v-row>
            </template>
        </scheduler-data-table>

        <scheduler-rule 
            ref="rule" 
            :demos="demos"
            @handleSearch="handleSearch"
        ></scheduler-rule>

        <add-scheduler 
            ref="add" 
            :role="role"
            :group="group"
            :demos="demos"
            @handleSearch="handleSearch"
        ></add-scheduler>

        <edit-scheduler 
            ref="edit"
            :role="role"
            :demos="demos"
            @handleSearch="handleSearch"
        ></edit-scheduler>

        <del-scheduler ref="del" @handleSearch="handleSearch"></del-scheduler>
    </div>
</template>

<script>
import * as api from '../../api'
import SchedulerDataTable from './components/SchedulerDataTable.vue'
import SchedulerSearch from './components/SchedulerSearch.vue'
import SchedulerRule from './components/SchedulerRule.vue'
import AddScheduler from './components/AddScheduler.vue'
import EditScheduler from './components/EditScheduler.vue'
import DelScheduler from './components/DelScheduler.vue'

export default {

    components: {
        SchedulerDataTable,
        SchedulerSearch,
        SchedulerRule,
        AddScheduler,
        EditScheduler,
        DelScheduler
    },

    data () {
        return {

            role: parseInt(window.sessionStorage.getItem('type')),

            loading: true,

            title: this.$vuetify.lang.t('$vuetify.portalManage.scheduler.title'),

            group: [],
            demos: [],

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.scheduler.table.name'),
                    sortable: false,
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.scheduler.table.groupName'),
                    sortable: false,
                    value: 'groupName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.scheduler.table.shopName'),
                    sortable: false,
                    value: 'shopName'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.portalManage.scheduler.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],

            data: [
                // rules: [
                //     {
                //         defaultID: 0,
                //         condition: [
                //             {
                //                 pageID: 0,
                //                 macs: 1,
                //                 device: 0,
                //                 dates: [
                //                     {
                                        
                //                     }
                //                 ]
                //             }
                //         ]
                //     }
                // ]
            ],
            loading: false,
            total  : 0,

            search: {
                pagesize : 8,
                pagenum  : 1,
                name     : null
            }
        }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
            this.getDemo();
            this.getGroup();
        },

        getDemo () {
            this.$http.post(api.default.portal.udemoListUrl, {
                pagenum : 1,
                pagesize: 1000
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.demos = resp.data.list;
            })
        },

        getGroup () {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.portal.scheduleListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                this.data = this.formatData(resp.data.list);
                this.$refs.rule.handleClose();
            }).finally(resp => {
                this.loading = false;
            })
        },

        formatData (value) {
            var data = [];
            var n = value.length;
            for (var i = 0; i < n; i++) {
                var temp = JSON.parse(value[i].rules);
                data[i] = {
                    id: value[i].id,
                    name: value[i].name,
                    groupName: value[i].group_name,
                    shopName: value[i].shop_name,
                    shop_id: value[i].shop_id,
                    group_id: value[i].group_id,
                    defaultID: temp.defaultID,
                    rules: temp.condition
                };
            }
            return data;
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleRule (item) {
            this.$refs.rule.handleOpen(item);
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }

    }
}
</script>

<style scoped>

.sdemo-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>