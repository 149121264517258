<template>
    <div id="Alert">
        <v-container fill-height
                     fluid
                     grid-list-xl
                     class="app-container">
            <v-layout justify-center
                      wrap>
                <v-flex md12>
                    <v-card>
                        <v-row>
                            <v-col cols="12">
                                <v-toolbar :flat="true">
                                    <v-toolbar-title>
                                        <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                    </v-toolbar-title>

                                    <v-divider class="mr-4 ml-4"
                                               inset
                                               vertical></v-divider>
                                    <v-btn color="primary ml-3"
                                           dark
                                           @click="search">
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.search')}}
                                        </span>
                                    </v-btn>
                                    <v-btn color="primary ml-3"
                                           @click="reset"
                                           dark>
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.reset')}}
                                        </span>
                                    </v-btn>
                                    <v-btn dark
                                           color="primary ml-3"
                                           @click="dialogAdd = true">
                                        {{ $vuetify.lang.t("$vuetify.alert.btn.add") }}
                                    </v-btn>
                                </v-toolbar>
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col md="2">
                                            <v-text-field :label="'名称'"
                                                          v-model="page.params.name"
                                                          clearable></v-text-field>
                                        </v-col>
                                        <v-col md="4">
                                            <v-text-field :label="'邮件标题'"
                                                        v-model="page.params.title"
                                                        clearable></v-text-field>
                                        </v-col>
                                        <v-col md="2"
                                               v-if="userType == 0">
                                            <v-select :items="groupArr"
                                                    @change="handelAddGroupChange"
                                                    v-model="page.params.groupID"
                                                    item-value="id"
                                                    item-text="name"
                                                    clearable
                                                    :label="$vuetify.lang.t('$vuetify.email.table.group')"></v-select>
                                        </v-col>
                                        <!-- 选择场所 -->
                                        <v-col md="2"
                                               v-if="userType == 0 || userType == 1">
                                            <v-select :items="shopArr"
                                                    v-model="page.params.shopID"
                                                    item-value="id"
                                                    item-text="name"
                                                    clearable
                                                    :label="$vuetify.lang.t('$vuetify.email.table.shop')"></v-select>
                                        </v-col>

                                        <v-col md="2">
                                            <v-select :items="statusArr"
                                                v-model="page.params.status"
                                                item-value="status"
                                                item-text="text"
                                                :label="'状态'"
                                                clearable></v-select>
                                        </v-col>

                                    </v-row>
                                </v-card-subtitle>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-flex>

                <v-flex md12>
                    <alert-list :alertList="alertList"
                                :groupArr="groupArr"
                                :shopArr="shopArr"
                                :emailList="emailList"
                                :userType="userType"
                                :rules="rules"
                                ref="alertListRef"
                                @handelClickPanel="handelClickPanel"
                                @handleGroupChange="handleGroupChange"
                                @alertEdit="alertEdit"
                                @alertDel="alertDel"
                                @handleBind="handleBind"></alert-list>
                </v-flex>
            </v-layout>
        </v-container>
        <div class="text-center">
            <v-pagination v-model="page.params.pageNum"
                          :length="page.length"
                          @input="getAlertList"
                          :total-visible="7"></v-pagination>
        </div>
        <v-dialogs :theme="$vuetify.lang.t('$vuetify.alert.btn.add')"
                   :dialog="dialogAdd"
                   :loading="addLoading"
                   @dialogSure="handelEmitAdd">
            <v-form ref="addForm"
                    lazy-validation>
                <v-text-field v-model="addForm.name"
                              :label="$vuetify.lang.t('$vuetify.alert.table.name')"
                              :rules="rules.name">
                </v-text-field>
                <v-text-field v-model="addForm.from_name"
                              :label="$vuetify.lang.t('$vuetify.alert.table.from_name')"
                              :rules="rules.fromName">
                </v-text-field>
                <v-text-field v-model="addForm.title"
                              :label="$vuetify.lang.t('$vuetify.alert.form.title')"
                              :rules="rules.title">

                </v-text-field>
                <v-text-field v-model="addForm.content"
                              :label="$vuetify.lang.t('$vuetify.alert.form.content')"
                              :rules="rules.content">

                </v-text-field>
                <v-select v-model="addForm.email_id"
                          :items="emailList"
                          item-value="id"
                          item-text="name"
                          :label="$vuetify.lang.t('$vuetify.alert.form.email')"
                          :rules="rules.email"></v-select>
                <v-select v-if="userType == 0"
                          v-model="addForm.group_id"
                          @change="handelAddGroupChange"
                          class="mt-6"
                          :items="groupArr"
                          item-value="id"
                          item-text="name"
                          :rules="rules.group"
                          :label="$vuetify.lang.t('$vuetify.email.table.group')"
                          dense></v-select>
                <v-select v-if="userType == 0 ||userType == 1"
                          v-model="addForm.shop_id"
                          class="mt-6"
                          :items="shopArr"
                          item-value="id"
                          item-text="name"
                          :hint="$vuetify.lang.t('$vuetify.alert.tip.shopEmpty')"
                          persistent-hint
                          :label="$vuetify.lang.t('$vuetify.email.table.shop')"
                          dense></v-select>
                <v-combobox v-model="addForm.addresses"
                            :label="$vuetify.lang.t('$vuetify.alert.form.addresses')"
                            multiple
                            :hint="$vuetify.lang.t('$vuetify.alert.tip.addAddress')"
                            :rules="rules.addresses"
                            persistent-hint
                            chips>
                    <template v-slot:selection="data">
                        <v-chip :key="JSON.stringify( data.item)"
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                :disabled="data.disabled"
                                @click:close="data.parent.selectItem(data.item)">
                            <v-avatar class="accent white--text"
                                      left
                                      v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                            {{ data.item }}
                        </v-chip>
                    </template>
                </v-combobox>
                <v-combobox v-model="addForm.ccs"
                            :label="$vuetify.lang.t('$vuetify.alert.form.ccs')"
                            :rules="rules.ccs"
                            multiple
                            chips>
                    <template v-slot:selection="data">
                        <v-chip :key="JSON.stringify( data.item)"
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                :disabled="data.disabled"
                                @click:close="data.parent.selectItem(data.item)">
                            <v-avatar class="accent white--text"
                                      left
                                      v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                            {{ data.item }}
                        </v-chip>
                    </template>
                </v-combobox>
            </v-form>
        </v-dialogs>
        <v-dialogs :theme="$vuetify.lang.t('$vuetify.alert.deleteTitle')"
                   :dialog="dialogDelete"
                   @dialogSure="handleDelete">
            <v-col cols="12"
                   sm="7"
                   md="7">
                <span class="app-f16">{{$vuetify.lang.t('$vuetify.server.dialogs.delete')}}</span>
            </v-col>
        </v-dialogs>
        <bind-gateway :groupArr="groupArr"
                      :shopArr="bindGateway.shopArr"
                      :isBindDialogShow="bindGateway.isDialogShow"
                      :alertID="bindGateway.alertID"
                      :userType="userType"
                      ref="bind_gateway"
                      @closeBindGatewayDialog="closeBindGatewayDialog"
                      @getShopList="getShopList"></bind-gateway>
    </div>
</template>

<script>
import * as api from "./../../api";
import { Notification } from "element-ui";
import AlertList from "./components/AlertList";
import vDialogs from "./../../components/core/Dialog.vue";
import BindGateway from "./components/BindGateway";
import { EMAIL_REG } from "../../utils/common.js";
let selectedAlert = null;
let selectedAlertID = null;
export default {
    data() {
        return {
            page: {
                params: {
                    pageNum: 1,
                    pageSize: 5,
                    name: null,
                    title: null,
                    groupID: null,
                    shopID: null,
                    status: null,
                },
                total: 0,
                length: 5,
            },
            statusArr: [
                {
                    status: 2,
                    text: "已停用",
                },
                {
                    status: 1,
                    text: "已启用",
                },
            ],
            alertList: [],
            groupArr: [],
            shopArr: [],
            emailList: [],
            userType: sessionStorage.getItem("type"),
            dialogAdd: false,
            dialogDelete: false,
            addLoading: false,
            bindGateway: {
                isDialogShow: false,
                alertID: "",
                shopArr: [],
            },
            addForm: {
                name: "",
                title: "",
                content: "",
                addresses: [],
                ccs: [],
                from_name: "",
                email_id: "",
                group_id: "",
                shop_id: "",
            },
            rules: {
                name: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.name"),
                ],
                title: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.title"),
                ],
                content: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.content"),
                ],
                email: [
                    (value) =>
                        value > 0 ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.email"),
                ],
                group: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.group"),
                ],
                fromName: [
                    (value) =>
                        this.formSpaces(value) != "" ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.fromName"),
                ],
                address: [
                    (value) =>
                        value.length != 0 ||
                        this.$vuetify.lang.t("$vuetify.alert.rules.address"),
                    //判断邮箱格式是否正确
                    (value) => {
                        let result = value.every((item) => {
                            return EMAIL_REG.test(item);
                        });
                        return (
                            result ||
                            this.$vuetify.lang.t(
                                "$vuetify.alert.rules.eamilFormatErr"
                            )
                        );
                    },
                ],
                ccs: [
                    //判断邮箱格式是否正确
                    (value) => {
                        let result = value.every((item) => {
                            return EMAIL_REG.test(item);
                        });
                        return (
                            result ||
                            this.$vuetify.lang.t(
                                "$vuetify.alert.rules.eamilFormatErr"
                            )
                        );
                    },
                ],
            },
        };
    },
    components: {
        AlertList,
        vDialogs,
        BindGateway,
    },
    mounted() {
        if (this.userType == 0) {
            this.getGroupList();
        } else if (this.userType == 1) {
            this.getShopList();
        }
        this.getAlertList();
        this.getEmailList();
    },
    methods: {
        search() {
            this.page.params.pageNum = 1;
            this.getAlertList();
        },
        reset() {
            this.page.params.pageNum = 1;
            this.page.params.name = null;
            this.page.params.title = null;
            this.page.params.status = null;
            this.page.params.groupID = null;
            this.page.params.shopID = null;
            this.getAlertList();
        },
        closeBindGatewayDialog() {
            this.bindGateway.shopArr = [];
            this.bindGateway.groupArr = [];
            this.bindGateway.isDialogShow = false;
        },
        handleBind(id) {
             if (this.userType == 0) {
                this.bindGateway.shopArr = [];
            }

            this.bindGateway.alertID = id;
            this.bindGateway.isDialogShow = true;
        },
        handleDelete(params) {
            if (params) {
                this.$http
                    .get(api.default.gateway.alertDelete+'?id='+selectedAlertID)
                    .then((res) => {
                        if (res.errno == 0) {
                            this.$refs.alertListRef.setPanel(-1);
                            this.getAlertList();
                            Notification({
                                type: "success",
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    })
                    .finally(() => {
                        this.dialogDelete = false;
                    });
            } else {
                this.dialogDelete = false;
            }
        },
        alertDel(id) {
            this.dialogDelete = true;
            selectedAlertID = id;
        },
        formSpaces(str) {
            if (str !== null && str !== undefined && typeof(str) == 'string') {
                let res = str.replace(/\s*/g, "");
                return res;
            }
        },
        handelEmitAdd(params) {
            if (!params) {
                this.dialogAdd = false;
            } else {
                this.alertAdd();
            }
        },
        alertAdd() {
            if (this.$refs.addForm.validate()) {
                this.addLoading = true;
                this.$http
                    .post(api.default.gateway.alertAdd, this.addForm)
                    .then((res) => {
                        if (res.errno == 0) {
                            this.dialogAdd = false;
                            this.$refs.addForm.reset();
                            this.getAlertList();
                            Notification({
                                type: "success",
                                message: this.$vuetify.lang.t(
                                    "$vuetify.common.success"
                                ),
                            });
                        }
                    })
                    .finally(() => {
                        this.addLoading = false;
                    });
            }
        },
        handelClickPanel(groupID, index) {
            if (this.alertList[index]['status'] == 1){
              this.alertList[index]['status'] = true
            }
            selectedAlert = index;
            this.getShopList(groupID);
        },
        handelAddGroupChange(event) {
            this.addForm.shop_id = null;
            this.getShopList(event);
        },
        handleGroupChange(event) {
            this.alertList[selectedAlert].shop_id = null;
            this.getShopList(event);
        },
        getGroupList() {
            this.$http
                .post(api.default.rbac.groupListUrl)
                .then((resp) => {
                    this.groupArr = resp.data;
                })
                .finally((resp) => {});
        },
        getShopList(groupID) {
            var params = { pagesize: 10000, pagenum: 1 };
            if (groupID) {
                params.groupID = groupID;
            }
            this.$http
                .post(api.default.rbac.shopListUrl, params)
                .then((resp) => {
                    let data = resp.data.list;
                    this.shopArr = [{ id: 0, name: "全部场所" }, ...data];
                    this.bindGateway.shopArr = [
                        { id: 0, name: "全部场所" },
                        ...data,
                    ];
                })
                .finally((resp) => {});
        },
        getAlertList() {
            let params = this.page.params;
            this.$http
                .post(api.default.gateway.alertList, params)
                .then((res) => {
                  if (res.data.list ==null){
                    this.alertList = [];
                  }else{
                    this.alertList = res.data.list;
                  }
                    this.page.total = res.data.total;
                    this.page.length = Math.ceil(res.data.total / 5);
                });
        },
        getEmailList() {
            this.$http
                .post(api.default.gateway.emailList, {
                    pageSize: 1000,
                    pageNum: 1,
                })
                .then((resp) => {
                    this.emailList = resp.data.list;
                })
                .finally((res) => {});
        },
        alertEdit(index) {
          if (this.alertList[index]['status']){
            this.alertList[index]['status'] = 1
          }else{
            this.alertList[index]['status'] = 0
          }
            this.$http
                .post(api.default.gateway.alertEdit, this.alertList[index])
                .then((res) => {
                    if (res.errno == 0) {
                        this.getAlertList();
                        Notification({
                            type: "success",
                            message: this.$vuetify.lang.t(
                                "$vuetify.common.success"
                            ),
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    border: 1px solid;
    position: absolute;
    text-align: center;
    left: 30%;
    bottom: 120px;
}
</style>