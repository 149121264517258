<template>
    <div id="v-table">
        <v-data-table 
            :search="search" 
            :headers="columns" 
            :items="data"
            :loading="loading"
            sort-by="calories" 
            class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    <slot name="title"></slot>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field 
                            width="200" 
                            v-model="search" 
                            :label="$vuetify.lang.t('$vuetify.userManage.search')" 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <slot name='action' :value='{ item }'></slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            search: null,
        }
    },

    props: {
        title: String,
        columns: Array,
        loading: Boolean,
        data: Array
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}
 
#v-table .v-card--material__header {
    width: 175px;
}

#v-table .v-btn__content {
    /* color: #000; */
}
</style>