<template>
    <div>
        <v-card class="mx-auto"
                outline>
            <v-toolbar flat
                       color="white">
                <v-toolbar-title>
                    {{ $vuetify.lang.t("$vuetify.license.title") }}
                </v-toolbar-title>
                <v-divider class="mx-4"
                           inset
                           vertical></v-divider>
                <v-btn color="#409eff"
                       class="ma-2 white--text"
                       @click="downloadLcr">
                    {{ $vuetify.lang.t("$vuetify.license.download") }}
                    <v-icon right>mdi-cloud-download</v-icon>
                </v-btn>

                <el-upload class="upload-demo"
                           :action="uploadLcsFileUrl"
                           :show-file-list="false"
                           :fileList="fileList"
                           accept=".lcs"
                           name="lcsFile"
                           :before-upload="beforeUpload"
                           :on-progress="onProgress"
                           :on-success="uploadSuccess"
                           :on-error="uploadError">
                    <v-btn color="#67C23A"
                           class="ma-2 white--text"
                           :loading="uploadLoading"
                           >
                        {{ $vuetify.lang.t("$vuetify.license.upload") }}
                        <v-icon right>mdi-cloud-upload</v-icon>
                    </v-btn>
                </el-upload>
                <v-btn v-if="isAuth"
                       color="#f56c6c"
                       class="ma-2 white--text"
                       @click="cancelLcs">
                    {{ $vuetify.lang.t("$vuetify.license.cancel") }}
                    <v-icon right>mdi-cancel</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-row style="font-size: 16px">
                    <v-col cols="1"
                           style="text-align: right">
                        {{ $vuetify.lang.t("$vuetify.license.status.title") }}
                    </v-col>
                    <v-col>
                        <span v-if="isAuth"
                              style="color: #67c23a">
                            {{$vuetify.lang.t("$vuetify.license.status.true")}}
                        </span>
                        <span v-else
                              style="color: #f56c6c">
                            {{$vuetify.lang.t("$vuetify.license.status.false")}}
                        </span>
                    </v-col>
                </v-row>
                <div v-if="isAuth">
                    <v-row style="font-size: 16px">
                        <v-col cols="1"
                               style="text-align: right">
                            {{ $vuetify.lang.t("$vuetify.license.serialNum") }}
                        </v-col>
                        <v-col>
                            <span style="color: #67c23a">{{ serialNum }}</span>
                        </v-col>
                    </v-row>
                    <v-row style="font-size: 16px">
                        <v-col cols="1"
                               style="text-align: right">
                            {{ $vuetify.lang.t("$vuetify.license.expireDate") }}
                        </v-col>
                        <v-col>
                            <span style="color: #67c23a">{{ expireDate }}</span>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        isAuth: Boolean,
        serialNum: String,
        beforeUpload: Function,
        uploadSuccess: Function,
        uploadError: Function,
        onProgress:Function,
        expireDate: String,
        fileList: Array,
        uploadLoading:Boolean,
        uploadLcsFileUrl: String,
    },
    methods: {
        downloadLcr() {
            this.$emit("downloadLcr");
        },
        cancelLcs() {
            this.$emit("cancelLcs");
        },
    },
};
</script>

<style lang="scss" scoped>
</style>