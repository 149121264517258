<template>
    
    <div class="main_lay">
        <v-row>
            <v-col cols="12" md="10">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="group"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalMac.pickjt')"
                            @change="changeGroup"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="shop"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalMac.pick_placename')"
                            @change="changeShop"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="authzone"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalMac.auth_name')"
                            @change="changeauthzone"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="smsGateway"
                            item-value="id" 
                            item-text="name"
                            clearable
                            :label="$vuetify.lang.t('$vuetify.portalManage.ac.table.smsNet')"
                            @change="changeSmsGatewayId"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    
                    <v-col cols="12" md="3">
                        <v-text-field dense filled rounded v-model="phone" :label="$vuetify.lang.t('$vuetify.sendingRecord.pickPhone')"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                    	<v-text-field dense  filled rounded v-model="content" :label="$vuetify.lang.t('$vuetify.sendingRecord.like')"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date"
                                :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            range
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="1">
                        <v-btn color="primary" dark  @click="search" style="margin: 10px 0 0 0"><span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.search')}}</span></v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="12">
                <material-table
                    :title="title"
                    :columns="columns"
                    :data="data"
                    :url = "url"
                    method = "post"
                    :filter = "filter"
                    :searchValue= "searchValue"
                    v-on:searchValue="setSearchValue"
                >
                    <template v-slot:item.action="{ action }">
                        <a class="span">
                            <v-icon
                                small
                                class="mr-2"
                                color='blue'
                                @click="editItem(action.value.item)"
                            >
                                {{$vuetify.lang.t('$vuetify.common.info')}}
                            </v-icon>
                        </a>
                    </template>
                </material-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import bus from '../../utils/bus.js'
import Echarts from 'echarts'
import * as api from '../../api'
// import CsvExport from './../../utils/CsvExport'
export default {
    data () {
      return {
            group: [],
            shop: [],
            authzone: [],
            smsGateway: [],

            groupID: 0,
            shopID: 0,
            authzoneId:0,
            sms_gateway_id: 0,

            searchValue: 0,
            content: '',
            phone: '',


            date: [this.getBeforeDate(7), new Date().toISOString().substr(0, 10)],
            menu: false,
            dialog: false,
            title: this.$vuetify.lang.t('$vuetify.sendingRecord.tableName'),
            filter: {
                time_range: [this.getBeforeDate(7) + ' 00:00:00', new Date().toISOString().substr(0, 10) + ' 23:59:59'],
                group_id: 0,
                shop_id: 0,
                authzone_id:  0,
                sms_gateway_id: 0
            },

            url: api.default.sms.recordList,
            loading: false,
            range: [0, 10],
            data: [],
          
            columns: [
                { text: this.$vuetify.lang.t('$vuetify.gateway.table.company'), value: 'group_name' },
            	// { text: this.$vuetify.lang.t('$vuetify.gateway.table.place'), value: 'shop_name' },
                { text: this.$vuetify.lang.t('$vuetify.sendingRecord.name'), value: 'sms_gateway_name' },
                { text: this.$vuetify.lang.t('$vuetify.sendingRecord.phone'), value: 'phone' },
                { text: this.$vuetify.lang.t('$vuetify.sendingRecord.info'), value: 'content' },
                { text: this.$vuetify.lang.t('$vuetify.sendingRecord.time'), value: 'create_at' }
            ],
            data: [
            ],
            editedIndex: -1,
            editedItem: {
                name: '',
                calories: 0,
                fat: 0,
                carbs: 0,
                protein: 0,
            },
            defaultItem: {
            },
        
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$vuetify.lang.t('$vuetify.portalMac.newItem') : this.$vuetify.lang.t('$vuetify.portalMac.editItem')
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchGroupList()
            this.fetchTable()
            this.fetchSmsGatewayList()
        })
    },
    methods: {
        setSearchValue(){
            this.searchValue = 0
        },
        fetchGroupList(){
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                
                this.group = resp.data

            }).finally(resp => {
                this.loading = false
            })
        },
        changeGroup(groupid){
            this.fetchShopList(groupid)
            this.groupID = groupid
            this.fetchAuthzoneList()
        },
        fetchShopList(groupid){
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.default.rbac.shopListUrl,postData).then(resp => {
                this.shop = resp.data.list
            })
        },
        changeShop(shopid){
            this.shopID = shopid
            this.fetchAuthzoneList()
        },

        fetchAuthzoneList(){
            const postData = {
                group_id: this.groupID,
                shop_id: this.shopID
            }
            this.$http.post(api.default.portal.zonenameListUrl,postData).then(resp => {
                if(resp.errno != 0)
                    return;
                this.authzone = resp.data
            })
        },
        changeauthzone(id){
            this.authzoneId = id
        },

        fetchSmsGatewayList(){
            this.$http.post(api.default.sms.gatewayList).then(resp => {
                this.smsGateway = resp.data.list
            })
        },
        changeSmsGatewayId(sms_gateway_id){
            this.sms_gateway_id = sms_gateway_id
        },

        search(){
            this.searchValue = 1
            this.fetchTable()
        },

        fetchTable(){
            this.filter = {
                time_range: [this.date[0] + ' 00:00:00', this.date[1] + ' 23:59:59'],
                group_id:this.groupID,
                shop_id:this.shopID,
                authzone_id:this.authzoneId,
                sms_gateway_id: this.sms_gateway_id,
                phone: this.phone,
                content: this.content
            }
        },

        handleExport(){
            // CsvExport(this.headers,this.desserts,this.title);
        },
        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if(day <= n) {
                if(mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
            return s;
        }
    }
}
</script>

<style scoped>

.main_lay {
    margin: 0 24px;
    min-height: 90%;
    padding-bottom: 20px;
}

</style>
