<template>
    <div id="v-table">
        <v-data-table :headers="columns" :items="data" 
            :loading="loading" class="elevation-1">

            <template v-slot:item.price="{ item }">
                {{ item.price/100 }}元
            </template>

        </v-data-table>
    </div>
</template>

<script>

export default {

    data() {
        return {
            options: {}
        }
    },

    props: {
        loading: Boolean,
        columns: Array,
        data: Array,
    },

    methods: {

    },

    watch: {
   
    }
}
</script>

<style language="scss">
#v-table .span {
    padding-right: 6px;
}

#v-table .span i {
    color: #1976D2 !important;
}

#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}

#v-table .v-card--material__header {
    width: 175px;
}

#v-table .v-btn__content {
    /* color: #000; */
}
</style>
