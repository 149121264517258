<template>
    <div>
        <v-container fluid
                     fill-height
                     grid-list-xl
                     class="app-container">
            <v-layout justify-center
                      wrap>
                <v-flex md12>
                    <v-card>
                        <v-row>
                            <v-col cols="12">
                                <v-toolbar :flat="true">
                                    <v-toolbar-title>
                                        <span>{{$vuetify.lang.t('$vuetify.portalMac.selectTitle')}}</span>
                                    </v-toolbar-title>

                                    <v-divider class="mr-4 ml-4"
                                               inset
                                               vertical></v-divider>

                                    <v-btn color="primary ml-3"
                                           dark
                                           @click="search">
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.search')}}
                                        </span>
                                    </v-btn>
                                    <v-btn color="primary ml-3"
                                           dark
                                           @click="reset">
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.reset')}}
                                        </span>
                                    </v-btn>
                                    <v-btn color="primary ml-3"
                                           dark
                                           :loading="exportLoading"
                                           @click="exportExcel">
                                        <span>
                                            {{$vuetify.lang.t('$vuetify.common.export')}}
                                        </span>
                                    </v-btn>
                                    <!-- 搜索按钮 -->
                                </v-toolbar>
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col md="2"
                                               v-if="userType == 0">
                                            <v-select :label="$vuetify.lang.t('$vuetify.email.table.group')"
                                                      v-model="recordListParams.groupID"
                                                      :items="groupArr"
                                                      item-value="id"
                                                      item-text="name"
                                                      clearable
                                                      @input="handleGroupChange"
                                                      @click:clear="handelGroupClear"></v-select>
                                        </v-col>
                                        <v-col md="2">
                                            <v-select :label="$vuetify.lang.t('$vuetify.email.table.shop')"
                                                      v-model="recordListParams.shopID"
                                                      :items="shopArr"
                                                      item-value="id"
                                                      item-text="name"
                                                      clearable></v-select>

                                        </v-col>
                                        <v-col md="2">
                                            <v-text-field :label="$vuetify.lang.t('$vuetify.gatewayManagement.name')"
                                                          v-model="recordListParams.name"
                                                          clearable></v-text-field>
                                        </v-col>
                                        <v-col md="2">
                                            <v-text-field label="MAC"
                                                          v-model="recordListParams.mac"
                                                          clearable></v-text-field>
                                        </v-col>
                                        <v-col md="2">
                                            <v-menu ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="date"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="date"
                                                                  :label="$vuetify.lang.t('$vuetify.common.pickTimeRange')"
                                                                  prepend-icon="event"
                                                                  readonly
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date"
                                                               range
                                                               scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text
                                                           color="primary"
                                                           @click="menu = false">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                                    <v-btn text
                                                           color="primary"
                                                           @click="$refs.menu.save(date)">{{$vuetify.lang.t('$vuetify.common.sureBtn')}}</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>

                                    </v-row>
                                </v-card-subtitle>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-flex>
                <v-flex md12>
                    <v-table :titleTable="$vuetify.lang.t('$vuetify.alert.alertRecord')"
                             :headers="headers"
                             :tableData="recordList"
                             :total="total"
                             @pageSize="handlePageSize"
                             @page="handlePage"></v-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import vTable from "./components/Table";
import api from "../../api";
import axios from "axios";
export default {
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t(
                        "$vuetify.gatewayManagement.name"
                    ),
                    value: "gateway_name",
                },
                {
                    text: "MAC",
                    value: "mac",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.group"),
                    value: "group_name",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.email.table.shop"),
                    value: "shop_name",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.alert.table.time"),
                    value: "created_at",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.gateway.table.status"),
                    value: "status",
                },
            ],
            recordListParams: {
                timeRange: [
                    this.getBeforeDate(1) + " 00:00:00",
                    new Date().toISOString().substr(0, 10) + " 23:59:59",
                ],
                groupID: 0,
                shopID: 0,
                mac: "",
                name: "",
                pageSize: 10,
                pageNum: 1,
            },
            recordList: [],
            userType: sessionStorage.getItem("type"),
            groupArr: [],
            shopArr: [],
            total: 0,
            date: [
                this.getBeforeDate(1),
                new Date().toISOString().substr(0, 10),
            ],
            menu: false,
            exportLoading: false,
        };
    },
    components: {
        vTable,
    },
    mounted() {
        this.getRecordList();
        if (this.userType == 0) {
            this.getGroupList();
        } else if (this.userType == 1) {
            this.getShopList();
        }
    },

    methods: {
        getBeforeDate(n) {
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon = d.getMonth() + 1;
            var day = d.getDate();
            if (day <= n) {
                if (mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            let s =
                year +
                "-" +
                (mon < 10 ? "0" + mon : mon) +
                "-" +
                (day < 10 ? "0" + day : day);
            return s;
        },
        handlePage(data) {
            this.recordListParams.pageNum = data;
            this.getRecordList();
        },
        handlePageSize(data) {
            this.recordListParams.pageSize = data;
            this.getRecordList();
        },
        reset() {
            this.recordListParams.groupID = "";
            this.recordListParams.shopID = "";
            this.recordListParams.mac = "";
            this.recordListParams.name = "";
            this.recordListParams.pageSzie = 10;
            this.recordListParams.pageNum = 1;
            this.getRecordList();
        },
        handelGroupClear() {
            this.recordListParams.groupID = null;
        },
        getRecordList() {
            this.$http
                .post(api.gateway.alertRecordList, this.recordListParams)
                .then((res) => {
                    if (res.errno == 0) {
                        this.total = parseInt(res.data.total);
                        if (res.data.list !=null)
                          this.recordList = res.data.list;

                    }
                });
        },
        handleGroupChange(event) {
            this.getShopList(event);
        },
        getShopList(groupID) {
            var params = { pagesize: 10000, pagenum: 1 };
            if (groupID) {
                params.groupID = groupID;
            }
            this.$http
                .post(api.rbac.shopListUrl, params)
                .then((resp) => {
                    this.shopArr = resp.data.list;
                })
                .finally((resp) => {});
        },
        getGroupList() {
            this.$http
                .post(api.rbac.groupListUrl)
                .then((resp) => {
                    this.groupArr = resp.data;
                })
                .finally((resp) => {});
        },
        search() {
            this.recordListParams.timeRange = [
                this.date[0] + " 00:00:00",
                this.date[1] + " 23:59:59",
            ];
            this.getRecordList();
        },
        exportExcel() {
            this.exportLoading = true;
            this.recordListParams.timeRange = [
                this.date[0] + " 00:00:00",
                this.date[1] + " 23:59:59",
            ];
            axios({
                method: "POST",
                url: api.gateway.alertRcordExport,
                data: this.recordListParams,
                responseType: "blob",
            })
                .then((response) => {
                    if (response.status != 200 || !response.data) {
                        this.$notify.error({
                            message: this.$vuetify.lang.t('$vuetify.common.errors'),
                        });
                        return;
                    }
                    let url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download", "告警记录.xlsx");

                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$notify.error({
                        message: this.$vuetify.lang.t('$vuetify.common.errors'),
                    });
                })
                .finally(() => {
                    this.exportLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
