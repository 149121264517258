<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="450px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <div style="margin-top: 10px;">
                                请选择开启计费时间
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                            mdi-lightbulb
                                        </v-icon>
                                    </template>
                                </v-tooltip>
                            </div>
                            <div style="margin-top: 20px;">
                                <v-date-picker
                                    style="width:100%"
                                    v-model="currentDate"
                                    no-title scrollable
                                    type="date" 
                                    :show-current="true"
                                    >
                                </v-date-picker>
                            </div>
                        </v-container>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="handleClose">
                                {{$vuetify.lang.t('$vuetify.portalManage.close')}}
                            </v-btn>
                            <v-btn color="primary" @click="handleUpdateRadacctStatus">
                                {{$vuetify.lang.t('$vuetify.portalManage.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import { Notification } from 'element-ui';
import * as api from '../../../api'
export default {

    data () {
        return {
            dialog: false,
            currentDate:null,
            id:0,
            status:0,
            title: this.$vuetify.lang.t('$vuetify.userManage.site.updateRadacctStatusTooltip')
        }
    },

    methods: {

        handleOpen (id,status) {
            this.id = id
            this.status = status
            this.dialog = true;
        },

        handleClose () {
            this.currentDate = null;
            this.dialog = false;
        },

        handleUpdateRadacctStatus() {
            this.loading = true;
            var param = {
                id: this.id,
                radacct_status: this.status,
                first_time:this.currentDate,
            }
            this.$http.post(api.default.rbac.setRadacctStatusUrl, param).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.userManage.success')
                });
                this.$emit('handleSearch');

            }).finally(resp => {
                this.loading = false
                this.handleClose();
            })
        },

    }
}
</script>