export const attributes = {
	
arr:[
{
	label:'BindMac-Times',
},
{
	label:'Session-Timeout',
},
{
	label:'Aruba-User-Role',
},
{
	label:'Macauth-Exptime',
},
{
	label:'Oldauth-Exptime',
},
{
	label:'Oneday-Exptime',
},
{
	label:'Idle-Timeout',
},
{
	label:'Mikrotik-Rate-Up',
},
{
	label:'Mikrotik-Rate-Down',
},
// {
// 	label:'Filter-Id',
// },
// {
// 	label:'Framed-Pool',
// },
// {
// 	label:'Aruba-User-Vlan',
// },
// {
// 	label:'User-Name',
// },
// {
// 	label:'Service-Type',
// },
// {
// 	label:'Framed-Protocol',
// },
// {
// 	label:'Framed-IP-Address',
// },
// {
// 	label:'Framed-IP-Netmask',
// },
// {
// 	label:'Framed-MTU',
// },
// {
// 	label:'Login-IP-Host',
// },
// {
// 	label:'Login-Service',
// },
// {
// 	label:'Reply-Message',
// },
// {
// 	label:'Reply-Message',
// },
// {
// 	label:'Framed-route',
// },
// {
// 	label:'State',
// },
// {
// 	label:'Class',
// },
// {
// 	label:'Vendor-Specific',
// },
// {
// 	label:'Termination-Action',
// },
// {
// 	label:'Port-Limit',
// },
// {
// 	label:'Tunnel-Type',
// },
// {
// 	label:'Tunnel-Medium-Type',
// },
// {
// 	label:'Tunnel-Client-Endpoint',
// },
// {
// 	label:'Tunnel-Server-Endpoint',
// },
// {
// 	label:'Tunnel-Password',
// },
// {
// 	label:'Message-Authenticator',
// },
// {
// 	label:'Tunnel-Assignment-ID',
// },
// {
// 	label:'Tunnel-Preference',
// },
// {
// 	label:'Acct-Interim-Interval',
// },
// {
// 	label:'Chargeable-User-Identity',
// },
// {
// 	label:'Tunnel-Client-Auth-ID',
// },
// {
// 	label:'Tunnel-Server-Auth-ID',
// },
// {
// 	label:'Framed-Interface-Id',
// },
// {
// 	label:'Framed-Ipv6-Prefix',
// },
// {
// 	label:'Framed-Ipv6-Route',
// },
// {
// 	label:'Framed-Ipv6-Pool',
// },
// {
// 	label:'Delegated-Ipv6-Prefix',
// },
// {
// 	label:'DS-Lite-Tunnel-Name',
// }
]
}
