<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.username')" 
                                        v-model="data.username"
                                        :rules="rules.username" 
                                        @change="data.username=formSpaces(data.username)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.password')"
                                        v-model="data.password"
                                        :rules="rules.password" 
                                        @change="data.password=formSpaces(data.password)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name" 
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.contact')" 
                                        v-model="data.contact"
                                        :rules="rules.contact" 
                                        @change="data.contact=formSpaces(data.contact)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        v-if="role === 0"
                                        :items="type"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.type.text')"
                                        item-text="label"
                                        item-value="value"
                                        v-model="data.type"
                                        :rules="rules.type" 
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        v-if="role === 0"
                                        :items="group"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.groupName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.groupID"
                                        :rules="rules.groupID" 
                                        @change="changeGroup"
                                        dense
                                        outlined
                                    ></v-select>
                                    <v-select
                                        v-if="data.type === 2"
                                        :items="tempshop"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.shopName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.shopID"
                                        :rules="rules.shopID" 
                                        @click="searchshop"
                                        dense
                                        outlined
                                    >
                                    <template v-slot:prepend-item>
                                        <v-text-field
                                            dense
                                            placeholder="搜索"
                                            v-model="shopname"
                                            outlined
                                        >
                                        <v-btn
                                            class="ma-2"
                                            slot="append"
                                            text
                                            icon
                                            small
                                            @click="searchshop"
                                        >
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                        </v-text-field>
                                    </template>
                                    </v-select>
                                    <v-select
                                        :items="roles"
                                        :label="$vuetify.lang.t('$vuetify.userManage.user.table.roleName')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.roleID"
                                        :rules="rules.roleID" 
                                        dense
                                        outlined
                                    ></v-select>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui';
import sha1 from 'js-sha1'
import md5 from 'js-md5'
import { returnSearch } from '../../../utils/common'

export default {

	props: {
		role: Number,
		group: Array,
	},

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.userManage.user.insertBtn'),

            dialog: false,

            loading: false,

            shop: [],
            roles: [],
            
            shopname:"",
            tempshop: [],

            type: [
            {
                    value: 1,
                    label: this.$vuetify.lang.t('$vuetify.userManage.user.table.type.group'),
                },{
                    value: 2,
                    label: this.$vuetify.lang.t('$vuetify.userManage.user.table.type.shop'),
                }
            ],

            data: {
                username: null,
                password: null,
                name: null,
                contact: null,
                type: 2,
                groupID: null,
                shopID: null,
                roleID: null,
            },

            rules: {
                username: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.username'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.username')
                ],
                password: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.password'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.password')
                ],
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.name')
                ],
                contact: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.user.rules.contact'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.contact')
                ],
                type: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.type')
                ],
                groupID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.group')
                ],
                shopID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.shop')
                ],
                roleID: [
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.user.rules.shop')
                ]
            }
            
        }
    },

    methods: {
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },
        handleOpen () {
            this.dialog = true;
            this.data.groupID = this.group[0].id === undefined ? null : this.group[0].id;
            this.changeGroup(this.data.groupID);
        },

        handleReset () {
            this.data = {
                username: null,
                password: null,
                name: null,
                contact: null,
                type: 2,
                groupID: null,
                shopID: null,
                roleID: null
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.data.password = sha1(md5(this.data.password));
                this.$http.post(api.default.rbac.accountAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false
                    this.handleClose();
                })
            }
        },

        changeGroup (value) {
            this.getShop(value);
            this.getRole(value);
        },

        getShop (value) {
            this.$http.post(api.default.rbac.shopListUrl, {
                pagenum : 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.shop = resp.data.list;
                this.data.shopID = this.shop[0].id;
            })
        },

        getRole (value) {
            this.$http.post(api.default.rbac.roleListUrl, {
                pagenum: 1,
                pagesize: 1000,
                groupID : value
            }).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.roles = resp.data.list;
                this.data.roleID = this.roles[0].id;
            })
        },

        searchshop(){
            this.tempshop = returnSearch(this.shopname,this.shop)
        },
    }
}
</script>

<style scoped>



</style>