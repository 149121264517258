<template>
	<v-img :src="image" height="100%" style="background-size: cover;background-position: top center;cursor:default;">
		<v-layout class="fill-height" align-center justify-center style="background: rgba(0, 0, 0, 0.4);height: 100%;">
			<v-flex xs12 sm8 md2>
				<material-card light color="indigo" :title="$vuetify.lang.t('$vuetify.common.login')" :text="$vuetify.lang.t('$vuetify.login.input')" class="elevation-12" style="min-width:300px">
					<v-card-text>
						<v-form>
							<v-text-field @keyup.enter="onSubmitForm" v-model="loginFormData.username" :label="$vuetify.lang.t('$vuetify.user.table.name')" id="username" type="text" color="indigo" prepend-icon="mdi-face"></v-text-field>
							<v-text-field @keyup.enter="onSubmitForm" v-model="loginFormData.password" :label="$vuetify.lang.t('$vuetify.user.table.password')" id="password" type="password" color="indigo" prepend-icon="mdi-lock"></v-text-field>
							<v-checkbox v-model="loginFormData.rememberUsername" :label="$vuetify.lang.t('$vuetify.user.table.rememberuser')"></v-checkbox>
							<!-- <checkbox v-model="form.rememberUsername">记住账号</checkbox> -->
						</v-form>
						<v-card-actions>
							<v-btn block  
                            color="indigo_lighten_1" 
                            style="color:#fff" 
                            v-on:click="onSubmitForm">
                                <span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.login')}}</span>
                            </v-btn>
						</v-card-actions>
						<!-- login-button -->
						<!-- <v-subheader style="height:2rem">
							其他登录方式
						</v-subheader>
						<v-divider></v-divider> -->
						<!-- other login type  -->
						<!-- <a href="javascript:void(0);" @click="qqLogin">
							<img style="height:30px;width:30px;margin: 10px 16px 0px;" :src="qqlogo">
						</a> -->
						<!-- qq -->
					</v-card-text>
				</material-card>
			</v-flex>
		</v-layout>
	</v-img>
</template>

<script>
	import * as api from './../../api'
	import sha1 from 'js-sha1'
	import md5 from 'js-md5'
	import { Notification  } from 'element-ui';

	export default {
		data() {
			return {
				dialog: false,
				loginFormData: {
					username: '',
					password: '',
					rememberUsername: false,
				},
				ConfirmPassword: '',
				reg_username: '',
				reg_password: '',
				image: require('./city-profile.jpg'),
				qqlogo: require('../../../public/static/img/logos/qq.png')
			}
		},
		mounted(){
			this.$nextTick(()=>{
			})
		},
		methods: {
			onRegisterForm: function(e) {
			},
			onSubmitForm(e) {
				e.preventDefault();

				if(this.loginFormData.username == ''||this.loginFormData.password == ''){
					Notification({
						type: 'error',
						message: this.$vuetify.lang.t('$vuetify.login.nullTip')
					});
				}else{
					const postData = {
						username: this.loginFormData.username,
						password: sha1(md5(this.loginFormData.password)),
					}
					if (this.loginFormData.rememberUsername) {
                        window.localStorage.setItem('rememberedUsername', this.loginFormData.username)
                        window.localStorage.setItem('rememberUsername', '1')
                    }else {
                        window.localStorage.removeItem('rememberedUsername')
                        window.localStorage.removeItem('rememberUsername')
                    }
					this.$http.post(api.default.auth.loginUrl, postData).then(resp => {
						if (!resp)
							return
						var authUser = this.loginFormData.username
						window.sessionStorage.setItem('authUser', authUser);
						window.sessionStorage.setItem('name', resp.data.name);
						window.sessionStorage.setItem('contact', resp.data.contact);
						window.sessionStorage.setItem('type', resp.data.type);
						window.sessionStorage.setItem('systemType', resp.data.systemtype);
                        window.sessionStorage.setItem('groupName', resp.data.groupname);
						this.$router.push('/dashboard');
					}).finally(resp => {
						
					})
				}
			},
			qqLogin(){
				this.$http.post("/captiveportal/api/getQqLogin").then(resp => {
					if(resp.errno !== 0)
						return false
					window.location.href=resp.data.url
				}).finally(resp => {
					
				})
			}
		},
		created() {
			let rememberedUsername = window.localStorage.getItem('rememberedUsername');
			if (rememberedUsername) {
				this.loginFormData.username = rememberedUsername;
			}

			let rememberUsername = window.localStorage.getItem('rememberUsername');
			if (rememberUsername) {
				this.loginFormData.rememberUsername = true
			}
			else {
				this.loginFormData.rememberUsername = false
			}
		}
	}
</script>

<style lang="scss">
	.button-container {
		left: 0;
		opacity: 1;
		visibility: visible;
		transition: .3s ease;
	}
	/* Footer */
	
	.footer {
		margin: 10px 0 0;
		color: #d3d3d3;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
		a {
			color: inherit;
			text-decoration: none;
			transition: .3s ease;
			&:hover {
				color: darken(#d3d3d3, 10%);
			}
		}
		.v-menu__content {
			top: 227px!important;
			left: 464px!important;
		}
	}
</style>
