<template>
	<div id="devicelist">
		<v-container fill-height fluid grid-list-xl>
			<v-layout justify-center wrap>
				<v-flex md12>
						<v-toolbar class="mb-0" :flat="true" dense>
						    <v-toolbar-title class="font-weight-regular"><a @click="handleReturn">{{$vuetify.lang.t('$vuetify.gateway.equipment2')}}</a>:</v-toolbar-title>
    						</v-toolbar>
    						<v-divider class="ml-0 mr-0" ></v-divider>
							<v-table @selectData="handleSelectKey" :showSelect="showSelect" :titleTable="titleTableReal" :headers="headersReal" :tableData='tableDataReal'>
					</v-table>
					<v-table
					class="mt-8" @selectData="handleSelectKey" :showSelect="showSelect" :titleTable="titleTableVirtual" :headers="headersVirtual" :tableData='tableDataVirtual'>
					</v-table>
					<v-table 
					class="mt-8" @selectData="handleSelectKey" :showSelect="showSelect" :titleTable="titleTableSniffing" :headers="headersSniffing" :tableData='tableDataSniffing'>
					</v-table>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import vTable from './components/Table.vue'
	export default {
		data(){
			return{
				showSelect:false,
				titleTableReal: '真实身份列表',
				titleTableVirtual:'虚拟身份列表',
				titleTableSniffing:'嗅探列表',
				headersSniffing:[
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.mac'),
						align: 'left',
						value: 'mac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.apmac'),
						align: 'left',
						value: 'apmac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.upTime'),
						align: 'left',
						value: 'time'
					},
				],
				headersVirtual:[
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.role2'),
						align: 'left',
						value: 'role'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.role2Type'),
						align: 'left',
						value: 'type'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.apmac'),
						align: 'left',
						value: 'apmac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.upTime'),
						align: 'left',
						value: 'time'
					},
				],
				headersReal:[
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.role1'),
						align: 'left',
						value: 'role'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.mac'),
						align: 'left',
						value: 'mac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.apmac'),
						align: 'left',
						value: 'apmac'
					},
					{
						text: this.$vuetify.lang.t('$vuetify.device.table.upTime'),
						align: 'left',
						value: 'time'
					},
				],
				tableDataReal:[
					{
						role:'xxx',
						mac:'112233',
						apmac:'44444',
						time:'2019-01-01 00:00:00'
					},
				],
				tableDataSniffing:[
					{
						mac:'xxx',
						apmac:'44444',
						time:'2019-01-01 00:00:00'
					},
				],
				tableDataVirtual:[
					{
						role:'xxx',
						apmac:'44444',
						type:'A类型',
						time:'2019-01-01 00:00:00'
					},
				]
			}
		},
		props: [],
		components:{
			vTable
		},
		mounted(){
		},
		methods: {
			handleSelectKey(data){
				
			},
			handleReturn(data){
				this.$router.push({path:'/net_safety/device'})
			},
		},
	}
</script>
</script>
