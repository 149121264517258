<template>
    <div>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{ title }}</span>
                        </v-toolbar-title>
                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                        <v-btn color="primary ml-3" dark @click="handleAdd">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.radacctRule.btn.addBtn') }}
                            </span>
                        </v-btn>
                    </v-toolbar>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
    },

    methods: {
        handleAdd(){
            this.$emit('handleAdd');
        }
    },
}
</script>